@import "~bootstrap/scss/bootstrap.scss";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";
@import "~lightgallery.js/dist/css/lightgallery.css";
@import "~react-modal-video/scss/modal-video.scss";
@import "../css/icons.min.css";
@import "variables";
@import "helper";
@import "common";
@import "preloader";
@import "slider";
@import "support";
@import "section-title";
@import "product";
@import "blog";
@import "footer";
@import "banner";
@import "suscribe";
@import "about";
@import "image-slider";
@import "testimonial";
@import "brand-logo";
@import "timers";
@import "breadcrumb";
@import "sidebar";
@import "shop";
@import "product-details";
@import "cart";
@import "compare";
@import "checkout";
@import "contact";
@import "blog-details";
@import "login-register";
@import "my-account";
@import "404";
@import "video-popup";
@import "header";
@import "styleDefault";
@import "content";
@import "home";
@import "bbs";
@import "csCenter";
@import "gqMarket";
@import "components";
@import "recent-order";
@import "inquriesWritebox";

:root {
  --theme-color: red;
}
.css-m2td1u {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000004a;
  z-index: 99;
  div {
    left: auto !important;
    top: auto !important;
  }
}
.css-16vsn7r {
  left: auto !important;
  top: auto !important;
}
.star-ratings {
  top: -3px;
}
.css-8z0ti4 {
  left: auto !important;
  top: auto !important;
}
.error-msg {
  display: block;
  position: relative;
  color: #e64646 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  box-sizing: border-box;
  position: relative;
  margin: 5px 0 0;
  padding: 0 0 0 20px;
  &:before {
    content: "\eca1";
    display: block;
    position: absolute;
    left: 0;
    top: 0px;
    width: 17px;
    height: 17px;
    font-family: "remixicon" !important;
    font-style: normal;
    font-size: 17px;
  }
}
.test {
  border: 3px solid #000;
}
.test1 {
  border: 3px solid #f00;
}
.test2 {
  border: 3px solid #0f0;
}
.test3 {
  border: 3px solid #00f;
}

/* ==============================
* React-toast
================================= */
.react-toast-notifications__container {
  left: 0;
  bottom: 0;
  .react-toast-notifications__toast {
    .react-toast-notifications__toast__icon-wrapper {
      svg {
        position: relative;
        top: 4px;
      }
    }
    .react-toast-notifications__toast__content {
      padding: 11px 12px 8px;
    }
    .react-toast-notifications__toast__dismiss-button {
      svg {
        position: relative;
        top: 4px;
      }
    }
  }
}

/* ==============================
* No-data
================================= */
.noDataWrap {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100%;
  .noData {
    .ico {
      line-height: 1;
    }
    .ico i {
      font-size: 5em;
    }
    .msg {
      display: block;
      line-height: 1.2;
      margin: 10px 0 0;
      font-size: 1.294em;
      font-weight: 500;
      a {
        display: inline-block;
        text-decoration: underline;
        margin: 5px 0 0;
        transition: all 0.3s ease;
        &:focus-visible,
        &:hover {
          color: var(--color-1);
        }
      }
    }
  }
  @media all and (max-width: 799px) {
    .noData {
      .ico i {
        font-size: 6em;
      }
    }
  }
  @media all and (max-width: 499px) {
    .noData {
      .ico i {
        font-size: 4em;
      }
      .msg {
        font-size: 1em;
        font-weight: 400;
      }
    }
  }
}
/* ==============================
* Loading
================================= */
.loadingWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
}

/* ==============================
* Withdraw
================================= */
.sweet-alert {
  * {
    font-family: "Noto Sans KR", "Noto Sans SC", "맑은고딕", "돋움", arial, Dotum, Verdana, helvetica, clean, sans-serif !important;
  }
  div {
    word-wrap: break-word !important;
    word-break: keep-all;
    white-space: normal;
    font-weight: 400 !important;
  }
  h2 {
    line-height: 1.2;
    color: #111;
    margin: 0;
  }
  .btn {
    font-size: 0.941em;
  }
  .btn-light {
    background: #ddd;
  }
  .btn-danger {
    color: #fff !important;
  }
}

/* ==============================
* Sns Share style
================================= */
ul.snsShare {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  li {
    margin: 0 6px 0 0;
    &:last-child {
      margin: 0;
    }
    button {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1px solid #000;
      color: #000;
      background: transparent;
      opacity: 0.5;
      transition: all 0.3s ease;
      .ico {
      }
      .txt {
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        top: -9999em;
        left: -9999em;
        text-indent: -9999em;
        font-size: 0;
        line-height: 0;
        opacity: 0;
      }
      &:focus-visible,
      &:hover {
        opacity: 1;
        background: var(--color-1);
        border-color: var(--color-1);
        color: #fff;
      }
    }
  }
}

/* ==============================
* Dev state
================================= */
.guideTestPkg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  display: none;
  > * {
    padding: 10px;
    height: 100%;
  }
  * {
    font-size: 13px;
  }
  .guideLanguageStatus {
  }
  .guideBtnTest {
    a,
    button {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 13px;
      line-height: 1.2;
      &:focus-visible,
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .guideBackEnd {
    span {
      display: inline-block;
      padding: 3px 5px 5px;
      line-height: 1;
      background: var(--color-o1);
      color: #fff;
      border-radius: 5px;
    }
  }
  .guideLogStatus {
  }
}

/* ==============================
* Datepiker
================================= */
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    box-sizing: border-box;
    position: relative;
    &:before {
      content: "\eb27";
      display: block;
      position: absolute;
      width: auto;
      height: auto;
      line-height: 1;
      left: 10px;
      top: 12px;
      font-family: "remixicon" !important;
      font-size: 20px;
    }
    input {
      padding-left: 35px;
      padding-right: 40px;
    }
    .react-datepicker__close-icon {
      display: block;
      padding: 0 10px;
    }
  }
}
.react-datepicker__tab-loop {
  .react-datepicker {
    box-shadow: var(--shadow1);
  }
  .react-datepicker__navigation {
    top: 10px;
    &.react-datepicker__navigation--previous {
      left: 0;
    }
    &.react-datepicker__navigation--next {
      right: 0;
    }
  }
  .react-datepicker__header__dropdown {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 0 40px;
    .react-datepicker__month-dropdown-container,
    .react-datepicker__year-dropdown-container {
      width: 50%;
      padding: 0 2px;
      box-sizing: border-box;
      margin: 0;
      select {
        min-width: 0;
        max-width: none;
        width: 100% !important;
        height: 30px !important;
        margin: 0 !important;
      }
    }
    .react-datepicker__month-dropdown-container {
      order: 2;
    }
    .react-datepicker__year-dropdown-container {
      order: 1;
    }
  }
  .react-datepicker__current-month {
    display: none;
  }
}

/* ==============================
* HTML5 ( Dialog / Div ) 공용
================================= */
.dialog,
.react-modal {
  .popupWrap {
    textarea,
    input[type="text"] {
      display: block;
      box-sizing: border-box;
      border: 1px solid #ccc;
      resize: none;
    }
    textarea {
      width: 100%;
      height: 150px;
    }
    input[type="text"] {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
    }
  }
  .fileAttach {
  }
  .filePreview {
    margin: 20px 0 0;
    ul {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      margin: -4px;
      border-bottom: 1px solid var(--color-db);
      li {
        padding: 4px;
        width: 16.6666%;
        &:last-child {
          margin: 0;
        }
        .boxImg {
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #ddd;
          .imgContainer {
            display: block;
            height: 0;
            padding: 0 0 100%;
            box-sizing: border-box;
            position: relative;
            background: #eee;
            overflow: hidden;
            img {
              display: block;
              position: absolute;
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              transition: all 0.3s ease;
            }
            i {
              display: block;
              position: absolute;
              font-size: 2em;
              color: var(--color-1);
              line-height: 1;
              width: 34px;
              height: 34px;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
        }
        .boxTxt {
          box-sizing: border-box;
          line-height: 1.2;
          font-size: 14px;
          span {
            display: block;
            width: auto;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-wrap: normal;
            padding: 5px 0;
            text-align: center;
          }
        }
      }
    }
  }
  .commonSummary {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin: 0 0 20px;
    dl {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      box-sizing: border-box;
      position: relative;
      padding: 0 15px 0 0;
      margin: 0 10px 0 0;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 13px;
        right: 0;
        top: 2px;
        background: #aaa;
        transform: rotate(30deg);
      }
      &:last-child {
        padding: 0;
        margin: 0;
        &:after {
          display: none;
        }
      }
      dt,
      dd {
        box-sizing: border-box;
        position: relative;
        line-height: 1;
      }
      dt {
        padding: 0 10px 0 0;
        margin: 0 5px 0 0;
        &:after {
          content: ":";
          display: block;
          position: absolute;
          width: auto;
          height: auto;
          line-height: 1;
          font-size: 13px;
          right: 0;
          top: 0px;
          color: #aaa;
        }
      }
      dd {
      }
    }
  }
  .commonAttach {
    .imgSelector {
      .selectedImg {
      }
      .customInp1 {
        flex: 1;
        min-width: 0;
        height: 33px;
      }
    }
  }
}

/* ==============================
* HTML5 Dialog ( Layer Popup ) div
================================= */
div#dialogCustom {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border: 0;
  box-sizing: border-box;
  padding: 20px;
  max-width: 100%;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  &[open] {
    animation: fadeInOut 0.5s ease;
    &::backdrop {
      background: rgba(0, 0, 0, 0.5);
      animation: fadeInOut 0.5s ease;
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .popupWrap {
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    position: absolute;
    margin: auto;
    z-index: 10000;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 50px;
      bottom: 0;
      filter: blur(10px);
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
    &:before {
      left: 10px;
      transform: rotate(-2deg) skew(-15deg);
    }
    &:after {
      right: 10px;
      transform: rotate(2deg) skew(15deg);
    }
    .popWrap {
      overflow: hidden;
      border-radius: 10px;
      background: #fff;
      height: 100%;
      .popHead {
        position: relative;
        height: 50px;
        z-index: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding: 0 50px 0 20px;
        border-bottom: 1px solid #ddd;
        .popHeadTit {
          font-size: 1.118em;
          font-weight: 500;
          letter-spacing: -0.05em;
        }
      }
      .popCont {
        height: calc(100% - 50px);
        box-sizing: border-box;
        padding: 20px;
        .popContScrollBox {
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 100%;
          height: 100%;
          box-sizing: border-box;
          position: relative;
          form {
            padding: 10px 0;
          }
        }
      }
      .popFoot {
        position: absolute;
        height: 50px;
        right: 0;
        top: 0;
        z-index: 1;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        button {
          width: 50px;
          height: 50px;
          border: 0;
          background: transparent;
          &:before,
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 2px;
            background: #aaa;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: all 0.3s ease;
          }
          &.popBtnClose {
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
          }
          &:focus-visible:before,
          &:hover:before,
          &:focus-visible:after,
          &:hover:after {
            background: var(--color-1);
          }
        }
      }
    }
  }
  .popupOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  @media all and (max-width: 699px) {
    .popupWrap {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      &:before,
      &:after {
        background: rgba(0, 0, 0, 0.75);
      }
    }
  }
}

/* ==============================
* HTML5 Dialog ( Layer Popup ) Common
================================= */
dialog#dialogCustom {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border: 0;
  box-sizing: border-box;
  padding: 20px;
  max-width: 100%;
  max-height: 100%;
  background: transparent;
  &[open] {
    animation: fadeInOut 0.5s ease;
    &::backdrop {
      background: rgba(0, 0, 0, 0.5);
      animation: fadeInOut 0.5s ease;
    }
  }
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .popupWrap {
    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 50%;
      height: 50px;
      bottom: 0;
      filter: blur(10px);
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
    &:before {
      left: 10px;
      transform: rotate(-2deg) skew(-15deg);
    }
    &:after {
      right: 10px;
      transform: rotate(2deg) skew(15deg);
    }
    .popWrap {
      overflow: hidden;
      border-radius: 10px;
      background: #fff;
      height: 100%;
      .popHead {
        position: relative;
        height: 50px;
        z-index: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding: 0 50px 0 20px;
        border-bottom: 1px solid #ddd;
        .popHeadTit {
          font-size: 1.118em;
          font-weight: 500;
          letter-spacing: -0.05em;
        }
      }
      .popCont {
        height: calc(100% - 50px);
        box-sizing: border-box;
        padding: 20px;
        .popContScrollBox {
          overflow-x: hidden;
          overflow-y: auto;
          max-height: 100%;
          height: 100%;
          box-sizing: border-box;
          position: relative;
          form {
            padding: 10px 0;
          }
        }
      }
      .popFoot {
        position: absolute;
        height: 50px;
        right: 0;
        top: 0;
        z-index: 1;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        button {
          width: 50px;
          height: 50px;
          border: 0;
          background: transparent;
          &:before,
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 2px;
            background: #aaa;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: all 0.3s ease;
          }
          &.popBtnClose {
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
          }
          &:focus-visible:before,
          &:hover:before,
          &:focus-visible:after,
          &:hover:after {
            background: var(--color-1);
          }
        }
      }
    }
  }
  .popupOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  @media all and (max-width: 699px) {
    .popupWrap {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      &:before,
      &:after {
        background: rgba(0, 0, 0, 0.75);
      }
    }
  }
}

/* ==============================
* HTML5 Dialog ( Layer Popup ) Chatting 전용
================================= */
dialog#dialogChat {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  border: 0;
  background: transparent;
  .coreWr {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 0;
    background: transparent;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: right;
    padding: 20px;
    .core {
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      position: relative;
      max-width: 500px;
      width: 100%;
      max-height: 800px;
      height: 100%;
      background: #fff;
      z-index: 1;
      border-radius: 10px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
      overflow: hidden;
      .chatHead {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        box-sizing: border-box;
        padding: 0 15px;
        height: 50px;
        position: relative;
        z-index: 0;
        box-sizing: border-box;
        border-bottom: 1px solid #ddd;
        line-height: 1;
        span {
          line-height: 1;
          color: var(--color-1);
        }
      }
      .chatBody {
        flex: 1;
        min-height: 0;
        box-sizing: border-box;
      }
      .chatFoot {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1;
        button {
          display: block;
          width: 30px;
          height: 30px;
          border: 0;
          position: relative;
          box-sizing: border-box;
          background: transparent;
          border-radius: 4px;
          transition: all 0.3s ease;
          &:before,
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 15px;
            height: 2px;
            background: var(--color-1);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: all 0.3s ease;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
          &:hover,
          &:focus-visible {
            background: #eaeaea;
            &:before {
              transform: rotate(135deg);
            }
            &:after {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
  .backDrop {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
  @media all and (max-width: 699px) {
    .coreWr {
      .core {
        max-width: none;
        max-height: none;
      }
    }
  }
  @media all and (max-width: 499px) {
    .coreWr {
      padding: 0;
      .core {
        border-radius: 0;
      }
    }
  }
}
body.dialogChatCast {
  overflow: hidden;
  #container-wrap {
    z-index: 99999;
  }
}

/* ==============================
* Cookie Consent
================================= */
.CookieConsent {
  flex-flow: row;
  background: rgba(0, 0, 0, 0.5) !important;
  align-items: center !important;
  align-content: center !important;
  box-sizing: border-box;
  padding: 15px;
  > div:nth-child(1) {
    flex: 1 !important;
    min-width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1.2;
    font-size: 1em !important;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
  }
  > div:nth-child(2) {
    padding: 0 !important;
    margin: 0 !important;
    button {
      background: var(--color-1) !important;
      color: #fff !important;
      font-size: 15px !important;
      padding: 10px 30px !important;
      margin: 0 !important;
      line-height: 1;
    }
  }
  @media all and (max-width: 799px) {
    padding: 10px;
    > div:nth-child(1) {
      font-size: 15px !important;
    }
  }
  @media all and (max-width: 499px) {
    > div:nth-child(1) {
      flex: none !important;
      width: 100%;
    }
    > div:nth-child(2) {
      width: 100%;
      margin: 10px 0 0 !important;
      button {
        display: block;
        width: 100%;
        font-size: 14px !important;
        padding: 10px 0 !important;
      }
    }
  }
}

/* ==============================
* Jodit
================================= */
.jodit-react-container {
  .jodit-container {
    .jodit-wysiwyg {
      img,
      video {
        display: block;
        max-width: 100%;
      }
    }
    .jodit-add-new-line {
      display: none;
    }
  }
}
.jodit-popup-container {
  .jodit-popup {
    .jodit-popup__content {
      .jodit-tabs {
        .jodit-tabs__buttons {
          .jodit-ui-button {
            .jodit-ui-button__text {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

/* ==============================
*
================================= */
.font-size-sm {
  font-size: 0.875rem !important;
}
.font-size-md {
  font-size: 1rem !important;
}
.font-size-lg {
  font-size: 1.25rem !important;
}
.font-size-xl {
  font-size: 1.5rem !important;
}
.w-8 {
  width: 8% !important;
}
.w-10 {
  width: 10% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-100-max-100 {
  width: 100% !important;
  max-width: 100% !important;
}
.w-590px {
  width: 590px !important;
}
.w-100px {
  width: 100px !important;
}
.fs-5 {
  font-size: 1.5rem !important;
}
.fs-4 {
  font-size: 1.25rem !important;
}
.fs-3 {
  font-size: 1.125rem !important;
}
.fs-2 {
  font-size: 1rem !important;
}
.fs-1 {
  font-size: 0.8rem !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.border-radius-5px {
  border-radius: 5px !important;
}
.no-border {
  border: none !important;
}
.no-border-left {
  border-left: none !important;
}
.tab-active {
  font-weight: bold;
}
.tab-inactive {
  font-weight: normal;
}
.flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.flex-direction-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
/* DetailInquiryModalView.css */
.loading-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 1050; /* Ensure it is above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray-overlay {
  background-color: rgba(128, 128, 128, 0.5);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.centeredImageContainer {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.objectFitCover {
  object-fit: cover;
}
