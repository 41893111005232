/*------ 25. My account Page  ------*/
.myArea {
	input:not([type='file']) {border:1px solid #ccc;height:40px;line-height:40px;padding:0 10px;box-sizing:border-box;}
	.myPkg {
		display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;
		> * {box-sizing:border-box;position:relative;}
		.mySumm {
			width:100%;border-radius:10px;background:#f0f0f0;margin:0 0 15px;padding:20px;overflow:hidden;/* min-height:200px */
			.mName,
			.mCoupon,
			.mCash,
			.mGq {
				display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;box-sizing:border-box;position:relative;
				&:after {content:"";display:block;position:absolute;width:1px;height:100%;background:#dbdbdb;right:0;top:0;}
				&:last-child:after {display:none}
			}
			.mName {
				display:flex;flex-flow:row;flex-wrap:wrap;padding:0;align-items:center;align-content:center;justify-content:left;color:#000;
				&:after {display:none;}
				.pTit1 {
					font-size:1.412em;line-height:1.2;
					.myStoreLink {
						color:var(--color-1);text-decoration:underline;
						em {font-style:normal;font-weight:700;}
					}
				}
				.pStar1 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;border:1px solid #00a0e9;color:#00a0e9;line-height:1;border-radius:3px;padding:0 7px 2px;margin:2px 0 0 5px;background:#fff;
					.pTxt1 {font-size:15px;}
					.pIco1 {font-size:13px;}
				}
			}
			.mCoupon {padding:9px 25px 16px;}
			.mCash {padding:9px 25px 16px;}
			.mGq {padding:9px 25px 16px;}
			.pTit2 {font-size:15px;color:#111;line-height:1;}
			.pNum1 {
				color:#111;line-height:1;margin:10px 0 auto 0;
				.num {font-size:1.353em;font-weight:700;}
				.unit {font-size:13px;position:relative;top:-1px;margin:0 0 0 5px;}
			}
			.pUtil1 {
				display:flex;flex-flow:row;flex-wrap:wrap;height:34px;align-items:center;align-content:center;
				.flexWrap {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;margin:0 0 5px;width:100%;
					&:last-child {margin:0;}
					.flexL,
					.flexR {font-size:12px;color:#777;font-weight:400;line-height:1.2;}
					.flexL {
						span {
							display:block;margin:0 0 5px;
							&:last-child {margin:0;}
						}
					}
					.flexR {
						em {font-style:normal;font-weight:700;color:var(--color-1);margin:0 2px 0 0;}
						a,
						button {
							font-size:12px;border-radius:3px;line-height:1;padding:5px 6px 6px;min-width:0;max-width:none;height:auto;
							&:before {display:none}
						}
					}
				}
			}
			.CUSTOMER {
				/* display:grid;grid-template-columns:3fr 1fr 1fr 1fr;height:100%;*/
				display:block;
			}
			.ENTERPRISE {
				position:static;
				.mName {
					/* position:absolute;left:20px;top:50%;transform:translateY(-50%);z-index:2;padding:0;*/
					position:relative;z-index:2;padding:0;
					&:after {display:none}
				}
				.mOrder,
				.mGQ,
				.mRepl {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;box-sizing:border-box;position:relative;padding:10px 20px;text-align:center;
					&:after {content:"";display:block;position:absolute;width:1px;height:100%;background:#dbdbdb;right:0;top:0;}
					&:last-child:after {display:none}
				}
				.mInner {
					.pNum1 {
						display:inline-block;margin:5px 0 0;padding:3px 10px 7px;border-radius:5px;transition:all .3s ease;
						* {transition:all .3s ease;}
						.num {color:var(--color-1);text-decoration:underline;}
						&:focus-visible,
						&:hover {
							background:var(--color-1);color:#fff;
							* {color:#fff;}
						}
					}
				}
				.swiper-container {
					position:static;
					&:before,
					&:after {content:"";display:block;position:absolute;height:100%;top:0;z-index:1;}
					&:before {width:33.3333%;left:0;background:linear-gradient( to right, #f0f0f0 33.3333%, rgba(240, 240, 240, 0) 100%)}
					&:after {width:50px;right:0;background:linear-gradient( to left, #f0f0f0 0, rgba(240, 240, 240, 0) 100%)}
					.swiper-wrapper {
						z-index:0;
						.swiper-slide {
							display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;padding:0 30px 0 50%;
							> * {flex:1;min-height:143px;}
						}
					}
					.swiper-button-prev,
					.swiper-button-next {
						width:30px;height:30px;border:0;background:transparent;z-index:3;top:50%;transform:translateY(-50%);margin:0;z-index:2;display:none;
						&:after {content:"";display:block;position:absolute;width:14px;height:14px;box-sizing:border-box;border:solid;border-width:0 0 2px 2px;border-color:#aaa;background:transparent;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						&.swiper-button-prev {
							&:after {transform:rotate(45deg);margin:auto auto auto 12px}
						}
						&.swiper-button-next {
							&:after {transform:rotate(225deg);margin:auto 12px auto auto}
						}
						&.swiper-button-disabled {opacity:1;}
						&:focus-visible,
						&:hover {
							&:after {border-color:var(--color-1);}
						}
					}
				}
				.swiper-pagination-wrap {
					position:absolute;height:0;z-index:1;left:0;top:0;width:100%;display:none;
					ul {
						text-align:left;transition:none;transform:none;display:flex;flex-flow:row;flex-wrap:wrap;left:20px;top:15px;
						li {
							width:auto;height:auto;opacity:1;margin:0 10px 0 0;background:transparent;border-radius:0;
							&:last-child {margin:0;}
							button {
								display:block;line-height:1;border:0;background:transparent;color:#777;padding:7px 0;box-sizing:border-box;position:relative;transition:all .3s ease;
								span {display:block;line-height:1;font-size:.941em;font-weight:500;}
								&:focus-visible,
								&:hover {color:var(--color-1);}
								&:after {content:"";display:block;position:absolute;width:0;height:2px;left:50%;bottom:0;background:var(--color-1);transition:all .3s ease;}
							}
							&.swiper-pagination-bullet-active {
								button {
									color:var(--color-1);
									&:after {left:0;width:100%}
								}
							}
						}
					}
				}
				.swiper-auto-controller {
					position:absolute;left:10px;bottom:10px;z-index:1;display:flex;flex-flow:row;flex-wrap:wrap;display:none;
					button {
						display:none;width:30px;height:30px;border:0;box-sizing:border-box;position:relative;background:transparent;
						&.on {display:block;}
						&:after {content:"";display:block;position:absolute;border:solid;box-sizing:border-box;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						&.stop:after {width:8px;height:12px;border-width:0 2px;border-color:#aaa}
						&.stop:focus-visible,
						&.stop:hover {
							&:after {border-color:var(--color-1);}
						}
						&.play:after {width:0;height:0;border-width:6px 0 6px 7px;border-color:transparent transparent transparent #aaa;}
						&.play:focus-visible,
						&.play:hover {
							&:after {border-color:transparent transparent transparent var(--color-1);}
						}
					}
				}
			}
		}
		.myMenu {
			width:210px;box-sizing:border-box;padding:20px 0;position:sticky;top:90px;height:calc( 100vh - 90px );
			.inner {
				display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;box-sizing:border-box;border:1px solid #dbdbdb;border-radius:10px;height:100%;overflow:hidden;
				.mmUnitWrap {
					flex:1;box-sizing:border-box;overflow:hidden;min-width:0;
					.mmUnit {
						padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #dbdbdb;
						&:last-child {padding:0;margin:0;border-bottom:0;}
					}
					.mmTit1 {font-size:1.059em;color:#111;line-height:1.2;font-weight:500;margin:0 0 15px;border:0;background:transparent;box-sizing:border-box;position:relative;transition:all .3s ease;}
					button.mmTit1,
					a.mmTit1 {
						display:block;width:100%;
						&.later {opacity:.5;}
						&.single {margin:0;}
						&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:var(--color-1);transform:rotate(-45deg);right:10px;top:6px;opacity:0;transition:all .3s ease;}
						&.on {
							color:var(--color-1);
							&:after {right:5px;opacity:1;}
						}
						&:not(.later):focus-visible,
						&:not(.later):hover {color:var(--color-1);}
					}
					.mmList1 {
						li {
							display:block;margin:0 0 12px;box-sizing:border-box;position:relative;
							&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:var(--color-1);transform:rotate(-45deg);right:15px;top:2px;opacity:0;transition:all .3s ease;}
							&:last-child {margin:0;}
							> a,
							> button {display:block;font-size:13px;color:#555;line-height:1.3;font-weight:500;border:0;background:transparent;word-wrap:break-word;word-break:keep-all;white-space:normal;text-align:left;transition:all .3s ease;}
							&.on > *,
							&.later > * {}
							&:not(.on):not(.later) > *:focus-visible,
							&:not(.on):not(.later) > *:hover {color:#000;}
							&.on {
								padding:0 15px 0 0;
								&:after {right:10px;opacity:1;}
								a {color:var(--color-1);}
							}
							&.later {opacity:.5;}
						}
					}
					.tabPkg {
						display:flex;flex-flow:column;flex-wrap:wrap;height:100%;
						.tabNavi {
							position:relative;width:100%;flex-flow:row;overflow:hidden;box-sizing:border-box;
							> ul {
								display:flex;flex-flow:row;flex-wrap:wrap;
								.isActive {background:#fff;color:var(--color-1);border:0;transition:all .3s ease;}
								> li {
									flex:1;background:#eee;min-width:0;
									> * {
										display:block;width:100%;padding:7px 20px 9px;border-radius:0;line-height:1;text-align:center;border:0;transition:all .3s ease;
										span {display:block;font-size:.882em;}
									}
									/* &:last-child {display:none;} */
								}
							}
						}
						.tabBody {
							flex:1;min-height:0;padding:15px 0 15px 15px;
							> .Buyer,
							> .Seller {
								overflow-x:hidden;overflow-y:auto;height:100%;
								&::-webkit-scrollbar {width:12px}
								&::-webkit-scrollbar-thumb {background:#ccc;border-left:5px solid #fff;border-right:5px solid #fff}
								&::-webkit-scrollbar-track {background:#eee;border-left:5px solid #fff;border-right:5px solid #fff}
								&::-webkit-scrollbar-button {height:0}
								&:hover::-webkit-scrollbar-thumb {background:var(--color-1);}
							}
						}
						.tabCommon {
							ul {
								li {
									display:block;box-sizing:border-box;border-top:1px solid #dbdbdb;
									> * {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;color:#111;font-weight:500;line-height:1;padding:12px 20px 14px;width:100%;border:0;background:transparent;transition:all .3s ease;
										.ico {font-size:22px;}
										.txt {
											font-size:13px;margin:0 0 0 5px;position:relative;padding:5px 0;
											&:after {content:"";display:block;position:absolute;width:0;height:2px;left:0;bottom:-2px;background:var(--color-1);transition:all .3s ease;}
										}
										&:focus-visible,
										&:hover {
											color:var(--color-1);
											.txt:after {width:100%}
										}
									}
									&.later {opacity:.5;}
								}
							}
						}
					}
				}
			}
		}
		.myCsContents {
			width:100% !important;padding:0 !important;
		}
		.myCheckout {
			flex:1;min-width:0;padding:20px 0 0 50px;
		}
		.myContents {
			flex:1;min-width:0;padding:20px 0 0 50px;
			.reqGuide {
				margin:0 0 50px;
				> * {display:block;line-height:1.2;}
				dt {color:#2f60fb;font-size:1.176em;font-weight:500;margin:0 0 16px;}
				dd {color:#010101;font-size:.882em;}
			}
			.req {
				display:inline-block;width:5px;height:10px;font-size:0 !important;box-sizing:border-box;position:relative;vertical-align:middle;line-height:1;font-style:normal;margin:0 3px;
				&:after {content:"*";display:block;position:absolute;width:auto;height:auto;color:#2f60fb;font-size:17px;left:0;top:-1px;font-weight:400;}
			}
			.myContTit {
				display:flex;flex-flow:row;flex-wrap:wrap;width:100%;line-height:1.2;font-weight:700;color:#000;border-bottom:2px solid var(--color-1);padding:0 0 34px;margin:0 0 37px;position:relative;
				h3 {display:flex;flex-flow:row;flex-wrap:wrap;}
				span.dir {
					display:inline-block;line-height:1.2;padding:0 20px 0 0;margin:0 15px 0 0;box-sizing:border-box;position:relative;font-size:1.412em;
					&:after {content:"";display:block;position:absolute;width:15px;height:15px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(-45deg);right:0;top:7px;}
					&:last-child {
						padding:0;margin:0;
						&:after {display:none;}
					}
					&.noArrow {
						padding:0;
						&:after {display:none;}
					}
					&.count1 {
						color:var(--color-1);
					}
				}
				&.nomargin {margin:0}
				&.noBorder1 {border-bottom:0;padding:0;margin:0 0 20px;}
				&.noBorder2 {border-bottom:0;padding:0;margin:0 0 50px;}
				&.cartTit {
					.cartTitWrap {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;width:100%;
						.cartTitTxt {}
						.cartTab {
							ul {
								display:flex;flex-flow:row;flex-wrap:wrap;
								li {
									box-sizing:border-box;position:relative;padding:0 0 0 10px;margin:0 0 0 10px;
									&:before {content:"";display:block;position:absolute;width:1px;height:15px;background:#aaa;left:0;top:50%;transform:translateY(-50%);}
									&:first-child {
										&:before {display:none;}
									}
									> * {
										display:block;border:0;background:transparent;box-sizing:border-box;position:relative;padding:5px 10px;border-radius:4px;font-size:1.176em;
										span {transition:all .3s ease;}
									}
									&.on {
										> * {
											background:var(--color-1);color:#fff;font-weight:500;
											span {color:#fff !important;}
										}
									}
									&:hover,
									&:focus-visible {
										span {color:var(--color-1);}
									}
								}
							}
						}
					}
				}
			}
			.myContTools {
				display:flex;flex-flow:row;flex-wrap:wrap;padding:0 0 20px;
				&.alignRight {justify-content:right;}
				.tools {
					display:flex;flex-flow:row;flex-wrap:wrap;justify-content:right;
					> * {
						margin:0 10px 0 0;
						&:last-child {margin:0;}
					}
				}
			}
			.myContBtns {
				position:absolute;right:0;top:-5px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				> * {
					height:43px;margin:0 10px 0 0;
					&:last-child {margin:0;}
					&:before {height:43px;}
				}
				.busiName {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;padding:0 10px 0 0;margin:0 10px 0 0;
						&:after {content:"";display:block;position:absolute;width:2px;height:15px;transform:rotate(10deg);background:#ddd;right:0;top:3px;}
						&:last-child {
							padding:0;margin:0;
							&:after {display:none;}
						}
						dt,
						dd {box-sizing:border-box;position:relative;line-height:1.2;}
						dt {
							font-weight:400;padding:0 7px 0 0;margin:0 5px 0 0;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:0px;}
						}
						dd {font-weight:500;}
						&.storeApproval {
							dt {display:block;position:absolute;width:0;height:0;overflow:hidden;top:-9999em;left:-9999em;text-indent:-9999em;font-size:0;line-height:0;opacity:0;}
							dd {
								.cw-nBtn2 {height:43px;min-width:0;padding:0 10px;}
							}
						}
					}
				}
			}
			.myContBody {
				padding:0 0 20px;
				.typeUl1 {
					box-sizing:border-box;border-bottom:1px solid #e0e0e0;
					> li {display:block;box-sizing:border-box;border-top:1px solid #e0e0e0;padding:15px 0;min-height:75px;}
				}
				.typeUl2 {
					box-sizing:border-box;border-top:2px solid #dbdbdb;position:relative;display:flex;flex-flow:row;flex-wrap:wrap;
					> li {
						box-sizing:border-box;border-bottom:1px solid #ccc;min-height:70px;
					}
				}
				.typeUl3 {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:-10px;
					> li {padding:10px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center}
				}
				.typeUl4 {
					display:flex;flex-flow:row;flex-wrap:wrap;border:solid;border-width:1px 0;border-color:var(--color-1);
					> li {
						display:block;box-sizing:border-box;border-bottom:1px solid #888;min-height:61px;
						&:last-child {border-bottom:0;}
					}
				}
				.typeDl1 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;min-height:44px;
					* {font-size:15px;line-height:1.2;}
					> dt,
					> dd {}
					> dt {
						font-weight:500;width:170px;color:#010101;padding:0 15px 0 0;
						> *:first-child {word-wrap:break-word;word-break:keep-all;white-space:normal}
						.req {margin:-4px 4px 0;}
					}
					> dd {
						flex:1;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;
						input:not([type='file']),
						select {
							width:100%;max-width:480px;height:44px;margin:7px 0 0;font-size:.941em;transition:all .3s ease;
							&:first-child {margin:0;}
							&:focus-visible,
							&:hover {border-color:var(--color-b1);}
							&::placeholder {font-size:14px;}
							&.w134px {width:134px;}
						}
						.btn1 {
							width:134px;height:44px;box-sizing:border-box;border:1px solid #bcbcbc;background:#f0f0f0;color:#000;padding:0 5px;line-height:1.2;margin:0 0 0 7px;border-radius:4px;transition:all .3s ease;
							span {font-size:12px;}
							&:focus-visible,
							&:hover {border-color:var(--color-b1);background:var(--color-b1);color:#fff;}
						}
						.fileWrap {height:44px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
						.error-msg {width:100%;}
						&.hasAddr {
							.addrZip {width:134px;}
							.addrSumm {flex:1;margin:0 0 0 7px;}
							.addrDaum {position:fixed;width:100%;height:100%;left:0;top:0;background:rgba(0, 0, 0, .3);border:0;z-index:9999;}
							.addrDaum::backdrop {background:rgba(0, 0, 0, .5);}
							.addrDaum .wrapper {position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;display:flex;flex-flow:column;flex-wrap:wrap;width:400px;height:520px;}
							.addrDaum .wrapper > div {min-height:0;flex:1}
							.addrDaum .wrapper > button {
								margin:10px 0 0;min-width:0;max-width:none;width:100%;
								span {font-size:15px;}
							}
						}
						&.hasList1 {
							max-width:100%;
						}
					}
				}
				.typeDl2 {
					display:flex;flex-flow:row;flex-wrap:wrap;height:100%;
					> dt,
					> dd {box-sizing:border-box;position:relative;line-height:1.2;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
					> dt {
						width:170px;background:#eee;justify-content:center;
						> *:fitst-child {line-height:1.2;color:#000;font-weight:500;text-align:center;}
						.desc {display:block;font-size:13px;color:#777;width:100%;text-align:center;color:var(--color-1);}
						.btnPkg {
							margin:15px 0 0;
							button {
								color:#fff;margin:0 0 5px;height:40px;
								&:before {height:40px;}
								&:last-child {margin:0;}
							}
						}
					}
					> dd {width:calc( 100% - 170px );padding:15px;}
				}
				.typeDl3 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid #eee;
					dt,
					dd {}
					dt {font-weight:500;}
					dd {
						flex:1;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
						input {flex:1;}
						.txtLen {
							width:70px;text-align:right;
							em {font-style:normal;}
						}
					}
				}
				.typeDl4 {
					display:flex;flex-flow:row;flex-wrap:wrap;height:100%;align-items:center;align-content:center;
					dt {
						padding:0 10px;background:#eee;width:155px;box-sizing:border-box;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;font-weight:500;height:100%;
						&:after {display:none;}
					}
					dd {flex:1;padding:13px;}
					input[type="text"],
					input[type="number"],
					select,
					button {height:35px !important;}
				}
				.typeBox1 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;width:100%;
					> * {
						width:calc( 50% - 10px );
						dl {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center
							dt,
							dd {}
							dt {width:80px;}
							dd {flex:1;}
						}
					}
				}
				input[type="text"],
				input[type="email"],
				input[type="password"],
				input[type="number"],
				input[type="date"],
				select {height:44px;border-radius:4px;}
				textarea {border-radius:4px;}
				.txt {}/* height:44px;line-height:44px */
				.customBtn1 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:130px;height:44px;background:#3b3b3b;border:1px solid #3b3b3b;color:#fff;border-radius:4px;transition:all .3s ease;
					&:focus-visible,
					&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
				}
				.customBtn2 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:auto;height:31px;background:#fff;border:1px solid #bcbcbc;color:var(--color-1);font-size:1.066em;padding:0 10px;border-radius:4px;transition:all .3s ease;
					span {display:block;line-height:1;letter-spacing:-.05em;margin:-2px 0 0;}
					&:focus-visible,
					&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
				}
				.customBtn3 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:130px;min-width:0;height:30px;background:#fff;box-sizing:border-box;border:solid;border-width:1px;font-size:1em;padding:0 10px;border-radius:4px;font-size:15px;transition:all .3s ease;
					&.blue {
						color:var(--color-1);border-color:var(--color-1);background:#fff;
						&:hover,
						&:focus-visible {color:#fff;background:var(--color-1);}
					}
					&.black {
						color:#fff;border-color:#3b3b3b;background:#3b3b3b;
						&:hover,
						&:focus-visible {border-color:#000;background:#000;}
					}
				}
				.customBox1 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:auto;height:30px;background:#fff;border:1px solid #ddd;color:#555;border-radius:4px;font-size:14px;padding:0 10px;
				}
				.customSel1 {width:50%;}
				.hasUrl {
					justify-content:space-between;
					input {flex:1;min-width:0;}
					button {margin:0 0 0 10px;}
					&.update {
						input {flex:none;width:100%;}
						button {display:none;}
					}
				}
				.hasAddr {
					align-items:flex-start !important;align-content:flex-start !important;
					.addrNational {width:130px;margin:0 10px 0 0;}
					.addrPkg {
						flex:1;
						> * {
							display:none;flex-flow:row;flex-wrap:wrap;width:100%;
							.addrZipFinder {width:130px;margin:0 0 0 7px;}
							.addrZip {width:130px;margin:0 10px 0 0;text-align:center;}
							.addrSumm {flex:1;}
							.addrDetail {}
							.addrDaum {position:fixed;width:100%;height:100%;left:0;top:0;background:rgba(0, 0, 0, .3);border:0;z-index:9999;}
							.addrDaum::backdrop {background:rgba(0, 0, 0, .5);}
							.addrDaum .wrapper {position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;display:flex;flex-flow:column;flex-wrap:wrap;width:400px;height:520px;}
							.addrDaum .wrapper > div {min-height:0;flex:1}
							.addrDaum .wrapper > button {margin:10px 0 0;min-width:0;max-width:none;width:100%;}
							&.on {display:flex;}
							&.domestic {
								.addrDetail {width:100%;margin:10px 0 0;}
							}
							&.oversea {
								.addrDetail {flex:1;min-height:0;margin:0 0 0 10px;}
							}
						}
					}
				}
				.hasCate {
					.schTxt {
						display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;width:100%;
						.cateSchResult {
							flex:1;box-sizing:border-box;padding:0 20px 0 0;
							.unit {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;height:44px;margin:0 0 10px;padding:0 5px 0 0;width:100%;box-sizing:border-box;border:1px solid #ccc;border-radius:4px;background:#f5f5f5;transition:all .3s ease;
								.unitNum {
									width:40px;box-sizing:border-box;padding:0 0 0 15px;
									&:after {content:".";}
								}
								.selectedCateLineage {
									margin:auto auto auto 10px;
									ul {
										display:flex;flex-flow:row;flex-wrap:wrap;
										li {
											box-sizing:border-box;position:relative;padding:0 15px 0 0;margin:0 8px 0 0;line-height:1.2;font-size:.882em;letter-spacing:-.05em;
											&:after {content:"";display:block;position:absolute;width:9px;height:9px;right:0;top:5px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(-45deg);}
											&:last-child {
												padding:0;margin:0;
												&:after {display:none}
											}
										}
									}
								}
								.lineageStr {margin:auto 0 auto auto;height:30px;border-color:#ddd;background:#eee;letter-spacing:-.05em;font-size:13px;width:130px;min-width:0;max-width:none;}
								.unitCtrl {
									width:30px;height:30px;border:1px solid #ddd;box-sizing:border-box;position:relative;margin:0 0 0 5px;border-radius:4px;background:#fff;transition:all .3s ease;
									&:before,
									&:after {content:"";display:block;position:absolute;width:12px;height:2px;background:#aaa;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
									&:focus-visible,
									&:hover {
										border-color:var(--color-1);
										&:before,
										&:after {background:var(--color-1);}
									}
									&.unitMinus {}
									&.unitPlus {
										&:after {transform:rotate(90deg);}
									}
									&.unitSelect {
										width:auto;padding:0 5px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
										&:before,
										&:after {display:none;}
										span {font-size:13px;line-height:1;}
										&:focus-visible,
										&:hover {color:var(--color-1);}
									}
								}
								&:first-child {
									.unitCtrl {
										&.unitMinus {display:none;}
									}
								}
								&.on {
									border-color:var(--color-1);background:#fff;
									.unitSelect {
										border-color:var(--color-1);background:var(--color-1);color:#fff !important;
										&:focus {outline:3px solid rgba(59, 153, 252, .5)}
									}
								}
								&.dupl {
									border-color:#a00;
								}
							}
						}
						.cateSchForm {
							width:300px;display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;
							.schKeywd {flex:1;min-width:0;max-width:none;}
							.schKeyCast {margin:0 0 0 10px;width:70px;}
							.schResBox {
								display:none;position:absolute;width:100%;left:0;top:54px;box-sizing:border-box;border-radius:4px;border:1px solid #ddd;padding:10px;max-height:210px;overflow-x:hidden;overflow-y:auto;box-shadow:0 1px 7px -2px rgba(0, 0, 0, 0.3);
								&::-webkit-scrollbar {width:8px}
								&::-webkit-scrollbar-thumb {background:#ccc;border-left:2px solid #fff;border-right:2px solid #fff;border-radius:4px;}
								&::-webkit-scrollbar-track {background:#eee;border-left:2px solid #fff;border-right:2px solid #fff}
								&::-webkit-scrollbar-button {height:2px}
								&:hover::-webkit-scrollbar-thumb {background:var(--color-1);}
								&.on {display:block;}
								ul {
									display:block;
									li {
										display:block;margin:0 0 5px;padding:0 0 5px;border-bottom:1px solid #dbdbdb;
										&:last-child {margin:0;padding:0;border-bottom:0;}
										button {
											display:flex;flex-flow:row;flex-wrap:wrap;line-height:1.2;text-align:center;border:0;background:transparent;transition:all .3s ease;
											.cate {
												padding:0 10px 0 0;margin:0 7px 0 0;font-size:14px;letter-spacing:-.05em;box-sizing:border-box;position:relative;
												&:after {content:"";display:block;position:absolute;width:9px;height:9px;box-sizing:border-box;border:solid;border-width:0 1px 1px 0;border-color:#aaa;transform:rotate(-45deg);right:0;top:4px;transition:all .3s ease;}
												&:last-child {
													padding:0;margin:0;
													&:after {display:none;}
												}
											}
											&:focus-visible,
											&:hover {
												color:var(--color-1);
												.cate {
													&:after {border-color:var(--color-1);}
												}
											}
										}
									}
								}
							}
						}
					}
					.schUI {
						box-sizing:border-box;padding:0 320px 0 0;width:100%;
						.hideNav {
							nav {
								display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
								.depth {
									flex:1;border:1px solid #ccc;box-sizing:border-box;margin:0 10px 0 0;
									&:last-child {margin:0;}
								}
								.parentReqMsg {
									box-sizing:border-box;position:relative;padding:0 0 0 15px;font-size:13px;line-height:1.2;word-wrap:break-word;word-break:keep-all;white-space:normal;
									&:before {content:"";display:block;position:absolute;width:8px;height:8px;box-sizing:border-box;border:solid;border-width:2px 0 0 2px;border-color:#aaa;left:2px;top:4px;transform:rotate(-45deg);}
								}
								.d1 {
									.d1Unit {
									}
								}
								.d2 {
									.d2Unit {
										display:none;
										&.on {display:block}
										ul {
										}
										> .parentReqMsg {display:none;margin:5px 0 0;}
										&.on > *:first-child {display:block;}
									}
									> .parentReqMsg {display:none;margin:15px 10px;}
									&.noChild > .parentReqMsg {display:block;}
								}
								.d3 {
									.d3Unit {
										display:none;
										&.on {display:block}
										ul {
										}
										> .parentReqMsg {display:none;margin:5px 0 0;}
										&.on > *:first-child {display:block;}
									}
									> .parentReqMsg {display:none;margin:15px 10px;}
									&.noChild > .parentReqMsg {display:block;}
								}
							}
							.dUnitTit {display:block;text-align:center;font-size:1.059em;color:#000;background:#d8d8d8;line-height:1.2;padding:8px 0 9px;}
							.dUnit {
								height:170px;box-sizing:border-box;overflow-x:hidden;overflow-y:auto;padding:10px;
								&::-webkit-scrollbar {width:8px}
								&::-webkit-scrollbar-thumb {background:#ccc;border-left:2px solid #fff;border-right:2px solid #fff;border-radius:4px;}
								&::-webkit-scrollbar-track {background:#eee;border-left:2px solid #fff;border-right:2px solid #fff}
								&::-webkit-scrollbar-button {height:2px}
								&:hover::-webkit-scrollbar-thumb {background:var(--color-1);}
								.noCateGuide {font-size:.882em;}
								> ul {
									> li {
										display:block;margin:0 0 2px;
										&:last-child {margin:0;}
										> * {
											display:block;width:100%;line-height:1.2;box-sizing:border-box;border:0;background:transparent;padding:5px;border-radius:4px;transition:all .3s ease;
											span {display:block;font-size:.882em;height:auto;line-height:1.2;letter-spacing:-.05em;word-wrap:break-word;word-break:keep-all;white-space:normal;}
											&:focus-visible,
											&:hover {background:#dbdbdb;}
										}
										&.on {
											> * {background:var(--color-1);color:#fff;}
										}
									}
								}
							}
							.dUnitSel {
								box-sizing:border-box;
								select {display:block;width:100%;border:0;height:42px;}
							}
						}
					}
				}
				.hasCateOnly1Depth {
					nav {
						position:relative;
						ul {
							display:flex;flex-flow:row;flex-wrap:wrap;margin:-4px;
							li {
								padding:4px;
								button {
									display:block;line-height:1.2;border-radius:5px;height:auto;border:0;background:linear-gradient( to bottom, #fff 0, #eee 100% );padding:5px 7px;border:1px solid #ddd;transition:all .3s ease;
									span {display:block;line-height:1.2;height:auto;font-size:.941em;letter-spacing:-.05em;}
									&:hover,
									&:focus-visible {border-color:var(--color-1);color:var(--color-1);}
								}
								&.on {
									button {
										background:var(--color-1);color:#fff;border-color:var(--color-1);
									}
								}
							}
						}
					}
				}
				.hasFiles1 {
					.listPaperChk {
						li {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;padding:0 0 5px;margin:0 0 5px;border-bottom:1px solid #ddd;
							&:last-child {padding:0;margin:0;border-bottom:0;}
							.chks {flex:1;min-width:0;margin-right:20px;}
							button {
								&:before {height:37px;}
							}
						}
					}
				}
				&.Account {
					.hasAddr {
						max-width:621px;
						.addrZip {width:100px !important;text-align:center;min-width:0;}
					}
				}
			}
		}
	}
	@media all and (max-width:1524px) {
		.myPkg {
			.mySumm {margin:0;}
			.myMenu {width:200px;}
			.myContents {padding:20px 0 0 20px;}
			.myCheckout {padding:20px 0 0 20px;}
		}
	}
	@media all and (max-width:1099px) {
		.myMenu {width:170px;}
	}
	@media all and (max-width:999px) {
		.myPkg {
			display:block;
			.mySumm {
				margin:0 0 20px;padding:15px;
				.mName {
					.pTit1 {
						font-size:1.2em;
					}
				}
			}
			.myMenu {
				width:100%;height:auto;padding:0;
				.inner {
					display:block;overflow:hidden;
					&.ENTERPRISE {padding:0;}
					.mmUnitWrap {
						padding:0;
						.tabPkg {
							.tabNavi {
								position:relative;left:auto;top:auto;border-radius:0;border:0;
							}
							.tabBody {
								height:200px;overflow:auto;box-sizing:border-box;padding:15px;
								> div {
									display:flex;flex-flow:row;flex-wrap:wrap;
									.mmUnit {
										position:relative;width:50%;padding:20px 0 0;margin:10px 0 0;border-bottom:0;
										&:nth-child(odd) {
											&:before {content:"";display:block;position:absolute;width:200%;height:1px;left:0;top:0;background:#dbdbdb;}
										}
										&:nth-child(-n+2) {
											padding:0;margin:0;
											&:before {display:none;}
										}
										.mmTit1 {}
										.mmList1 {
											display:flex;flex-flow:row;flex-wrap:wrap;
											li {
												width:50%;margin:0 0 10px;
												&:last-child {margin:0 0 10px;}
											}
										}
									}
								}
							}
							.tabCommon {
								margin:0;height:auto;background:#fafafa;
								ul {
									display:flex;flex-flow:row;flex-wrap:wrap;
									li {
										flex:1;min-width:0;box-sizing:border-box;position:relative;
										&:after {content:"";display:block;position:absolute;width:1px;height:15px;right:0;top:50%;transform:translateY(-50%);background:#aaa;}
										&:last-child {
											&:after {display:none;}
										}
										button {justify-content:center;padding:10px 15px;letter-spacing:-.05em;}
									}
								}
							}
						}
					}
				}
			}
			.myContents {padding:20px 0 0;}
			.myCheckout {padding:20px 0 0;}
		}
	}
	@media all and (max-width:599px) {
		.myPkg {
			.myMenu {
				.inner {
					.mmUnitWrap {
						.tabBody {
							> div {
								.mmUnit {
									.mmList1 {
										li {width:100%;}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:499px) {
		.myPkg {
			.myMenu {
				.inner {
					.tabCommon {
						ul {
							display:block;
							li {
								flex:none;display:block;box-sizing:border-box;border:0;border-top:1px solid var(--color-db);
								&:after {display:none;}
							}
						}
					}
				}
			}
		}
	}
}

/* ==============================
* Withdraw
================================= */
.Withdraw {
	.pTit1 {font-size:1.176em;color:#000;font-weight:700;margin:0 0 20px;}
	.pTit2 {font-size:1.059em;color:#010101;font-weight:500;margin:0 0 15px;}
	.pTit3 {font-size:.882em;color:#767676;font-weight:500;margin:0 0 13px;}
	.list1 {
		li {
			font-size:14px;
			&:before {top:8px;}
		}
	}
	.list2 {
		display:block;text-align:center;
		li {
			display:inline-block;
			font-size:15px;
			&:before {top:8px;}
			em {font-style:normal;color:var(--color-1);font-weight:700;}
		}
	}
	.ico1 {display:block;width:58px;height:58px;margin:36px auto 0;background:url(/assets/img/_member/login_spr.png) no-repeat -199px -440px}
	.msg1 {
		text-align:center;
		> * {display:block;line-height:1.2;color:#000;}
		.pTxt1 {font-size:2.235em;font-weight:700;}
		.pTxt2 {font-size:1.941em;font-weight:400;margin:9px 0 0;}
	}
	.msg2 {box-sizing:border-box;border:1px solid #bcbcbc;border-radius:8px;padding:6px 10px 10px;margin:28px 0 0;}
	textarea {resize:none;height:150px;}
	.list3 {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;min-height:44px;
		li {
			margin:0 20px 0 0;
			&:last-child {margin:0;}
		}
	}
	.castWithdraw,
	.castCancel {min-width:245px}
}

/* ==============================
* Favorite
================================= */
.Favorite {
	.favoriteTools {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;background:#eee;width:100%;padding:10px 20px;box-sizing:border-box;border-top:1px solid #ddd;
		.chks {}
		.delete {margin:0 0 0 25px;}
	}
	.favoriteTbl {
		.colSelect {
			width:50px;
			.chks {
				display:block;margin:0;padding:0;width:17px;height:17px;margin:0 auto;
				label {
					display:block !important;width:100% !important;height:100% !important;margin:0;padding:0;
					&:before {left:0;top:0;}
					&:after {top:4px;}
				}
			}
		}
		.colImage {
			width:120px;
			a {
				display:block;width:100px;box-sizing:border-box;border:1px solid #ddd;
				.imgContainer {
					display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
					img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
				}
				&:hover,
				&:focus-visible {
					.imgContainer {
						img {transform:scale(1.1);}
					}
				}
			}
		}
		.colName {
			width:auto;text-align:left;
			.pkg {
				.title {
					display:block;font-size:1.059em;font-weight:500;color:#333;margin:0 0 8px;padding:0 0 10px;border-bottom:1px solid #eee;
					span {transition:all .3s ease;}
					&:hover,
					&:focus-visible {
						span {box-shadow:0 -15px 0 rgba(17, 101, 224, .1) inset;color:var(--color-1);}
					}
				}
				.price {}
			}
		}
		.colController {
			width:140px;
			button {width:100%;min-width:0;max-width:none;}
		}
		td {padding:10px;}
	}
}

/* ==============================
* Withdraw Success
================================= */
.WithdrawSuccessWr {
	.myPkg {
		width:100%;
		.myContents {
			padding:0;width:100%;
			.WithdrawSuccess {
				padding:70px 0 0;
				.msg1 {
					text-align:center;font-size:1.764em;font-weight:700;color:#000;line-height:1.2;
					em {font-style:normal;color:var(--color-b1)}
				}
				.ico1 {width:189px;height:172px;background:url(/assets/img/_member/login_spr.png) no-repeat -267px -440px;margin:51px auto 0;}
				.msg2 {
					box-sizing:border-box;border:1px solid #bcbcbc;border-radius:8px;text-align:center;width:100%;max-width:735px;margin:50px auto 0;padding:16px 20px 18px;
					> * {display:block;line-height:1.2;font-size:.941em;font-weight:400;}
					.pTxt1 {color:#000;}
					.pTxt2 {color:#8b8b8b;margin:6px 0 0;}
				}
				.btns {
					> * {
						height:64px;min-width:245px;
						&:before {height:100%}
					}
				}
			}
		}
	}
}

/* ==============================
* Account
================================= */
.Account {
	.myContUnit {
		margin:0 0 40px;
		&:last-child {margin:0;}
		.myContUnitTit {}
		.myContUnitBody {}
	}
}

/* ==============================
* Buyer Main
================================= */
.buyerMain {
	.opening {
		.titPkg {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-end;align-content:flex-end;justify-content:space-between;margin:0 0 20px;
			.sTit1 {font-size:1.412em;color:#000;font-weight:500;line-height:1;}
			.sTab1 {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:auto auto -2px 30px;
				li {
					box-sizing:border-box;padding:0 15px 0 0;margin:0 15px 0 0;position:relative;
					&:after {content:"";display:block;position:absolute;width:1px;height:16px;right:0;top:7px;background:#aaa;}
					&:last-child {
						padding:0;margin:0;
						&:after {display:none;}
					}
					button {
						display:block;line-height:1;border:0;background:transparent;position:relative;padding:0 5px;transition:all .3s ease;
						span {
							display:block;line-height:1;padding:5px 0;box-sizing:border-box;position:relative;
							&:after {content:"";display:block;position:absolute;width:0;height:2px;left:50%;bottom:-2px;background:var(--color-1);transition:all .3s ease;}
						}
						&:focus-visible,
						&:hover {
							color:var(--color-1);
							span:after {left:0;width:100%;}
						}
					}
					&.on {
						button {
							color:var(--color-1);
							span {
								&:after {left:0;width:100%;}
							}
						}
					}
				}
			}
			.sTxt1 {line-height:1;font-size:1.059em;}
		}
		.titBody {
			.unit {
				display:none;flex-flow:row;flex-wrap:wrap;justify-content:space-between;box-sizing:border-box;border:2px solid var(--color-db);
				&.on {display:flex;}
				.group1 {
					flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;padding:34px 0 37px;
					dl {
						width:20%;
						dt,
						dd {text-align:center;line-height:1;}
						dt {font-size:1.059em;font-weight:700;color:#000;}
						dd {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-end;align-content:flex-end;justify-content:center;margin:8px 0 0;
							.num {font-size:2.823em;color:var(--color-1);font-weight:700;}
							.numUnit {margin:0 0 2px;}
						}
					}
				}
				.group2 {
					width:150px;background:var(--color-db);display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;box-sizing:border-box;padding:20px 0 25px;
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						dt,
						dd {width:50%;text-align:center;line-height:1;}
						dt {}
						dd {}
					}
				}
			}
		}
	}
	.middle {
		display:grid;grid-template-columns:1fr 1fr 2fr;gap:30px;margin:30px 0 0;
		> * {
			box-sizing:border-box;border:2px solid var(--color-db);padding:17px 25px 25px;text-align:center;
			.title {display:block;line-height:1;font-size:1.294em;font-weight:500;color:var(--color-1);border-bottom:1px solid #3b3b3b;padding:0 0 18px;text-align:left;}
			.current {
				display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;box-sizing:border-box;padding:20px 20px 0px;
				dt,
				dd {line-height:1;font-size:1.059em;color:#000;margin:0 0 25px;word-wrap:break-word;word-break:keep-all;white-space:normal;}
				dt:last-of-type,
				dd:last-of-type {margin:0;}
				dt {text-align:left;width:calc( 100% - 50px );}
				dd {
					text-align:right;width:50px;
					em {font-style:normal;font-weight:700;}
				}
			}
			.regi {
				display:inline-block;width:auto;background:#3b3b3b;color:#fff;line-height:1;border-radius:8px;padding:11px 27px 15px;margin:30px 0 0;transition:all .3s ease;
				&:focus-visible,
				&:hover {background:#000;}
			}
		}
		.histQuery {}
		.histReview {}
		.banner {
			border:0;padding:0;
			a {
				display:block;
				img {display:block;width:100%;}
			}
		}
	}
	.bottom {
		margin:40px 0 0;
		.titPkg {display:block;font-size:1.764em;font-weight:700;letter-spacing:0;color:#000;margin:0 0 15px;}
		.tabPkg {
			.tab {
				ul {
					display:flex;flex-flow:row;flex-wrap:wrap;padding:0 1px;
					li {
						flex:1;margin:0 -1px;position:relative;z-index:0;
						button {
							display:block;line-height:1;width:100%;text-align:center;padding:13px 10px 14px;box-sizing:border-box;position:relative;border:0;background:#f0f0f0;color:#888;transition:all .3s ease;
							&:before,
							&:after {content:"";display:block;position:absolute;left:0;bottom:0;box-sizing:border-box;border:solid;transition:all .3s ease}
							&:before {width:100%;height:2px;background:var(--color-1);border:0;z-index:1}
							&:after {width:100%;height:100%;border-width:2px 2px 0 2px;border-color:var(--color-db);z-index:0}
							span {display:block;line-height:1.1;font-weight:500;text-align:center;letter-spacing:-.05em;word-wrap:break-word;word-break:keep-all;white-space:normal;font-size:1.176em;}
							&:focus-visible,
							&:hover {color:var(--color-1);}
						}
						&.on {
							z-index:1;
							button {
								color:var(--color-1);background:#fff;
								&:before {display:none;}
								&:after {border-color:var(--color-1);}
							}
						}
					}
				}
			}
			.unit {
				display:none;box-sizing:border-box;border:solid;border-width:0 2px 2px 2px;border-color:var(--color-1);padding:30px 30px 20px;margin:0 1px 0 0;
				&.on {display:block;}
				.swiper-navi-prev,
				.swiper-navi-next {
					position:absolute;border:0;top:50%;transform:translateY(-50%);margin:0;width:40px;height:40px;background:rgba(0, 0, 0, .5);border-radius:4px;z-index:1;transition:all .3s ease;
					&:after {content:"";display:block;position:absolute;width:15px;height:15px;border:solid;border-width:0 0 2px 2px;border-color:#fff;top:0;right:0;bottom:0;left:0;transition:all .3s ease;}
					&:not(.swiper-button-disabled):focus-visible,
					&:not(.swiper-button-disabled):hover {background:rgba(0, 0, 0, .75);}
					&.swiper-button-disabled {opacity:.25;cursor:default;}
				}
				.swiper-navi-prev {
					left:10px;
					&:after {transform:rotate(45deg);margin:auto auto auto 16px;}
					&:not(.swiper-button-disabled):focus-visible:after,
					&:not(.swiper-button-disabled):hover:after {margin:auto auto auto 12px}
				}
				.swiper-navi-next {
					right:10px;
					&:after {transform:rotate(225deg);margin:auto 16px auto auto;}
					&:not(.swiper-button-disabled):focus-visible:after,
					&:not(.swiper-button-disabled):hover:after {margin:auto 12px auto auto}
				}
				.swiper-slide {
					display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;
					.boxImg {
						width:150px;box-sizing:border-box;height:100%;
						.imgContainer {
							display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;transition:all .3s ease;
							&:before {content:"";display:block;position:absolute;width:100%;height:100%;left:0;top:0;box-sizing:border-box;border:1px solid var(--color-db);z-index:1;transition:all .2s linear;}
							img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;z-index:0;transition:all .3s ease}
							&:hover,
							&:focus-visible {
								img {transform:scale(1.2);}
								&:before {border-color:var(--color-1);border-width:3px;}
							}
						}
					}
					.boxTxt {
						flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 15px;display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;
						.prodName {
							display:block;line-height:1;color:#000;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;padding:0 0 4px;
							span {line-height:1;font-weight:500;transition:all .3s ease;}
							&:hover,
							&:focus-visible {
								color:var(--color-1);
								span {box-shadow:0 -13px 0 rgba(17, 101, 224, .2) inset;}
							}
						}
						.prodPrice {display:block;line-height:1;margin:0 0 auto 0;font-size:.882em;margin:5px 0 0;}
						.prodRate {
							margin:auto 0 0 0;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
							.prodRateScore {
								display:block;font-size:0;line-height:1;
								* {box-sizing:border-box;position:relative;line-height:1;font-size:0;top:auto;}
							}
							.prodSalesNum {font-size:13px;letter-spacing:0;line-height:1;margin:0 0 0 5px;}
						}
						.prodFactory,
						.prodFactoryType {font-size:.882em;line-height:1.2;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:36px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical}
						.prodFactory {margin:5px 0 0}
						.prodFactoryType {margin:5px 0 0;}
					}
					.noData {
						height:150px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
						p {
							text-align:center;
							i {font-size:3em;color:#000;}
							span {display:block;text-align:center;}
						}
					}
				}
				.swiper-pagination-wrap {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;margin:15px 0 0;
					* {box-sizing:border-box;line-height:1;font-size:0;}
					.swiper-auto-controller {
						display:flex;flex-flow:row;flex-wrap:wrap;margin:0 10px 0 0;
						button {
							display:none;width:15px;height:15px;border:0;background:transparent;box-sizing:border-box;position:relative;
							&:after {content:"";display:block;position:absolute;box-sizing:border-box;border:solid;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							&.stop {
								&:after {width:8px;height:10px;border-width:0 3px;border-color:#aaa;}
							}
							&.play {
								&:after {width:0;height:0;border-width:6px 0 6px 7px;border-color:transparent transparent transparent #aaa;}
							}
							&.on {display:block;}
						}
					}
					.swiper-pagination {
						position:relative;transition:none;transform:none;display:flex;flex-flow:row;flex-wrap:wrap;
						li {
							position:relative;left:auto;top:auto;opacity:1;width:auto;height:auto;background:transparent;margin:0 3px 0 0;
							&:last-child {margin:0;}
							button {
								border:0;background:transparent;width:15px;height:15px;position:relative;
								&:after {content:"";display:block;position:absolute;width:10px;height:10px;border-radius:50%;background:#ddd;top:0;right:0;bottom:0;left:0;margin:auto;}
							}
							&.swiper-pagination-bullet-active {
								background:transparent;
								button {
									&:after {background:var(--color-1);}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* ==============================
* Seller Main
================================= */
.sellerMainCarousel {
	margin:0 0 20px;
	.swiper-container {
		box-sizing:border-box;position:relative;overflow:hidden;z-index:0;border:2px solid #e5e5e5;
		&:before,
		&:after {content:"";display:block;position:absolute;width:200px;height:100%;top:0;z-index:1;}
		&:before {left:0;background:linear-gradient( to right, #fff 50%, rgba(255, 255, 255, 0) 100%);}
		&:after {right:0;background:linear-gradient( to left, #fff 50%, rgba(255, 255, 255, 0) 100%);}
		.swiper-wrapper {
			z-index:0;
			.swiper-slide {
				box-sizing:border-box;padding:0 200px;
				a {
					display:block;line-height:1.2;padding:36px 0;font-size:1.25em;transition:all .3s ease;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;text-align:center;
					span {transition:all .3s ease;}
					&:focus-visible,
					&:hover {
						color:var(--color-1);
						span {box-shadow:0 -15px 0 rgba(17, 101, 224, .1) inset}
					}
				}
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			width:40px;height:40px;position:absolute;top:50%;transform:translateY(-50%);border:0;background:transparent;z-index:2;margin:0;transition:all .3s ease;
			&:after {content:"";display:block;position:absolute;width:12px;height:12px;box-sizing:border-box;border:solid;border-width:2px 2px 0 0;border-color:#b4b4b4;top:0;right:0;bottom:0;left:0;transition:all .3s ease;}
			&.swiper-button-prev {
				left:20px;
				&:after {transform:rotate(225deg);margin:auto auto auto 16px;}
			}
			&.swiper-button-next {
				right:20px;
				&:after {transform:rotate(45deg);margin:auto 16px auto auto;}
			}
			&:focus-visible:after,
			&:hover:after {border-color:var(--color-1);}
		}
		.swiper-pagination {
			line-height:1;font-size:0;left:50%;transform:translateX(-50%);bottom:10px;
			span {margin:0 2px;}
		}
	}
	.swiper-pagination-wrap {
		display:flex;flex-flow:row;flex-wrap:wrap;z-index:1;align-items:center;align-content:center;position:relative;justify-content:right;margin:10px 0 0;
		* {box-sizing:border-box;line-height:1;font-size:0;position:relative;}
		.swiper-auto-controller {
			margin:0 5px 0 0;
			button {
				display:none;width:20px;height:20px;border:0;box-sizing:border-box;position:relative;background:transparent;
				&.on {display:block;}
				&:after {content:"";display:block;position:absolute;border:solid;box-sizing:border-box;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
				&.stop:after {width:8px;height:12px;border-width:0 2px;border-color:#aaa}
				&.stop:focus-visible,
				&.stop:hover {
					&:after {border-color:var(--color-1);}
				}
				&.play:after {width:0;height:0;border-width:6px 0 6px 7px;border-color:transparent transparent transparent #aaa;}
				&.play:focus-visible,
				&.play:hover {
					&:after {border-color:transparent transparent transparent var(--color-1);}
				}
			}
		}
		.swiper-pagination {
			display:flex;flex-flow:row;flex-wrap:wrap;position:relative;left:auto;top:auto;transition:none;transform:none;
			li {
				width:auto;height:auto;opacity:1;background:transparent;
				button {
					display:block;width:15px;height:15px;box-sizing:border-box;position:relative;border:0;background:transparent;
					&:after {content:"";display:block;position:absolute;width:10px;height:10px;border-radius:50%;background:#ddd;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
					&:focus-visible,
					&:hover {
						&:after {background:#aaa;}
					}
				}
				&.swiper-pagination-bullet-active {
					opacity:1;background:transparent;
					button {
						&:after {background:var(--color-1);}
					}
				}
			}
		}
	}
}
.sellerMain {
	display:grid;gap:30px;grid-template-columns:1fr 1fr;
	.item {
		box-sizing:border-box;border:2px solid #e5e5e5;min-height:100px;padding:15px 23px 10px;
		&.item1 {
			grid-column:1 / 3;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;padding:0;
			> * {width:50%;box-sizing:border-box;padding:15px 23px;}
			.side {}
		}
		.itemTit {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;line-height:1.2;border-bottom:1px solid #3b3b3b;padding:0 0 16px;
			span {font-size:1.294em;color:var(--color-1);font-weight:700;}
			button {
				width:30px;height:30px;border:0;background:transparent;font-size:1.176em;box-sizing:border-box;position:relative;
				i {display:block;width:20px;height:20px;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;transform:rotate(0deg);color:#b4b4b4;transition:all .3s ease;}
				&:focus-visible i,
				&:hover i {transform:rotate(360deg);color:var(--color-1);}
			}
		}
		.itemBody {
			.boxPkg {
				display:flex;flex-flow:row;flex-wrap:wrap;
				&.pad1 {padding:37px 0 31px;}
				&.pad2 {padding:15px 0;}
				.boxGraph {flex:1;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center}
				.boxText {
					flex:1;display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;padding:7px 0 7px;
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
						dt,
						dd {width:50%;line-height:1;text-align:center;}
						dt {font-size:1.059em;color:#000;font-weight:400;}
						dd {
							.state {
								display:inline-block;font-size:14px;box-sizing:border-box;border:solid;border-width:1px;padding:6px 0 8px;border-radius:4px;width:86px;text-align:center;
								&.complete {background:var(--color-1);border-color:var(--color-1);color:#fff;cursor:default}
								&.required {
									background:#fff;border-color:#b2b2b2;color:#000;transition:all .3s ease;
									&:focus-visible,
									&:hover {border-color:var(--color-1);color:var(--color-1);text-decoration:underline;}
								}
							}
							.status {
								display:block;font-size:14px;box-sizing:border-box;padding:5px 0;text-align:center;text-decoration:underline;transition:all .3s ease;
								&:focus-visible,
								&:hover {color:var(--color-1);}
							}
						}
					}
				}
			}
		}
		.circleGraphOut {
			width:217px;height:217px;background:#dcdcdc;border-radius:50%;box-sizing:border-box;position:relative;
			.circleGraph {
				display:block;box-sizing:border-box;position:absolute;border-radius:50%;width:200px;height:200px;top:0;right:0;bottom:0;left:0;margin:auto;z-index:1;transition:all .3s ease;
				svg {
					position:absolute;left:0;top:0;width:100%;height:100%;transform:rotate(-90deg);
					circle {fill:none;stroke:#1877F2;stroke-width:13px;stroke-dasharray:628;stroke-dashoffset:628;stroke-dashoffset:0;transition:all 1s ease;}/* svg width * 3.14 */
				}
			}
			.circleGraphIn {
				position:absolute;width:154px;height:154px;background:#fff;border-radius:50%;top:0;right:0;bottom:0;left:0;margin:auto;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
				> div {
					line-height:1;
					.num {font-size:1.764em;font-weight:700;color:#000;}
					.txt {font-size:1.059em;}
				}
			}
		}
	}
}

/* ==============================
* Seller > 판매관리[탭] > 주문/배송 > 주문리스트
================================= */
.Logistics {
	/** -- Order 공용 */
	.commonSummary {
		display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 20px;align-items:center;align-content:center;justify-content:space-between;
		.orderInfo {
			display:flex;flex-flow:row;flex-wrap:wrap;
		}
		.orderStatus {
			display:flex;flex-flow:row;flex-wrap:wrap;
			select {height:40px !important;}
			button {min-width:0;padding:0 10px;margin:0 0 0 5px;}
		}
		dl {
			display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;padding:0 15px 0 0;margin:0 10px 0 0;
			&.noSeperateLine {
				padding:0;margin:0;
				&:after {display:none;}
			}
			&:after {content:"";display:block;position:absolute;width:2px;height:13px;right:-7px;top:3px;background:#aaa;transform:rotate(30deg);}
			&:last-child {
				padding:0;margin:0;
				&:after {display:none;}
			}
			dt,
			dd {box-sizing:border-box;position:relative;line-height:1;}
			dt {
				padding:0 10px 0 0;margin:0 5px 0 0;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:13px;right:0;top:3px;color:#aaa;}
			}
			dd {letter-spacing:0;}
		}
	}
	.commonAttach {
		.imgSelector {
			.selectedImg {}
			.customInp1 {flex:1;min-width:0;height:33px;}
		}
	}
	.commonSearch {
		box-sizing:border-box;border-top:2px solid #ddd;border-bottom:1px solid #ddd;
		.itemSet {
			.itemUnit {
				box-sizing:border-box;border-bottom:1px solid #ddd;
				.chkLayout {
					display:flex;flex-flow:row;flex-wrap:wrap;
					> li {
						margin:0 20px 0 0;
						&:last-child {margin:0;}
					}
				}
			}
		}
		.itemBtns {
			box-sizing:border-box;background:#eee;padding:10px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;text-align:center;justify-content:center;
			button,
			a {margin:0 5px;}
		}
	}

	/** -- List 전용 */
	&.List {
		.searchBar {}
		.DataGridAdjust {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;width:100%;margin:30px 0 15px;
			.posLeft {}
			.posCenter {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;margin:0 10px 0 auto;
				p {}
				select {margin:0 0 0 10px;}
			}
			.posRight {
				button {height:44px;}
				> * {
					margin:0 10px 0 0;
					&:last-child {margin:0;}
				}
			}
			select,
			button {height:40px !important;}
		}
		.DataGridOrderState {
			margin:10px 0 0;
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;
				li {
					margin:0 5px 0 0;
					&:last-child {margin:0;}
					span {display:block;min-width:100px;line-height:1;padding:5px 10px;font-size:13px;font-weight:500;text-align:center;}
				}
			}
		}
		.DataGrid.MuiBox-root {
			outline:0 !important;
			* {
				outline:0 !important;
				/* font-family:"Noto Sans KR", "맑은고딕","돋움",arial,Dotum,Verdana,helvetica,clean,sans-serif !important; */
			}
			.MuiDataGrid-columnHeaders {
				> div:first-child {
					> div:first-child {border-right:1px solid #e0e0e0;}
				}
				.MuiDataGrid-columnHeader {outline:0;}
				.css-yrdy0g-MuiDataGrid-columnHeaderRow {background:#e9eff5 !important;}
				.MuiDataGrid-detailPanelToggleCell {position:absolute;left:0;top:0;border-radius:0;width:100%;height:100%;}
				.customHeader {
					box-sizing:border-box;padding:2px 0 0;line-height:1.2;font-size:12px;text-align:center;
					.headCaption {}
				}
			}
			.Mui-selected {background:none !important;}
			.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root,
			.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover,
			.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:focus-visible {background:none !important;background-color:transparent !important;}
			.MuiDataGrid-virtualScrollerContent {
				.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedLeft,
				.MuiDataGrid-row:focus-visible .MuiDataGrid-cell--pinnedLeft,
				.MuiDataGrid-row:hover .MuiDataGrid-cell--pinnedRight,
				.MuiDataGrid-row:focus-visible .MuiDataGrid-cell--pinnedRight,
				.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedLeft,
				.MuiDataGrid-row.Mui-selected .MuiDataGrid-cell--pinnedRight {background:#fff !important;}
				.MuiDataGrid-detailPanelToggleCell {width:80%;height:80%;position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);}
				.MuiDataGrid-virtualScrollerRenderZone {
					> div {
						> div:first-child {border-right:1px solid #e0e0e0;}
					}
				}
				.MuiDataGrid-row {
					&:hover,
					&:focus-visible {background:none !important;}
				}
				.MuiCheckbox-root {
					position:absolute;width:100%;height:100%;left:0;top:0;border-radius:0;
					.PrivateSwitchBase-input {position:absolute;width:100%;height:100%;left:0;top:0;}
					input[type="checkbox"] {height:100%;}
				}
				.MuiDataGrid-cell {
					font-size:12px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;padding:0 5px !important;background:#fff;
					dialog,
					dialog * {
						font-size:initial;font-family:"Noto Sans KR", "Noto Sans SC", "맑은고딕","돋움",arial,Dotum,Verdana,helvetica,clean,sans-serif !important;
					}
				}
				.orderState {
					width:100%;height:100%;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					> * {
						margin:0 0 2px;
						&:last-child {margin:0;}
					}
				}
				.port1 {
					background:var(--color-1);border:1px solid var(--color-1);color:#fff;
					&:hover,
					&:focus-visible {background:var(--color-2);border-color:var(--color-2);}
				}
				.port2 {
					background:#e02844;border:1px solid #e02844;color:#fff;
					&:hover,
					&:focus-visible {background:#c9142f;border-color:#c9142f;}
				}
				.singleLine1 {display:block;width:100%;line-height:1.2;padding:2px 10px 0;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;text-align:center;}
				.multiLine1 {display:block;width:100%;word-wrap:break-word;word-break:keep-all;white-space:normal;box-sizing:border-box;line-height:1.2;padding:2px 0 0;}
				.multiLine2 {width:100%;word-wrap:break-word;word-break:keep-all;white-space:normal;box-sizing:border-box;line-height:1.2;padding:2px 0 0;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:31px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
				.hasProdInfo {
					width: 100%;
					a {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;
						.pImg1 {
							width:42px;height:100%;box-sizing:border-box;border:1px solid #ddd;
							.imgContainer {
								display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							}
						}
						.pTxt1 {flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 5px;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:29px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.3;letter-spacing:-.05em;text-align:left;transition:all .3s ease;}
						&:hover,
						&:focus-visible {
							.pImg1 {
								.imgContainer {
									img {transform:scale(1.1);}
								}
							}
							.pTxt1 {color:var(--color-1);}
						}
					}
				}
				.hasState {
					width:100%;text-align:center;
					span {
						color:#fff;border-radius:4px;padding:4px 5px 3px;font-weight:bold;
						&.complete {background:var(--color-1);}
						&.waiting {background:#999;}
					}
				}
			}
			.childNode {
				padding:0 0 0 539px;box-sizing:border-box;border-top:1px solid rgba(224, 224, 224, 1);border-right:0 !important;background:#f5f5f5;
				/*
				&:before,
				&:after {content:"";display:block;position:absolute;width:100%;height:1px;background:rgba(224, 224, 224, 1);}
				&:before {top:-1px;}
				&:after {bottom:-1px;}
				*/
				> div {border-radius:0 !important;box-shadow:none !important;padding:0 !important;}
				.MuiDataGrid-root {
					border-radius:0;border-width:0 0 0 1px;
					* {border-radius:0;outline:0}
					.MuiTouchRipple-root {opacity:0 !important;}
					div[role='presentation'] {border:0 !important;}
					.MuiDataGrid-virtualScrollerContent {
						.MuiDataGrid-cell {position:relative;}
					}
					.MuiDataGrid-columnHeader {
						background:#eee !important;
						.MuiDataGrid-columnHeaderDraggableContainer {
							background:transparent !important;
							.MuiDataGrid-columnHeaderTitleContainer {background:transparent !important;}
						}
					}
					.MuiDataGrid-filler {display:none;}
					.productInfo {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;text-align:left;
						.boxImg {
							box-sizing:border-box;border:1px solid #ddd;width:40px;transition:all .3s ease;
							.imgContainer {
								display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							}
						}
						.boxTxt	{
							flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 15px;
							p {
								display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:32px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.3;
								span {transition:all .3s ease;}
							}
						}
						/*
						&:hover,
						&:focus-visible {
							.boxImg {
								border-color:var(--color-1);
								.imgContainer {
									img {transform:scale(1.2);}
								}
							}
							.boxTxt {
								p {
									span {box-shadow:0 -8px 0 rgba(17, 101, 224, .1) inset;color:var(--color-1);}
								}
							}
						}
						*/
					}
				}
			}

			.productInfo_column {
				box-sizing:border-box;border-top:1px solid rgba(224, 224, 224, 1);border-right:0 !important;background:#f5f5f5;
				width: 90%;
				
				.productInfo{
					display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;text-align:left;
					.boxImg {
						box-sizing:border-box;border:1px solid #ddd;width:40px;transition:all .3s ease;
						.imgContainer {
							display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
							img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						}
					}
					.boxImg-mideium {
						box-sizing:border-box;border:1px solid #ddd;width:60px;transition:all .3s ease;
						.imgContainer {
							display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
							img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						}
					}
					.boxTxt	{
						flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 0px;
						p {
							display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:32px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.3;
							span {transition:all .3s ease;}
						}
					}
					
				}
			}
		}
	}

	/** -- Edit 전용 */
	&.Edit {
		.summTit {position:relative;z-index:6;}
		.summ {
			position:sticky;left:0;top:0;padding:20px 0 10px;margin:-20px 0 0;background:#fff;z-index:5;
			.summCore {
				box-sizing:border-box;border:1px solid #ddd;width:100%;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;padding:10px 10px 10px 15px;background:#fff;
				.orderNum {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					dt,
					dd {line-height:1;box-sizing:border-box;position:relative;font-size:20px;}
					dt {
						color:#333;font-weight:500;padding:0 11px 0 0;margin:0 7px 0 0;
						&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;color:#aaa;right:0;top:-1px;}
					}
					dd {
						> * {box-sizing:border-box;position:relative;}
						.orderNumStr {
							color:var(--color-1);padding:0 12px 0 0;margin:0 8px 0 0;
							&:after {content:"";display:block;position:absolute;width:2px;height:15px;background:#aaa;transform:rotate(20deg);right:0;top:8px;}
						}
						.orderTypeStr {color:#000;}
					}
				}
			}
		}
		.frmUnit {
			margin:20px 0 0;
			.frmWr {
				clear:both;
				.sBtn1 {
					font-size:13px;border-radius:5px;box-sizing:border-box;border:solid;border-width:2px;border-color:#327eed var(--color-2) var(--color-2) #327eed;padding:5px 10px 7px;margin:3px 0 0;background:var(--color-1);font-weight:500;color:#fff;transition:all .3s ease;
					span {display:block;line-height:1;}
					&:hover,
					&:focus-visible {border-color:var(--color-2);background:var(--color-2);}
				}
				.hasPayDetail {
					.payList {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
						li {
							width:100%;padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid #ddd;
							&:last-child {padding:0;margin:0;border-bottom:0;}
							dl {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
								dt,
								dd {font-weight:500;box-sizing:border-box;position:relative;}
								dt {
									width:120px;
									&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:20px;color:#aaa;right:0;top:-2px;}
								}
								dd {text-align:right;}
							}
							&.total {
								border-bottom:1px solid var(--color-1);
								dl {
									dt,
									dd {color:var(--color-1);}
								}
							}
						}
					}
				}
				.hasCustom {
					ul {
						width:100%;
						li {
							&.buyerResponsibility {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
								.rdos {}
								dl {
									flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;margin:0 0 0 10px;opacity:.5;transition:all .3s ease;
									&.on {opacity:1;}
									dt,
									dd {}
									dt {font-weight:400;font-size:15px;margin:0 10px 0 0;}
									dd {flex:1;min-width:0;}
								}
							}
							&:last-child {
								flex:1;min-width:0;
							}
						}
					}
				}
				.hasBtn1 {
					.flexTarget {flex:1;min-width:0;}
					.flexRemain {margin:0 0 0 10px;}
					span {font-size:14px;}
				}
				.hasSecondaryPay {
					justify-content:space-between;
					.noDataWrap4 {
						&.hide {display:none;}
					}
					.sPayTblWr {
						&.hide {display:none;}
						> #sPayTbl {
							button {display:none;}
							input {outline:0;border:0;height:auto;line-height:1;background:transparent;}
							thead {
								position:static;z-index:1;box-sizing:border-box;border-top:2px solid #333;border-bottom:1px solid #333;
								tr {
									th {
										&:before,
										&:after {display:none;}
									}
								}
							}
						}
					}
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid #ddd;width:100%;justify-content:space-between;
						&:last-child {padding:0;margin:0;border-bottom:0;}
						dt,
						dd {font-weight:500;}
						dt {}
						dd {
							color:#000;
							.unit {font-family:"Malgun Gothic";color:#333;}
						}
						&.point {
							* {color:var(--color-1) !important;font-weight:700 !important;}
						}
					}
				}
				.hasAddr {
					display:flex;flex-flow:row;flex-wrap:wrap;
					.a-addrNational {}
					.addrPkg {
						flex:1;min-width:0;margin:0 0 0 10px;
						> div {
							display:none;
							&.on {display:block;}
							.a-addr2 {
								flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;
								> * {
									margin:0 5px 0 0 !important;
									&:last-child {margin:0 !important;}
								}
								.addrZip {width:120px !important;text-align:center;}
								.addrZipFinder {
									width:120px !important;
									span {font-size:14px;}
								}
								.addrSumm {flex:.4;}
								.addrDetail {flex:.6;}
							}
							&.domestic {
							}
							&.oversea {
								.addrSumm {flex:1;min-width:0;}
							}
						}
					}
				}
				.hasDeliveryMethod {
					.listType2 {
						li {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
					}
					.childMethod {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;opacity:.5;transition:all .3s ease;
						&.on {opacity:1;}
						.listType2 {
							align-items:center;align-content:center;
							li {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
							&.child {
								margin:0 5px;
								select {width:100px;min-width:100px;margin:0 0 0 5px;}
							}
						}
					}
				}
				.hasDeliveryInfo {
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid #ddd;
						&:last-child {border-bottom:0;padding:0;margin:0;}
						dt,
						dd {line-height:1;box-sizing:border-box;position:relative;}
						dt {
							font-weight:500;width:150px;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;color:#aaa;right:0;top:-1px;}
						}
						dd {flex:1;min-width:0;padding:0 0 0 15px;}
					}
				}
				.hasProduct {
					align-items:normal;
					.boxImg {
						width:120px;box-sizing:border-box;border:1px solid #ddd;height:100%;
						.imgContainer {
							display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
							img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						}
					}
					.boxTxt {
						flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 15px;display:flex;flex-flow:column;justify-content:space-between;
						.prodCategory {
							display:flex;flex-flow:row;flex-wrap:wrap;
							li {
								padding:0 15px 0 0;margin:0 10px 0 0;box-sizing:border-box;position:relative;line-height:1;
								&:last-child {
									padding:0;margin:0;
									&:after {display:none;}
								}
								&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;right:0;top:4px;transform:rotate(-45deg);}
							}
						}
						.prodName {
							font-size:20px;letter-spacing:-.05em;color:#000;font-weight:500;line-height:1.2;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;margin:5px 0 auto;
						}
						.prodOpt {
							min-height:0;margin:auto 0 0;
							.optTit {display:block;line-height:1;color:var(--color-1);margin:10px 0 0;display:none;}
							.optBody {
								box-sizing:border-box;padding:10px;background:#fff;border:1px solid #ddd;border-radius:5px;display:flex;flex-flow:row;flex-wrap:wrap;margin:5px 0 0;
								dl {
									display:flex;flex-flow:row;flex-wrap:wrap;width:50%;
									dt,
									dd {font-weight:500;box-sizing:border-box;position:relative;line-height:1.2;}
									dt {
										width:100px;padding:0 20px 0 0;
										&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:1px;color:#aaa;font-size:15px;}
									}
									dd {flex:1;min-width:0;padding:0 20px 0 10px;}
								}
							}
						}
					}
				}
				.hasLocalDelivery {
					justify-content:space-between;
					dl {
						flex:1;min-width:0;box-sizing:border-box;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						dt,
						dd {box-sizing:border-box;position:relative;font-weight:400;}
						dt {
							width:100px;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;font-size:15px;color:#aaa;right:10px;top:-2px;}
						}
						dd {
							flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;
							input {flex:1;min-width:0;}
							button {margin:0 0 0 10px;}
						}
					}
				}

				.productInfo_column {
					box-sizing:border-box;border-top:1px solid rgba(224, 224, 224, 1);border-right:0 !important;background:#f5f5f5;width: "100%";height:100px;
					
					.productInfo{
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;text-align:left;
						.boxImg {
							box-sizing:border-box;border:1px solid #ddd;width:100px;transition:all .3s ease;
							.imgContainer {
								display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							}
						}
						
						.boxTxt	{
							flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 0px;
							p {
								display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:32px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.3;
								span {transition:all .3s ease;}
							}
						}
						
					}
				}
			}
		}
		#sPayTbl {
			border:0;margin:0 0 20px;
			thead {
				box-sizing:border-box;position:sticky;left:0;top:0;background:#fff;z-index:9;
				tr {
					th {
						padding:10px 5px;line-height:1;font-size:15px;box-sizing:border-box;position:relative;border:0;border-right:1px solid #ddd;
						&:last-child {border-right:0;}
						&:before,
						&:after {content:"";display:block;position:absolute;width:100%;background:#333;left:0;}
						&:before {top:0;height:2px;}
						&:after {bottom:0;height:1px;top:auto;}
						.unit {
							display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;width:100%;align-items:center;align-content:center;
							span {padding:2px 0 0;}
							button {
								font-size:13px;border-radius:5px;box-sizing:border-box;border:solid;border-width:1px;border-color:#ddd #999 #999 #ddd;padding:3px 5px;margin:0 0 0 10px;background:#eee;font-weight:500;transition:all .3s ease;
								&:hover,
								&:focus-visible {border-color:var(--color-1);background:var(--color-1);color:#fff;}
							}
						}
					}
				}
			}
			tbody,
			tfoot {
				tr {
					th {
						word-wrap:break-word;word-break:keep-all;white-space:normal;padding:5px;line-height:1.2;font-size:13px;letter-spacing:-.05em;
						a {color:var(--color-1);text-decoration:underline;}
						&[rowspan] {color:var(--color-1);font-weight:600;}
						.sTxt {font-size:12px;color:#888;letter-spacing:-.025em;font-weight:400;margin:0 0 0 5px;}
					}
					td {
						padding:5px 5px 5px 10px;line-height:1;
						.unit {
							display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;
							.symbol {text-align:left;padding:0;font-size:14px;font-family:"Malgun Gothic";width:25px;}
							.value {text-align:right;padding:0 10px 0 0;font-size:14px;flex:1;min-width:0;color:#333;}
							input {
								min-width:0;max-width:none;text-align:right;flex:1;min-width:0;height:30px;font-size:14px;
								&::-webkit-outer-spin-button,
								&::-webkit-inner-spin-button {-webkit-appearance:none;margin:0}
								&:read-only {border-color:#eee;outline:0;}
							}
						}
					}
				}
			}
			tfoot {
				tr {
					> * {border-top:2px solid #333;background:#fff;}
				}
			}
		}
		dialog {
			&.SecondaryPayInsert {
				.currencyUtils {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
					.commonSummary {margin:0;}
					.btns {
						button {
							margin:0 0 0 10px;
							&:first-child {margin:0;}
						}
					}
					&.top {margin:0 0 10px;}
					&.bottom {margin:10px 0 0;}
				}
				.currencyTbl {
				}
			}
		}
	}

	/** -- 정산 */
	&.Settlement {
		.hasSearch {
			select {width:160px;}
			input {flex:1;min-width:0;margin:0 0 0 10px;}
		}
	}

	/** -- INV/PKL외 Dialog */
	#dialogCustom {
		.popupWrap {
			.popWrap {
				.popHead {
					.popHeadTit {
						padding:2px 0 0;
					}
				}
				.popCont {
					.popContScrollBox {
						font-size:15px;line-height:1.4;text-align:left;
						dd {display:flex;flex-flow:row;flex-wrap:wrap;}
					}
				}
			}
		}

		/** -- INV / PKL 입력 */
		&.InputInvPkl {
			.react-datepicker-wrapper {width:160px;}
			.react-datepicker-popper {transform:translate(0, 40px) !important;}
			.top {
				.btns {
					> * {
						margin:0 10px 0 0;
						&:last-child {margin:0;}
					}
				}
			}
			.frmWr {
				.react-datepicker__input-container {
					display:block;width:100%;
					input[type='text'] {display:block;width:100%;}
				}
				.typeDl1 {
					dt {
						box-sizing:border-box;padding:0 20px 0 0;line-height:1.2;
						> * {word-wrap:break-word;word-break:keep-all;white-space:normal;font-size:14px !important;line-height:1.2;}
					}
					dd {}
				}
				.typeDl5 {
					dt {
						height:34px;margin:0 0 10px;
						> * {word-wrap:break-word;word-break:keep-all;white-space:normal;line-height:1.1;font-size:14px !important;}
					}
				}
				.typeDl1,
				.typeDl5 {
					dt {
						.type {
							display:block;
							.typeStr,
							.typeLbl {
								line-height:1.2;
								&.typeStr {
									display:inline-block;font-size:12px !important;background:var(--color-1);color:#fff;letter-spacing:0;margin:0 0 2px;border-radius:3px;line-height:1;padding:2px 3px 3px;
									&.opa0 {opacity:0;}
								}
								&.typeLbl {display:block;font-size:13px !important;}
							}
						}
					}
				}
				.hasDateTxt {
					input[type='date'] {width:auto;max-width:none;}
					.txt {flex:1;min-width:0;margin:0 0 0 10px;}
				}
				.hasMulti {
					margin:-3px;
					dl {
						padding:3px;
						&:last-child {margin:0;}
						dt {background:#fff;}
						dd {
							padding:0;
							input {width:100%;min-width:0;max-width:none;}
						}
					}
				}
				.hasUnitSelect {
					display:flex;flex-flow:row;flex-wrap:wrap;
					input {flex:1;min-width:0;}
					select {width:70px;margin:0 0 0 5px;min-width:0;padding:0 20px 0 5px;background:#fff url(/assets/img/ico_sel2.png) no-repeat right center}
				}
				.hasUnitStr {
					display:flex;flex-flow:row;flex-wrap:wrap;
					input {flex:1;min-width:0;}
					span {width:auto;margin:0 0 0 5px;min-width:0;text-align:right;box-sizing:border-box;font-size:14px !important;color:#999;}
				}
				.hasSailing {
					.react-datepicker-wrapper {width:100%;}
				}
			}
			.multiSet {
				input[type=number] {-webkit-appearance:auto !important;appearance:auto !important;padding:0 15px 0 10px !important;}
				input[type=number]::-webkit-outer-spin-button,
				input[type=number]::-webkit-inner-spin-button {-webkit-appearance:inner-spin-button !important;appearance:inner-spin-button !important;}
				.btnWr {
					margin:0 0 10px;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:right;
					button {
						min-width:0;margin:0 10px 0 0;
						&:last-child {margin:0;}
					}
				}
				.frmWr {
					input {text-align:right;}
					.unitWr {
						padding:5px 0;
						.unit {
							border-top:0;padding:10px 0 5px;
							dt {display:none;}
							&:first-child {
								border-top:2px solid #aaa;
								dt {display:block;line-height:1;}
							}
							li {
								box-sizing:border-box;flex:none;
								&:nth-child(1) {width:130px;}
								&:nth-child(2) {flex:1;min-width:0;}
								&:nth-child(3) {width:100px;}
								&:nth-child(4) {width:200px;}
								&:nth-child(5) {width:100px;}
								&:nth-child(6) {width:100px;}
								&:nth-child(7) {width:120px;}
								&:nth-child(8) {width:120px;}
								&:nth-child(9) {width:120px;}
								&:last-child {
									dl:last-child:after {display:none;}
								}
								dl {
									&:after {content:"";display:block;position:absolute;width:0;height:100%;right:-5px;top:0;box-sizing:border-box;border-right:1px dashed #ddd;}
								}
							}
							input[type='number'] {padding:0 0 0 10px;}
						}
					}
					.totalWr {
						padding:5px 0;background:#fafafa;
						input {padding:0 15px 0 10px;}
						.lv1-1 {
							border-top:0;padding:0;margin:5px -5px -5px;
							&:first-child {margin:-5px;}
							li {
								flex:none;
								&:nth-child(1) {flex:1;min-width:0;text-align:center !important;}
								&:nth-child(2) {width:100px;}
								&:nth-child(3) {width:200px;}
								&:nth-child(4) {width:100px;}
								&:nth-child(5) {width:100px;}
								&:nth-child(6) {width:120px;}
								&:nth-child(7) {width:120px;}
								&:nth-child(8) {width:120px;}
								.box {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;font-weight:700;letter-spacing:0;position:relative;
									&.head {justify-content:center;display:none;height:40px;}
									&:after {content:"";display:block;position:absolute;width:0px;height:100%;border-right:1px dashed #ddd;right:-5px;top:0;}
									input {flex:1;min-width:0;}
									.unitStr {width:auto;margin:0 0 0 5px;min-width:0;text-align:right;box-sizing:border-box;font-size:14px !important;font-weight:400;color:#999;}
									.unitStr2 {width:70px;margin:0 0 0 5px;box-sizing:border-box;padding:0 0 0 5px;font-weight:400;}
								}
								&.roleLbl {
									flex:2;
									.box {justify-content:center;}
								}
								&:last-child {
									.box {
										&:after {display:none;}
									}
								}
							}
							&:first-child {
								li {
									.box {
										&.head {display:flex;}
									}
								}
							}
						}
					}
				}
			}
		}

		/** -- Shipping Mark 입력 UI */
		&.ShipMark {
			.formBaseSet {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;padding:0 0 30px;margin:0 0 30px;border-bottom:1px solid #ddd;
				.markLen {
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						dt,
						dd {box-sizing:border-box;line-height:1.2;font-weight:400;position:relative;}
						dt {
							color:var(--color-1);padding:0 10px 0 0;margin:0 10px 0 0;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:0px;font-size:14px;color:var(--color-1);}
						}
						dd {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
							.count {padding-right:0;min-width:0;width:120px;}
							.countUnit {padding:0 20px 0 10px;}
							.create {}
						}
					}
				}
				.print {}
			}
			.formLayout {
				display:flex;flex-flow:row;flex-wrap:wrap;padding:40px 0 0;box-sizing:border-box;position:relative;
				> div {
					box-sizing:border-box;position:relative;
					.inner {
						box-sizing:border-box;position:relative;display:flex;flex-flow:column;flex-wrap:wrap;
						.head {box-sizing:border-box;background:#f5f5f5;border-bottom:1px solid #ddd;width:100%;}
						.body {
							box-sizing:border-box;border-top:0;flex:1;min-height:0;overflow-y:auto;overflow-x:hidden;width:100%;
							&::-webkit-scrollbar {width:9px}
							&::-webkit-scrollbar-thumb {background:#aaa;border-right:3px solid #fff;border-left:3px solid #fff;}
							&::-webkit-scrollbar-track {background:#ddd;border-right:3px solid #fff;border-left:3px solid #fff;}
							&::-webkit-scrollbar-button {height:3px}
							&:hover::-webkit-scrollbar-thumb {background:var(--color-1)}
							.unitWr {
								> li {
									box-sizing:border-box;border-bottom:1px solid #ddd;background:#fff;transition:all .3s ease;
									&:last-child {border-bottom:0;}
									&.expand {background:rgba(17, 101, 224, .05);}
									&.on {
										.toggle {
											.toggler {
												background:#333;border-color:#333;
												&:after {border-color:#fff;transform:rotate(225deg);margin:6px auto auto auto;}
											}
										}
										.sUnit {display:block;}
									}
								}
							}
						}
						.unit {
							.unitElement {
								display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;
								li {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;box-sizing:border-box;line-height:1;height:40px;border-right:1px solid #ddd;
									&:last-child {border-right:0;}
									&.check {
										width:40px;
										.chks {
											label {
												padding:0;margin:0;
												&:before {position:relative;left:auto;top:auto;}
												&:after {top:4px;}
											}
										}
									}
									&.toggle {
										width:40px;
										button {
											width:17px;height:17px;border:1px solid #ccc;background:#f5f5f5;font-size:0;box-sizing:border-box;position:relative;transition:all .3s ease;
											&:after {content:"";display:block;position:absolute;width:7px;height:7px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(45deg);top:0;right:0;bottom:0;left:0;margin:3px auto auto auto;transition:all .3s ease;}
											&:hover,
											&:focus-visible {
												border-color:var(--color-1);background:var(--color-1);
												&:after {border-color:#fff;}
											}
										}
									}
									&.info {flex:1;min-width:0;justify-content:left;padding:0 0 0 15px;}
								}
							}
							.sUnit {
								display:none;padding:0 0 10px;background:#fff;
								.unitElement {
									background:#fff;transition:all .3s ease;
									&.expand {background:rgba(17, 101, 224, .05);}
									li {
										height:30px;box-sizing:border-box;position:relative;
										&:before {content:"";display:block;position:absolute;width:1px;height:100%;left:-1px;top:10px;background:#ddd;}
										&.info {
											span {width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;font-size:14px;color:#777;padding:0 0 0 20px;}
										}
									}
								}
							}
						}
					}
				}
				.sellector {
					flex:1;min-width:0;border:2px solid #ddd;
					.inner {
						height:450px;
					}
				}
				.convertor {
					width:100px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
					.inner {
						button {
							display:block;width:40px;height:40px;margin:0 auto 10px;box-sizing:border-box;border:1px solid #ddd;color:#777;transition:all .3s ease;
							i {font-size:20px;}
							&:last-child {margin-bottom:0;}
							&:hover,
							&:focus-visible {border-color:var(--color-1);background:var(--color-1);color:#fff;}
						}
					}
				}
				.configuration {
					flex:1;min-width:0;
				}
			}
		}

		/** -- B/L */
		&.BL {}

		/** -- 적화보험 */
		&.cargoInsurance {}

		/** -- 수출면장 */
		&.ExportLicense {}

		/** -- 운송장(센디/경동) */
		&.DomesticTrans {
			.hasTxtBtn {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				.txt {
					> * {display:inline-flex;box-sizing:border-box;position:relative;line-height:1;}
					.txt1 {
						padding:0 10px 0 0;margin:0 10px 0 0;box-sizing:border-box;position:relative;
						&:before {content:"";display:block;position:absolute;width:2px;height:14px;background:#ddd;right:0;top:50%;transform:rotate(30deg) translateY(-50%);}
					}
					.txt2 {margin:0 10px 0 0;}
				}
				.btn {}
			}
			.hasProduct {
				textarea {display:block;width:100%;height:150px;resize:none;}
			}
		}

		/** -- 배송조회 */
		&.DeliveryView {
			.current {
				background:#eee;box-sizing:border-box;padding:18px 10px 20px;text-align:center;line-height:1;
				em {font-style:normal;color:#000;font-weight:700;}
			}
			.flow {
				margin:20px 0 0;
				ol {
					display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;
					li {
						box-sizing:border-box;position:relative;
						&:after {content:"";display:block;position:absolute;width:70%;height:2px;background:#ddd;left:100%;top:50%;transform:translateY(-50%);}
						.boxIco {}
						.boxTxt {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;box-sizing:border-box;border:2px solid #ddd;line-height:1;width:100px;height:100px;border-radius:50% !important;background:#fff;position:relative;z-index:1;
							span {line-height:1;letter-spacing:-.05em;}
						}
						&.on {
							&:after {background:linear-gradient( to right, var(--color-1) 0%, #ddd 100% )}
							.boxTxt {
								background:var(--color-1);border-color:var(--color-2);color:#fff;
							}
						}
					}
				}
			}
			.history {
				margin:30px 0 0;
				ol {
					li {
						dl {
							display:flex;flex-flow:row;flex-wrap:wrap;
							dt,
							dd {line-height:1.2;box-sizing:border-box;position:relative;}
							dt {
								padding:0 20px 0 0;font-weight:500;
								.date {}
								.time {margin:0 0 0 10px;}
							}
							dd {
								flex:1;min-width:0;padding:0 0 20px 20px;
								&:before,
								&:after {content:"";display:block;position:absolute;}
								&:before {width:8px;height:8px;border-radius:50%;border:2px solid #ddd;background:#fff;left:-3px;top:6px;z-index:1;}
								&:after {width:2px;height:100%;background:#ddd;left:0;top:6px;z-index:0;}
							}
						}
						&:first-child {
							dl {
								dd {
									&:before {border-color:var(--color-1);}
								}
							}
						}
					}
				}
				.cargoState {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					> span {
						box-sizing:border-box;position:relative;padding:0 10px 0 0;margin:0 8px 0 0;
						&:after {content:"";display:block;position:absolute;width:2px;height:15px;background:#ddd;transform:rotate(20deg);right:0;top:2px;}
					}
				}
			}
		}

		/** -- 판매자 QC */
		&.SellerQc {
			.commonSummary {
				align-items:center;align-content:center;justify-content:space-between;
				dl:last-of-type {
					padding:0;margin:0 auto 0 0;
					&:after {display:none;}
				}
				.QcState {
					em {font-weight:700;}
				}
			}
			.frmWr {
				.mediaUl {
					display:flex;flex-flow:row;flex-wrap:wrap;
					li {
						width:100px;margin:0 10px 0 0;
						&:last-child {margin:0;}
						.mediaContainer {
							height:0;box-sizing:border-box;padding:0 0 100%;position:relative;background:#eee;border:1px solid #ddd;
							> * {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
						}
					}
				}
				.hasComment {word-wrap:break-word;word-break:keep-all;white-space:normal;}
			}
			.qcHistory {
				li {
					padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid #ddd;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					&:last-child {margin:0;}
					.num {color:var(--color-1);width:80px;}
					.state {width:120px;text-align:center;}
					.date {flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 15px;}
				}
			}
		}

		/** -- 현지 배송정보 */
		&.Localdelivery {
			.frmWr {
				.hasTracking {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					input {flex:1;min-width:0;margin:0 10px 0 0;}
				}
			}
		}
	}
}

.dateCompo {
	display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
	.dateUnit {
		display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;border-radius:4px;border:1px solid #ddd;
		.dateText {height:40px;width:120px;text-align:center;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;cursor:pointer;border:0;justify-content:center;}
		.dateLable {}
		.dateInput {
			width:40px;height:40px !important;border:0;border-left:1px solid #ddd;cursor:pointer;transition:all .3s ease;
			&:hover,
			&:focus-visible {background:#eee;}
		}
	}
	.dateTilde {padding:0 10px;}
}

#dialogCustom.additionalServices {
	.productInfos {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;border:1px solid #ddd;padding:10px;border-radius:5px;
		.prodImg {
			width:70px;height:70px;box-sizing:border-box;position:relative;border:1px solid #ddd;
			img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
		}
		.prodName {flex:2;min-width:0;word-wrap:break-word;word-break:keep-all;white-space:normal;box-sizing:border-box;padding:0 15px;}
		.prodQty {
			display:flex;flex-flow:row;flex-wrap:wrap;
			dt,
			dd {line-height:1;box-sizing:border-box;font-weight:500;position:relative;}
			dt {
				padding:0 10px 0 0;margin:0 10px 0 0;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;color:#aaa;right:0;top:0px;font-size:13px;}
			}
			dd {padding:0 10px 0 0;}
		}
	}
	.productServices {
		dl {
			border-bottom:1px solid #ddd;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
			&:first-child {border-top:2px solid #aaa;}
			dt,
			dd {box-sizing:border-box;line-height:1;position:relative;}
			dt {
				font-weight:400;width:150px;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;font-size:13px;color:#aaa;right:0;top:3px;}
			}
			dd {
				flex:1;min-width:0;
				ul {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					> li {
						flex:1;min-width:0;text-align:center;padding:5px 0;box-sizing:border-box;position:relative;
						&:after {content:"";display:block;position:absolute;width:1px;height:15px;right:0;top:50%;transform:translateY(-50%);background:#ddd;}
						&:last-child {
							padding:10px 10px 12px;width:150px;flex:none;
							&:after {display:none;}
						}
						.uploadBtn {
							display:block;width:100%;min-width:0;height:30px;
							&:before {height:100%;}
							span {font-size:15px;margin:0;}
						}
					}
				}
			}
		}
	}
	.productUpload {
		display:none;
		&.on {display:block;}
		h3 {
			em {font-style:normal;color:var(--color-1);}
		}
		li {
			box-sizing:border-box;
			dl {
				dd {
					box-sizing:border-box;padding:0 40px 0 0;
					.uploadType {width:100%;}
					.uploadMsg {display:block;width:100%;height:100px;resize:none;}
					.uploadInput {}
				}
			}
		}
	}
	.productHistory {
		.unit {
			box-sizing:border-box;border-radius:4px;padding:10px 15px 15px;margin:0 0 10px;border:1px solid #ddd;
			&:last-child {margin:0;}
			dt {color:#333;font-weight:500;}
			dd {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;padding:0 5px 0 0;
				.date {flex:1;min-width:0;}
				.down {
					height:30px;width:140px;min-height:0;
					span {font-size:15px;margin:0;}
				}
				.msg {
					display:flex;flex-flow:row;flex-wrap:wrap;width:100%;box-sizing:border-box;font-size:15px;position:relative;color:#777;
					.text {}
					.writer {display:block;width:100%;margin:5px 0 0;font-size:13px;}
					&.repl {
						padding-left:50px;
						&:before,
						&:after {display:block;position:absolute;box-sizing:border-box;border:solid;}
						&:before {content:"";width:7px;height:9px;border-width:0 0 1px 1px;border-color:var(--color-1);left:5px;top:7px;}
						&:after {content:"Re :";border:0;font-size:12px;line-height:1;background:var(--color-1);color:#fff;border-radius:4px;left:12px;top:7px;padding:2px 5px 3px;}
					}
				}
			}
		}
	}
}
.strStyle1 {
	width:100%;line-height:1;border-radius:4px;padding:4px 0 3px;box-sizing:border-box;border:solid;border-width:1px;color:#fff;letter-spacing:-.05em;font-size:12px;font-weight:bold;
	&.state1 {background:#66a0b6;border-color:#66a0b6;}
	&.state2 {background:#c9801a;border-color:#c9801a;}
	&.state3 {background:#FF6961;border-color:#FF6961;}
	&.state4 {background:#36b136;border-color:#36b136;}
	&.state5 {background:#a1a129;border-color:#a1a129;}
	&.state6 {background:#bb68b8;border-color:#bb68b8;}
	&.state7 {background:#ca3c7c;border-color:#ca3c7c;}
	&.state8 {background:#a1a144;border-color:#a1a144;}
	&.state9 {background:#86398d;border-color:#86398d;}
}
.strStyle2 {
	width:100%;line-height:1;padding:0 0 1px;box-sizing:border-box;letter-spacing:0;font-size:12px;
}
.btnStyle1 {
	display:block;width:100%;line-height:1;border-radius:4px !important;padding:4px 3px;background:#eee;border:solid;border-width:1px;border-color:#ddd #999 #999 #ddd;margin:0;transition:all .3s ease;
	.br {
		display:block;margin:0 0 2px;
		&:last-child {margin:0;}
	}
	i {margin:0 2px 0 0;}
	&:hover,
	&:focus-visible {border-color:#000;background:#000;color:#fff;}
}
.btnStyle2 {
	display:inline-block;line-height:1;border-radius:4px !important;padding:5px;background:#eee;border:solid;border-width:1px;border-color:#ddd #999 #999 #ddd;margin:0;transition:all .3s ease;
	&:hover,
	&:focus-visible {border-color:#000;background:#000;color:#fff;}
}
.btnStyle3 {
	display:inline-block;line-height:1;border-radius:4px !important;padding:4px 3px;background:#eee;border:solid;border-width:1px;border-color:#ddd #999 #999 #ddd;margin:0 2px;color:#000 !important;transition:all .3s ease;
	&:hover,
	&:focus-visible {border-color:#000;background:#000;color:#fff !important;}
}
.btnStyle4 {
	display:inline-block;max-width:100%;line-height:1;border-radius:4px !important;padding:5px;background:#eee;border:solid;border-width:1px;border-color:#ddd #999 #999 #ddd;margin:0;transition:all .3s ease;
	span {display:block;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;}
	&:hover,
	&:focus-visible {border-color:#000;background:#000;color:#fff;}
}
.listStyle1 {
	line-height:1;
	li {}
}
.listStyle2 {
	display:block;line-height:1;width:100%;
	li {
		display:block;width:100%;text-align:center;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;margin:0 0 5px;
		&:last-child {margin:0;}
	}
}

/** -- INV / PKL 출력 */
#printTbl {
	.pTxt0,
	.pTxt1,
	.pTxt2,
	.pTxt3,
	.pTxt4 {display:block;word-wrap:break-word;word-break:keep-all;white-space:normal;margin:0 0 5px;word-wrap:break-word;word-break:keep-all;white-space:normal}
	.pTxt0 {font-size:13px !important;letter-spacing:0;color:#999;line-height:1.2;}
	.pTxt1 {font-size:20px !important;font-weight:700;line-height:1.3;}
	.pTxt2 {font-size:13px !important;line-height:1.3;}
	.pTxt3 {font-size:15px !important;font-weight:500;line-height:1.4;}
	.pTxt4 {font-size:14px !important;font-weight:500;line-height:1.2;}
	.pSet1 {
		display:flex;flex-flow:row;flex-wrap:wrap;
		> * {
			width:50%;box-sizing:border-box;position:relative;line-height:1.2;
			&:last-child {
				padding:0 0 0 10px;
				&:before {content:"";display:block;position:absolute;width:1px;height:13px;left:0;top:3px;background:#aaa;transform:rotate(30deg);}
			}
		}
	}
	.pPkg1 {
		display:flex;flex-flow:row;flex-wrap:wrap;
		> * {flex:1;min-width:0;margin:0;}
	}
	table {
		table-layout:fixed;box-sizing:border-box;border-top:2px solid #aaa;border-collapse:collapse;min-height:100vh;
		.col1 {width:25%;}
		.col2 {width:25%;}
		.col3 {width:25%;}
		.col4 {width:25%;}
		thead {
			tr {
				height:0;
				.title {text-align:center;font-size:25px !important;color:#000;font-weight:700;height:50px !important;padding:0;line-height:1;vertical-align:middle;}
			}
		}
		tbody {
			tr {
				height:0;
				td {
					border:solid;box-sizing:border-box;position:relative;border-color:#ddd;border-width:1px 0 1px 0;vertical-align:top;padding:10px;height:0;
					&.borl1 {border-left-width:1px}
				}
				&.list {
					height:auto;
					td {height:auto;}
				}
			}
		}
	}
	.listWr {
		box-sizing:border-box;padding:0 0 20px;
		.listLbl {
			display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;
			> li {
				box-sizing:border-box;padding:0 20px 0 0;line-height:1.3;text-align:right;
				&:nth-child(1),
				&:nth-child(2) {text-align:left;}
				&:last-child {padding:0;}
				> * {margin:0;}
			}
		}
		.listStr {
			display:flex;flex-flow:row;flex-wrap:wrap;width:100%;margin:10px 0 0;padding:10px 0 0;border-top:1px solid #eee;page-break-inside:avoid;
			&:first-child {margin:0;padding:0;border-top:0;}
			dt,
			dd {box-sizing:border-box;}
			dd {
				flex:1;min-width:0;
				ul {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 10px;
					&:last-child {margin:0;}
					li {
						box-sizing:border-box;padding:0 20px 0 0;text-align:right;line-height:1.3;
						&:first-child {text-align:left;}
						&:last-child {padding:0;}
						.pTxt4 {margin:0;}
						.unitNum {font-size:14px;letter-spacing:0 !important;}
						.unitSym {font-size:14px;font-family:"Monospace" !important;font-weight:bold;letter-spacing:0 !important;padding:0 0 0 5px;}
					}
				}
			}
			&.sub-total {
				dt {
					> * {font-weight:700 !important;color:#000 !important;margin:0 !important;}
				}
			}
			&.total {
				justify-content:space-between;
				dt {
					> * {font-weight:700 !important;color:#000 !important;margin:0 !important;}
				}
				dd {
					flex:none;text-align:right;
					> * {margin:0 !important;}
				}
			}
		}
	}
	.totalWr {
		box-sizing:border-box;position:relative;
		.total {display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 20px;}
	}
	&.INV {
		.listWr {
			.listLbl {
				> li {
					&:nth-child(1) {width:140px;}
					&:nth-child(2) {flex:1;min-width:0;}
					&:nth-child(3) {width:130px;}
					&:nth-child(4) {width:130px;}
					&:nth-child(5) {width:170px;}
					&:nth-child(6) {width:140px;}
				}
			}
			.listStr {
				dt {width:140px;}
				dd {
					flex:1;min-width:0;
					ul {
						li {
							&:nth-child(1) {flex:1;min-width:0;}
							&:nth-child(2) {width:130px;}
							&:nth-child(3) {width:130px;}
							&:nth-child(4) {width:170px;}
							&:nth-child(5) {width:140px;}
						}
					}
				}
			}
		}
		.totalWr {
			.total {justify-content:space-between;}
		}
	}
	&.PKL {
		.listWr {
			.listLbl {
				> li {
					&:nth-child(1) {width:140px;}
					&:nth-child(2) {flex:1;min-width:0;}
					&:nth-child(3) {width:130px;}
					&:nth-child(4) {width:130px;}
					&:nth-child(5) {width:170px;}
					&:nth-child(6) {width:140px;}
					&:nth-child(7) {width:60px;}
				}
			}
			.listStr {
				dt {width:140px;}
				dd {
					flex:1;min-width:0;
					ul {
						li {
							&:nth-child(1) {flex:1;min-width:0;}
							&:nth-child(2) {width:130px;}
							&:nth-child(3) {width:130px;}
							&:nth-child(4) {width:170px;}
							&:nth-child(5) {width:140px;}
							&:nth-child(6) {width:60px;}
						}
					}
				}
			}
		}
		.totalWr {
			.total {justify-content:space-between;}
		}
	}
}
@media print {
	#printTbl {
		.pTxt0 {font-size:10px !important;}
		.pTxt1 {font-size:13px !important;}
		.pTxt2 {font-size:11px !important;}
		.pTxt3 {font-size:11px !important;}
		.pTxt4 {font-size:11px !important;}
		table {
			tbody,
			tfoot,
			tr,
			td {page-break-inside:avoid;}
			thead {
				tr {
					.title {font-size:20px !important;}
				}
			}
		}
		.listWr {
			.listLbl {
				> li {padding:0 10px 0 0;}
			}
			.listStr {
				dd {
					ul {
						li {padding:0 10px 0 0;}
					}
				}
			}
		}
		&.INV {
			.listWr {
				.listLbl {
					> li {
						&:nth-child(1) {width:100px;}
						&:nth-child(2) {flex:1;min-width:0;}
						&:nth-child(3) {width:100px;}
						&:nth-child(4) {width:100px;}
						&:nth-child(5) {width:120px;}
						&:nth-child(6) {width:80px;}
					}
				}
				.listStr {
					dt {width:100px;}
					dd {
						ul {
							li {
								&:nth-child(1) {flex:1;min-width:0;}
								&:nth-child(2) {width:100px;}
								&:nth-child(3) {width:100px;}
								&:nth-child(4) {width:120px;}
								&:nth-child(5) {width:80px;}
								.unitNum {font-size:11px;}
								.unitSym {font-size:11px;}
							}
						}
					}
				}
			}
		}
		&.PKL {
			.listWr {
				.listLbl {
					display:flex;flex-flow:row;flex-wrap:wrap;
					> li {
						&:nth-child(1) {width:100px;}
						&:nth-child(2) {flex:1;min-width:0;}
						&:nth-child(3) {width:80px;}
						&:nth-child(4) {width:80px;}
						&:nth-child(5) {width:80px;}
						&:nth-child(6) {width:80px;}
						&:nth-child(7) {width:60px;}
					}
				}
				.listStr {
					dt {width:100px;}
					dd {
						flex:1;min-width:0;
						ul {
							li {
								&:nth-child(1) {flex:1;min-width:0;}
								&:nth-child(2) {width:80px;}
								&:nth-child(3) {width:80px;}
								&:nth-child(4) {width:80px;}
								&:nth-child(5) {width:80px;}
								&:nth-child(6) {width:60px;}
							}
						}
					}
				}
			}
			.totalWr {
				.total {justify-content:space-between;}
			}
		}
	}
}

/** -- Shipping Mark 출력 */
#printShippingMark {
	.frmController {
		display:none;flex-flow:row;flex-wrap:wrap;position:absolute;right:0;bottom:100%;align-items:center;align-content:center;line-height:1;margin:0 0 10px;
		&.on {display:flex;}
		button {
			width:30px;height:30px;box-sizing:border-box;border:1px solid #ddd;position:relative;transition:all .3s ease;
			&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;top:0;right:0;bottom:0;left:0;transition:all .3s ease;}
			&.prev:after {transform:rotate(135deg);margin:auto auto auto 11px;}
			&.next:after {transform:rotate(-45deg);margin:auto 11px auto auto;}
			&:hover,
			&:focus-visible {
				border-color:var(--color-1);background:var(--color-1);
				&:after {border-color:#fff;}
			}
		}
		.nums {line-height:1;padding:0 10px;}
	}
	.inner {
		display:block;height:450px;overflow-x:hidden;overflow-y:auto;
		&::-webkit-scrollbar {width:8px}
		&::-webkit-scrollbar-thumb {background:#aaa;border-left:5px solid #fff;}
		&::-webkit-scrollbar-track {background:#ddd;border-left:5px solid #fff;}
		&::-webkit-scrollbar-button {height:0}
		&:hover::-webkit-scrollbar-thumb {background:var(--color-1)}
		.shipMarkLayout {
			display:none;flex-flow:column;flex-wrap:wrap;width:100%;height:100%;box-sizing:border-box;border:2px solid #ddd;
			&.on {display:flex;}
			li {
				flex:1;min-height:0;box-sizing:border-box;border-bottom:1px solid #ddd;width:100%;
				&:nth-child(3) {
					flex:2;
					textarea,
					select {display:block;width:100%;height:100%;font-size:12px;}
					select {
						overflow:auto;padding:10px;background:#fff;outline:0;
						option {font-size:12px;}
					}
				}
				&:last-child {border-bottom:0;}
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;height:100%;width:100%;
					dt,
					dd {box-sizing:border-box;position:relative;line-height:1.2;word-wrap:break-word;word-break:keep-all;white-space:normal;height:100%;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;}
					dt {width:120px;background:#f5f5f5;font-size:15px;font-weight:500;}
					dd {
						padding:15px;flex:1;min-width:0;
						> * {
							width:100%;resize:none;min-width:0;max-width:none;
							&.frmShipper,
							&.frmConsignee,
							&.frmBoxNo,
							&.frmMadeIn {text-align:center;}
							&.frmCommodity {text-align:left;}
						}
					}
				}
			}
		}
	}
}
@media print {
	#printShippingMark {
		.frmController {display:none;}
		.inner {
			height:auto;overflow:hidden;
			.shipMarkLayout {
				display:flex;page-break-before:always;page-break-inside:avoid;height:100vh;border:1mm solid #333;
				li {
					flex:.75;border-bottom:1mm solid #333;
					&:nth-child(3) {flex:2.75;}
					dl {
						dt,
						dd {
							font-size:8mm;color:#000;
							* {color:#000;}
						}
						dt {width:75mm;background:#fff;border-right:1mm solid #333;}
						dd {
							input,
							textarea {border:0;}
							textarea {font-size:5mm !important;}
						}
					}
				}
			}
		}
	}
}

/* ==============================
* Seller : 스토어 관리 > 기본 정보
================================= */
.sellerStore {
	.hasDate {
		.react-datepicker-wrapper {
			display:block;width:100%;
			input {min-width:0;max-width:100%;}
		}
	}
	.hasWorker {
		input {min-width:0;max-width:none;flex:1}
		span {width:30px;text-align:right;}
	}
	.hasSite {
		input {min-width:0;max-width:none;}
	}
	.hasMngr {
	}
	.hasDescription {
		textarea {display:block;width:100%;height:150px;resize:none;}
	}
	.hasConfig {
		justify-content:space-between;
		dl {
			display:flex;flex-flow:row;flex-wrap:wrap;flex:1;
			dt,
			dd {box-sizing:border-box;line-height:1.2;position:relative;font-weight:400;color:#000;font-size:.941em;}
			dt {
				padding:0 10px 0 0;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:17px;right:3px;top:-1px;font-weight:400;}
			}
			dd {
				a {
					color:#000;text-decoration:underline;
					em {font-weight:500;font-style:normal;}
					&:focus-visible,
					&:hover {color:var(--color-1);}
				}
			}
		}
		.customBtn1 {font-size:14px;height:30px !important;min-width:0;padding:0;width:100px !important;}
	}
	.hasImg {
		width:100%;
		.unit {
			display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;width:100%;align-items:center;align-content:center;padding:5px 0 0;margin:5px 0 0;border-top:1px solid var(--color-db);
			&:first-child {padding:0;margin:0;border-top:0;}
			.selectedImg {
				width:100px;box-sizing:border-box;border:1px solid #ddd;margin:0 10px 0 0;
				.imgContainer {
					display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
					img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
				}
			}
			.imgSelector {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				.customInp1 {
					width:200px;height:30px;box-sizing:border-box;border:1px solid var(--color-db);border-radius:4px;margin:0 5px 0 0;padding:2px;overflow:hidden;
					input {display:block;width:100%;min-width:0;max-width:none;}
				}
				.customInp2 {
					flex:1;min-width:0;height:30px;box-sizing:border-box;border:1px solid var(--color-db);border-radius:4px;padding:2px;overflow:hidden;
					input {display:block;width:100%;min-width:0;max-width:none;}
				}
				.customBtn1 {font-size:14px;height:30px !important;min-width:0;padding:0;width:100px !important;}
			}
			.imgController {
				button {
					width:30px;height:30px;border-radius:4px;border:1px solid var(--color-db);background:transparent;box-sizing:border-box;position:relative;margin:0 5px 0 0;transition:all .3s ease;
					&:last-child {margin:0;}
					&:before,
					&:after {content:"";display:block;position:absolute;width:10px;height:2px;background:#aaa;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
					&.minus {}
					&.plus {
						&:after {transform:rotate(90deg);}
					}
					&:hover,
					&:focus-visible {
						border-color:var(--color-1);background:var(--color-1);
						&:before,
						&:after {background:#fff;}
					}
				}
			}
			&:first-child {
				.imgController {
					.minus {display:none;}
				}
			}
		}
	}
}

/* ==============================
* Seller : 상품 관리 > 상품목록 / 상품등록
================================= */
.sellerProduct {
	h3,
	h4 {
		box-sizing:border-box;position:relative;
		.fakeAnchor {width:0;height:0;position:absolute;z-index:-1;left:0;top:-250px;}
	}
	&.List {
		.haskeyword {
			select {width:130px;margin:0 10px 0 0;}
			input {flex:1;height:44px !important;}
		}
		.hasPeriod {
			justify-content:space-between;
			.period {
				display:flex;flex-flow:row;flex-wrap:wrap;
				select {width:130px;margin:0 10px 0 0;}
				.react-datepicker-wrapper {
					width:170px;
					input {min-width:0;max-width:100%;width:100%;}
				}
				.tilde {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;padding:0 10px;}
			}
			.btns {
				display:flex;flex-flow:row;flex-wrap:wrap;
				.rdos2 {
					margin:0 10px 0 0;
					&:last-child {margin:0;}
					input {display:block;position:absolute;width:0;height:0;overflow:hidden;top:-9999em;left:-9999em;text-indent:-9999em;font-size:0;line-height:0;opacity:0;}
					label {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;height:34px;width:65px;text-align:center;box-sizing:border-box;position:relative;cursor:pointer;border-radius:4px;box-sizing:border-box;border:1px solid #ccc;background:#f7f7f7;transition:all .3s ease;
						&:hover {border-color:var(--color-1);color:var(--color-1);}
					}
					input:checked + label {border-color:var(--color-1);background:var(--color-1);color:#fff !important;}
					input:focus-visible + label {outline:3px solid rgba(59, 153, 252, .5);}
					input:hover + label {border-color:var(--color-1);color:var(--color-1);}
				}
			}
		}
		.hasDelivery {
			select {width:130px;margin:0 20px 0 0;}
			ul {flex:1}
		}
		.hidden {
			&.on {display:block;}
		}
		.cBtnBox1 {
			.detail {
				position:absolute;right:0;top:0;margin:0;
				span {
					position:relative;padding:0 20px 0 0;
					&:after {content:"";display:block;position:absolute;width:10px;height:10px;border:solid;box-sizing:border-box;border-width:0 2px 2px 0;border-color:#aaa;right:0;top:3px;transform:rotate(45deg);transition:all .3s ease;}
				}
				&:focus-visible,
				&:hover {
					span:after {border-color:#fff;}
				}
				&.on {
					border-color:var(--color-2);background:var(--color-2);color:#fff !important;
					span {
						&:after {transform:rotate(225deg);top:9px;border-color:#fff;}
					}
				}
			}
		}

		/** -- Products table */
		.myContBtns {}
		.productsTbl {
			.colMngr {width:70px;}
			.colProd {width:auto;}
			.colImg {width:120px;}
			.colState {width:120px;}
			.colMoq {width:100px;}
			.colPrice {width:100px;}
			.colDlvr {width:100px;}
			.colOpt {width:100px;}
			.colDate {width:100px;}
			thead th {
				letter-spacing:-.05em;padding:10px 5px;font-size:.941em;
				&:after {height:40%;margin:0;transform:translateY(-50%);}
			}
			tbody td {
				padding:5px;word-wrap:break-word;word-break:keep-all;white-space:normal;
				.noDataWrap {
					padding:80px 0;
					i {font-size:3em;}
				}
			}
			.prodTools {
				display:flex;flex-flow:column;flex-wrap:wrap;
				button {
					width:60px;height:25px;min-width:0;max-width:none;width:auto;line-height:1;border-radius:3px;margin:0 0 5px;
					&:last-child {margin:0;}
					span {font-size:.882em;letter-spacing:-.05em;line-height:1;margin:-3px 0 0;}
					&:before {height:20px;}
				}
			}
			.prodChk {
				text-align:left;padding:5px 15px;
				.chks {
					display:block;margin:0 0 10px;
					label {
						display:block;letter-spacing:0;padding:0 0 0 25px;line-height:1;outline:0;
						&:before {top:0;}
						&:after {top:4px;}
					}
				}
				.name {
					display:block;line-height:1.2;color:#111;font-weight:500;
					span {transition:all .3s ease;}
					&:focus-visible,
					&:hover {
						span {color:var(--color-1);box-shadow:0 -10px 0 rgba(47, 96, 251, .2) inset;}
					}
				}
				.move {
					&:hover,
					&:focus-visible {text-decoration:underline;}
				}
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 5px;
					&:last-child {margin:0;}
					dt,
					dd {box-sizing:border-box;line-height:1;position:relative;font-size:14px;font-weight:400;}
					dt {
						padding:0 8px 0 0;margin:0 5px 0 0;
						&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:0;top:2px;}
					}
					dd {flex:1;min-width:0;letter-spacing:0;}
				}
			}
			.prodImg {
				display:block;box-sizing:border-box;border:1px solid #ddd;width:100%;transition:all .3s ease;
				.imgContainer {
					display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
					img,
					video {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
					.fileCrack,
					.fileNone {
						line-height:1.2;box-sizing:border-box;padding:0 10px;position:absolute;width:100%;height:100%;left:0;top:0;
						&:after {content:"\ecd7";display:block;position:absolute;width:30px;height:30px;line-height:1;font-size:30px;font-family:"remixicon" !important;top:0;right:0;bottom:0;left:0;margin:auto;color:#aaa;}
					}
				}
				&:focus-visible,
				&:hover {
					border-color:var(--color-1);
					.imgContainer {
						img {transform:scale(1.2);}
					}
				}
			}
			.prodState {
				p {
					display:block;line-height:1;text-align:center;margin:0 0 5px;
					&:last-child {margin:0;}
					span {
						display:inline-block;line-height:1;color:#fff;font-size:14px;width:70px;padding:3px 0 4px;border-radius:3px;
						&.approval {
							&.state1 {background:var(--color-1);}	/* 승인완료 */
							&.state2 {background:var(--color-3);}	/* 승인대기 */
							&.state3 {background:var(--color-r1);}	/* 승인거절 */
						}
						&.sales {
							&.state1 {background:var(--color-1);}	/* 판매가능 */
							&.state2 {background:var(--color-r1);}	/* 판매중지 */
						}
						&.exposure {
							&.state1 {background:var(--color-1);}	/* 노출 */
							&.state2 {background:var(--color-r1);}	/* 미노출 */
						}
					}
				}
			}
			.prodMoq {}
			.prodPrice {}
			.prodDlvr {
				span {
					display:block;line-height:1.2;border-bottom:1px solid #ddd;padding:3px 0 4px;
					&:last-child {border-bottom:0;}
					&.local {}
					&.type {}
				}
			}
			.prodOpt {}
			.prodDate {
				span {
					display:block;line-height:1.2;border-bottom:1px solid #ddd;padding:3px 0 4px;
					&:last-child {border-bottom:0;}
					&.posted {}
					&.modified {}
				}
			}
		}
	}
	&.Register {
		.c-info1 {
			a {
				color:var(--color-1);text-decoration:underline;font-weight:500;transition:all .3s ease;
				span {}
				&:hover,
				&:focus-visible {color:#000;}
			}
		}
		.a-tab01 {
			position:sticky;left:0;top:90px;z-index:5;padding:20px 0 10px;margin:-20px 0 0;background:#fff;
			li.on {
				a {background:#fff;}
			}
		}
		.cateSelectorBox {
			background:#fff;position:sticky;left:0;top:170px;z-index:5;padding:10px;overflow:visible;
			.typeDl2 {
				position:relative;z-index:1;
				dt {width:160px !important;}
				.hasCate {
					width:calc( 100% - 160px ) !important;padding:0 0 0 10px !important;align-items:flex-start !important;align-content:flex-start !important;
					.schTxt {
						order:2;width:auto;margin:0 0 0 10px;
						.cateSchResult {display:none;}
						.cateSchForm {
							.schResBox {max-height:178px;background:#fff;}
						}
					}
					.schUI {
						flex:1;min-width:0;width:auto;padding:0 !important;order:1;
						.hideNav {
							nav {
								.dUnitTit {font-size:1em;padding:5px 0;letter-spacing:-.05em;display:block;position:absolute;width:0;height:0;overflow:hidden;top:-9999em;left:-9999em;text-indent:-9999em;font-size:0;line-height:0;opacity:0;}
								.dUnit {
									height:200px;padding:5px;
									ul {
										li {margin:0;}
									}
									select {width:100%;}
								}
								.depth {
									.parentReqMsg {margin:10px !important;}
								}
							}
						}
					}
				}
			}
		}
		.hasNames {
			justify-content:space-between;
			dl {margin:10px 0 0 !important;}
			.prodNameOriginal {
				display:flex;flex-flow:row;flex-wrap:wrap;width:100%;margin:0 !important;
				dt,
				dd {}
				dt {width:60px;padding:12px 0 0;}
				dd {
					flex:1;min-width:0;
					input {width:100%;}
				}
			}
			.prodNameNation {
				width:48% !important;padding:0 !important;border-bottom:0 !important;
				button {
					margin:0 0 0 10px;line-height:1;border:1px solid #aaa;background:#eee;border-radius:4px;padding:3px 5px 4px;font-size:14px;line-height:1;transition:all .3s ease;
					span {display:block;line-height:1;}
					&:hover,
					&:focus-visible {background:var(--color-1);border-color:var(--color-1);color:#fff;}
				}
			}
		}
		.hasExposure {
			.exposureByLevel {
				width:auto !important;height:30px !important;font-size:14px;padding:0 5px;margin:2px 0 0 5px;
				span {margin:-2px 0 0;}
			}
		}
		.hasPrcie {
			width:100%;
			.unit {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;width:100%;margin:0 0 10px;
				&:last-child {margin:0;}
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					dt,
					dd {}
					dt {width:50px;font-weight:500;}
					dd {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						input {width:135px;}
						.unitStr {color:#777;}
					}
				}
				.moq {
					.pkgInp {
						position:relative;
						input {position:relative;z-index:0;padding:0 45px 0 10px;}
						.unitStr {position:absolute;right:10px;top:12px;z-index:1;}
					}
					.tilde {padding:0 10px;}
					input,
					select {width:100px;min-width:0;}
				}
				.price {
					.unitStr {padding:0 0 0 10px;}
				}
				.ctrl {
					display:flex;flex-flow:row;flex-wrap:wrap;width:93px;justify-content:right;
					button {
						width:44px;height:44px;border-radius:4px;background:#fff;border:1px solid #ddd;background:#fff;box-sizing:border-box;position:relative;margin:0 0 0 5px;transition:all .3s ease;
						&:first-child {margin:0;}
						&:before,
						&:after {content:"";display:block;position:absolute;width:15px;height:2px;background:#aaa;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						&.unitPlus:after {transform:rotate(90deg);}
						&:focus-visible,
						&:hover {
							background:var(--color-1);border-color:var(--color-1);
							&:before,
							&:after {background:#fff;}
						}
					}
				}
				&:first-child {
					.ctrl {
						.unitMinus {display:none}
					}
				}
			}
		}
		.hasOpt {
			.configOpt {width:120px;}
			.configList {
				flex:1;min-width:0;margin:0 0 0 20px;box-sizing:border-box;border:1px solid #ddd;background:#fafafa;padding:10px;
				.lv1_1 {
					border-top-width:1px;border-top-color:#ddd;
					> li {
						.typeDl1 {
							min-height:20px;align-items:center;align-content:center;
							dt {width:100px;}
							dd {word-wrap:break-word;word-break:keep-all;white-space:normal;padding-right:20px;}
						}
					}
				}
			}
			dialog {
				.toolbar {
					button {
						height:40px;margin:0 5px 0 0;
						&:last-child {margin:0;}
					}
				}
				.frmWr {
					dt {width:220px;}
					dd {flex:1;min-width:0;}
					input[type='text'] {height:35px;}
					dl.hasLabelInput {
						dt {
							padding:0 20px 0 0;
							label {
								&:before {top:12px;}
								&:after {top:16px;}
							}
							input[type='text'] {width:100%;min-width:0;}
						}
					}
					.hasEtc {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						.chks {}
						input {margin:0 0 0 5px !important;min-width:0;flex:1;width:150px !important;}
					}
				}
			}
			#optionByPrice,
			#qtyByPrice {
				margin:5px 0 0;display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
				.configOpt {margin:0 10px 0 0;}
			}
			#optSelected,
			#optSelected2,
			#priceData {
				width:100%;margin:10px 0 0;
				.optSelectedNum {width:70px;}
				.optSelectedItem {width:auto;}
				.optSelectedPrice {width:100px;}
				.optSelectedStock {width:100px;}
				.optSelectedSku {width:210px;}
				.optSelectedDiscount {width:100px;}
				.optSelected2No {width:70px;}
				.optSelected2Item {width:auto;}
				.optSelected2Stock {width:100px;}
				.optSelected2Sku {width:210px;}
				.priceDataNum {width:70px;}
				.priceDataQty {width:auto;}
				.priceDataPrice {width:auto;}
				.priceDataDiscount {width:auto;}
				li {
					height:auto;
					dl {
						height:100%;display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
						dt,
						dd {width:100%;}
						dd {
							padding:5px;
							.lv1CloneTarget,
							.lv2CloneTarget {
								height:auto;
								input[type='text'],
								button {height:30px !important;line-height:30px;}
							}
						}
					}
				}
				table {
					colgroup {
						col:first-child {width:50px;}
					}
					th,
					td {padding:5px 10px;font-size:.882em;}
				}
				.imgPkg {
					display:flex;flex-flow:row;flex-wrap:wrap;width:100%;align-items:center;align-content:center;
					.imgContainer {
						width:40px;height:40px;box-sizing:border-box;position:relative;border:1px solid #ddd;
						img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
					}
					.txtContainer {flex:1;min-width:0;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:40px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;box-sizing:border-box;padding:0 0 0 10px;font-size:14px;line-height:1.2;text-align:left;}
				}
			}
		}
		.hasDc {
			input[type="text"] {width:130px;min-width:0;max-width:none;}
			.unitStr {padding:0 0 0 10px;}
		}
		.hasMulti {
			.typeDl3 {
				&:last-child {padding:0;margin:0;border-bottom:0;}
			}
			.txtArea1 {width:100%;height:100px;resize:none}
		}
		.hasNotice {
			.customSel1 {}
			.customBtn1 {}
			.noticeContents {
				width:100%;box-sizing:border-box;padding:5px 15px;border:1px solid #ddd;border-radius:4px;margin:10px 0 0;
				.lv1 {
					display:flex;flex-flow:row;flex-wrap:wrap;
					> li {
						box-sizing:border-box;width:50%;padding:5px 0;
						.typeDl1 {
							align-items:flex-start;align-content:flex-start;min-height:0;
							dt,
							dd {box-sizing:border-box;position:relative;}
							dt {
								&:after {content:"";display:block;position:absolute;width:1px;height:15px;right:0;top:2px;background:#ddd;}
								> * {word-break:break-all;}
							}
							dd {padding:0 15px;}
						}
					}
				}
			}
		}
		.hasEPR {
			table {
				thead {
					th {padding:10px 5px;}
				}
				tbody {
					th {
						padding:10px;
						.chks {
							display:block;width:17px;height:17px;margin:0 auto;font-size:0;
							label {
								width:17px;height:17px;padding:0;margin:0;font-size:0;line-height:1;
								&:before {left:0;top:0;}
								&:after {top:4px;}
							}
						}
					}
					td {
						padding:10px;
						input[type="text"] {height:30px !important;}
					}
				}
			}
			.subRdos {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				&:before,
				&:after {display:inline-block;margin-top:3px;}
				&:before {content:"(";margin-right:15px;}
				&:after {content:")";margin-left:15px;}
				.listType2 {
					li {
						&:last-child {margin:0;}
						.chks {
							label {margin:0;word-wrap:break-word;word-break:keep-all;white-space:normal;}
						}
					}
				}
			}
			.configList {
				flex:1;min-width:0;margin:0 0 0 20px;box-sizing:border-box;border:1px solid #ddd;background:#fafafa;padding:10px;
				.c-tit04 {color:var(--color-1);}
				.lv1_1 {
					border-top-width:1px;border-top-color:#ddd;
					> li {
						border-bottom:1px solid #ddd;
						&:last-child {border-bottom:0;}
						&:before {display:none;}
						.typeDl1 {
							min-height:20px;align-items:center;align-content:center;
							dt {width:100%;margin:0 0 5px;}
							dd {word-wrap:break-word;word-break:keep-all;white-space:normal;padding-right:20px;min-height:0;}
						}
					}
				}
			}
		}
		.hasSampleOrder {
			justify-content:space-between;
			.posibility {
				li {
					&.single {
						display:flex;flex-flow:row;flex-wrap:wrap;
						label {}
						input {width:150px;margin:0 10px;}
						.unitStr {}
					}
				}
			}
			.possible {}
		}
		.hasThumbnail {
			.image-grid {
				width:100%;
				.image-container {
					.transExecBtns {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;box-sizing:border-box;border:1px solid #ddd;
						li {
							flex:1;min-width:0;box-sizing:border-box;padding:5px;border-right:1px solid #ddd;
							&:last-child {border-right:0;}
							dl {
								dt,
								dd {}
								dt {
									height:25px;
									button {
										display:block;width:100%;border-radius:4px;border:1px solid #ddd;background:#f5f5f5;padding:3px 5px;transition:all .3s ease;
										span {display:block;font-size:14px;letter-spacing:-.05em;}
										&:hover,
										&:focus-visible {background:var(--color-1);border-color:var(--color-1);color:#fff;}
									}
								}
								dd {
									margin:5px 0 0;
									.imgContainer {
										width:100%;
									}
								}
							}
						}
					}
				}
			}
		}
		.hasWebEditorOriginal {min-height:530px;}
		.hasWebEditorLanguage {min-height:430px;}
		.hasWebEditorEtc {min-height:200px;}
		.hasThumbnail {
			.dropzone {
				.image-grid {
					.image-container {
						.rdos {}
						.imgContainer {
							border:0;
							.img {border:1px solid #ddd;width:100px;height:100px;padding:0;}
							.delete-button {top:20px;}
						}
					}
				}
			}
		}
		.titHasUtils {
			display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;
			.utils {
				display:flex;flex-flow:row;flex-wrap:wrap;
				> * {
					margin:0 10px 0 0;
					&:last-child {margin:0;}
				}
			}
		}
	}
}

/** -- 상품등록 > 옵션설정 */
.dialog.ConfigOptiton {
	.boxPkg {
		h3.boxTit {
			font-size:1.059em;font-weight:500;color:#333;margin:0 0 20px;
			em {font-style:normal;color:var(--color-1);}
		}
		div.boxTit {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-end;align-content:flex-end;justify-content:space-between;margin:0 0 20px;
			h3 {
				font-size:1.059em;font-weight:500;color:#333;
				em {font-style:normal;color:var(--color-1);}
			}
			.getOpt {
				height:40px;
				&:before {height:40px;}
			}
			ul.radioUl {
				display: inherit;
				li {
					span {
						font-size:1.059em;font-weight:500;color:#333;
					}
					h4 {
						font-size:1.059em;font-weight:300;color:#333;
						
					}
				}
			}
		}
		.boxListWr {
			box-sizing:border-box;
			.boxList {
				display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;
				> li {
					box-sizing:border-box;border-bottom:1px solid #ddd;min-width:0;
					input {
						height:40px !important;min-width:0;max-width:none;box-sizing:border-box;position:relative;
						&:before {height:40px;}
					}
					dt input {height:30px !important;width:100%;}
					// dt span {display: inline; height:50px !important;width:100%;}
					dd input {height:40px !important}
					button {
						width:40px;height:40px;border:1px solid #ccc;border-radius:4px;background:#f5f5f5;box-sizing:border-box;position:relative;transition:all .3s ease;
						&.minus,
						&.plus {
							&:before,
							&:after {content:"";display:block;position:absolute;width:15px;height:2px;background:#aaa;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							&.plus {
								&:after {transform:rotate(90deg);}
							}
							&:focus-visible,
							&:hover {
								border-color:var(--color-1);background:var(--color-1);
								&:before,
								&:after {background:#fff;}
							}
						}
					}
					&.optNum {width:50px;}
					&.optRadio {
						width:120px;
						.lv1Unit {
							width:100%;
							.rdos {
								label {white-space:nowrap;}
							}
						}
					}
					&.optName {flex:1;min-width:0;}
					&.optValue {flex:1;min-width:0;}
					&.optCore {flex:1;min-width:0;}
					&.optImage {
						width:80px;
						.hasImg {
							width:100%;justify-content:center !important;position:relative;
							.imgView {
								display:block;margin:0 auto;width:40px;height:40px;border-radius:4px;border:1px solid #ccc;background:#f5f5f5;box-sizing:border-box;position:relative;z-index:0;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							}
							img.imgView {border:0 !important;}
							.imgDel {
								position:absolute;width:0;height:0;right:30px;top:-4px;z-index:1;overflow:visible;
								button {
									width:16px;height:16px;border-radius:50%;border:0;background:#000;
									&:before,
									&:after {content:"";display:block;position:absolute;width:10px;height:2px;top:0;right:0;bottom:0;left:0;margin:auto;background:#fff;}
									&:before {transform:rotate(45deg);}
									&:after {transform:rotate(-45deg);}
								}
							}
							.upImg {
								width:100%;margin:0;
								span {color:#555;font-size:14px;}
								&:focus-visible,
								&:hover {
									span {color:#fff;}
								}
							}
						}
					}
					&.optPrice {
						width:100px;
						input {text-align:right;}
					}
					&.optStock {
						width:100px;
						input {text-align:right;}
					}
					&.optSku {
						width:250px;
						input {text-align:center;}
					}
					&.optQty {
						flex:.65;min-width:0;
						.unit {justify-content:center;}
						dt input {
							width: 30% !important;
						}
						.w100Input {
							width: 100px !important;
						}

						.moqPrice {
							align-items: center;
							justify-content: center;
							height: 105px;
						}

						.moqQualityItem {
							padding: 5px 10px;

							.others {
								height: 30px !important;
								width: 170px !important;
								box-sizing:border-box;position:relative;border-bottom:1px solid #ddd;
								border-radius: 4px;
							}

							select {
								height: 30px !important;
								width: 170px !important;
							}

							button {
								width: 25px !important;
								height: 25px !important;
							}

							
						}
					}
					&.optPrice2 {
						flex:.35;min-width:0;
						.unit {justify-content:center;}
					}
					&.optMngr {
						width:150px;
						dd {
							justify-content:center;
							button {margin:0 5px;}
						}
					}
					> dl {
						> dt,
						> dd {display:block;text-align:center;box-sizing:border-box;line-height:1;}
						> dt {background:#eee;border-bottom:1px solid #ddd;padding:10px 5px;display:none}
						> dd {
							display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;padding:10px 5px;
							.unit {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;margin:0 0 5px;width:100%;
								&:last-child {margin:0;}
								input {flex:1;}
								button {margin:0 0 0 5px;}
								.optImg {
									margin:0 0 0 5px;display:block;width:40px;height:40px;box-sizing:border-box;border-radius:4px;position:relative;border:1px solid #ccc;background:#f5f5f5;overflow:hidden;
									img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
								}
								&.lv1Unit {
									.lv1CloneTarget {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;margin:0 0 5px;width:100%;height:40px;position:relative;
										&:last-child {margin:0;}
										&.num {
											justify-content:center;
											&:after {content:".";}
										}
									}
								}
								&.lv2Unit {
									.lv2CloneTarget {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;width:100%;padding:0;margin:0 0 5px;
										&:first-child {
											.lv2.minus {}/* display:none */
										}
										&:last-child {margin:0;}
									}
								}
							}
						}
					}
				}
				&:first-child {
					border-top:2px solid var(--color-1);
					> li {
						> dl {
							> dt {display:block;}
						}
						.lv1Unit {
							.lv1.minus {}/* display:none; */
						}
					}
				}
				&.list2 {
					&:first-child {
						.optMngr {
							dd {
								.minus {display:none;}
							}
						}
					}
					input[type="text"] {width:150px;}
				}
			}
		}
		.boxInput {
			margin:0 0 20px;padding:0 0 20px;box-sizing:border-box;position:relative;border-bottom:1px solid #ddd;
		}
		.boxApplied {
			box-sizing:border-box;position:relative;
			h3.boxTit {display:none;}
			.boxListWr {
				.boxList {
					> li {
						> dl {
							dt {padding:5px;height:41px;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;display:none;}
							dd {}
						}
						.hasImg {
							justify-content:center !important;align-items:flex-start !important;align-content:flex-start !important;display:none !important;margin:0 !important;
							.img {
								box-sizing:border-box;border:1px solid #ddd;width:40px;height:40px;
								.imgContainer {
									display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
									img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
								}
							}
							.delImg {
								width:20px;height:20px;border:1px solid #ddd;background:#eee;border-radius:0;transition:all .3s ease;
								&:before {background:#777;transform:rotate(45deg);width:10px;}
								&:after {background:#777;transform:rotate(-45deg);width:10px;}
								&:focus-visible,
								&:hover {
									background:#333;border-color:#333;
									&:before,
									&:after {background:#fff;}
								}
							}
							.upImg {
								width:65px;color:#555;margin:0;
								&:before,
								&:after {display:none;}
								&:focus-visible,
								&:hover {color:#fff;}
							}
							&.show {display:flex !important;}
						}
					}
					&:first-child {
						> li {
							border-bottom:0;
							> dl {
								dt {display:flex;}
							}
						}
					}
				}
			}
		}
	}
}

/** -- 상품등록 > 대표/제품상세 등등 */
.dialog.ConfigLibraryGetItem {
	#bbsSch {
		.regiSet {
			display:flex;flex-flow:row;flex-wrap:wrap;
			> * {
				margin:0 10px 0 0;width:auto !important;
				&:last-child {margin:0;}
				.unitAdder {
					height:44px;
					&:before {height:100%;}
				}
			}
		}
		.schFrm {}
	}
	.utils {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;margin:0 0 20px;
		.pager {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
			.tilde {font-weight:400;padding:0 20px;}
			button {min-width:0;width:40px !important;height:40px !important;}
		}
		.tools {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:right;
			button {
				width:120px !important;min-width:120px;margin:0 10px 0 0;height:40px !important;
				&:before {height:40px !important;}
				&:last-child {margin:0;}
			}
		}
	}
}

/* ==============================
* Seller : 스토어 관리 > 배송유형 설정.
================================= */
.sellerDelivereyMngr {
	&.List {
		.regi {position:absolute;right:0;top:10px;}
		.colChk {
			width:100px;
			.rdos {
				label {
					width:17px;height:17px;margin:0;padding:0;
					&:before {top:0;}
					&:after {top:4px;}
				}
			}
		}
		.colNick {width:200px;}
		.colReceiver {width:150px;}
		.colAddress {width:auto;}
		.colPhone {width:150px;}
		.colMngr {width:100px;}

		.colTmpl {width:200px;}
		.colLocal {width:100px;}
		.colMethod {width:180px;}
		.colDeparture {width:auto;}
		.colFee {width:180px;}
		.colApply {width:100px;}
		.colDelete {width:100px;}
		tbody {
			td {padding:15px 10px !important;}
		}
	}
}

/** -- 배송유형 설정 Dialog */
.dialog.ConfigDelivery {
	dd {text-align:left;}
	.hasTmpl {
		.nameStr {
			margin:0 20px 0 0;
			input {}
			.txtLen {
				margin:0 0 0 10px;
				em {font-style:normal;}
			}
		}
		.chks {
			margin:-2px 0 0;
			label {
				&:before {top:5px;}
				&:after {top:9px;}
			}
		}
	}
	.hasAddr {
		display:flex;flex-flow:row;flex-wrap:wrap;
		.addrNational {width:125px !important;min-width:0;}
		.addrPkg {
			flex:1;
			.addrZip,
			.addrZipFinder {width:120px !important;}
			.addrSumm {width:calc( 100% - 240px - 20px );}
		}
	}
	.hasReturnPay {
		align-items:center;align-content:center;
		.pTit1 {margin:0 10px 0 0;}
		.pInp1 {margin:0 10px 0 0;}
		.pUnit1 {}
	}
	.hasReturnType {
		select {width:125px !important;min-width:0;}
	}
}

/** -- 상품정보 제공고시 */
.dialog.ConfigRegiInfo {
	dt {
		> * {word-break:break-all !important;}
	}
	dd {
		.itemInp {max-width:none !important;width:100% !important;}
		.itemMsg {
			width:100%;margin:5px 0 0;padding:0 0 0 20px;
			&:before {top:3px;}
		}
	}
}

/** -- 일반속성 Dialog */
.dialog.OptNormal {
	.toolbar {
		display:flex;flex-flow:row;flex-wrap:wrap;justify-content:right;padding:10px !important;
		> * {
			margin:0 10px 0 0;
			&:last-child {margin:0;}
		}
	}
	.frmWr {
		dt {
			display:flex;flex-flow:row;flex-wrap:wrap;
			.chks {
				label {
					width:100% !important;
					.dtInputTxt {flex:1;min-width:0;}
				}
			}
		}
		dd {
			.ddInputTxt {margin:0 !important;}
		}
	}
}

/* ==============================
* Seller :스토어 관리 > 인증서류 설정.
* Seller :스토어 관리 > 지식재산권 서류 설정.
* Seller :스토어 관리 > 수출통관 서류 설정.
================================= */
.sellerPapersMngr {
	&.List {
		.regi {
			position:absolute;right:0;top:10px;
			&:before {height:43px;}
		}
		.boxPkg {
			.boxTools {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
				.ctrlTmpl {
					> * {
						margin:0 5px 0 0;
						&:last-child {margin:0;}
					}
				}
				.ctrlUtil {
					> * {
						margin:0 0 0 5px;
						&:first-child {margin:0;}
					}
					.reload {width:50px;padding:0;min-width:0;font-size:1.176em;display:none;}
					.save {}
				}
			}
			.boxContents {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:20px 0 0;
				.listTmpl {
					width:400px;box-sizing:border-box;position:relative;border:2px solid #e5e5e5;
					.papers {
						height:100%;
						li {
							border-bottom:1px solid var(--color-db);
							.btnPkg {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;padding:10px;transition:all .3s ease;
								button {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;background:transparent;transition:all .3s ease;
									span {display:block;line-height:1.2;text-align:left;letter-spacing:-.05em;word-wrap:break-word;word-break:keep-all;white-space:normal;font-weight:500;}
									i {font-size:20px;}
									&.btnPaper {
										flex:1;min-width:0;justify-content:left;border:0;font-size:.941em;padding:0 10px 0 0;min-height:30px;
										&:hover,
										&:focus-visible {}
									}
									&.btnEdit {
										width:30px;height:30px;justify-content:center;border:1px solid var(--color-db);border-radius:5px;color:#777;background:#fff;
										&:hover,
										&:focus-visible {border-color:var(--color-1);background:var(--color-1);color:#fff;}
									}
								}
								.modifyForm {
									flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;margin:0 5px 0 0;display:none;
									input {flex:1;min-width:0;font-size:.941em;height:30px;letter-spacing:-.05em;margin:0 5px 0 0;}
									.btnSave {
										width:30px;height:30px;border:1px solid var(--color-db);border-radius:5px;color:#777;background:#fff;justify-content:center;
									}
								}
								&:hover {background:#f7f7f7;}
								&.select {
									background:#d8d8d8;
								}
								&.modify {
									.btnPaper {display:none;}
									.btnEdit {border-color:var(--color-1);}
									.btnSave {
										border-color:var(--color-1);
										&:hover,
										&:focus-visible {
											background:var(--color-1);
											i {color:#fff;}
										}
									}
									.modifyForm {
										display:flex;
									}
								}
							}
							&.hasNoData {
								padding:0;margin:0;border-bottom:0;width:100%;height:100%;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
								.noData {
									text-align:center;
									i {font-size:2.058em;line-height:1;}
									span {display:block;line-height:1.2;margin:10px 0 0;}
								}
							}
						}
					}
				}
				.listPaper {
					flex:1;min-width:0;box-sizing:border-box;margin:0 0 0 20px;border:2px solid #e5e5e5;
					.setDefault {box-sizing:border-box;padding:20px;border-bottom:1px solid var(--color-1);}
					.papers {
						box-sizing:border-box;padding:20px;
						.listPaperChk {
							li {
								border-bottom:1px solid var(--color-db);padding:0 0 10px;margin:0 0 10px;
								&:last-child {margin:0;padding:0;border-bottom:0;}
								&.hasNoData {
									padding:0;margin:0;border-bottom:0;width:100%;height:auto;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
									.noData {
										text-align:center;padding:100px 0;
										i {font-size:2.058em;line-height:1;}
										span {display:block;line-height:1.2;margin:10px 0 0;}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.dialog.ConfigPapers {
	.fileGuide {
		padding:20px 20px 20px 60px;box-sizing:border-box;position:relative;border-radius:5px;border:1px solid var(--color-db);margin:0 0 20px;
		.ico1 {position:absolute;font-size:2em;left:15px;top:7px;color:var(--color-1);}
		.txt1 {
			li {
				word-wrap:break-word;word-break:keep-all;white-space:normal;font-size:.941em;
				.fileType {color:var(--color-2);}
			}
		}
	}
}

/* ==============================
* Seller :스토어 관리 > 라이브러리 설정.
================================= */
.sellerLibraryMngr {
	&.List {
		.regi {
			position:absolute;right:0;top:10px;
			> * {
				margin:0 0 0 10px;
				&:first-child {margin:0;}
			}
		}
		.gallery1 {
			ul {
				li {
					display:flex;flex-flow:row wrap;min-width:0;box-sizing:border-box;
					&.chk {
						.boxImg {border:1px solid #ddd;}
					}
					.boxChk {
						margin:0 10px 0 0;
						.chks {
							display:block;width:17px;height:17px;margin:0;
							input {width:0;height:0;}
							label {
								display:block;padding:0;margin:0;
								&:before {position:relative;left:auto;top:auto;}
								&:after {top:4px;}
							}
						}
					}
					.boxImg {
						flex:1;box-sizing:border-box;border:1px solid #ddd;transition:all .3s ease;
						.imgContainer {
							display:block;height:0;padding:0 0 68.3453%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
							i {display:block;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;font-size:30px;width:30px;height:30px;line-height:1;}
							.innerCellImg {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;z-index:0;transition:all .3s ease}
							.hiddenBtn {position:absolute;width:100%;height:100%;left:0;top:0;border-radius:0;font-size:0;border:0;background:transparent;z-index:1;}
							&:focus-visible > img.innerCellImg,
							&:hover > img.innerCellImg {transform:scale(1.2);}
						}
					}
					.boxTit {
						width:100%;box-sizing:border-box;padding:0 0 0 0px;line-height:1;text-align:center;margin:5px 0 0;
						span {display:block;color:#000;width:100%;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;padding:5px 0;}
					}
				}
			}
		}
	}
}

/** -- Library preview */
.libraryPreview {
	.utils {
		position:absolute;left:0;top:0;z-index:1;display:flex;flex-flow:row;flex-wrap:wrap;
		button {
			width:40px;height:40px;border:0;background:rgba(0, 0, 0, .5);color:#fff;font-size:20px;transition:all .3s ease;
			&:hover,
			&:focus-visible {background:rgba(0, 0, 0, .8);}
		}
	}
	.imgBox {
		height:100%;overflow:auto;
		img {display:block;}
		&.expandHight {
			img {height:100%;}
		}
		&.expandWidth {
			img {width:100%;}
		}
		&.expandFull {
			img {width:100%;height:100%;}
		}
		&.expandCancel {}
	}
}

/* ==============================
* Seller :고객문의
================================= */
.sellerCs {
	&.List {
		#bbsSch {
			.schTit {}
			.schFrm {
				.period {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					.react-datepicker-wrapper {
						width:170px;
						input {width:100%;line-height:44px;}
					}
					.tilde {padding:0 5px;}
				}
				select {width:150px;min-width:0;}
				.schCast {width:100px;min-width:0;}
			}
		}
		#bbs.List {
			.colNum {width:80px;}
			.colType {width:150px;}
			.colProd {width:auto;}
			.colWriter {width:140px;}
			.colHit {width:130px;}
			.colDate {width:130px;}
			.colState {width:130px;}
			.colLeft {text-align:left !important;}
			.tblBtn1 {
				display:block;
				span {width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;}
			}
		}
	}
	&.View {
		.hasThumb {
			.boxPkg {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				.boxImg {
					box-sizing:border-box;border:1px solid #ddd;width:100px;
					.imgContainer {
						display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
						img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
					}
				}
				.boxName {
					flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 15px;transition:all .3s ease;
					span {}
					&:after {content:"\eeb8";font-family:"remixicon";margin:0 0 0 5px;}
				}
				&:hover,
				&:focus-visible {color:var(--color-1);}
			}
		}
		.hasAttaches {
			padding:15px !important;
			.noDataWrap {
				width:100%;padding:30px 0;
				i {font-size:2em;}
				.msg {font-size:1em;}
			}
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:-10px;width:100%;
				li {
					width:20%;box-sizing:border-box;padding:10px;
					button {
						display:block;box-sizing:border-box;background:transparent;border:1px solid #ddd;width:100%;
						.imgContainer {
							display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
							img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						}
						&:hover,
						&:focus-visible {
							.imgContainer {
								img {transform:scale(1.1);}
							}
						}
					}
				}
			}
		}
		.hasRepl {
			.repleContents {width:100%;}
		}
	}
}

/* ==============================
* SmartContents
================================= */
.sellerSmartContents {
	&.List {
		.regi {
			position:absolute;right:0;top:15px;height:43px;
			&:before {height:43px;}
		}
	}
}

/** -- Dialog Outer */
dialog#dialogCustom.smartContents {
	padding:0;transition:none;
	.popupWrap {
		max-width:100%;max-height:100%;
		&:before,
		&:after {display:none;}
		.popWrap {
			border-radius:0;
			.popHead {display:none;}
			.popCont {height:100%;background:#eee;}
			.popFoot {display:none;}
		}
	}
	&[open] {animation:none;}
}

/** -- Dialog Inner */
dialog#dialogCustom.smartContents {
	.scGrid {
		display:grid;grid-template-columns:300px auto 250px;grid-template-rows:auto 1fr;gap:20px;height:100%;
		.cell {
			background:#fff;
			&.cellName {}
			&.cellBtns {grid-column:2 / span 2;}
			&.cellTmpl {}
			&.cellPreview {}
			&.cellMiniview {}
		}
	}
}

/* ==============================
* Review
================================= */
.MyReview {
	.Summary {
		box-sizing:border-box;border:1px solid #ddd;border-radius:4px;padding:30px 0;margin:0 0 20px;
		> dl {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;line-height:1;width:100%;
			> dt,
			> dd {box-sizing:border-box;}
			> dt {
				width:300px;color:#777;padding:0 0 0 30px;
				em {font-style:normal;font-size:2em;color:#222;}
			}
			> dd {
				flex:1;min-width:0;
				ul {
					display:flex;flex-flow:row;flex-wrap:wrap;
					li {
						flex:1;min-width:0;box-sizing:border-box;border-left:1px solid #ddd;padding:10px 0;
						dl {
							display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;width:100%;box-sizing:border-box;padding:0 30px;align-items:flex-end;align-content:flex-end;
							dt,
							dd {}
							dt {
								font-weight:400;
								svg {margin:-4px 0 0 5px;position:absolute;}
							}
							dd {
								em {font-size:2em;font-style:normal;color:#222;font-weight:700;}
							}
						}
					}
				}
			}
		}
	}
	.Tab {
		display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;border-bottom:2px solid var(--color-1);
		/* &:after {content:"";display:block;position:absolute;width:100%;height:2px;left:0;bottom:0;z-index:0;background:var(--color-1);} */
		button {
			line-height:1;background:transparent;font-size:1.176em;border:0;border-bottom:2px solid #fff;padding:10px 10px;font-weight:500;position:relative;z-index:1;transition:all .3s ease;
			&.on {color:var(--color-1);border-bottom-color:var(--color-1);}
		}
	}
	.List {
		ul {
			li {
				border-bottom:1px solid #ddd;
				.unit {
					display:flex;flex-flow:row;flex-wrap:wrap;padding:20px 0;
					> * {
						padding:0 20px;border-right:1px solid #ddd;
						&:first-child {padding-left:0;}
						&:last-child {padding-right:0;border-right:0;}
					}
					.boxImg {
						width:150px;box-sizing:border-box;
						.img {
							width:100%;
							.imgContainer {
								display:block;width:100%;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;border:1px solid #ddd;overflow:hidden;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							}
						}
					}
					.boxProduct {
						flex:1;min-width:0;box-sizing:border-box;display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;align-content:flex-start;
						.productBadge {display:block;height:auto;width:100px;border-radius:4px;border:1px solid var(--color-1);color:var(--color-1);text-align:center;line-height:1;padding:3px 5px 5px;}
						.productInfos {
							flex:1;min-width:0;margin:0 0 0 10px;text-align:left;border:0;background:transparent;display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;height:100%;
							.title {
								font-weight:500;letter-spacing:-.05em;word-wrap:break-word;word-break:keep-all;white-space:normal;
								span {transition:all .3s ease;}
							}
							.price,
							.message {width:100%;font-size:.941em;color:#777;margin:20px 0 0;transition:all .3s ease;}
							&:hover,
							&:focus-visible {
								.title {
									span {box-shadow:0 -10px 0 rgba(17, 101, 224, .1) inset;color:var(--color-1);}
								}
								.price,
								.message {color:#000;}
							}
						}
					}
					.boxDelivery {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:180px;}
					.boxRatings {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:180px;
						.productScore {
							display:flex;flex-flow:row;flex-wrap:wrap;
							* {line-height:1;padding:0;}
							.star-ratings {}
							.star-scores {margin:0 0 0 5px;}
						}
						.productDate {font-size:.941em;color:#777;margin:5px 0 0;}
					}
					.boxTools {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:150px;
						button {
							width:100%;margin:0 0 10px;
							&:last-child {margin:0;}
							span {line-height:1;letter-spacing:-.05em;}
						}
					}
				}
				&.hasNoData {
					.noDataWrap {padding:50px 0 60px;}
				}
			}
		}
	}
	.Write {
	}
}
.MyReviewWrite.modalCustom {
	height:520px;
	.modalCustomBody {
		.star-ratings {
			font-size:0;line-height:1;top:auto;
			* {font-size:0;line-height:1;}
		}
		textarea {display:block;width:100%;height:150px;resize:none}
	}
}