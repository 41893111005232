#header-wrap {
  box-sizing: border-box;
  position: sticky;
  z-index: 2;
  left: 0;
  top: 0;
  background: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  .pkgs {
    > * {
      box-sizing: border-box;
    }
    #gnavi {
      display: none;
    }
    #head {
      box-sizing: border-box;
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        opacity: 0;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        z-index: 1;
        transition: all 0.3s ease;
      }
      .container {
        max-width: 1400px;
        padding: 0;
        margin: 0 auto;
        padding: 30px 0 25px;
        transition: all 0.3s ease;
        .row1 {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          position: relative;
          z-index: 2;
          .boxLogo {
            width: 304px;
            position: relative;
            top: 5px;
            transition: all 0.3s ease;
            a {
              display: block;
              img {
                display: block;
                width: 100%;
              }
            }
          }
          .boxSearch {
            margin: 0 auto 0 33px;
            flex: 1;
            border-radius: 999px;
            background: linear-gradient(to right, #04a2eb 0, #222dd7 100%);
            box-sizing: border-box;
            padding: 1px;
            position: relative;
            transition: all 0.3s ease;
            .inner {
              background: #fff;
              border-radius: 999px;
              width: 100%;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              overflow: hidden;
              box-sizing: border-box;
              padding: 0 15px 0 0;
              > * {
                height: 49px;
              }
              .searchKeywd {
                flex: 1;
                box-sizing: border-box;
                border: 0;
                padding: 0 10px 0 20px;
                width: 100%;
              }
              button {
                width: 35px;
                box-sizing: border-box;
                position: relative;
                border: 0;
                background: transparent;
                margin: 0 3px;
                transition: all 0.3s ease;
                > * {
                  box-sizing: border-box;
                  position: relative;
                  transition: all 0.3s ease;
                }
                .ico {
                  color: #666;
                  font-size: 26px;
                }
                .txt {
                  font-size: 0;
                  position: absolute;
                  width: 0;
                  height: 0;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  visibility: hidden;
                }
                &:focus-visible,
                &:hover {
                  color: var(--color-1);
                }
                &.schVoice {
                }
                &.schImg {
                }
                &.schTxt {
                }
              }
            }
            .autoComplete {
              position: absolute;
              left: 0;
              top: 100%;
              width: 100%;
              background: #fff;
              box-sizing: border-box;
              border: 1px solid #ddd;
              border-radius: 10px;
              padding: 15px;
              box-shadow: var(--shadow1);
              div {
                max-height: 300px;
                overflow-x: hidden;
                overflow-y: auto;
                &::-webkit-scrollbar {
                  width: 14px;
                }
                &::-webkit-scrollbar-thumb {
                  background: #ccc;
                  border-left: 10px solid #fff;
                }
                &::-webkit-scrollbar-track {
                  background: #eee;
                  border-left: 10px solid #fff;
                }
                &::-webkit-scrollbar-button {
                  height: 6px;
                }
                &:hover::-webkit-scrollbar-thumb {
                  background: var(--color-1);
                }
                ul {
                  display: block;
                  li {
                    display: block;
                    box-sizing: border-box;
                    border-bottom: 1px solid #eee;
                    &:last-child {
                      border-bottom: 0;
                    }
                    button {
                      display: block;
                      width: 100%;
                      font-size: 15px;
                      line-height: 1.2;
                      border: 0;
                      background: transparent;
                      text-align: left;
                      padding: 5px 0;
                      transition: all 0.3s ease;
                      span {
                        display: block;
                      }
                      &:hover,
                      &:focus-visible {
                        color: var(--color-1);
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }
          }
          .boxIcon {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 0 0 50px;
            transition: all 0.3s ease;
            .headerMsg {
              display: none;
            }
            .header-right-wrap {
              margin: 0;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              > ul {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                > li {
                  margin: 0 30px 0 0;
                  box-sizing: border-box;
                  position: relative;
                  &:last-child {
                    margin: 0;
                  }
                  > a,
                  > button,
                  > .tmp {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    padding: 0;
                    width: auto;
                    height: auto;
                    text-align: center;
                    box-sizing: border-box;
                    position: relative;
                    background: transparent;
                    border: 0;
                    align-items: center;
                    .count-style {
                      position: absolute;
                      width: 14px;
                      height: 14px;
                      text-align: center;
                      line-height: 14px;
                      border-radius: 50%;
                      top: 0;
                      left: 20px;
                      background: #000;
                      color: #fff;
                      font-size: 10px;
                      transition: all 0.3s ease;
                    }
                    .ico,
                    .txt {
                      color: #666;
                      line-height: 1;
                      transition: all 0.3s ease;
                    }
                    .ico {
                      font-size: 30px;
                    }
                    .txt {
                      font-size: 16px;
                      margin: 0 0 0 10px;
                      text-align: left;
                      &.currency {
                        width: 35px;
                      }
                    }
                    &:not(.tmp):focus-visible:after,
                    &:not(.tmp):hover:after {
                      border-color: var(--color-1);
                    }
                    &:not(.tmp):focus-visible .ico,
                    &:not(.tmp):hover .ico,
                    &:not(.tmp):focus-visible .txt,
                    &:not(.tmp):hover .txt {
                      color: var(--color-1);
                    }
                    &:not(.tmp):focus-visible .count-style,
                    &:not(.tmp):hover .count-style {
                      background: var(--color-1);
                      color: #fff;
                    }
                  }
                  > .tmp {
                    opacity: 0.5;
                  }
                }
              }

              .hasChild {
                > a,
                > button {
                  padding-right: 20px;
                  &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    box-sizing: border-box;
                    border: solid;
                    border-width: 0 2px 2px 0;
                    border-color: #aaa;
                    right: 2px;
                    top: 10px;
                    transform: rotate(45deg);
                    transition: all 0.3s ease;
                  }
                }
                .hideChild {
                  position: absolute;
                  background: #fff;
                  border: 1px solid #ddd;
                  border-radius: 5px;
                  z-index: 10;
                  padding: 15px;
                  margin: 13px 0 0;
                  top: 100%;
                  visibility: hidden;
                  opacity: 0;
                  transition: all 0.5s ease;
                  ul {
                    li {
                      width: 100%;
                      margin: 0;
                      border-bottom: 1px solid #ddd;
                      text-align: center;
                      &:last-child {
                        border-bottom: 0;
                      }
                      > * {
                        display: block;
                        line-height: 1.2;
                        padding: 5px 0;
                        font-size: 14px;
                        width: 100%;
                        border: 0;
                        background: transparent;
                        transition: all 0.3s ease;
                        span {
                          display: block;
                        }
                        &:focus-visible,
                        &:hover {
                          color: var(--color-1);
                        }
                      }
                    }
                  }
                }
                &.active > a > *,
                &.active > button > * {
                  color: var(--color-1);
                }
                &.active > a:after,
                &.active > button:after {
                  transform: rotate(225deg);
                  top: 14px;
                  border-color: var(--color-1);
                }
                &.active > a .count-style,
                &.active > button .count-style {
                  background: var(--color-1);
                  color: #fff;
                }
                &.active .hideChild {
                  visibility: visible;
                  opacity: 1;
                  box-shadow: var(--shadow1);
                }
              }
              .trans {
                .hideChild.langSet {
                  width: 150px;
                  transform: translateX(-50%) rotateX(90deg);
                  transform-origin: center top 0;
                  left: 50%;
                }
                &.active {
                  .hideChild.langSet {
                    transform: translateX(-50%) rotateX(0deg);
                  }
                }
              }
              .cart {
                .hideChild.shopping-cart-content {
                  width: 340px;
                  transform: translateX(-50%) rotateX(90deg);
                  transform-origin: center top 0;
                  left: 50%;
                  > ul {
                    display: block;
                    overflow-x: hidden;
                    overflow-y: auto;
                    max-height: 342px;
                    &::-webkit-scrollbar {
                      width: 14px;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #ccc;
                      border-left: 10px solid #fff;
                    }
                    &::-webkit-scrollbar-track {
                      background: #eee;
                      border-left: 10px solid #fff;
                    }
                    &::-webkit-scrollbar-button {
                      height: 6px;
                    }
                  }
                  .single-shopping-cart {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    gap: 5px;
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;
                    padding: 0 0 10px;
                    margin: 0 0 10px;
                    &:last-child {
                      padding: 0;
                      margin: 0;
                    }
                    .shopping-cart-img {
                      width: 100px;
                      box-sizing: border-box;
                      border: 1px solid #ddd;
                      height: auto;
                      padding: 0;
                      overflow: hidden;
                      height: 100%;
                      a {
                        display: block;
                        width: 100%;
                        height: 0;
                        box-sizing: border-box;
                        position: relative;
                        padding: 0 0 100%;
                        background: #f5f5f5;
                        img {
                          display: block;
                          position: absolute;
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          margin: auto;
                          transition: all 0.3s ease;
                        }
                        &:focus-visible img,
                        &:hover img {
                          transform: scale(1.2);
                        }
                      }
                    }
                    .shopping-cart-title {
                      flex: 1;
                      box-sizing: border-box;
                      padding: 0 0 0 10px;
                      text-align: left;
                      display: flex;
                      flex-flow: column;
                      flex-wrap: wrap;
                      justify-content: space-between;
                      > * {
                        display: block;
                        margin: 0;
                      }
                      .name {
                        display: block;
                        box-sizing: border-box;
                        font-size: 15px;
                        line-height: 1.3;
                        padding: 0 20px 0 0;
                        a {
                          display: block;
                          text-align: left;
                          padding: 0;
                          color: #000;
                          transition: all 0.3s ease;
                          &:focus-visible,
                          &:hover {
                            color: var(--color-1);
                          }
                        }
                      }
                      .info {
                        margin: 5px 0 0;
                        > * {
                          display: block;
                          line-height: 1.2;
                        }
                        .qty {
                          font-size: 15px;
                        }
                        .price {
                          font-size: 15px;
                          margin: 5px 0 0;
                          font-weight: 500;
                          color: var(--color-r1);
                        }
                      }
                    }
                    .shopping-cart-delete {
                      position: absolute;
                      right: 0;
                      top: 0;
                      width: auto;
                      padding: 0;
                      button {
                        border: 0;
                        background: transparent;
                        opacity: 0.5;
                        width: auto;
                        height: auto;
                        line-height: 1;
                        transition: all 0.3s ease;
                        &:focus-visible,
                        &:hover {
                          opacity: 1;
                        }
                      }
                      button i {
                        font-size: 20px;
                      }
                    }
                  }
                  .shopping-cart-options {
                    border: 1px solid #dbdbdb;
                    border-radius: 4px;
                    background-color: #fafafa;
                    padding: 5px 10px;
                    dl {
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      padding: 0 0 5px;
                      margin: 0 0 5px;
                      border-bottom: 1px dashed #ccc;
                      &:last-child {
                        padding: 0;
                        margin: 0;
                        border-bottom: 0;
                      }
                      dt,
                      dd {
                        box-sizing: border-box;
                        line-height: 1.2;
                        position: relative;
                      }
                      dt {
                        width: 60px;
                        padding: 0 10px 0 0;
                      }
                      dd {
                        flex: 1;
                        min-width: 0;
                      }
                    }
                  }
                  .shopping-cart-total {
                    line-height: 1;
                    padding: 10px 0 0;
                    margin: 10px 0;
                    border-top: 1px solid #ddd;
                    dl {
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      justify-content: space-between;
                    }
                    dl dt,
                    dl dd {
                      font-size: 0.941em;
                    }
                    dl dt {
                      font-weight: 300;
                    }
                    dl dd {
                      font-weight: 500;
                      color: #000;
                    }
                  }
                  .shopping-cart-btn {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    a {
                      width: calc(50% - 5px);
                      span {
                        font-size: 14px;
                      }
                    }
                  }
                  .noData {
                    text-align: center;
                    line-height: 1;
                    padding: 0 0 10px;
                    i {
                      font-size: 2em;
                      color: var(--color-1);
                    }
                    span {
                      display: block;
                      font-size: 0.941em;
                      margin: 5px 0 0;
                    }
                  }
                }
                &.active {
                  .hideChild.shopping-cart-content {
                    transform: translateX(-50%) rotateX(0deg);
                  }
                }
              }
              .my {
                .hideChild.user-profile {
                  width: 200px;
                  text-align: center;
                  transform: translateX(-50%) rotateX(90deg);
                  transform-origin: center top 0;
                  left: 50%;
                  .userInfo {
                    border: 1px solid #ddd;
                    padding: 10px;
                    margin: 0 0 5px;
                    font-size: 14px;
                    border-radius: 3px;
                    background: #fafafa;
                    .user-type {
                      display: inline-block;
                      border-radius: 4px;
                      background: #333;
                      color: #fff;
                      line-height: 1;
                      padding: 3px 5px 5px;
                      margin: 0 0 2px;
                    }
                    .user-name {
                      display: block;
                      line-height: 1.2;
                      font-weight: 500;
                      color: #000;
                    }
                    .user-email {
                      line-height: 1.2;
                      margin: 5px 0 0;
                      display: block;
                      width: auto;
                      max-width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      word-wrap: normal;
                      text-align: center;
                      padding: 0 0 5px;
                    }
                  }
                }
                &.active {
                  .hideChild.user-profile {
                    transform: translateX(-50%) rotateX(0deg);
                  }
                }
              }
              .qr {
                .hideChild.qrCode {
                  width: 150px;
                  line-height: 1;
                  transform: translateX(-50%) rotateX(90deg);
                  transform-origin: center top 0;
                  text-align: center;
                  left: 50%;
                  i {
                    font-size: 80px;
                    color: #000;
                    line-height: 1;
                  }
                  .guide {
                    display: block;
                    line-height: 1.2;
                  }
                }
                &.active {
                  .hideChild.qrCode {
                    transform: translateX(-50%) rotateX(0deg);
                  }
                }
              }
              .mobile {
                display: none;
                margin: 0 0 0 20px;
                button {
                  width: 40px;
                  height: 40px;
                  border: 1px solid #c8c8c8;
                  background: #fff;
                  border-radius: 50%;
                }
              }
            }
          }
        }
        .row2 {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          margin: 15px 0 0;
          justify-content: space-between;
          transition: all 0.3s ease;
          .boxNav {
            width: 100%;
            .hMenu {
              .menuCont {
                .menuTopM {
                  display: none;
                }
                .menuCore {
                  .depth1-wrap {
                    ul {
                      li {
                        box-sizing: border-box;
                        position: relative;
                        a {
                          display: block;
                          box-sizing: border-box;
                          position: relative;
                        }
                      }
                    }
                    .ico {
                      margin: 0 0 0 3px;
                    }
                    .has_sub {
                      > a {
                        padding-right: 15px !important;
                        &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 8px;
                          height: 8px;
                          box-sizing: border-box;
                          border: solid;
                          border-width: 0 2px 2px 0;
                          border-color: #aaa;
                          right: 0;
                          top: 14px;
                          transform: rotate(45deg);
                          transition: all 0.3s ease;
                        }
                        &:focus-visible:after,
                        &:hover:after {
                          border-color: var(--color-1);
                          transform: rotate(225deg);
                          top: 19px;
                        }
                      }
                    }
                    .depth1-ul {
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      justify-content: space-between;
                      margin: 0;
                      .mn_li1 {
                        margin: 0 20px 0 0;
                        .mn_a1 {
                          display: flex;
                          flex-flow: row;
                          flex-wrap: wrap;
                          font-size: 1.059em;
                          font-weight: 700;
                          color: #666;
                          line-height: 1.2;
                          padding: 0 5px;
                          align-items: center;
                          align-content: center;
                          height: 50px;
                          transition: all 0.3s ease;
                          span {
                            display: block;
                            box-sizing: border-box;
                            position: relative;
                            padding: 10px 0;
                          }
                          span:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 2px;
                            left: 50%;
                            bottom: 0;
                            background: var(--color-1);
                            transition: all 0.3s ease;
                          }
                          &:focus-visible,
                          &:hover {
                            color: var(--color-1);
                          }
                          &:focus-visible span:after,
                          &:hover span:after {
                            width: 100%;
                            left: 0;
                          }
                        }
                        .mn_tgl1 {
                          display: none;
                        }
                        &.on .mn_a1 {
                          color: var(--color-1);
                        }
                        &.on .mn_a1 span:before {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 7px;
                          height: 7px;
                          box-sizing: border-box;
                          border: solid;
                          border-width: 2px 0 0 2px;
                          border-color: var(--color-1);
                          transform: rotate(45deg) translateX(-50%);
                          left: 50%;
                          top: 3px;
                        }
                        &.on .mn_a1 span:after {
                          width: 100%;
                          left: 0;
                        }
                        &:nth-last-child(3) {
                          margin: auto 20px auto auto;
                        }
                        &:last-child {
                          margin: auto 0;
                        }
                      }
                      .all {
                        display: flex;
                        flex-flow: row;
                        flex-wrap: wrap;
                        align-items: center;
                        margin: 0 40px 0 0;
                        button {
                          display: flex;
                          flex-flow: row;
                          flex-wrap: wrap;
                          justify-content: left;
                          align-items: center;
                          width: 304px;
                          height: 50px;
                          border-radius: 999px;
                          border: 1px solid #b2b2b2;
                          background: #fff;
                          box-sizing: border-box;
                          position: relative;
                          padding: 0 20px;
                          overflow: hidden;
                          transition: all 0.3s ease;
                          > * {
                            line-height: 1;
                          }
                          .stripe {
                            width: 20px;
                            height: 18px;
                            box-sizing: border-box;
                            position: relative;
                            margin: -2px 0 0;
                            &:before,
                            & span,
                            &:after {
                              content: "";
                              display: block;
                              position: absolute;
                              width: 20px;
                              height: 2px;
                              font-size: 0;
                              top: 0;
                              right: 0;
                              bottom: 0;
                              left: 0;
                              background: #686868;
                              transition: all 0.3s ease;
                            }
                            &:before {
                              margin: 0 auto auto;
                            }
                            & span {
                              margin: auto;
                            }
                            &:after {
                              margin: auto auto 0;
                            }
                          }
                          &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            box-sizing: border-box;
                            border: solid;
                            border-width: 0 2px 2px 0;
                            border-color: var(--color-1);
                            transform: rotate(45deg);
                            right: 20px;
                            top: 17px;
                            transition: all 0.3s ease;
                          }
                          em {
                            font-style: normal;
                            text-transform: uppercase;
                            color: var(--color-1);
                            font-weight: 500;
                            margin: -2px 0 0 10px;
                            transition: all 0.3s ease;
                          }
                          &:not(.on):focus-visible,
                          &:not(.on):hover {
                            background: var(--color-1);
                            border-color: var(--color-1);
                          }
                          &:not(.on):focus-visible em,
                          &:not(.on):hover em {
                            color: #fff;
                          }
                          &:not(.on):focus-visible:after,
                          &:not(.on):hover:after {
                            border-color: #fff;
                          }
                          &:not(.on):focus-visible .stripe:before,
                          &:not(.on):hover .stripe:before,
                          &:not(.on):focus-visible .stripe span,
                          &:not(.on):hover .stripe span,
                          &:not(.on):focus-visible .stripe:after,
                          &:not(.on):hover .stripe:after {
                            background: #fff;
                          }
                          &.on {
                            background: var(--color-1);
                            border-color: var(--color-1);
                          }
                          &.on:after {
                            border-color: #fff;
                            transform: rotate(225deg);
                            top: 22px;
                          }
                          &.on em {
                            color: #fff;
                          }
                          &.on .stripe span {
                            transform: translateX(-30px);
                            opacity: 0;
                            background: #fff;
                          }
                          &.on .stripe:before {
                            margin: auto;
                            transform: rotate(45deg);
                            background: #fff;
                          }
                          &.on .stripe:after {
                            margin: auto;
                            transform: rotate(-45deg);
                            background: #fff;
                          }
                        }
                      }
                    }
                  }
                  .depth2-wrap {
                    display: none;
                    position: absolute;
                    z-index: 999;
                    top: 100%;
                    left: 0;
                    width: 210px;
                    padding: 20px;
                    background: #fff;
                    box-shadow: 0 0 7px 0.5px rgba(0, 0, 0, 0.15);
                    .depth2-ul {
                      .mn_li2 {
                        .mn_a2 {
                          transition: all 0.3s ease;
                          &:focus-visible,
                          &:hover {
                            color: var(--color-1);
                          }
                        }
                        .mn_tgl2 {
                          display: none;
                        }
                        &.has_sub {
                          > a {
                            &:after {
                              top: 8px;
                            }
                            &:focus-visible:after,
                            &:hover:after {
                              top: 12px;
                            }
                          }
                        }
                      }
                    }
                  }
                  .depth3-wrap {
                    .depth3-ul {
                      box-sizing: border-box;
                      padding: 0 0 0 15px;
                      .mn_li3 {
                        .mn_a3 {
                          transition: all 0.3s ease;
                          &:focus-visible,
                          &:hover {
                            color: var(--color-1);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .hMenuOverlay {
              display: none;
            }
          }
        }
      }
      .offcanvas-mobile-menu {
        display: none;
      }
      .hideNav {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background: #fff;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin: 0;
        z-index: 1;
        transition: box-shadow 0.5s ease;
        &.on {
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        }
        nav {
          width: 100%;
          max-width: 1400px;
          margin: 0 auto;
          display: grid;
          gap: 0;
          grid-template-columns: 1fr 1fr 3fr;
          .depth {
            box-sizing: border-box;
            position: relative;
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 1px;
              height: 100%;
              background: #ddd;
              right: 0;
              bottom: 0;
            }
            &:last-child:after {
              display: none;
            }
            > * {
              min-height: 250px;
              height: 100%;
              max-height: 50vh;
              overflow-x: hidden;
              overflow-y: visible;
              transition: all 0.3s ease;
              &::-webkit-scrollbar {
                width: 10px;
              }
              &::-webkit-scrollbar-thumb {
                background: #ccc;
                border-right: 5px solid #fff;
              }
              &::-webkit-scrollbar-track {
                background: #f0f0f0;
                border-right: 5px solid #fff;
              }
              &::-webkit-scrollbar-button {
                height: 5px;
              }
              button {
                display: none;
              }
            }
          }
          .parentReqMsg {
            box-sizing: border-box;
            position: relative;
            padding: 0 0 0 15px;
            font-size: 13px;
            line-height: 1.2;
            &:before {
              content: "";
              display: block;
              position: absolute;
              width: 8px;
              height: 8px;
              box-sizing: border-box;
              border: solid;
              border-width: 2px 0 0 2px;
              border-color: #aaa;
              left: 2px;
              top: 4px;
              transform: rotate(-45deg);
            }
          }
          .d1 {
            .d1Unit {
              ul {
                li {
                  a {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    line-height: 1.2;
                    box-sizing: border-box;
                    position: relative;
                    padding: 0 15px 0 12px;
                    height: 50px;
                    margin: 0 5px 0 0;
                    transition: all 0.3s ease;
                    span {
                      font-size: 13px;
                      line-height: 1;
                      position: relative;
                      top: -2px;
                    }
                    &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 10px;
                      height: 10px;
                      right: 25px;
                      top: 19px;
                      box-sizing: border-box;
                      border: solid;
                      border: solid;
                      border-width: 0 2px 2px 0;
                      border-color: var(--color-1);
                      transform: rotate(-45deg);
                      visibility: hidden;
                      opacity: 0;
                      transition: all 0.3s ease;
                    }
                    &:focus-visible,
                    &:hover {
                      background: #efefef;
                    }
                  }
                  &.active {
                    a {
                      color: var(--color-1);
                      background: #efefef;
                      border: 1px solid var(--color-1);
                      &:after {
                        visibility: visible;
                        opacity: 1;
                        right: 15px;
                      }
                    }
                  }
                  &.on {
                    a {
                      color: var(--color-1);
                      background: #efefef;
                      &:after {
                        visibility: visible;
                        opacity: 1;
                        right: 15px;
                      }
                    }
                  }
                  &.has_sub {
                    a {
                      span {
                      }
                      &:focus-visible span:after,
                      &:hover span:after {
                        border-color: var(--color-1);
                      }
                    }
                  }
                }
              }
            }
          }
          .d2 {
            .d2Unit {
              display: none;
              &.on {
                display: block;
              }
              ul {
                li {
                  a {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    box-sizing: border-box;
                    position: relative;
                    padding: 0 30px 0 12px;
                    height: 50px;
                    margin: 0 5px;
                    transition: all 0.3s ease;
                    .img {
                      width: 22px;
                      height: 22px;
                      margin: 0 5px 0 0;
                      box-sizing: border-box;
                      border: 1px solid #666;
                      overflow: hidden;
                      border-radius: 50%;
                      .imgContainer {
                        display: block;
                        height: 0;
                        padding: 0 0 100%;
                        box-sizing: border-box;
                        position: relative;
                        background: #fff;
                        img,
                        .icoBg {
                          display: block;
                          position: absolute;
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          margin: auto;
                          background-repeat: no-repeat;
                          background-position: center center;
                          background-size: cover;
                          transition: all 0.3s ease;
                        }
                      }
                    }
                    .txt {
                      flex: 1;
                      min-width: 0;
                      font-size: 13px;
                      line-height: 1.2;
                      position: relative;
                    }
                    &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 10px;
                      height: 10px;
                      right: 25px;
                      top: 19px;
                      box-sizing: border-box;
                      border: solid;
                      border: solid;
                      border-width: 0 2px 2px 0;
                      border-color: var(--color-1);
                      transform: rotate(-45deg);
                      visibility: hidden;
                      opacity: 0;
                      transition: all 0.3s ease;
                    }
                    &:focus-visible,
                    &:hover {
                      background: #efefef;
                    }
                  }
                  &.active {
                    a {
                      color: var(--color-1);
                      background: #efefef;
                      border: 1px solid var(--color-1);
                      &:after {
                        visibility: visible;
                        opacity: 1;
                        right: 15px;
                      }
                    }
                  }
                  &.on {
                    a {
                      color: var(--color-1);
                      background: #efefef;
                      &:after {
                        visibility: visible;
                        opacity: 1;
                        right: 15px;
                      }
                    }
                  }
                  &.has_sub {
                    a {
                      span {
                      }
                      &:focus-visible span:after,
                      &:hover span:after {
                        border-color: var(--color-1);
                      }
                    }
                  }
                }
              }
              > .parentReqMsg {
                display: none;
                margin: 15px 15px 0;
              }
              &.on > *:first-child {
                display: block;
              }
            }
            > .parentReqMsg {
              display: none;
              min-height: 0;
              margin: 15px 15px 0;
            }
            &.noChild > .parentReqMsg {
              display: block;
            }
          }
          .d3 {
            .d3Unit {
              display: none;
              box-sizing: border-box;
              padding: 17px 24px;
              &.on {
                display: block;
              }
              .lv3Tit {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                line-height: 1;
                align-items: center;
                align-content: center;
                font-size: 13px;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  box-sizing: border-box;
                  border: solid;
                  border-width: 0 2px 2px 0;
                  border-color: #9fa0a0;
                  transform: rotate(-45deg);
                  margin: 0 0 0 5px;
                }
              }
              ul {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                margin: 42px 0 0;
                li {
                  width: 20%;
                  margin: 45px 0 0;
                  &:nth-child(-n + 5) {
                    margin: 0;
                  }
                  a {
                    display: block;
                    line-height: 1;
                    text-align: center;
                    .img {
                      width: 72px;
                      box-sizing: border-box;
                      border: 2px solid #fff;
                      padding: 2px;
                      border-radius: 50%;
                      margin: 0 auto;
                      transition: all 0.3s ease;
                      .imgContainer {
                        display: block;
                        height: 0;
                        padding: 0 0 100%;
                        box-sizing: border-box;
                        position: relative;
                        background: #fff;
                        overflow: hidden;
                        border-radius: 50%;
                        transition: all 0.3s ease;
                        img,
                        .icoBg {
                          display: block;
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          top: 0;
                          left: 0;
                          background-repeat: no-repeat;
                          background-position: center center;
                          background-size: cover;
                        }
                      }
                    }
                    .txt {
                      display: block;
                      box-sizing: border-box;
                      font-size: 13px;
                      line-height: 1.2;
                      margin: 11px 0 0;
                    }
                    &:focus-visible .img,
                    &:hover .img {
                      border-color: var(--color-1);
                    }
                    &:focus-visible .txt,
                    &:hover .txt {
                      color: var(--color-1);
                    }
                  }
                  &.active,
                  &.on {
                    a {
                      .img {
                        border-color: var(--color-1);
                      }
                      .txt {
                        color: var(--color-1);
                      }
                    }
                  }
                }
              }
              > .parentReqMsg {
                display: none;
              }
              &.on > *:first-child {
                display: block;
              }
            }
            > .parentReqMsg {
              display: none;
              min-height: 0;
              margin: 17px 24px;
            }
            &.noChild > .parentReqMsg {
              display: block;
            }
          }
          .mobileTop {
            display: none;
          }
        }
      }
    }
  }
  @media all and (max-width: 1599px) {
    .pkgs {
      #head {
        .container {
          .row1 {
            .boxIcon {
              .header-right-wrap {
                .qr {
                  .hideChild.qrCode {
                    transform: translateX(0) rotateX(90deg);
                    left: auto;
                    right: 0;
                  }
                  &.active {
                    .hideChild.qrCode {
                      transform: translateX(0) rotateX(0deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1440px) {
    .pkgs {
      #head {
        .container {
          margin: 0 20px;
          width: auto;
        }
        .hideNav {
          box-sizing: border-box;
          padding: 0 20px;
        }
      }
    }
  }
  @media all and (max-width: 1399px) {
    .pkgs {
      #head {
        .hideNav {
          nav {
            grid-template-columns: 0.75fr 0.75fr auto;
            .d3 {
              .d3Unit {
                padding: 15px 10px;
                ul {
                  li {
                    margin: 20px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1299px) {
    .pkgs {
      #head {
        .container {
          padding: 30px 0;
          .row1 {
            .boxLogo {
              width: 200px;
            }
            .boxSearch {
              margin: 0 auto 0 20px;
              .inner {
                padding: 0 5px 0 0;
              }
            }
            .boxIcon {
              margin: 0 0 0 20px;
              .header-right-wrap {
                > ul {
                  > li {
                    margin: 0 15px 0 0;
                    > a,
                    > button {
                      .count-style {
                        left: 14px;
                      }
                      .ico {
                        font-size: 24px;
                      }
                      .txt {
                        font-size: 15px;
                        margin: 0 0 0 5px;
                      }
                    }
                  }
                }
                .hasChild {
                  > a,
                  > button {
                    padding-right: 15px;
                    &:after {
                      top: 7px;
                    }
                  }
                }
              }
            }
          }
          .row2 {
            margin: 20px 0 0;
            .boxNav {
              .hMenu {
                .menuCont {
                  .menuCore {
                    .depth1-wrap {
                      .depth1-ul {
                        .mn_li1 {
                          margin: 0 15px 0 0;
                          .mn_a1 {
                            padding: 0;
                            span {
                              font-size: 0.941em;
                            }
                          }
                        }
                        .all {
                          button {
                            width: 240px;
                            .stripe {
                              width: 18px;
                              height: 16px;
                            }
                            em {
                              font-size: 0.941em;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1199px) {
    .pkgs {
      #head {
        .hideNav {
          nav {
            grid-template-columns: 1fr 1fr 2fr;
            .d3 {
              .d3Unit {
                ul {
                  margin: 20px 0 0;
                  li {
                    width: 25%;
                    margin: 15px 0 0;
                    &:nth-child(-n + 4) {
                      margin: 0;
                    }
                    &:nth-child(n + 5) {
                      margin: 15px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1099px) {
    .pkgs {
      #head {
        .container {
          .row1 {
            .boxIcon {
              width: auto;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 999px) {
    .pkgs {
      #head {
        .container {
          .row1 {
            .boxIcon {
              .header-right-wrap {
                ul {
                }
                .mobile {
                }
              }
            }
          }
        }
      }
    }
  }
}
.tmM {
  #header-wrap {
    .pkgs {
      max-width: none;
      #head {
        .container {
          padding: 20px 0;
          .row1 {
            .boxLogo {
            }
            .boxSearch {
              margin: 0 0 0 20px;
              .inner {
                > * {
                  height: 40px;
                }
                button {
                  .ico {
                    font-size: 22px;
                  }
                }
              }
            }
            .boxIcon {
              width: 100%;
              margin: 15px 0 0;
              .header-right-wrap {
                justify-content: space-between;
                width: 100%;
                .trans {
                  .hideChild.langSet {
                    transform: translateX(0) rotateX(90deg);
                    left: 0;
                  }
                  &.active {
                    .hideChild.langSet {
                      transform: translateX(0) rotateX(0deg);
                    }
                  }
                }
                .cart {
                  .hideChild.shopping-cart-content {
                    transform: translateX(0) rotateX(90deg);
                    left: 0;
                  }
                  &.active {
                    .hideChild.shopping-cart-content {
                      transform: translateX(0) rotateX(0deg);
                    }
                  }
                }
                .qr {
                  .hideChild.qrCode {
                    transform: translateX(-50%) rotateX(90deg);
                    left: 50%;
                    right: auto;
                  }
                  &.active {
                    .hideChild.qrCode {
                      transform: translateX(-50%) rotateX(0deg);
                    }
                  }
                }
                .mobile {
                  display: block;
                  margin: 0;
                }
              }
            }
          }
          .row2 {
            margin: 0;
            .boxNav {
              .hMenu {
                display: block;
                position: fixed;
                right: -400px;
                top: 0;
                width: 100%;
                max-width: 400px;
                height: 100%;
                background: #fff;
                z-index: 99;
                transition: right 0.5s ease, box-shadow 0.5s ease;
                .menuCont {
                  height: 100%;
                  .menuTopM {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    height: 60px;
                    align-items: center;
                    box-sizing: border-box;
                    position: relative;
                    padding: 0 20px;
                    border-bottom: 1px solid #ddd;
                    a {
                      display: inline-flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      line-height: 1;
                      align-items: flex-end;
                      img {
                        display: block;
                        width: 150px;
                        margin: 4px 0 0;
                      }
                    }
                    button {
                      display: block;
                      position: absolute;
                      width: 60px;
                      height: 60px;
                      right: 100%;
                      top: 50%;
                      transform: translateY(-50%);
                      margin: 0 -80px 0 0;
                      border: 0;
                      background: transparent;
                      transition: all 0.3s ease;
                      &:before,
                      &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 40px;
                        height: 2px;
                        background: #fff;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        opacity: 0;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                      }
                      &:before {
                        transition: all 0.3s ease 0.3s;
                      }
                      &:after {
                        transition: all 0.3s ease 0.6s;
                      }
                    }
                  }
                  .menuCore {
                    height: calc(100% - 60px);
                    overflow-x: hidden;
                    overflow-y: auto;
                    box-sizing: border-box;
                    padding: 15px;
                    .depth1-wrap {
                      .depth1-ul {
                        .has_sub {
                          > a {
                            padding-right: 0 !important;
                            &:after {
                              display: none;
                            }
                          }
                        }
                        .mn_li1 {
                          width: 100%;
                          margin: 0;
                          border-bottom: 1px solid #ddd;
                          .mn_a1 {
                            height: 60px;
                            span {
                            }
                            span:after {
                              display: none;
                            }
                          }
                          .mn_tgl1 {
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 5px;
                            width: 30px;
                            height: 30px;
                            box-sizing: border-box;
                            border: 1px solid #ddd;
                            background: transparent;
                            transition: all 0.3s ease;
                            &:before {
                              content: "";
                              display: block;
                              position: absolute;
                              width: 10px;
                              height: 10px;
                              box-sizing: border-box;
                              border: solid;
                              border-width: 0 2px 2px 0;
                              border-color: #aaa;
                              top: 0;
                              right: 0;
                              bottom: 0;
                              left: 0;
                              margin: 7px auto auto auto;
                              transform: rotate(45deg);
                              transition: all 0.3s ease;
                            }
                          }
                          &.on .mn_a1 span:before {
                            display: none;
                          }
                          &.on .mn_tgl1 {
                            background: var(--color-1);
                            border-color: var(--color-1);
                            &:before {
                              transform: rotate(225deg);
                              margin: 12px auto auto auto;
                              border-color: #fff;
                            }
                          }
                        }
                        .all {
                          width: 100%;
                          margin: 0 0 10px;
                          button {
                            width: 100%;
                            .stripe {
                              position: relative;
                              top: auto;
                              right: auto;
                              bottom: auto;
                              left: auto;
                              margin: 0 10px 0 0;
                            }
                            em {
                              display: flex;
                              font-style: normal;
                              font-size: 14px;
                              transition: all 0.3s ease;
                            }
                            &.on em,
                            &:focus-visible em,
                            &:hover em {
                              color: #fff;
                            }
                          }
                        }
                      }
                    }
                    .depth2-wrap {
                      display: none;
                      position: relative;
                      left: auto;
                      top: auto;
                      width: 100%;
                      box-shadow: none;
                      padding: 5px 0 5px 10px;
                      border-top: 1px solid #ddd;
                      .depth2-ul {
                        .has_sub {
                          > a {
                            padding-right: 0 !important;
                            &:after {
                              display: none;
                            }
                          }
                        }
                        .mn_li2 {
                          width: 100%;
                          margin: 0;
                          .mn_a2 {
                            height: 40px;
                            display: flex;
                            flex-flow: row;
                            flex-wrap: wrap;
                            align-items: center;
                            span {
                              font-size: 0.882em;
                            }
                          }
                          .mn_tgl2 {
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 5px;
                            width: 30px;
                            height: 30px;
                            box-sizing: border-box;
                            border: 1px solid #ddd;
                            background: transparent;
                            transition: all 0.3s ease;
                            &:before {
                              content: "";
                              display: block;
                              position: absolute;
                              width: 10px;
                              height: 10px;
                              box-sizing: border-box;
                              border: solid;
                              border-width: 0 2px 2px 0;
                              border-color: #aaa;
                              top: 0;
                              right: 0;
                              bottom: 0;
                              left: 0;
                              margin: 7px auto auto auto;
                              transform: rotate(45deg);
                              transition: all 0.3s ease;
                            }
                          }
                          &.on .mn_tgl2 {
                            background: var(--color-1);
                            border-color: var(--color-1);
                            &:before {
                              transform: rotate(225deg);
                              margin: 12px auto auto auto;
                              border-color: #fff;
                            }
                          }
                        }
                      }
                    }
                    .depth3-wrap {
                      display: none;
                      position: relative;
                      left: auto;
                      top: auto;
                      width: 100%;
                      box-shadow: none;
                      padding: 5px 0 5px 10px;
                      border-top: 1px solid #ddd;
                      .depth3-ul {
                        padding: 0;
                        .has_sub {
                          > a {
                            padding-right: 0 !important;
                            &:after {
                              display: none;
                            }
                          }
                        }
                        .mn_li3 {
                          width: 100%;
                          margin: 0;
                          .mn_a3 {
                            span {
                              font-size: 0.824em;
                            }
                          }
                          .mn_tgl3 {
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .hMenuOverlay {
                display: block;
                position: fixed;
                width: 100%;
                height: 0;
                background: rgba(0, 0, 0, 0.5);
                left: 0;
                top: 0;
                opacity: 0;
                z-index: 9;
                transition: opacity 0.5s ease;
                &.show {
                  height: 100%;
                  opacity: 1;
                }
                &.hide {
                  opacity: 0;
                }
              }
            }
          }
        }
        .hideNav {
          position: fixed;
          top: 0;
          width: 100%;
          height: 100%;
          border: 0;
          margin: 0;
          padding: 0;
          z-index: 99;
          &.on {
            box-shadow: none;
          }
          nav {
            width: 100%;
            height: 100%;
            gap: 0;
            grid-template-columns: none;
            grid-template-rows: minmax(auto, 25%) minmax(auto, 25%) minmax(auto, 50%);
            padding: 55px 20px 20px;
            .depth {
              &:after {
                width: 100%;
                height: 1px;
                left: 0;
                bottom: 0;
              }
              > * {
                &::-webkit-scrollbar {
                  width: 13px;
                }
                &::-webkit-scrollbar-thumb {
                  border-left: 5px solid #fff;
                  border-right: 5px solid #fff;
                  border-radius: 0;
                }
                &::-webkit-scrollbar-track {
                  border-left: 5px solid #fff;
                  border-right: 5px solid #fff;
                }
                &::-webkit-scrollbar-button {
                  height: 5px;
                }
              }
              > * {
                min-height: 0;
                max-height: 100%;
                overflow-y: scroll;
                button {
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  box-sizing: border-box;
                  border: 0;
                  background: #fff;
                  position: relative;
                  border: 1px solid #999;
                  &:before,
                  &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 10px;
                    height: 2px;
                    box-sizing: border-box;
                    background: #999;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    transition: all 0.3s ease;
                  }
                  &:before {
                  }
                  &:after {
                    transform: rotate(90deg);
                  }
                }
              }
            }
            .d1 {
              padding: 0 0 10px;
              .d1Unit {
                ul {
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  margin: -3px -10px;
                  li {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 33.3333%;
                    box-sizing: border-box;
                    position: relative;
                    line-height: 1.2;
                    min-height: 0;
                    padding: 3px 10px;
                    justify-content: space-between;
                    align-items: center;
                    align-content: center;
                    &:nth-child(-n + 3) {
                      margin: 0;
                    }
                    a {
                      flex: 1;
                      min-width: 0;
                      padding: 0;
                      line-height: 1;
                      height: auto;
                      span {
                        line-height: 1.2;
                        display: -webkit-box;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        height: auto;
                        max-height: 32px;
                        word-wrap: break-word;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                      &:after {
                        display: none;
                      }
                    }
                    button {
                      margin: 0 0 0 5px;
                    }
                    &.on {
                      a {
                        background: #fff;
                      }
                      button {
                        border-color: var(--color-1);
                        &:before,
                        &:after {
                          background: var(--color-1);
                        }
                      }
                    }
                  }
                }
              }
            }
            .d2 {
              padding: 10px 0;
              .d2Unit {
                ul {
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  margin: -3px -10px;
                  &:first-child {
                    display: flex;
                  }
                  li {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 33.3333%;
                    box-sizing: border-box;
                    position: relative;
                    line-height: 1.3;
                    min-height: 30px;
                    padding: 3px 10px;
                    align-items: center;
                    align-content: center;
                    justify-content: space-between;
                    &:nth-child(-n + 2) {
                      margin: 0;
                    }
                    a {
                      flex: 1;
                      min-width: 0;
                      padding: 0;
                      line-height: 1;
                      height: auto;
                      span {
                        line-height: 1.2;
                        display: -webkit-box;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        height: auto;
                        max-height: 32px;
                        word-wrap: break-word;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                      &:after {
                        display: none;
                      }
                    }
                    button {
                      margin: 0 0 0 5px;
                    }
                    &.on {
                      a {
                        background: #fff;
                      }
                    }
                  }
                }
              }
              > .parentReqMsg {
                margin: 15px 0 0;
              }
            }
            .d3 {
              &:after {
                display: none;
              }
              .d3Unit {
                ul {
                  li {
                    width: 20%;
                    margin: 15px 0 0;
                    &:nth-child(-n + 5) {
                      margin: 0;
                    }
                    &:nth-child(n + 6) {
                      margin: 15px 0 0;
                    }
                    a {
                      .txt {
                        margin: 5px 0 0;
                      }
                    }
                  }
                }
              }
              > .parentReqMsg {
                margin: 15px 0 0;
              }
            }
            .mobileTop {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              position: absolute;
              left: 0;
              top: 0;
              box-sizing: border-box;
              width: 100%;
              justify-content: space-between;
              padding: 10px 20px;
              img {
                display: block;
                width: auto;
                height: 30px;
              }
              .hideNavCloser {
                width: 40px;
                height: 40px;
                border: 0;
                background: transparent;
                box-sizing: border-box;
                position: relative;
                margin: 0 -5px 0 0;
                &:before,
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 35px;
                  height: 2px;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                  background: #555;
                }
                &:before {
                  transform: rotate(45deg);
                }
                &:after {
                  transform: rotate(-45deg);
                }
              }
            }
          }
        }
      }
    }
    @media all and (max-width: 699px) {
      .pkgs {
        #head {
          .hideNav {
            nav {
              .d3 {
                .d3Unit {
                  ul {
                    li {
                      width: 25%;
                      &:nth-child(-n + 4) {
                        margin: 0;
                      }
                      &:nth-child(n + 5) {
                        margin: 15px 0 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media all and (max-width: 599px) {
      .pkgs {
        #head {
          .container {
            padding: 15px 0;
            .row1 {
              .boxLogo {
                width: 100%;
                top: 0;
                a {
                  width: 200px;
                  margin: 0;
                }
              }
              .boxSearch {
                width: 100%;
                margin: 10px -3px 0;
                .inner {
                  .searchKeywd {
                    font-size: 15px;
                  }
                }
              }
              .boxIcon {
                margin: 10px 0 0;
                .header-right-wrap {
                  ul {
                    width: auto;
                    flex: 1;
                    min-width: 0;
                    justify-content: space-between;
                    flex: 1;
                    min-width: 0;
                    position: relative;
                    li {
                      margin: 0;
                      position: static;
                      &.hasChild.active {
                        > button {
                          &:after {
                            top: 11px;
                          }
                        }
                      }
                    }
                  }
                  .hasChild {
                    .hideChild {
                      width: 100% !important;
                      transform: translateX(0) rotateX(90deg) !important;
                      left: 0 !important;
                      margin: 7px 0 0;
                    }
                    &.active {
                      .hideChild {
                        transform: translateX(0) rotateX(0deg) !important;
                      }
                    }
                  }
                  .mobile {
                    position: absolute;
                    right: 0;
                    top: -5px;
                  }
                }
              }
            }
            .row2 {
              .boxNav {
                .hMenu {
                  .menuCont {
                    .menuTopM {
                      button {
                        width: 60px;
                        height: 60px;
                        right: 0;
                        margin: 0;
                        &:before,
                        &:after {
                          box-shadow: none;
                        }
                        &:before,
                        &:after {
                          width: 40px;
                          background: #555;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .hideNav {
            nav {
              .d1 {
                .d1Unit {
                  ul {
                    li {
                      width: 50%;
                    }
                  }
                }
              }
              .d2 {
                .d2Unit {
                  ul {
                    li {
                      width: 50%;
                    }
                  }
                }
              }
              .d3 {
                .d3Unit {
                  ul {
                    li {
                      width: 33.3333%;
                      &:nth-child(-n + 3) {
                        margin: 0;
                      }
                      &:nth-child(n + 4) {
                        margin: 15px 0 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media all and (max-width: 499px) {
      .pkgs {
        #head {
          .container {
            .row1 {
              .boxIcon {
                .header-right-wrap {
                  > ul {
                    li {
                      .count-style {
                        display: none;
                      }
                      .ico {
                        font-size: 18px;
                      }
                      .txt {
                        font-size: 13px;
                        margin: 0 0 0 5px;
                      }
                      &.qr {
                        display: none;
                      }
                    }
                  }
                  .hasChild {
                    > button {
                      &:after {
                        top: 4px;
                      }
                    }
                    &.active > button:after {
                      top: 8px;
                    }
                  }
                }
              }
            }
          }
          .hideNav {
            nav {
              .mobileTop {
                img {
                  height: 25px;
                }
                .hideNavCloser {
                  &:before,
                  &:after {
                    width: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
    @media all and (max-width: 399px) {
      .pkgs {
        #head {
          .container {
            .row1 {
              .boxLogo {
                a {
                  width: 170px;
                }
              }
              .boxIcon {
                .header-right-wrap {
                  > ul {
                    li {
                      .ico {
                        font-size: 15px;
                      }
                      .txt {
                        font-size: 13px;
                        margin: 0 0 0 2px;
                      }
                    }
                  }
                  .hasChild {
                    > button {
                      &:after {
                        top: 2px;
                      }
                    }
                    &.active > button:after {
                      top: 7px;
                    }
                  }
                }
              }
            }
          }
          .hideNav {
            nav {
              .d1 {
                .d1Unit {
                  ul {
                    li {
                      width: 100%;
                      margin: 0 !important;
                    }
                  }
                }
              }
              .d2 {
                .d2Unit {
                  ul {
                    li {
                      width: 100%;
                      margin: 0 !important;
                    }
                  }
                }
              }
              .d3 {
                .d3Unit {
                  ul {
                    li {
                      width: 50%;
                      &:nth-child(-n + 2) {
                        margin: 0;
                      }
                      &:nth-child(n + 3) {
                        margin: 15px 0 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.tmM_open {
    overflow: hidden;
    #header-wrap {
      .pkgs {
        #head {
          .container {
            .row2 {
              .boxNav {
                .hMenu {
                  right: 0;
                  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.2);
                  .menuCont {
                    .menuTopM {
                      button {
                        margin: 0;
                        &:before {
                          opacity: 1;
                          transform: rotate(45deg);
                        }
                        &:after {
                          opacity: 1;
                          transform: rotate(-45deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media all and (max-width: 599px) {
      #header-wrap {
        .pkgs {
          #head {
            .container {
              .row2 {
                .boxNav {
                  .hMenu {
                    .menuCont {
                      .menuTopM {
                        button {
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
