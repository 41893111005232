.recent-order-body {
  .tab-content {
    cursor: pointer !important;
    margin-right: 10px;
  }

  .tab-active {
    font-weight: bold !important;
  }

  .tab-inactive {
    font-weight: normal !important;
  }

  .col-margin-10 {
    margin: 0 10px;
  }

  .date-box {
    margin-right: 30px !important;
  }

  .flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .center-alignment {
    display: flex;
    align-items: center;
  }

  .vertical-list {
    display: flex !important;
    flex-direction: column !important;
  }

  .mr-i-10 {
    margin-right: 10px !important;
  }

  .cancel-order-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    .cancle-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 4px;
      .cancle-title {
        margin-right: 5px;
      }
    }
  }

  .inqury-box {
    .sales-titile {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .on-recent-detail {
    list-style: none;
    padding: 0;
    display: flex;

    li {
      margin-right: 10px;

      button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        transition: color 0.6s ease;

        &:hover {
          color: #007bff;
        }
      }

      &.on {
        button {
          font-weight: bold;
          color: #007bff; // 클릭 시 색상 변경
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .flex-between-center {
    flex-direction: column;
    align-items: flex-start;
  }

  .date-box {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }

  .tab-content {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cancel-order-box {
    .cancle-wrap {
      flex-direction: column;
      align-items: center;
      .cancle-title {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }

  .on-recent-detail {
    flex-direction: column;
    li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 480px) {
  .inqury-box .sales-titile {
    font-size: 1.2rem;
  }

  .tab-content {
    font-size: 14px;
  }

  .on-recent-detail li button {
    font-size: 14px;
  }
}
