@charset "utf-8";
#footer-wrap {
  padding: 0 0 60px;
  background: #fff;
  margin: 100px 0 0;
  position: relative;
  .container1 {
    border: solid;
    border-width: 2px 0;
    border-color: #e2e2e2;
    ul {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 1400px;
      margin: 0 auto;
      li {
        box-sizing: border-box;
        padding: 7px 17px 7px 0;
        margin: 0 13px 0 0;
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 17px;
          background: #b2b2b2;
          right: 0;
          top: 14px;
        }
        &:last-child {
          padding-right: 0;
          margin-right: 0;
        }
        &:last-child:after {
          display: none;
        }
        a {
          display: block;
          line-height: 1.2;
          padding: 5px 0;
          font-size: 0.941em;
          font-weight: 500;
          transition: all 0.3s ease;
          span {
            display: block;
            line-height: 1.2;
          }
          &:focus-visible,
          &:hover {
            color: var(--color-1);
          }
        }
      }
    }
  }

  .sns_ul_wrapper {
    gap: 10px;
  }

  .container2 {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    gap: 0;
    grid-template-columns: 266px 1fr 340px;
    padding: 0;
    > * {
      box-sizing: border-box;
      position: relative;
    }
    .footCopy {
      box-sizing: border-box;
      padding: 37px 0 0;
      .logo {
        width: 100%;
        a {
          display: block;
        }
        img {
          display: block;
          max-width: 100%;
        }
      }
      .sns {
        margin: 23px 0 0;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          li {
            &:last-child {
              margin: 0;
            }
            a {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              background: #e5e5e5;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              transition: all 0.3s ease;
              i {
                font-size: 1.412em;
                color: #696969;
                transition: all 0.3s ease;
              }
              &:focus-visible,
              &:hover {
                background: var(--color-1);
              }
              &:focus-visible i,
              &:hover i {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .footAddr {
      box-sizing: border-box;
      padding: 41px 20px 0 45px;
      .list {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        .footer_list {
          width: 50%;
          margin: 0 0 8px;
          box-sizing: border-box;
          padding: 0 15px 0 0;
          &.w100p {
            width: 100%;
          }
          &:last-child {
            margin: 0;
          }
          dl {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            dt,
            dd {
              font-size: 14px;
              font-weight: 500;
              color: #727171;
              box-sizing: border-box;
              position: relative;
              line-height: 1.2;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
            }
            dt {
              width: 120px;
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 2px;
                height: 13px;
                background: #727171;
                right: 0;
                top: 2px;
              }
            }
            dd {
              flex: 1;
              padding: 0 0 0 8px;
              a {
                transition: all 0.3s ease;
                &:hover,
                &:focus-visible {
                  color: var(--color-1);
                }
              }
            }
          }
        }
      }
      .msg {
        font-size: 14px;
        margin: 15px 0 0;
      }
      .copy {
        line-height: 1.2;
        margin: 10px 0 0;
        color: #b2b2b2;
        p {
          display: inline-block;
          font-size: 14px;
          &:last-child {
            margin: 0 0 0 5px;
          }
        }
        a {
          text-decoration: underline;
          color: var(--color-1);
        }
      }
    }
    .footUtil {
      box-sizing: border-box;
      padding: 22px 20px 0;
      text-align: center;
      .inner {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        box-sizing: border-box;
        border: solid;
        border-width: 0 1px;
        border-color: #ddd;
        padding: 0 20px;
        height: 100%;
        .csCenter {
          dt,
          dd {
            line-height: 1;
            color: var(--color-1);
          }
          dt {
            font-size: 1.353em;
            .ico {
              font-weight: 500;
              margin: 0 7px 0 0;
            }
            .txt {
              font-weight: 700;
            }
          }
          dd {
            margin: 26px 0 0;
            .num {
              display: block;
              font-size: 1.764em;
              font-weight: 700;
            }
            .guide {
              display: block;
              font-size: 14px;
              color: #808080;
              margin: 8px 0 0;
            }
          }
        }
        .msg {
          margin: 19px 0 0;
          span {
            display: block;
            font-size: 0.882em;
            color: #727171;
            line-height: 1.2;
          }
        }
      }
    }
  }
  .scroll-top {
    display: none;
    position: fixed;
    right: -70px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--color-1);
    color: #fff;
    border: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease;
  }
  .scroll-top.show {
    right: 20px;
    z-index: 100;
  }
  @media all and (max-width: 1440px) {
    .container1 {
      ul {
        width: auto;
        margin: 0 20px;
      }
    }
    .container2 {
      width: auto;
      margin: 0 20px;
    }
  }
  @media all and (max-width: 1399px) {
    .container2 {
      grid-template-columns: 200px 1fr 340px;
      .footCopy {
        padding: 20px 0 0;
      }
      .footAddr {
        padding: 25px 0 0 30px;
        .list {
          li {
            dl {
              dt {
                width: 120px;
              }
            }
          }
        }
        .msg {
          margin: 15px 0 0;
          p {
            &:last-child {
              margin: 0;
            }
          }
        }
        .copy {
          margin: 10px 0 0;
        }
      }
      .footUtil {
        padding: 20px 20px 0;
      }
    }
  }
  @media all and (max-width: 1199px) {
    & {
      padding: 0 0 30px;
      margin: 50px 0 0;
    }
    .container2 {
      grid-template-columns: 160px 1fr 250px;
      .footUtil {
        padding: 20px 0 0 20px;
      }
    }
  }
  @media all and (max-width: 999px) {
    .container2 {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      .footCopy {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 10px;
        .logo {
          width: 200px;
        }
        .sns {
          margin: 0;
        }
      }
      .footAddr {
        width: calc(100% - 220px);
        padding: 0;
        .list {
          li {
            margin: 0 0 5px;
            dl {
              dt {
                &:after {
                  width: 1px;
                  background: #aaa;
                  top: 3px;
                }
              }
            }
          }
        }
      }
      .footUtil {
        width: 220px;
        padding: 0;
      }
    }
  }
  @media all and (max-width: 799px) {
    & {
      padding: 0 0 20px;
      margin: 30px 0 0;
    }
    .container1 {
      ul {
        margin: 0;
        padding: 5px 0;
        li {
          width: 50%;
          margin: 0;
          padding: 2px 20px;
          &:after {
            top: 10px;
            height: 15px;
          }
          &:nth-child(even) {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .container2 {
      .footCopy {
        order: 1;
        .sns {
          ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            li {
              &:nth-child(odd) {
                margin-right: 0;
              }
            }
          }
        }
      }
      .footAddr {
        order: 3;
        width: 100%;
        margin: 20px 0 0;
        .msg {
          p {
            display: inline;
            word-wrap: break-word;
            word-break: keep-all;
            white-space: normal;
          }
        }
      }
      .footUtil {
        order: 2;
        width: 100%;
        .inner {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 15px;
          border: 1px solid #ddd;
          align-items: flex-end;
          align-content: flex-end;
          border-radius: 5px;
          .csCenter {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            dt,
            dd {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              line-height: 1;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
            }
            dt {
              margin: 0 10px 0 0;
            }
            dd {
              margin: 0;
              .num {
                font-size: 1.353em;
              }
              .guide {
                margin: 6px 0 0 5px;
              }
            }
          }
          .msg {
            margin: 0;
            line-height: 1;
            margin: 0 0 -2px;
            span {
              display: inline;
              margin: 0 10px 0 0;
              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
    .scroll-top {
      display: block;
    }
  }
  @media all and (max-width: 757px) {
    .container2 {
      .footCopy {
        .sns {
          ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            li {
              &:nth-child(odd) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 699px) {
    .container1 {
      ul {
        li {
          a {
            padding: 3px 0;
          }
        }
      }
    }
    .container2 {
      .footUtil {
        .inner {
          > * {
            width: 100%;
            text-align: left;
          }
          .csCenter {
          }
          .msg {
            margin: 5px 0 0;
            justify-content: left;
          }
        }
      }
    }
  }
  @media all and (max-width: 499px) {
    .container2 {
      .footCopy {
        display: block;
        .logo {
          width: 150px;
          margin: 0 auto;
        }
        .sns {
          width: 100%;
          margin: 5px 0 0;
          ul {
            width: 100%;
            justify-content: center;
          }
        }
      }
      .footUtil {
        .inner {
          text-align: center;
          justify-content: center;
          padding: 10px;
          .csCenter {
            display: inline-block;
            width: auto;
            dt,
            dd {
              width: 100%;
              font-size: 1em;
              justify-content: left;
            }
            dt {
              margin: 0 0 5px;
              .ico {
                margin: 2px 5px 0 0;
              }
            }
            dd {
              .num {
                font-size: 1.2em;
              }
            }
          }
          .msg {
            span {
              display: block;
              text-align: center;
              margin: 2px 0 0;
            }
          }
        }
      }
      .footAddr {
        .list {
          li {
            dl {
              dt,
              dd {
                font-size: 13px;
              }
              dt {
                width: 110px;
                padding: 0 0 0 7px;
                color: #777;
                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 2px;
                  height: 2px;
                  border-radius: 50%;
                  background: #aaa;
                  left: 0;
                  top: 7px;
                }
                &:after {
                  top: 2px;
                }
              }
              dd {
                color: #333;
              }
            }
          }
        }
        .msg {
          font-size: 13px;
        }
        .copy p {
          display: block;
          font-size: 13px;
          letter-spacing: 0;
          margin: 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 768px) {
    .container1 li {
      width: 100%;
      margin: 5px 0;
      padding: 10px 0;
      border-bottom: 1px solid #e2e2e2;
    }

    .container1 li:after {
      display: none;
    }

    .container1 a {
      font-size: 1em;
      padding: 10px 0;
    }

    .container2 {
      grid-template-columns: 1fr;
      padding: 20px 10px;
    }

    .footCopy,
    .footAddr,
    .footUtil {
      padding: 20px 10px;
      text-align: left;
    }

    .sns ul {
      justify-content: flex-start;
    }

    .footAddr {
      box-shadow: none;
      border-radius: 0;
    }

    .footUtil {
      box-shadow: none;
      border-radius: 0;
    }

    .msg {
      font-size: 12px;
    }

    .copy {
      font-size: 12px;
    }

    .pTxt1,
    .pTxt2 {
      font-size: 12px;
    }

    .inner {
      flex-direction: column;
      align-items: flex-start;
    }

    .csCenter dt,
    .csCenter dd {
      font-size: 12px;
    }

    .scroll-top {
      width: 30px;
      height: 30px;
    }

    .footer_list {
      width: 100% !important;
    }
  }
}
html[lang="zh"] {
  #footer-wrap {
    .container2 {
      .footAddr {
        .list {
          li {
            dl {
              dt {
                width: 140px;
              }
            }
          }
        }
      }
    }
  }
}
