@charset "utf-8";
/* ==============================
*
================================= */
#GqMarket {
	.gqCateSelector {
		box-sizing:border-box;position:relative;z-index:1;
		.catePkg {
			box-sizing:border-box;position:relative;display:flex;flex-flow:row;flex-wrap:wrap;width:100%;height:66px;
			.schTxt {
				position:absolute;left:8px;top:8px;z-index:1;
				.cateSchResult {
					position:absolute;left:0;bottom:100%;margin:0 0 20px;width:800px;
					.unit {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
						.selectedCateLineage {
							ul {
								display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
								li {
									box-sizing:border-box;position:relative;padding:0 15px 0 0;margin:0 10px 0 0;
									&:last-child {
										padding:0;margin:0;
										&:after {display:none;}
									}
									&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(-45deg);right:0;top:9px;}
								}
							}
						}
						.lineageStr {display:block;width:100%;}
					}
				}
				.cateSchForm {
					.schOpener {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:50px;height:50px;border:0;background:#dbdbdb;border-radius:50%;font-size:0;position:relative;transition:all .3s ease;
						i {font-size:25px;transition:all .3s ease;}
						&:hover,
						&:focus-visible {
							background:var(--color-1);
							i {color:#fff;}
						}
					}
					.searchPop {
						.popupWrap {
							width:700px;height:600px;
							.popWrap {
								height:100%;
								.popContScrollBox {
									display:flex;flex-flow:column;flex-wrap:wrap;
									.schInpBox {
										height:50px;
										.pkg {
											display:flex;flex-flow:row;flex-wrap:wrap;width:100%;height:100%;box-sizing:border-box;border:1px solid #ddd;border-radius:999px;overflow:hidden;padding:0 10px 0 10px;
											.schKeywd {flex:1;min-width:0;border:0;outline:0;box-sizing:border-box;padding:0 20px 0 15px;height:100%;}
											.schKeyCast {
												width:50px;height:100%;border:0;background:transparent;box-sizing:border-box;position:relative;
												i {font-size:25px;transition:all .3s ease;}
												&:before {content:"";display:block;position:absolute;width:1px;height:24px;left:0;top:50%;transform:translateY(-50%);background:#ddd;margin:0 0 0 -10px;}
											}
										}
									}
									.schResBox {
										flex:1;min-height:0;overflow-x:hidden;overflow-y:auto;padding:20px 0 0;
										&::-webkit-scrollbar {width:13px}
										&::-webkit-scrollbar-thumb {background:#ccc;border-left:5px solid #fff;border-right:5px solid #fff;}
										&::-webkit-scrollbar-track {background:#eee;border-left:5px solid #fff;border-right:5px solid #fff;}
										&::-webkit-scrollbar-button {height:5px}
										.noDataWr {
											width:100%;height:100%;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
											.noData {
												text-align:center;
												i {font-size:3em;}
												span {display:block;}
											}
										}
										ul {
											li {
												padding:0 0 5px;margin:0 0 5px;border-bottom:1px solid #ddd;
												&:last-child {padding:0;margin:0;border-bottom:0;}
												button {
													display:block;width:100%;border:0;background:transparent;box-sizing:border-box;padding:3px 5px 4px;text-align:left;font-size:.882em;letter-spacing:-.05em;line-height:1;transition:all .3s ease;
													.cate {
														box-sizing:border-box;position:relative;padding:0 12px 0 0;margin:0 10px 0 0;word-wrap:break-word;word-break:keep-all;white-space:normal;
														&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(-45deg);right:0;top:6px;transition:all .3s ease;}
														&:last-child {
															padding:0;margin:0;
															&:after {display:none;}
														}
													}
													&:hover,
													&:focus-visible {
														color:var(--color-1);
														.cate {
															&:after {border-color:var(--color-1);}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.schUI {
				flex:1;min-width:0;
				.hideNav {
					height:100%;
					nav {
						display:flex;flex-flow:row;flex-wrap:wrap;height:100%;
						.depth {
							flex:1;min-width:0;height:100%;box-sizing:border-box;position:relative;
							.dUnitTit {
								position:relative;width:100%;height:100%;background:#fff;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;border:solid;border-width:1px 1px 1px 0;border-color:#999;padding:0 60px;transition:all .3s ease;
								span {word-wrap:break-word;word-break:keep-all;white-space:normal;line-height:1.2;letter-spacing:-.05em;}
								&:hover,
								&:focus-visible {
									background:var(--color-1);color:#fff;border-color:var(--color-1);
									&:after {opacity:0;}
								}
								&.on {
									span {color:var(--color-1);font-weight:500;}
									&:hover,
									&:focus-visible {
										span {color:#fff;}
									}
								}
							}
							.dUnitBody {
								display:none;position:absolute;width:100%;left:0;top:100%;box-sizing:border-box;padding:15px 5px 15px 15px;margin:15px 0 0;border:1px solid #ddd;background:#fff;height:315px;box-shadow:var(--shadow1);
								.scrollBox {
									height:100%;overflow-x:hidden;overflow-y:auto;
									&::-webkit-scrollbar {width:13px}
									&::-webkit-scrollbar-thumb {background:#ccc;border-left:5px solid #fff;border-right:5px solid #fff;}
									&::-webkit-scrollbar-track {background:#eee;border-left:5px solid #fff;border-right:5px solid #fff;}
									&::-webkit-scrollbar-button {height:0}
									ul {
										border-top:0;
										li {
											padding:0 0 5px;margin:0 0 5px;border-bottom:1px solid #ddd;
											&:last-child {padding:0;margin:0;border:0;}
											button {
												display:block;line-height:1;width:100%;text-align:left;border:0;background:#fff;border-radius:3px;padding:3px 5px 4px;outline:0;transition:all .3s ease;
												span {display:block;line-height:1.2;font-size:.882em;font-weight:500;letter-spacing:-.05em;}
												&:hover,
												&:focus-visible {color:var(--color-1);}
											}
											&.on {
												button {background:var(--color-1);color:#fff;}
											}
										}
									}
								}
							}
							.dUnitCloser {
								position:absolute;width:40px;height:40px;border-radius:0 5px 5px 0;background:var(--color-1);left:100%;top:20px;border:0;transition:all .3s ease;
								&:before,
								&:after {content:"";display:block;position:absolute;width:15px;height:2px;top:0;right:0;bottom:0;left:0;margin:auto;background:#fff;border:0;transition:all .3s ease;}
								&:before {transform:rotate(45deg);}
								&:after {transform:rotate(-45deg);}
							}
							&.d1 {
								.dUnitTit {border-radius:999px 0 0 999px;border-left-width:1px;}
								.dUnitBody {
									.d1Unit {}
								}
							}
							&.d2 {
								.dUnitTit {}
								.dUnitBody {
									.d2Unit {
										display:none;
										&.on {display:block}
										ul {}
										> .parentReqMsg {display:none;margin:5px 0 0;}
										&.on > *:first-child {display:block;}
									}
									.scrollBox > .parentReqMsg {display:none;}
								}
								&.noChild .scrollBox .parentReqMsg {display:block;}
							}
							&.d3 {
								.dUnitTit {border-radius:0 999px 999px 0;}
								.dUnitBody {
									.d3Unit {
										display:none;
										&.on {display:block}
										ul {
										}
										> .parentReqMsg {display:none;margin:5px 0 0;}
										&.on > *:first-child {display:block;}
									}
									.scrollBox > .parentReqMsg {display:none;}
								}
								&.noChild .scrollBox .parentReqMsg {display:block;}
							}
							.parentReqMsg {
								box-sizing:border-box;position:relative;padding:0 0 0 15px;
								&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 0 2px 2px;border-color:#888;left:3px;top:3px;transform:rotate(45deg);}
								span {display:block;line-height:1;font-size:.882em;}
							}
						}
					}
				}
			}
			&.hover1 {
				.schTxt {
					.cateSchForm {
						.schOpener {
							background:#fff;
							&:before,
							span,
							&:after {background:var(--color-1);}
						}
					}
				}
			}
		}
	}
	.gqTitle1 {font-size:1.470em;color:#1a1a1a;letter-spacing:-.05em;margin:0 0 26px;}
	.gqTool1 {
		display:flex;flex-flow:row;flex-wrap:wrap;
		&.bet {justify-content:space-between;}
		&.ac {align-items:center;align-content:center;}
	}
	.reqMsg {
		font-size:14px;
	}
	.req {
		padding:0 5px;vertical-align:middle;line-height:1;font-style:normal;
		&:before {content:"*";color:#e64646;font-size:15px;line-height:1;}
	}
	&.Main {
		.deco1,
		.deco2 {
			box-sizing:border-box;position:relative;
			&:after {content:"";display:block;position:absolute;width:2000px;height:100%;left:50%;top:0;transform:translateX(-50%);z-index:-1;}
		}
		.gqCateSelector {padding:75px 240px 30px;}
		.gqRequest {
			display:inline-block;border-radius:20px;line-height:1;padding:23px 65px 28px;position:relative;overflow:hidden;transition:all .3s ease;
			span {display:block;font-size:1.529em;font-weight:500;position:relative;z-index:1;}
			&:after {content:"";display:block;position:absolute;width:100%;height:100%;z-index:0;right:100%;top:0;transition:all .3s ease;}
				&:hover,
				&:focus-visible {
					&:after {right:0;}
				}
		}
		.deco1 {
			margin:-30px 0 0;padding:90px 115px 75px;text-align:center;
			&:after {background:#fefddf;}
			.tag {
				font-size:2.529em;font-style:italic;font-weight:700;font-family:"forma-djr-deck",sans-serif;letter-spacing:0;line-height:1.2;
				em {color:var(--color-1);}
			}
			.msg {
				font-family:"supersize-bk",sans-serif;font-size:3.529em;font-style:italic;color:#333;line-height:1.43;letter-spacing:-.025em;margin:30px 0 0;
				em {color:var(--color-1);}
			}
			.nums {
				display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;box-sizing:border-box;padding:0 100px;margin:45px 0 0;
				li {
					width:33.3333%;
					.ico {
						width:160px;height:160px;margin:0 auto;background:url(/assets/img/_gq/gq_spr1.png) no-repeat;
						&.n01 {background-position:0 0;}
						&.n02 {background-position:-170px 0;}
						&.n03 {background-position:-340px 0;}
					}
					dl {
						margin:5px 0 0;
						dt,
						dd {line-height:1;}
						dt {font-size:.941em;color:#666;}
						dd {font-size:2em;color:var(--color-1);font-weight:700;margin:12px 0 0;}
					}
				}
			}
			.gqRequest {
				background:var(--color-1);color:#fff;margin:70px 0 0;
				&:after {background:linear-gradient( to left, var(--color-1) 0%, var(--color-2) 100% );}
			}
		}
		.deco2 {
			padding:100px 550px 100px 115px;text-align:left;
			&:before {content:"";display:block;position:absolute;width:533px;height:591px;right:0;bottom:0;z-index:1;background:url(/assets/img/_gq/gq_spr1.png) no-repeat 0 -170px}
			&:after {background:linear-gradient( to right, var(--color-1) 0%, var(--color-2) 100% );}
			.msg1 {font-size:2em;font-weight:700;color:#fff;line-height:1.2;}
			.msg2 {
				font-size:2.941em;font-family:"supersize-bk",sans-serif;color:#fff;font-style:italic;text-shadow:10px 10px 10px rgba(33, 45, 215, .75);line-height:1.2;margin:50px 0 0;
				em {color:#ff0;}
			}
			.msg3 {
				margin:80px 0 0;
				li {
					box-sizing:border-box;position:relative;padding:0 0 0 30px;line-height:1.2;color:#fff;margin:0 0 27px;
					&:last-child {margin:0;}
					&:before,
					&:after {content:"";display:block;position:absolute;box-sizing:border-box;border:solid;border-color:#fff;}
					&:before {width:8px;height:5px;border-width:0 0 2px 2px;transform:rotate(-45deg);left:4px;top:6px;z-index:1;}
					&:after {width:18px;height:18px;border-width:2px;left:0;top:1px;z-index:0;}
				}
			}
			.gqRequest {
				background:#fff;color:var(--color-1);margin:90px 0 0;box-shadow:10px 10px 10px rgba(33, 45, 215, .75);
				&:after {background:linear-gradient( to left, #fff 0%, var(--color-db) 100% );}
			}
		}
		.gqOptFilter {
			box-sizing:border-box;padding:0 115px;
			.pkg {
				border:1px solid #bcbcbc;border-radius:8px;overflow:hidden;
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;border-bottom:1px solid #bcbcbc;
					&:last-child {border-bottom:0;}
					dt,
					dd {box-sizing:border-box;line-height:1.2;}
					dt {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:180px;background:#e5e5e5;font-size:.882em;color:#666;border-right:1px solid #bcbcbc;text-align:center;}
					dd {
						flex:1;min-width:0;padding:15px 0 15px 17px;
						.listType1 {
							display:flex;flex-flow:row;flex-wrap:wrap;
							li {
								margin:0 20px 0 0;width:100px;
								&:last-child {margin:0;}
							}
						}
					}
				}
			}
		}
		.gqList {
			box-sizing:border-box;padding:0 115px;margin:30px 0 0;
			.tool {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
				.sort {
					button {
						line-height:1;color:#666;background:#fff;font-size:.941em;padding:3px 18px 4px 0;box-sizing:border-box;position:relative;margin:0 20px 0 0;border:0;transition:all .3s ease;
						&:last-child {margin:0;}
						&:after {content:"";display:block;position:absolute;width:0;height:0;border:solid;box-sizing:border-box;border-width:6px 6px 0;border-color:#666 transparent transparent;right:0;top:8px;transition:all .3s ease;}
						span {display:block;line-height:1;}
						&:hover,
						&:focus-visible {
							color:var(--color-1);
							&:after {border-color:var(--color-1) transparent transparent;}
						}
						&.on {
							&:after {border-width:0 6px 6px;border-color:transparent transparent var(--color-1);top:9px;}
							span {color:var(--color-1);}
						}
					}
				}
				.search {
					height:32px;display:flex;flex-flow:row;flex-wrap:wrap;
					> * {
						margin:0 7px 0 0;
						&:last-child {margin:0;}
					}
					select,
					input,
					button {height:100%;box-sizing:border-box;border-radius:5px;border:1px solid #888;font-size:14px;}
					select {width:120px;}
					input {width:250px;padding:0 15px;}
					button {
						width:70px;border-color:#666;background:#666;color:#fff;transition:all .3s ease;
						&:hover,
						&:focus-visible {background:#000;border-color:#000;}
					}
				}
			}
			.list {
				margin:30px 0 0;
				> ul {
					display:block;
					> li {
						display:block;margin:0 0 30px;
						&:last-child {margin:0;}
						.item {
							display:flex;flex-flow:row;flex-wrap:wrap;border-radius:20px;box-sizing:border-box;border:1px solid #c9c9c9;min-height:207px;overflow:hidden;
							.boxImg {
								width:200px;box-sizing:border-box;position:relative;background:#eee;
								.imgContainer {
									display:block;position:relative;height:100%;
									img {display:block;position:absolute;width:auto;height:auto;width:100%;height:100%;top:0;left:0;transition:all .3s ease;}
								}
								&:hover,
								&:focus-visible {
									.imgContainer {
										img {transform:scale(1.1);}
									}
								}
							}
							.boxSumm {
								display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;flex:1;min-width:0;
								.setTop {
									display:flex;flex-flow:row;flex-wrap:wrap;flex:1;min-height:0;width:100%;
									.pkgCont {
										flex:1;min-width:0;box-sizing:border-box;padding:21px 27px 15px;
										.gqInfo {
											display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
											> * {box-sizing:border-box;position:relative;line-height:1;}
											.sellerIco {font-size:16px;color:var(--color-1);margin:0 5px 0 0;}
											.sellerName {
												font-size:12px;padding:0 10px 0 0;margin:0 10px 0 0;
												&:after {content:"";display:block;position:absolute;width:1px;height:10px;background:#aaa;right:0;top:2px;}
											}
											.sellerLv {font-size:12px;}
										}
										.gqTitle {
											display:block;font-size:1.059em;font-weight:700;color:#333;line-height:1.2;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;margin:13px 0 0;
											span {transition:all .3s ease;}
											&:hover,
											&:focus-visible {
												span {box-shadow:0 -12px 0 rgba(17, 101, 224, .1) inset;color:var(--color-1);}
											}
										}
										.gqDesc {display:block;font-size:13px;color:#666;line-height:1.2;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;margin:14px 0 16px;}
										.gqSpec {
											display:flex;flex-flow:row;flex-wrap:wrap;margin:-5px;
											> li {
												padding:5px;
												.pkg {
													display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;border:1px solid #80adee;border-radius:4px;line-height:1;padding:3px 6px 3px;
													dl {
														display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;height:17px;
														dt,
														dd {box-sizing:border-box;position:relative;line-height:1;font-size:12px;font-weight:500;}
														dt {
															padding:0 5px 0 0;margin:0 2px 0 0;
															&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:0px;font-size:12px;}
														}
														dd {
															img {display:block;width:24px;height:auto;box-sizing:border-box;border:1px solid #aaa;}
														}
													}
												}
											}
										}
									}
									.pkgBtn {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:235px;height:100%;
										.gqSend {
											display:inline-block;line-height:1;border-radius:999px;color:#fff;background:var(--color-1);padding:9px 22px 13px;transition:all .3s ease;
											span {display:block;line-height:1;font-size:1.059em;font-weight:500;}
											&:hover,
											&:focus-visible {background:var(--color-2);}
										}
									}
								}
								.setBottom {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;border-top:1px solid #ccc;height:44px;padding:0 27px;
									dl {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;padding:0 15px 0 0;margin:0 10px 0 0;box-sizing:border-box;position:relative;
										&:after {content:"/";display:block;position:absolute;width:auto;height:auto;right:0;top:1px;font-size:10px;line-height:1;}
										&:last-child {
											margin:0;padding:0;
											&:after {display:none;}
										}
										dt,
										dd {box-sizing:border-box;position:relative;line-height:1;font-size:12px;}
										dt {
											padding:0 5px 0 0;margin:0 5px 0 0;
											&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:0;top:0px;}
										}
										dd {}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&.Request {
		box-sizing:border-box;padding:0 115px;
		.gqCateSelector {
			.cateSchResult {display:none;}
		}
		.frmWr {
			box-sizing:border-box;padding:30px 30px 0;margin:30px 0 0;
			&.hasBorder {border-top:1px solid #999;}
			> ul {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:0 -34px;
				> li {
					box-sizing:border-box;position:relative;padding:0 34px;margin:26px 0 0;
					select,
					input[type="text"] {display:block;width:100%;box-sizing:border-box;border-radius:4px;border:1px solid #ccc;height:45px;line-height:45px;}
					select {}
					input[type="text"] {}
					&.w33p {width:33.3333%;}
					&.w50p {width:50%;}
					&.w66p {width:66.6666%;}
					.unitPkg {
						display:flex;flex-flow:row;flex-wrap:wrap;margin:0 -34px;
						.unit {
							box-sizing:border-box;padding:0 34px;
						}
					}
				}
			}
			.agreeBox {
				.chks {
					margin:0 0 10px;
					&:last-child {margin:0;}
				}
				.em {font-style:normal;color:var(--color-1);font-weight:500;}
			}
		}
		.gqRequestLatest.dialog {
			> ul {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:-10px;
				li {
					width:25%;box-sizing:border-box;padding:10px;
					> * {
						&.boxImg {
							box-sizing:border-box;border:1px solid #ddd;
							.imgContainer {
								display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
							}
						}
						&.boxTxt {
							margin:10px 0 0;
							p {font-size:13px;line-height:1.3;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:34px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;text-align:center;}
						}
						&.boxBtn {text-align:center;margin:10px 0 0;}
					}
				}
			}
		}
	}
}