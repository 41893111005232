/* ==============================
* Login
================================= */
.loginArea {
	width:100%;max-width:478px;margin:0 auto;padding:100px 0;
	input:not([type='file']) {border:1px solid #ccc;height:40px;line-height:40px;padding:0 10px;box-sizing:border-box;border-radius:4px;}
	.loginTit {display:block;font-size:1.764em;color:#000;font-weight:700;}
	.loginFrame {
		margin:23px 0 0;
		.loginInput {
			margin:11px 0 0;
			&:first-child {margin:0;}
			input {display:block;width:100%;box-sizing:border-box;height:44px;font-size:15px;position:relative;z-index:0;}
			.error-msg {margin:2px 0 0;}
		}
		.saveAcc {
			margin:8px 0 0;
			.chks {
				label {font-size:15px;color:#000;padding-top:5px;}
			}
		}
		.loginBtn {
			margin:37px 0 0;
			button {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:100%;border-radius:8px;background:#2f60fb;line-height:1;border:0;color:#fff;height:60px;transition:all .3s ease;
				span {line-height:1;}
				&:focus-visible,
				&:hover {background:var(--color-2);}
			}
		}
		.loginTools {
			padding:22px 0;border-bottom:2px solid #dbdbdb;
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
				li {
					box-sizing:border-box;position:relative;padding:0 20px;
					&:after {content:"";display:block;position:absolute;width:1px;height:14px;background:#dfdfdf;right:0;top:9px;}
					&:last-child:after {display:none}
					> * {
						display:block;line-height:1;border:0;background:transparent;padding:5px 0;
						span {font-size:14px;color:#8b8b8b;line-height:1;transition:all .3s ease;}
						&:focus-visible span,
						&:hover span {color:#000;box-shadow:0 -8px 0 rgba(0, 0, 0, .2) inset}
					}
				}
			}
		}
		.loginWiths {
			margin:30px 0 0;display:none;
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
				li {
					margin:0 50px 0 0;
					&:last-child {margin:0;}
					> * {
						display:block;border:0;background:transparent;
						.ico {
							display:block;box-sizing:border-box;width:74px;height:74px;border-radius:50%;position:relative;overflow:hidden;
							&:before,
							&:after {content:"";display:block;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							&:before {width:40px;height:40px;z-index:1;background:url(/assets/img/_member/login_spr.png) no-repeat}
							&:after {width:100%;height:100%;background:rgba(0, 0, 0, .5);opacity:0;z-index:0;}
						}
						.txt {display:block;line-height:1;font-size:13px;color:#000;font-weight:500;margin:10px 0 0;box-shadow:0 -3px 0 transparent inset, 0 -40px 0 rgba(255, 255, 255, .5) inset;transition:all .3s ease;}
						&.naver .ico {
							background:#03c75b;
							&:before {background-position:0 0}
						}
						&.kakao .ico {
							background:#ffde00;
							&:before {background-position:-50px 0}
						}
						&.apple .ico {
							background:#000;
							&:before {background-position:-100px 0}
						}
						&:focus-visible .txt,
						&:hover .txt {box-shadow:0 -8px 0 rgba(0, 0, 0, .2) inset}
					}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		.loginFrame {
			.loginTools {
				ul {
					li {
						&:after {top:8;}
					}
				}
			}
		}
	}
	@media all and (max-width:899px) {
		padding:40px 0;
	}
	@media all and (max-width:799px) {
		.loginFrame {
			.loginBtn {
				margin:20px 0 0;
				button {height:50px;}
			}
			.loginTools {
				ul {
					li {
						&:after {top:7px;}
					}
				}
			}
		}
	}
	@media all and (max-width:599px) {
		padding:0;
		.loginFrame {
			.loginBtn {
				margin:20px 0 0;
				button {height:50px;}
			}
			.loginTools {padding:15px 0 0;border-bottom:0;}
		}
	}
	@media all and (max-width:499px) {
		.loginFrame {
			.loginBtn {
				margin:20px 0 0;
				button {height:50px;}
			}
			.loginTools {
				padding:15px 0 0;border-bottom:0;
				ul {
					display:block;
					li {
						display:block;box-sizing:border-box;border-bottom:1px solid #ddd;
						&:after {display:none;}
						&:last-child {border-bottom:0;}
						a,
						button {
							display:block;width:100%;text-align:center;padding:10px 0;
							span {display:inline-block;line-height:1;text-align:center;color:#555;font-weight:500;}
							&:focus-visible span,
							&:hover span {box-shadow:none;}
						}
					}
				}
			}
		}
	}
}

/* ==============================
* Request pass initialize
================================= */
.findArea {
	width:100%;max-width:750px;margin:0 auto;padding:40px 0;
	.findTit {display:block;font-size:1.764em;color:#000;font-weight:700;margin:0 0 20px;}
	.findTxt {font-size:15px;color:#8b8b8b;margin:0 0 5px;line-height:1.3;}
	.findTarget {font-size:2em;color:var(--color-1);border-radius:10px;text-align:center;border:1px solid #ddd;margin:40px 0 0;line-height:1.2;padding:20px 10px 25px;}
	.findFrame {
		margin:40px 0 0;
		.c-tab01 {
			margin:0 0 50px;
			ul {justify-content:left;}
		}
		.findInput {
			box-sizing:border-box;padding:0 100px;
			.unit {
				box-sizing:border-box;padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #ddd;
				&:last-child {padding:0;margin:0;border-bottom:0;}
			}
			dl {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;margin:0 0 10px;
				&:last-child {margin:0;}
				dt,
				dd {box-sizing:border-box;line-height:1;}
				dt {width:120px;font-size:15px;font-weight:400;}
				dd {
					flex:1;min-width:0;
					input {display:block;width:100%;box-sizing:border-box;border:1px solid #dbdbdb;height:44px;padding:0 10px;}
				}
			}
		}
		.cBtnBox1 {
			border-top:1px solid #ddd;padding:50px 0 0;margin:50px 0 0;
			> * {min-width:200px;}
		}
	}
	@media all and (max-width:899px) {
		.findTit {font-size:1.5em;}
		.findFrame {
			margin:20px 0 0;
			.findInput {
				padding:0;
			}
		}
	}
	@media all and (max-width:599px) {
		padding:0;
		.findTit {font-size:1.2em;}
		.findTxt {font-size:14px;color:#777;margin:15px 0 0;}
		.findFrame {
			.findInput {
				dl {
					display:block;
					dt {width:100%;color:#333;font-weight:500;margin:0 0 5px;}
					dd {flex:none;}
				}
			}
		}
	}
}

/* ==============================
* Join
================================= */
.joinArea {
	input:not([type='file']) {border:1px solid #ccc;height:40px;line-height:40px;padding:0 10px;box-sizing:border-box;border-radius:4px;}
	.joinHead {
		text-align:center;margin:0 0 40px;
		.sticker {display:inline-block;line-height:1;box-sizing:border-box;border:1px solid #80adee;border-radius:17px;color:var(--color-1);padding:8px 24px 11px;}
		.pTit1 {
			color:#000;margin:23px 0 0;word-wrap:break-word;word-break:keep-all;white-space:normal;
			> * {
				display:block;line-height:1.2;
				em {font-style:normal;color:var(--color-1);}
			}
			.sTxt1 {font-size:2.24em;font-weight:700;}
			.sTxt2 {font-size:1.94em;margin:8px 0 0;}
		}
		.pTit2 {font-size:15px;margin:37px 0 0;word-wrap:break-word;word-break:keep-all;white-space:normal;}
	}
	.joinBody {
		.req {
			font-size:0;box-sizing:border-box;position:relative;line-height:1;width:10px;height:10px;margin:0 2px 0 0;font-style:normal;vertical-align:middle;
			&:after {content:"*";display:block;position:absolute;font-size:15px;color:#2f60fb;top:0;right:0;bottom:0;left:0;margin:auto;}
		}
		.joinTit1 {
			display:block;line-height:1.2;box-sizing:border-box;border-bottom:2px solid #bbb;font-size:1.118em;color:#000;font-weight:700;padding:0 0 17px;
			.base {line-height:1;}
			.assist {
				font-size:15px;color:var(--color-1);font-weight:500;margin:0 0 0 20px;
				.req {display:inline-block;margin:-2px 0 0;}
			}
		}
		.joinTxt1 {
			font-size:15px;color:#010101;margin:17px 0 0;font-weight:500;
			em {font-style:normal;color:var(--color-1);}
		}
		.joinTxt2 {font-size:14px;color:#444;margin:5px 0 0;font-weight:500;}
		.joinDecoBox1 {
			box-shadow:var(--shadow1);margin:20px 0 0;
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;
				li {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;flex:1;box-sizing:border-box;padding:20px;
					.ico {
						width:200px;height:150px;background:url(/assets/img/_member/login_spr.png) no-repeat;
						&.ico1 {background-position:0 -150px;}
						&.ico2 {background-position:-210px -150px;}
						&.ico3 {background-position:-420px -150px;}
					}
					.txt {
						font-size:1.059em;font-weight:500;box-sizing:border-box;padding:0 0 0 20px;
						.pTit1 {color:#2f60fb;}
						.pTxt1 {color:#010101;}
					}
				}
			}
		}
		.joinType1 {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;align-content:flex-start;margin:60px 0 0;
			li {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;justify-content:center;flex:1;
				button {
					border:0;background:transparent;box-sizing:border-box;position:relative;
					> div {}
					.ico {
						width:150px;height:150px;border-radius:50%;box-sizing:border-box;position:relative;
						&:before {content:"";display:block;position:absolute;width:90px;height:90px;top:0;right:0;bottom:0;left:0;margin:auto;background:url(/assets/img/_member/login_spr.png) no-repeat;}
					}
					.txt {
						font-size:15px;color:#010101;font-weight:500;margin:13px 0 0;font-weight:500;
						span {transition:all .3s ease;}
					}
					&.email .ico {
						background:#2f60fb;
						&:before {background-position:0 -50px;}
					}
					&.naver .ico {
						background:#03c75b;
						&:before {background-position:-100px -50px;}
					}
					&.kakao .ico {
						background:#ffde00;
						&:before {background-position:-200px -50px;}
					}
					&.apple .ico {
						background:#000;
						&:before {background-position:-300px -50px;}
					}
					&:focus-visible .txt span,
					&:hover .txt span {box-shadow:0 -8px 0 rgba(0, 0, 0, .2) inset}
					&:before {content:"";display:block;position:absolute;height:20px;width:13px;box-sizing:border-box;border:solid;border-width:0 3px 3px 0;border-color:var(--color-1);transform:rotate(-135deg);right:0;top:10px;opacity:0;transition:all .3s ease;}
					&.on {
						&:before {transform:rotate(45deg);opacity:1;top:0;}
						.txt span {box-shadow:0 -8px 0 rgba(47, 96, 251, .2) inset;color:var(--color-1);}
					}
				}
			}
		}
		.joinBtn1 {
			margin:50px 0 0;
			> * {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;min-height:0;width:100%;max-width:680px;margin:0 auto 20px;height:85px;box-sizing:border-box;border:solid;border-width:2px;border-radius:8px;transition:all .3s ease;
				img {box-sizing:border-box;border:1px solid #ddd;margin:0 10px 0 0;}
				span {font-size:1.294em;}
				&:last-child {margin:0 auto;}
				&.kr {
					border-color:#003478;color:#003478;
					&:hover,
					&:focus-visible {background:#003478;color:#fff;}
				}
				&.cn {
					border-color:#de2910;color:#de2910;
					&:hover,
					&:focus-visible {background:#de2910;color:#fff;}
				}
			}
		}
		.joinAuth1 {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;padding:100px 0 0;
			> * {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:336px;height:auto;box-sizing:border-box;border:1px solid #cecece;border-radius:50%;background:#fff;margin:0 140px 0 0;transition:all .3s ease;
				&:last-child {margin:0;}
				.pkg {
					box-sizing:border-box;padding:0 0 100%;position:relative;width:100%;
					.absWr {
						position:absolute;width:100%;height:100%;left:0;top:0;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;align-items:center;align-content:center;
						.ico {
							box-sizing:border-box;position:relative;width:120px;height:120px;
							&:before,
							&:after {content:"";display:block;position:absolute;width:100%;height:100%;left:0;top:0;background:url(/assets/img/_member/login_spr.png) no-repeat;transition:all .3s ease;}
							&:before {opacity:0;}
							&:after {}
							&.ico1 {
								&:before {background-position:-260px -310px;}
								&:after {background-position:0 -310px;}
							}
							&.ico2 {
								&:before {background-position:-390px -310px;}
								&:after {background-position:-130px -310px;}
							}
						}
						.txt {
							width:100%;font-size:1.059em;font-weight:500;line-height:1;margin:28px 0 0;text-align:center;
							span {transition:all .3s ease;}
						}
					}
				}
				&:focus-visible .pkg .absWr .txt span,
				&:hover .pkg .absWr .txt span {box-shadow:0 -10px 0 rgba(0, 0, 0, .2) inset}
				&.on {
					border-color:var(--color-1);
					.pkg {
						.absWr {
							.ico {
								&:before {opacity:1;}
								&:after {opacity:0;}
							}
							.txt span {box-shadow:0 -10px 0 rgba(47, 96, 251, .2) inset;color:var(--color-1);}
						}
					}
				}
			}
		}
		.joinTerms1 {
			.pTxt1 {display:block;font-size:13px;color:#010101;box-sizing:border-box;padding:0 0 0 25px;word-wrap:break-word;word-break:keep-all;white-space:normal;}
			.chksList1 {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:30px 0 0;
				li {
					width:50%;margin:0 0 10px;
					&.all {margin:0 0 40px;width:100%;}
					.pkg {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;width:500px;
						.chks {
							flex:1;
							input[type=checkbox] + label {
								font-weight:500;color:#010101;word-wrap:break-word;word-break:keep-all;white-space:normal;
								em {
									font-style:normal;
									&.req {
										color:var(--color-b1);
										&:after {display:none;}
									}
									&.opt {color:#8b8b8b}
								}
							}
						}
						.preView {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:100px;border-radius:0;height:26px;
							&:before {display:none}
							span {font-size:13px;}
						}
					}
				}
			}
		}
		.joinForms1 {
			margin:70px 0 0;
			.listForm {
				margin:32px 0 0;
				> li {
					margin:0 0 26px;
					&:last-child {margin:0;}
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;
						dt,
						dd {}
						dt {
							width:180px;box-sizing:border-box;padding:12px 0 0;
							> * {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;font-size:.882em;font-weight:500;line-height:1.2;
							}
						}
						dd {
							flex:1;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;max-width:621px;
							input:not([type='file']) {
								width:100%;max-width:480px;height:44px;margin:7px 0 0;font-size:.941em;transition:all .3s ease;
								&:first-child {margin:0;}
								&:focus-visible,
								&:hover {border-color:var(--color-b1);}
								&::placeholder {font-size:14px;}
								&.w134px {width:134px;}
							}
							.addrZip {text-align:center;}
							.addrZipFinder,
							.customBtn1 {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:134px;height:44px;box-sizing:border-box;border:1px solid #bcbcbc;background:#f0f0f0;color:#000;font-size:12px;padding:0 5px;line-height:1.2;margin:0 0 0 7px;transition:all .3s ease;
								&:focus-visible,
								&:hover {border-color:var(--color-b1);background:var(--color-b1);color:#fff;}
							}
							.customBtn2 {
								width:134px;height:44px;box-sizing:border-box;border:1px solid var(--color-1);background:#f0f0f0;color:var(--color-1);font-size:12px;padding:0 5px;line-height:1.2;margin:0 0 0 7px;transition:all .3s ease;
								&:focus-visible,
								&:hover {border-color:var(--color-b1);background:var(--color-b1);color:#fff;}
							}
							.fileWrap {height:44px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
							.error-msg {width:100%;}
							&.hasEmail {
								.emailStr {flex:1;min-width:0;}
								.emailValidation {
									width:134px;margin:0 0 0 7px;text-align:center;
									&::-webkit-outer-spin-button,
									&::-webkit-inner-spin-button {-webkit-appearance:none;margin:0}
								}
							}
							&.hasAddr {
							}
							&.hasPeriod {
								.react-datepicker-wrapper {
									width:calc( 50% - 15px );
								}
								.tilde {
									display:flex;flex-flow:row;flex-wrap:wrap;width:30px;align-items:center;align-content:center;justify-content:center;
								}
							}
							&.hasTimepic {
								.react-datepicker-wrapper {
									display:block !important;width:100%;
									input {min-height:0;max-width:none;}
								}
							}
							&.hasBusiNum {
								max-width:621px;
								input {flex:1;min-width:0;}
								button {}
							}
							&.hasCnPhone {
								max-width:480px;
								.phonePrefix {padding:0 10px 0 0;}
								input {margin:0;width:auto;flex:1;min-width:0;}
							}
						}
					}
				}
			}
			.listChk {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;padding:5px 0 0;
				> li {
					margin:0 30px 0 0;
					&:last-child {margin:0;}
				}
			}
			.listGuide {
				width:100%;margin:10px 0 0;
				> li {
					font-size:.882em;margin:0 0 5px;padding:0 0 0 12px;
					&:last-child {margin:0;}
					&:before {width:5px;top:9px;}
				}
			}
			.castRegister {
				margin:50px auto 0;box-sizing:border-box;max-width:1124px;
				.otherLink {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;margin:0 0 20px;
					a {
						margin:0 20px 0 0;
						&:last-child {margin:0;}
						span {transition:all .3s ease;}
						&:focus-visible span,
						&:hover span {box-shadow:0 -8px 0 rgba(47, 96, 251, .2) inset;color:var(--color-1);}
					}
				}
				button {min-width:0;max-width:none;width:100%;height:80px;}
			}
		}
		.joinList1 {
			margin:100px 0 0;
		}
		.joinSuccess {
			text-align:center;padding:50px 0 0;
			.sectMsg {
				.pTit1 {
					> * {display:block;line-height:1.2;color:#000;word-wrap:break-word;word-break:keep-all;white-space:normal;}
					.sTxt1 {font-size:1.764em;font-weight:700;}
					.sTxt2 {font-size:1.118em;font-weight:500;margin:10px 0 0;}
				}
				.pIco1 {width:189px;height:172px;margin:46px auto 0;background:url(/assets/img/_member/login_spr.png) no-repeat 0 -440px;}
				.pTxt2 {font-size:15px;color:#8b8b8b;line-height:1.2;font-weight:500;margin:57px 0 0;word-wrap:break-word;word-break:keep-all;white-space:normal;}
				.pFiled {width:189px;height:172px;margin:46px auto 0;background:url(/assets/img/_member/failed.png) no-repeat center center;background-size:contain;}
				.pError {width:189px;height:172px;margin:46px auto 0;background:url(/assets/img/_member/error.png) no-repeat center center;background-size:contain;}
			}
			.sectBtn {
				width:100%;border-top:2px solid #777;padding:61px 0 0;margin:134px auto 0;
			}
		}
	}
	@media all and (max-width:1399px) {
		.joinBody {
			.joinTerms1 {
				.pTxt1 {padding:0 0 0 25px;}
				.chksList1 {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:30px 0 0;
					li {
						box-sizing:border-box;padding:0 20px 0 0;
						&.all {margin:0 0 30px;}
						.pkg {
							width:100%;
						}
					}
				}
			}
			.joinSuccess {
				padding:50px 0 0;
				.sectMsg {
					.pIco1 {margin:30px auto 0;}
					.pTxt2 {margin:30px 0 0;}
				}
				.sectBtn {
					padding:50px 0 0;margin:50px auto 0;
				}
			}
		}
	}
	@media all and (max-width:999px) {
		.joinBody {
			.joinAuth1 {
				justify-content:space-around;padding:50px 0 0;
				> * {
					width:calc( 50% - 100px );margin:0;
				}
			}
			.joinTerms1 {
				.pTxt1 {padding:0 0 0 28px;}
				.chksList1 {
					margin:20px 0 0;
					li {
						width:100%;padding:0 0 10px;margin:0 0 10px;border-bottom:1px solid #ddd;
						&:last-child {padding:0;margin:0;border-bottom:0;}
						&.all {margin:0 0 10px;}
					}
				}
			}
		}
	}
	@media all and (max-width:899px) {
		input:not([type='file']) {border-radius:0;}
		.joinBody {
			.joinForms1 {
				.listForm {
					> li {
						dl {
							dt,
							dd {}
							dt {width:140px;}
							dd {
								min-width:0;max-width:100% !important;
								input {max-width:100% !important;}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:799px) {
		.joinBody {
			.joinAuth1 {
				padding:30px 0 0;/* justify-content:space-between; */
				> * {
					width:calc( 50% - 10px );border-radius:10px;
					.pkg {
						padding:20px;
						.absWr {
							position:relative;height:auto;left:auto;top:auto;
							.txt {margin:15px 0 0;}
						}
					}
				}
			}
			.joinTit1 {
				.assist {display:block;margin:5px 0 0;}
			}
			.joinBtn1 {
				margin:50px 0 0;
				> * {
					height:50px;
				}
			}
			.joinList1 {
				margin:50px 0 0;
			}
			.joinForms1 {
				.castRegister {
					margin:50px 0 0;
					button {
						height:50px;
						&:before {height:50px;}
					}
				}
			}
			.joinSuccess {
				padding:30px 0 0;
				.sectMsg {
					.pTit1 {
						.sTxt1 {font-size:1.5em;}
						.sTxt2 {font-size:1.0em;}
					}
					.pIco1 {width:132px;height:120px;margin:20px auto 0;background-position:0 -308px;background-size:434px 428px;}
					.pFiled {width:132px;height:120px;margin:20px auto 0;}
					.pError {width:132px;height:120px;margin:20px auto 0;}
				}
				.sectBtn {
					padding:30px 0 0;margin:30px auto 0;
				}
			}
		}
	}
	@media all and (max-width:768px) {
		.joinBody {
			.joinTerms1 {
				.pTxt1 {padding:0 0 0 25px;}
				.chksList1 {
					margin:20px 0 0;
				}
			}
		}
	}
	@media all and (max-width:699px) {
		.joinBody {
			.joinForms1 {
				.listForm {
					> li {
						dl {
							dd {
								&.hasEmail {
									justify-content:space-between;
									.emailStr {width:100%;flex:none;}
									.emailValidation {width:calc( 50% - 5px );margin:10px 0 0;}
									button {width:calc( 50% - 5px );margin:10px 0 0;}
								}
								&.hasBusiNum {
									input {flex:none;width:100%;}
									button {width:100%;max-width:100%;margin:10px 0 0;}
								}
								&.hasAddr {
									.addrZipFinder {order:1;max-width:100%;margin:0 0 10px;width:100%;}
									.addrZip {order:2;margin:0;}
									.addrSumm {order:3;margin:0 0 0 10px !important;}
									.addrDetail {order:4;margin:10px 0 0;}
								}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:599px) {
		.joinHead {
			.pTit1 {
				margin:15px 0 0;
				.sTxt1 {font-size:2.0em;}
				.sTxt2 {font-size:1.5em;}
			}
			.pTit2 {
				font-size:1em;margin:20px 0 0;
				br {display:none;}
			}
		}
		.joinBody {
			.joinAuth1 {
				padding:20px 0 0;
				> * {
					.pkg {
						padding:10px 20px 20px;
						.absWr {
							.ico {
								width:84px;height:84px;
								&:before,
								&:after {background-size:434px 428px;}
								&.ico1 {
									&:before {background-position:-182px -217px;}
									&:after {background-position:0 -217px;}
								}
								&.ico2 {
									&:before {background-position:-273px -217px;}
									&:after {background-position:-91px -217px;}
								}
							}
							.txt {margin:5px 0 0;}
						}
					}
				}
			}
			.joinTerms1 {
				.pTxt1 {padding:0 0 0 25px;}
				.chksList1 {
					margin:20px 0 0;
					li {
						.pkg {
							.chks {flex:1;min-width:0;box-sizing:border-box;padding:0 15px 0 0;}
							.preView {width:auto;padding:0 10px;min-width:0;}
						}
					}
				}
			}
			.joinForms1 {
				.listForm {
					> li {
						margin:0 0 15px;
						dl {
							display:block;
							dt {width:100%;padding:0;margin:0 0 5px;}
							dd {width:100%;flex:none;}
						}
					}
				}
				.castRegister {
					margin:30px 0 0;
				}
			}
			.joinSuccess {
				padding:20px 0 0;
				.sectMsg {
					.pTit1 {
						.sTxt1 {font-size:1.3em;}
						.sTxt2 {font-size:1.0em;}
					}
					.pIco1 {width:92px;height:84px;background-position:0 -216px;background-size:304px 300px;}
					.pFiled {width:92px;height:84px;}
					.pError {width:92px;height:84px;}
				}
				.sectBtn {
					padding:20px 0 0;margin:20px auto 0;
				}
			}
		}
	}
	@media all and (max-width:499px) {
		.joinHead {
			margin:0 0 20px;
			.pTit1 {
				.sTxt1 {font-size:1.5em;}
				.sTxt2 {font-size:1.2em;}
			}
		}
		.joinBody {
			.joinAuth1 {
				> * {
					width:100%;
				}
			}
			.joinBtn1 {
				margin:20px 0 0;
				> * {
					height:40px;
					span {font-size:1em;}
				}
			}
			.joinList1 {
				margin:20px 0 0;
			}
			.joinForms1 {
				.castRegister {
					margin:20px 0 0;
					button {
						height:40px;
						&:before {height:40px;}
						span {font-size:1em;}
					}
				}
			}
		}
	}
}