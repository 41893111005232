.custom-select-wrapper {
	display:block;position:relative;width:100%;box-sizing:border-box;
	.custom-select {
		position:relative;display:flex;flex-direction:column;width:100%;
		.custom-select-trigger {
			display:flex;justify-content:space-between;align-items:center;padding:10px 40px 10px 10px;background:#fff;border:1px solid #ccc;cursor:pointer;line-height:1;min-height:40px;
			.roleLbl {margin:-2px 0 0;}
			.selected-product {
			}
			.arrow {display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;right:15px;top:50%;transform:rotate(45deg) translateY(-50%);margin:-4px 0 0;transition:all .3s ease;}
		}
		.custom-options {
			display:none;position:absolute;top:100%;left:0;right:0;background:#fff;border:1px solid #ccc;border-top:0;max-height:200px;overflow-y:auto;z-index:1;
			.custom-option {
				display:flex;align-items:center;padding:10px;cursor:pointer;transition:all .3s ease;
				&:hover,
				&:focus-visible {background:#f9f9f9;}
				&.selected {background:#f0f0f0;}
			}
		}
		.products {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;
			.product-image {
				width:50px;height:50px;box-sizing:border-box;border:1px solid #ddd;background:#eee;position:relative;
				img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
			}
			.product-name {flex:1;min-width:0;word-wrap:break-word;word-break:keep-all;white-space:normal;box-sizing:border-box;padding:0 0 0 10px;line-height:1.2;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:36px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;}
			.product-quantity {width:80px;text-align:right;}
		}
		&.open .custom-options {display:block;}
	}
}