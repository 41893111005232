@charset "utf-8";


/* RemixIcon */
/* 긴급 대체용 @import url("//fastly.jsdelivr.net/npm/remixicon@3.5.0/fonts/remixicon.css"); */
@import url("//cdn.jsdelivr.net/npm/remixicon@3.5.0/fonts/remixicon.css");

/* WebFonts */
/*
https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&amp;display=swap
https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;200;300;400;500;600;700;800;900&display=swap
https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;200;300;400;500;600;700;800;900&display=swap
https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap
*/
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
@import url("//fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");

/* 로딩개선을 위해 제거. */
/* @import url("//fonts.cdnfonts.com/css/fogtwono5"); */

html, body {touch-action:pan-x pan-y;}
body > iframe {box-sizing:border-box !important;width:0 !important;height:0 !important;right:0 !important;top:0 !important;border:0px solid #f00 !important;inset:auto !important;opacity:0 !important;}

:root {
	--color-b1:#2f60fb;
	--color-1:#1165e0;
	--color-2:#054ab3;
	--color-3:#333;
	--color-o1:#f60;
	--color-o2:#f30;
	--color-r1:#fe5252;
	--color-g1:#aaa;
	--color-y1:#ffcc00;
	--color-db:#dbdbdb;
	--shadow1:0 1px 7px -2px rgba(0, 0, 0, 0.3);
}
* {margin:0;padding:0;}
*:focus-visible {outline:3px solid rgba(59, 153, 252, .5)}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {border:0;font-size:100%;margin:0}
html {scroll-behavior:smooth}

/* 프린트시 배경이미지 출력 */
* {-webkit-print-color-adjust:exact}

/* html5 호환 설정*/
article, aside, canvas, details, figcaption, figure, main, footer, header, hgroup, menu, nav, section, summary {display:block}
address {font-style:normal;display:inline}
table {width:100%;border-collapse:collapse;border-spacing:0}
table, caption, tbody, tfoot, thead, tr, th, td {outline:0;background:transparent;font-size:100%}
nav, ul, ol {list-style:none;margin:0;padding:0;}
blockquote, q {quotes:none}
blockquote:before, blockquote:after, q:before, q:after {content:"";content:none}
hr {display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}

/* 기본 폰트 및 사이즈 설정 */
/* 사이트 기본 폰트 설정 */
body {font-family:"Noto Sans KR", "Noto Sans SC", "맑은고딕","돋움",arial,Dotum,Verdana,helvetica,clean,sans-serif !important;font-size:17px;font-weight:400;color:#555;line-height:1.5;background:#fff;overscroll-behavior:none;letter-spacing:-.025em !important;}

/* 글자 속성 */
/* a:link, a:visited, a:hover, a:active순서로 작성 */
a, a:link {color:#555;text-decoration:none}
.btn-primary {color:#FFFF !important;text-decoration:none}
a:visited, a.visited, a.visited:visited {color:inherit;text-decoration:none}
a:hover, a.visited:hover, a:active {color:inherit;text-decoration:none}
a:focus {text-decoration:none}

/* LIST 요소 기본 */
ol.onum li {list-style-type:decimal}
.onum {padding-left:22px}

/* Form 요소들 기본 */
/* input, button, select, textarea {font-family:inherit} */
fieldset {padding:0px;margin:0px;border:0px}
legend {display:none}
input[type=text], input[type=password], input[type=button], input[type=submit], input[type=file], button, textarea, select {-webkit-appearance:none;-moz-appearance:none;appearance:none}
input[readonly] {background:#eee}
select {border:0;height:40px;box-sizing:border-box;border:1px solid #ccc;min-width:120px;padding:0 35px 0 10px;line-height:1.2;border-radius:0;background:#fff url(../img/ic_sel1.png) no-repeat right 50%}
select::-ms-expand {display:none}
input[type="radio"], input[type="checkbox"] {border:none;padding:0;margin:0;vertical-align:middle}
input[type="file"] {border:0;padding:0;margin:0;height:auto;font-size:15px !important;letter-spacing:-.05em;}
input.readonly {background-color:#f7f7f7}
textarea {border:1px solid #ccc;padding:5px;box-sizing:border-box}
textarea.noResize {resize:none}

/* 이미지 요소 기본 */
img {vertical-align:top}

/* ime-mode */
.imeOff {ime-mode:disabled}
.imehangul {ime-mode:active}

/* 오버-클릭 커서로 지정 */
button, a, .pointer, .hand {cursor:pointer}
.mvPointer {cursor:move}

.disb {display:block;}
.disbF {display:block !important;}
.disf {display:flex;}
.disfF {display:flex !important;}

/*기본 스타일 정의*/
/*플로팅*/
.fr {float:right}
.fl {float:left}
.cr {clear:both}
.aftercr:after {clear:both;content:"";display:block}
.hidden {display:none !important}
.blind {width:1px;height:1px;overflow:hidden;position:absolute;top:-9999em;left:-9999em;display:block;text-indent:-9999em;font-size:0px;line-height:0;opacity:0;}
.wblind {width:1px;height:1px;overflow:hidden;font-size:0;line-height:0;text-indent:-9999em}/* 크롬에서 col width 값 안먹는 문제 대응 */
.gap10 {height:10px;clear:both;overflow:hidden;display:block}
.gap20 {height:20px;clear:both;overflow:hidden;display:block}
.gap30 {height:30px;clear:both;overflow:hidden;display:block}
.gap {height:60px;clear:both;display:block}
.sgap {height:40px;clear:both;display:block}
.ssgap {height:30px;clear:both;display:block}
.sssgap {height:20px;clear:both;display:block}
@media all and (max-width:600px){
	.gap {height:40px;clear:both;display:block}
	.sgap {height:25px;clear:both;display:block}
	.ssgap {height:15px;clear:both;display:block}
	.sssgap {height:10px;clear:both;display:block}
}
.posa {position:absolute;}
.posr {position:relative;}

/*정렬*/
.tc {text-align:center}
.tl {text-align:left}
.tr {text-align:right}
.left, .l {text-align:left !important;justify-content:left !important;}
.right, .r {text-align:right !important;justify-content:right !important;}
.center, .c {text-align:center !important}
.vtop, .vtop * {vertical-align:text-top !important}
.vcen, .vcen * {vertical-align:middle !important}
.vfoot, .vfoot * {vertical-align:text-bottom !important}
.vtop2 {vertical-align:top !important}
.vcen2 {vertical-align:middle !important}
.vfoot2 {vertical-align:bottom !important}
.c0 {color:#000 !important}
.c2 {color:#222 !important}
.c3 {color:#333 !important}
.c5 {color:#555 !important}
.c6 {color:#666 !important}
.c8 {color:#888 !important}
.c9 {color:#999 !important}
.cf {color:#fff !important}
.cg1 {color:#69696a !important}
.cg2 {color:#ababab !important}
.cg3 {color:#848484 !important}
.cg4 {color:#727272 !important}
.cg5 {color:#b3b7bc !important}
.cblack {color:#000 !important}
.cred {color:#df2519 !important}
.cblue {color:#1165e0 !important}
.cpink {color:#f9587d !important}
.cgold {color:#b8883c !important}
.cgreen {color:#758d0a !important}
.cbrown {color:#9f2C00 !important}
.corg {color:#ff5612 !important}
.bg1 {background-color:#69696a !important}
.bg2 {background-color:#ababab !important}
.bg3 {background-color:#848484 !important}
.bg4 {background-color:#727272 !important}
.bg5 {background-color:#b3b7bc !important}

/* 폰트속성 */
.nb {font-weight:normal}
.b {font-weight:bold}
.fs {font-size:90%}
.fb {font-size:120%}
.f9 {font-size:9px}
.f10 {font-size:10px}
.f11 {font-size:11px}
.f12 {font-size:12px}
.f13 {font-size:13px}
.f14 {font-size:14px}
.f15 {font-size:15px}
.f16 {font-size:16px}
.f17 {font-size:17px}
.f18 {font-size:18px}
.f19 {font-size:19px}
.f20 {font-size:20px}

.pad5a {padding:5px}
.pad5tf {padding-top:5px;padding-bottom:5px}
.pad5lr {padding-left:5px;padding-right:5px}
.pad5r {padding-right:5px}
.pad5l {padding-left:5px}
.pad5t {padding-top:5px}
.pad5f {padding-bottom:5px}
.pad10a {padding:10px}
.pad10tf {padding-top:10px;padding-bottom:10px}
.pad10lr {padding-left:10px;padding-right:10px}
.pad10r {padding-right:10px}
.pad10l {padding-left:10px}
.pad10t {padding-top:10px}
.pad10f {padding-bottom:10px}
.pad15a {padding:15px}
.pad15tf {padding-top:15px;padding-bottom:15px}
.pad15lr {padding-left:15px;padding-right:15px}
.pad15r {padding-right:15px}
.pad15l {padding-left:15px}
.pad15t {padding-top:15px}
.pad15f {padding-bottom:15px}
.pad20a {padding:20px}
.pad20tf {padding-top:20px;padding-bottom:20px}
.pad20lr {padding-left:20px;padding-right:20px}
.pad20r {padding-right:20px}
.pad20l {padding-left:20px}
.pad20t {padding-top:20px}
.pad20f {padding-bottom:20px}
.pad25a {padding:25px}
.pad25tf {padding-top:25px;padding-bottom:25px}
.pad25lr {padding-left:25px;padding-right:25px}
.pad25r {padding-right:25px}
.pad25l {padding-left:25px}
.pad25t {padding-top:25px}
.pad25f {padding-bottom:25px}
.pad30a {padding:30px}
.pad30tf {padding-top:30px;padding-bottom:30px}
.pad30lr {padding-left:30px;padding-right:30px}
.pad30r {padding-right:30px}
.pad30l {padding-left:30px}
.pad30t {padding-top:30px}
.pad30f {padding-bottom:30px}
.pad40a {padding:40px}
.pad40tf {padding-top:40px;padding-bottom:40px}
.pad40lr {padding-left:40px;padding-right:40px}
.pad40r {padding-right:40px}
.pad40l {padding-left:40px}
.pad40t {padding-top:40px}
.pad40f {padding-bottom:40px}
.pad50a {padding:50px}
.pad50tf {padding-top:50px;padding-bottom:50px}
.pad50lr {padding-left:50px;padding-right:50px}
.pad50r {padding-right:50px}
.pad50l {padding-left:50px}
.pad50t {padding-top:50px}
.pad50f {padding-bottom:50px}

.mgAll0F {margin:0 !important;}
.mg0f {margin-bottom:0 !important}
.mg5f {margin-bottom:5px}
.mg10f {margin-bottom:10px}
.mg15f {margin-bottom:15px}
.mg20f {margin-bottom:20px}
.mg25f {margin-bottom:25px}
.mg30f {margin-bottom:30px}
.mg40f {margin-bottom:40px}
.mg50f {margin-bottom:50px}
.mg0t {margin-top:0 !important}
.mg5t {margin-top:5px}
.mg10t {margin-top:10px}
.mg10tF {margin-top:10px !important}
.mg15t {margin-top:15px}
.mg20t {margin-top:20px}
.mg25t {margin-top:25px}
.mg30t {margin-top:30px}
.mg40t {margin-top:40px}
.mg50t {margin-top:50px}
.mg5l {margin-left:5px}
.mg10l {margin-left:10px}
.mg15l {margin-left:15px}
.mg20l {margin-left:20px}
.mg25l {margin-left:25px}
.mg30l {margin-left:30px}
.mg40l {margin-left:40px}
.mg50l {margin-left:50px}
.mg5r {margin-right:5px}
.mg10r {margin-right:10px}
.mg15r {margin-right:15px}
.mg20r {margin-right:20px}
.mg25r {margin-right:25px}
.mg30r {margin-right:30px}
.mg40r {margin-right:40px}
.mg50r {margin-right:50px}
.mg5a {margin:5px}
.mg10a {margin:10px}
.mg15a {margin:15px}
.mg20a {margin:20px}
.mg25a {margin:25px}
.mg30a {margin:30px}
.mg20tf {margin-top:20px;margin-bottom:20px}
.mg50tf {margin-top:50px;margin-bottom:50px}
.mg50lr {margin-left:50px;margin-right:50px}

.w400 {width:400px}
.w350 {width:350px}
.w300 {width:300px}
.w200 {width:200px}
.w150 {width:150px}
.w140 {width:140px}
.w130 {width:130px}
.w120 {width:120px}
.w120F {width:120px !important;}
.w110 {width:110px}
.w100 {width:100px}
.w90 {width:90px}
.w80 {width:80px}
.w70 {width:70px}
.w60 {width:60px}
.w50 {width:50px}
.w40 {width:40px}
.w30 {width:30px}
.w20 {width:20px}
.w100p {width:100%}
.w100pF {width:100% !important}
.wMax100p {max-width:100%}
.wMax100pF {max-width:100% !important}
.w98p {width:98%}
.w95p {width:95%}
.w90p {width:90%}
.w80p {width:80%}
.w70p {width:70%}
.w60p {width:60%}
.w50p {width:50%}
.w50pF {width:50% !important;}
.w45p {width:44%}
.w40p {width:40%}
.w33p {width:33.3333%}
.w30p {width:30%}
.w25p {width:25%}
.w20p {width:20%}
.w15p {width:15%}
.w10p {width:10%}
.h100p {height:100%}
.h50p {height:50%}
.h300 {height:300px}
.h200 {height:200px}
.h150 {height:150px}
.h100 {height:100px}
.h50 {height:50px}
.h50vh {height:50vh}

.border-b-1 { box-sizing: border-box; border-bottom: 1px solid #ccc;}

.fonstn {font-style:normal;}

/* ==============================
* Animation
================================= */
@keyframes colorChange1 {
	0% {background-color:var(--color-1)}
	50% {background-color:var(--color-o1)}
	100% {background-color:var(--color-1)}
}
@keyframes buzz {
	10%, 30% {transform:translateX(3px) rotate(2deg);}
	20%, 40% {transform:translateX(-3px) rotate(-2deg);}
	50%, 70% {transform:translateX(2px) rotate(1deg);}
	60%, 80% {transform:translateX(-2px) rotate(-1deg);}
	90% {transform:translateX(1px) rotate(0);}
	100% {transform:translateX(-1px) rotate(0);}
}
@keyframes spin {
	to {-webkit-transform:rotate(360deg)}
}

*:disabled {background-color:#eee;}
.cancelLine {text-decoration:line-through;}

.phraseUnit {word-wrap:break-word;word-break:keep-all;white-space:normal;}
.syllableUnit {word-break:break-all;}