.csCenter {
  .schTit1 {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding: 0 0 20px;
    border-bottom: 1px solid #777;
    font-size: 1.412em;
    color: #000;
    font-weight: 500;
    margin: 0 0 30px;
    i {
      line-height: 1;
      margin: 5px 0 0 5px;
    }
  }
  .sectSearch {
    padding: 50px 0;
    display: none;
    .msg {
      font-size: 2.235em;
      text-align: center;
      font-weight: 300;
      color: #000;
      em {
        font-weight: 700;
        color: var(--color-1);
        font-style: normal;
      }
    }
    .frm {
      max-width: 968px;
      margin: 30px auto 0;
      box-sizing: border-box;
      border: 1px solid var(--color-1);
      border-radius: 999px;
      height: 65px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 0 15px 0 25px;
      input {
        flex: 1;
        min-width: 0;
        height: 40px;
        border: 0;
        font-size: 1.176em;
      }
      button {
        width: 50px;
        height: 50px;
        border: 0;
        background: transparent;
        font-size: 1.5em;
        color: #666;
        line-height: 1;
        transition: all 0.3s ease;
        i {
          line-height: 1;
        }
        &:hover,
        &:focus-visible {
          color: var(--color-1);
        }
      }
    }
    .util {
      max-width: 968px;
      margin: 0 auto;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 10px 10px 0 20px;
      .roleBtn {
        display: block;
        line-height: 1;
        box-sizing: border-box;
        border: 0;
        background: transparent;
        border-radius: 4px;
        padding: 5px 10px 7px;
        transition: all 0.3s ease;
        span {
          font-size: 15px;
          letter-spacing: -0.05em;
          font-weight: 400;
        }
        &:hover,
        &:focus-visible {
          background: var(--color-1);
          color: #fff;
        }
      }
      .quickKey {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        li {
          box-sizing: border-box;
          padding: 0 5px 0 0;
          margin: 0 3px 0 0;
          position: relative;
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 14px;
            right: 0;
            top: 8px;
            background: var(--color-db);
          }
          &:last-child {
            padding: 0;
            margin: 0;
            &:after {
              display: none;
            }
          }
        }
      }
      .chat {
        line-height: 1;
        box-sizing: border-box;
        border: 0;
        background: transparent;
        color: var(--color-1);
        padding: 5px 10px 7px;
        transition: all 0.3s ease;
        i {
          font-size: 1.118em;
          margin: 0 5px 0 0;
        }
      }
    }
  }
  &.Main {
    .sectFaq1 {
      .boxCate {
        .tab {
          ul {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            li {
              box-sizing: border-box;
              padding: 0 20px;
              button {
                display: block;
                text-align: center;
                padding: 0;
                border: 0;
                background: transparent;
                .ico {
                  width: 124px;
                  height: 124px;
                  border-radius: 50%;
                  border: 1px solid #c5c5c5;
                  box-sizing: border-box;
                  position: relative;
                  overflow: hidden;
                  transition: all 0.3s ease;
                  &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    background: url(/assets/img/_csCenter/csMain_faq1.png) no-repeat;
                  }
                }
                .txt {
                  margin: 10px 0 0;
                  transition: all 0.3s ease;
                  span {
                    font-size: 15px;
                    color: #666;
                    line-height: 1.2;
                  }
                }
                &:hover,
                &:focus-visible {
                  background: transparent;
                  .ico {
                    border-color: var(--color-1);
                    background: var(--color-1);
                  }
                  .txt {
                    span {
                      color: var(--color-1);
                      font-weight: 500;
                    }
                  }
                }
              }
              &.on {
                .ico {
                  border-color: var(--color-1);
                  background: var(--color-1);
                }
                .txt {
                  span {
                    color: var(--color-1);
                    font-weight: 500;
                  }
                }
              }
              &.n01 {
                button {
                  .ico:before {
                    background-position: 0 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: 0 -110px;
                  }
                }
                &.on .ico:before {
                  background-position: 0 -110px;
                }
              }
              &.n02 {
                button {
                  .ico:before {
                    background-position: -110px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -110px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -110px -110px;
                }
              }
              &.n03 {
                button {
                  .ico:before {
                    background-position: -220px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -220px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -220px -110px;
                }
              }
              &.n04 {
                button {
                  .ico:before {
                    background-position: -330px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -330px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -330px -110px;
                }
              }
              &.n05 {
                button {
                  .ico:before {
                    background-position: -440px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -440px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -440px -110px;
                }
              }
              &.n06 {
                button {
                  .ico:before {
                    background-position: -550px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -550px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -550px -110px;
                }
              }
              &.n07 {
                button {
                  .ico:before {
                    background-position: -660px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -660px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -660px -110px;
                }
              }
              &.n08 {
                button {
                  .ico:before {
                    background-position: -770px 0;
                  }
                  &:hover .ico:before,
                  &:focus-visible .ico:before {
                    background-position: -770px -110px;
                  }
                }
                &.on .ico:before {
                  background-position: -770px -110px;
                }
              }
            }
          }
        }
        .tabBody {
          padding: 30px 0 20px;
          box-sizing: border-box;
          position: relative;
          margin: 50px 0 0;
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 2000px;
            height: 100%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            z-index: -1;
            background: linear-gradient(to right, #2871fa 0, #6717cd 100%);
          }
          .tabUnit {
            display: none;
            &.on {
              display: block;
            }
            .roleHead {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              justify-content: space-between;
              margin: 0 0 20px;
              .cateName {
                display: block;
                font-size: 1.412em;
                color: #fff;
                font-weight: 700;
                line-height: 1.2;
              }
              .cateMove {
                display: block;
                color: #fff;
                font-size: 0.882em;
                box-sizing: border-box;
                position: relative;
                padding: 5px 20px 5px 5px;
                line-height: 1.2;
                span {
                  display: block;
                  line-height: 1;
                }
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  box-sizing: border-box;
                  border: solid;
                  border-width: 0 2px 2px 0;
                  border-color: #fff;
                  right: 5px;
                  top: 9px;
                  transform: rotate(-45deg);
                  transition: all 0.3s ease;
                }
                &:hover,
                &:focus-visible {
                  &:after {
                    right: 0;
                  }
                }
              }
            }
            .roleBody {
              .swiper-wrapper {
                .swiper-slide {
                  > a {
                    display: block;
                    background: #fff;
                    border-radius: 8px;
                    height: 345px;
                    box-sizing: border-box;
                    padding: 30px 30px 30px;
                    position: relative;
                    &:before {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                      box-sizing: border-box;
                      border: 0 solid var(--color-1);
                      border-radius: 7px;
                      transition: all 0.1s linear;
                    }
                    .articleTitle {
                      font-size: 1.412em;
                      color: var(--color-1);
                      line-height: 1.45;
                      font-weight: 500;
                      word-wrap: break-word;
                      word-break: keep-all;
                      white-space: normal;
                      display: -webkit-box;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: normal;
                      height: 70px;
                      word-wrap: break-word;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      span {
                        transition: all 0.3s ease;
                      }
                    }
                    .articleContent {
                      font-size: 0.882em;
                      color: #666;
                      line-height: 1.8;
                      word-wrap: break-word;
                      word-break: keep-all;
                      white-space: normal;
                      display: -webkit-box;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: normal;
                      height: 189px;
                      word-wrap: break-word;
                      -webkit-line-clamp: 7;
                      -webkit-box-orient: vertical;
                      margin: 20px 0 0;
                    }
                    &:hover,
                    &:focus-visible {
                      .articleTitle {
                        span {
                          box-shadow: 0 -20px 0 rgba(17, 101, 224, 0.15) inset;
                        }
                      }
                      &:before {
                        border-width: 3px;
                      }
                    }
                  }
                }
              }
              .swiper-navi-prev,
              .swiper-navi-next {
                position: absolute;
                z-index: 2;
                width: 40px;
                height: 40px;
                top: 50%;
                transform: translateY(-50%);
                border: 0;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 5px;
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 15px;
                  height: 15px;
                  border: solid;
                  box-sizing: border-box;
                  border-width: 2px;
                  border-width: 0 2px 2px 0;
                  border-color: var(--color-1);
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  transition: all 0.3s ease;
                }
                &.swiper-navi-prev {
                  left: 10px;
                  &:after {
                    transform: rotate(135deg);
                    margin: auto auto auto 16px;
                  }
                  &:hover:after,
                  &:focus-visible:after {
                    margin: auto auto auto 10px;
                  }
                }
                &.swiper-navi-next {
                  right: 10px;
                  &:after {
                    transform: rotate(-45deg);
                    margin: auto 16px auto auto;
                  }
                  &:hover:after,
                  &:focus-visible:after {
                    margin: auto 10px auto auto;
                  }
                }
                &:hover,
                &:focus-visible {
                  background: #fff;
                }
              }
              .swiper-pagination-wrap {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                margin: 10px 0 0;
                .swiper-auto-controller {
                  button {
                    display: none;
                    width: 30px;
                    height: 30px;
                    border: 0;
                    box-sizing: border-box;
                    position: relative;
                    background: transparent;
                    opacity: 0.5;
                    transition: all 0.3s ease;
                    &:focus-visible,
                    &:hover {
                      opacity: 1;
                    }
                    &.on {
                      display: block;
                    }
                    &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      border: solid;
                      box-sizing: border-box;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      left: 0;
                      margin: auto;
                      transition: all 0.3s ease;
                    }
                    &.stop:after {
                      width: 8px;
                      height: 12px;
                      border-width: 0 2px;
                      border-color: #fff;
                    }
                    &.play:after {
                      width: 0;
                      height: 0;
                      border-width: 6px 0 6px 7px;
                      border-color: transparent transparent transparent #fff;
                    }
                  }
                }
                .swiper-pagination {
                  position: relative;
                  transition: none;
                  transform: none;
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  li {
                    position: relative;
                    left: auto;
                    top: auto;
                    opacity: 1;
                    width: auto;
                    height: auto;
                    background: transparent;
                    margin: 0 3px 0 0;
                    font-size: 0;
                    line-height: 1;
                    &:last-child {
                      margin: 0;
                    }
                    button {
                      border: 0;
                      background: transparent;
                      width: 15px;
                      height: 15px;
                      position: relative;
                      font-size: 0;
                      opacity: 0.5;
                      transition: all 0.3s ease;
                      &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #fff;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                      }
                    }
                    &.swiper-pagination-bullet-active {
                      background: transparent;
                      button {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
              .noDataWrap {
                color: #fff;
                padding: 50px 0 80px;
              }
            }
          }
        }
      }
    }
    .sectNotice1 {
      margin: 50px 0 0;
      .tab-btns {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: left;
        position: relative;
        z-index: 0;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          justify-content: left;
          li {
            margin: 0 15px 0 0;
            &:last-child {
              margin: 0;
            }
            .nav-item {
              a {
                display: block;
                border-radius: 999px;
                line-height: 1;
                padding: 8px 20px 10px;
                min-width: 100px;
                text-align: center;
                box-sizing: border-box;
                border: 1px solid #ddd;
                .txt {
                  display: block;
                  text-align: center;
                  font-size: 0.941em;
                }
                &[aria-selected="true"],
                &.active {
                  border-color: var(--color-1);
                  background: var(--color-1);
                  color: #fff;
                }
                &:hover,
                &:focus-visible {
                  border-color: var(--color-1);
                }
              }
            }
          }
        }
      }
      .tab-content {
        margin: 20px 0 0;
        position: relative;
        .noticeMove {
          display: block;
          position: relative;
          color: #000;
          font-size: 0.882em;
          box-sizing: border-box;
          padding: 5px 20px 5px 5px;
          line-height: 1.2;
          margin: auto 0 15px auto;
          width: 120px;
          span {
            display: block;
            line-height: 1;
            text-align: right;
          }
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            border: solid;
            border-width: 0 1px 1px 0;
            border-color: #000;
            right: 5px;
            top: 9px;
            transform: rotate(-45deg);
            transition: all 0.3s ease;
          }
          &:hover,
          &:focus-visible {
            &:after {
              right: 0;
            }
          }
        }
        .tab-pane {
          &.show {
            display: block;
          }
        }
        .List {
          .faq1 {
            ul {
              li {
                .question {
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  padding: 10px 0;
                  border-bottom: 1px solid #ddd;
                  p {
                    margin: 0;
                    padding: 0 10px;
                    &.hit,
                    &.subj,
                    &.info {
                      flex: 1;
                      text-align: center;
                    }
                    &.subj {
                      flex: 2;
                    }
                    span {
                      display: block;
                      font-size: 0.882em;
                      color: #666;
                    }
                    &.date span {
                      font-size: 0.8em;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .sectPoint1 {
      display: none;
    }
    .sectPoint1,
    .sectHist1 {
      margin: 50px 0 0;
      .unitPkg {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        padding: 25px 0 0;
        justify-content: space-between;
        .unit {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          width: calc(50% - 25px);
          border-radius: 10px;
          box-sizing: border-box;
          border: 1px solid var(--color-db);
          min-height: 160px;
          line-height: 1.2;
          box-sizing: border-box;
          padding: 0 30px;
          position: relative;
          .txtWr {
            .pTxt1 {
              font-size: 0.941em;
            }
            .pTxt2 {
              font-size: 0.941em;
              margin: 5px 0 0;
            }
            .pTxt3 {
              font-size: 1.764em;
              font-weight: 700;
              color: var(--color-1);
              letter-spacing: -0.05em;
              box-sizing: border-box;
              position: relative;
              padding: 0 30px 0 0;
              line-height: 1;
              transition: all 0.3s ease;
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 15px;
                height: 15px;
                box-sizing: border-box;
                border: solid;
                border-width: 0 3px 3px 0;
                transform: rotate(-45deg);
                right: 0;
                top: 9px;
              }
            }
            em {
              font-style: normal;
              color: var(--color-1);
            }
            strong {
              font-size: 1.764em;
              color: var(--color-1);
              margin: 0 10px 0 0;
            }
          }
        }
        a.unit {
          transition: all 0.3s ease;
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100px;
            height: 100px;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            background: url(/assets/img/_csCenter/csMain_faq1.png) no-repeat;
          }
          &.ico01 {
            &:after {
              background-position: 0 -220px;
            }
          }
          &.ico02 {
            &:after {
              background-position: -110px -220px;
            }
          }
          &:hover,
          &:focus-visible {
            border-color: var(--color-1);
            background: var(--color-1);
            color: #fff;
            .pTxt3 {
              color: #fff;
              &:after {
                border-color: #fff;
              }
            }
            &.ico01 {
              &:after {
                background-position: -220px -220px;
              }
            }
            &.ico02 {
              &:after {
                background-position: -330px -220px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .schTit1 {
      font-size: 1.2em;
      padding: 0 0 15px;
      margin: 0 0 20px;
    }
    .sectSearch {
      padding: 30px 0;
      .msg {
        font-size: 1.8em;
      }
      .frm {
        height: 55px;
        padding: 0 10px 0 15px;
        input {
          height: 35px;
          font-size: 1em;
        }
        button {
          width: 40px;
          height: 40px;
          font-size: 1.2em;
        }
      }
      .util {
        padding: 10px 5px 0 10px;
        .roleBtn {
          padding: 5px 8px 6px;
          span {
            font-size: 14px;
          }
        }
      }
    }
    &.Main {
      .sectFaq1 {
        .boxCate {
          .tab {
            ul {
              li {
                padding: 0 10px;
                button {
                  .ico {
                    width: 100px;
                    height: 100px;
                  }
                  .txt {
                    span {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        .tabBody {
          padding: 20px 0 15px;
          margin: 30px 0 0;
          .tabUnit {
            .roleHead {
              .cateName {
                font-size: 1.2em;
              }
              .cateMove {
                font-size: 0.8em;
                padding: 5px 15px 5px 5px;
              }
            }
            .roleBody {
              .swiper-wrapper {
                .swiper-slide {
                  > a {
                    height: 300px;
                    padding: 20px 20px 20px;
                    .articleTitle {
                      font-size: 1.2em;
                      height: 60px;
                    }
                    .articleContent {
                      font-size: 0.8em;
                      height: 160px;
                    }
                  }
                }
              }
              .swiper-navi-prev,
              .swiper-navi-next {
                width: 35px;
                height: 35px;
                &:after {
                  width: 12px;
                  height: 12px;
                }
              }
              .swiper-pagination-wrap {
                .swiper-auto-controller {
                  button {
                    width: 25px;
                    height: 25px;
                    &.stop:after {
                      width: 6px;
                      height: 10px;
                    }
                    &.play:after {
                      border-width: 5px 0 5px 6px;
                    }
                  }
                }
                .swiper-pagination {
                  li {
                    button {
                      width: 12px;
                      height: 12px;
                      &:after {
                        width: 8px;
                        height: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sectNotice1 {
        .tab-btns {
          ul {
            li {
              .nav-item {
                a {
                  padding: 6px 15px 8px;
                  min-width: 80px;
                  .txt {
                    font-size: 0.8em;
                  }
                }
              }
            }
          }
        }
        .tab-content {
          .noticeMove {
            font-size: 0.8em;
            padding: 5px 15px 5px 5px;
            width: 100px;
          }
          .List {
            .faq1 {
              ul {
                li {
                  .question {
                    flex-direction: column;
                    align-items: flex-start;
                    p {
                      text-align: left;
                      padding: 5px 0;
                      &.subj {
                        flex: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sectPoint1,
      .sectHist1 {
        .unitPkg {
          padding: 20px 0 0;
          .unit {
            width: calc(100% - 20px);
            min-height: 140px;
            padding: 0 20px;
            .txtWr {
              .pTxt1,
              .pTxt2 {
                font-size: 0.8em;
              }
              .pTxt3 {
                font-size: 1.5em;
                padding: 0 20px 0 0;
                &:after {
                  width: 12px;
                  height: 12px;
                }
              }
              strong {
                font-size: 1.5em;
              }
            }
          }
        }
      }
    }
  }
}
