/*---- 17. Sidebar style  ------*/
.sidebar-widget {
	margin:20px 0 0;box-sizing:border-box;
	&:first-child {
		margin:0;
		.widgetTit {
			&:after {display:none;}
		}
	}
	.widgetTit {
		display:block;font-size:16px;font-weight:700;margin:0;color:#000;line-height:1.2;box-sizing:border-box;position:relative;
		&:after {content:"";display:block;position:absolute;width:100%;height:1px;left:0;top:9px;background:#ddd;z-index:0;}
		span {position:relative;z-index:1;background:#fff;padding:0 10px 0 0;}
	}
	.smartFilter {display:flex;justify-content:space-between;align-items:center;margin:0 0 10px;}
	.expanded {transform:translateY(-50%) rotate(180deg);}
	&.no-arrow:after { content: none; }
	button.widgetTit {
		border:0;background:#fff;margin:0;transition:all .3s ease;
		&:focus-visible,
		&:hover {color:var(--color-1);}
	}
	.widgetBody {
		margin:10px 0 0;
		ul {
			li {
				position:relative;margin:0 0 5px;box-sizing:border-box;
				&:last-child {margin:0;}
				a,
				button {
					position:relative;text-align:left;text-transform:capitalize;border:none;background:none;font-size:14px;
					span {display:block;color:#777;line-height:1.2;transition:all .3s ease;}
					&:focus-visible span,
					&:hover span {color:var(--color-1)}
				}
				&.active {
					> .nodeSelf {
						a {
							span {color:var(--color-1)}
						}
					}
				}
				.chks,
				.rdos {
					display:block;
					label {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;font-size:14px;padding:0 0 0 25px;margin:0;outline:0;line-height:1.4;
						&:before {top:2px;}
						&:after {top:6px;}
						span {
							box-sizing:border-box;position:relative;padding:0 10px 0 0;margin:0 5px 0 0;
							&:after {content:"";display:block;position:absolute;width:7px;height:7px;border:solid;box-sizing:border-box;border-width:0 1px 1px 0;border-color:#aaa;right:0;top:5px;transform:rotate(-45deg);}
							&:last-child {
								padding:0;margin:0;
								&:after {display:none;}
							}
						}
					}
					input:checked + label {color:var(--color-1);font-weight:500;}
				}
			}
		}
		.no-data {font-size:14px;}
		&.wgCategory {
			max-height:300px;overflow-x:hidden;overflow-y:auto;
			&::-webkit-scrollbar {width:13px}
			&::-webkit-scrollbar-thumb {background:#ccc;border-left:5px solid #fff;border-right:5px solid #fff;}
			&::-webkit-scrollbar-track {background:#eee;border-left:5px solid #fff;border-right:5px solid #fff;}
			&::-webkit-scrollbar-button {height:0}
			.nodeChild {
				height:0;overflow:hidden;
			}
			ul {
				li {line-height:1;margin:0;}
			}
			.nodeSelf {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				a {
					flex:1;min-width:0;
				}
				button.toggler {
					width:20px;height:20px;box-sizing:border-box;font-size:0;
					&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#000;top:0;right:0;bottom:0;left:0;margin:2px auto auto auto;transform:rotate(45deg);transition:all .3s ease;}
					&:hover,
					&:focus-visible {
						&:after {opacity:1 !important;}
					}
				}
				&.on {
					a {
						span {font-weight:500;color:#000;}
					}
				}
			}
			.nodeChild {
			}
			.lv1Wrap {
				.lv1 {
					margin:5px 0;
					> li {
						padding:0 0 3px;margin:0 0 3px;
						&:last-child {padding:0;margin:0;border-bottom:0;}
						> .nodeSelf {
							a {font-size:15px;padding:3px 0;}
							.toggler {
								&:after {opacity:.6;}
							}
						}
						> .nodeChild {}
						&.active {
							> .nodeSelf {
								.toggler {
									&:after {transform:rotate(225deg);opacity:1;border-color:var(--color-1);margin:7px auto auto;}
								}
							}
							> .nodeChild {height:auto;}
						}
					}
				}
			}
			.lv2Wrap {
				.lv2 {
					box-sizing:border-box;padding:5px 0 5px 10px;background:#f8f8f8;margin:5px 0;
					> li {
						> .nodeSelf {
							a {font-size:14px;padding:3px 0;}
							.toggler {
								&:after {opacity:.3;}
							}
						}
						> .nodeChild {}
						&.active {
							> .nodeSelf {
								.toggler {
									&:after {transform:rotate(225deg);opacity:1;border-color:var(--color-1);margin:8px auto auto;}
								}
							}
							> .nodeChild {height:auto;}
						}
					}
				}
			}
			.lv3Wrap {
				.lv3 {
					box-sizing:border-box;padding:5px 0 5px 10px;background:#eee;margin:5px 0;
					> li {
						> .nodeSelf {
							a {font-size:13px;padding:3px 0;}
						}
						> .nodeChild {}
					}
				}
			}
		}
		&.wgNation {
			label {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;margin:0;padding:6px 0 2px 25px !important;
				img {height:17px;box-sizing:border-box;border:1px solid #ddd;}
				span {flex:1;line-height:1;padding:0 0 0 5px;box-sizing:border-box;font-size:14px;}
			}
		}
		&.wgStores {}
		&.wgSellerGrade {
			label {
				font-size:0;letter-spacing:0;
				i {font-size:14px;color:#999;}
				&[data-count="1"] i:nth-child(-n+1) {color:#ffcc00;}
				&[data-count="2"] i:nth-child(-n+2) {color:#ffcc00;}
				&[data-count="3"] i:nth-child(-n+3) {color:#ffcc00;}
				&[data-count="4"] i:nth-child(-n+4) {color:#ffcc00;}
				&[data-count="5"] i:nth-child(-n+5) {color:#ffcc00;}
			}
		}
		&.wgSalesUnit {}
		&.wgSalesPrice {
			.priceRange {
				* {outline:0;}
				display:flex;flex-flow:row;flex-wrap:wrap;
				input {padding:0 10px;flex:1;height:30px;min-width:0;max-width:none;font-size:14px;box-sizing:border-box;border:1px solid #ddd;}
				.tilde {width:15px;font-size:12px;text-align:center;line-height:30px;}
				button {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;width:30px;height:30px;border:1px solid #ddd;background:transparent;margin:0 0 0 5px;line-height:1;transition:all .3s ease;
					&:focus-visible,
					&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
				}
			}
		}
		&:not(.wgCategory):not(.wgCategories) {
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;
				li {
					min-width:50%;max-width:100%;
					.chks {
						label {padding-right:10px;box-sizing:border-box;}
					}
				}
			}
		}
	}
	.sidebar-widget-tag {
		ul {
			li {
				display:inline-block;margin:0 10px 15px 0;
				a,
				button {
					line-height:1;display:inline-block;padding:9px 17px 12px;text-transform:capitalize;color:#424242;border:none;border-radius:50px;background:none;background-color:#f6f6f6;
					&:hover,
					&.active {color:#fff;background-color:$theme-color;}
				}
			}
		}
	}
	@media all and (max-width:1399px) {
		padding:0;
	}
	@media all and (max-width:899px) {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
		&.wgCategory {margin:-20px 0 0;}
		.widgetTit {
			width:120px;margin:0;
			&.wgCategory {display:none;}
		}
		.widgetBody {
			flex:1;min-width:0;margin:0;
			&.wgCategory {display:none;}
			&.wgSalesPrice {
				.priceRange {margin:0;}
			}
		}
	}
}