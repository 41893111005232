/* ==============================
* Cart
================================= */
#cart {
	.disabled-button {
		color:#666;background-color:#ccc;border:#ccc;cursor:not-allowed;
		&:hover {
			background:#ccc;border-color:#ccc;
			&:before,
			&:after {background:#666;;}
		}
	}
	.cartTit {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
		.storeName {}
		.storeLink {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;font-size:.7em;box-sizing:border-box;border:1px solid #333;border-radius:4px;margin:0 0 0 15px;line-height:1;color:#333;padding:5px 10px;position:relative;top:2px;background:#fff;transition:all .3s ease;
			.ico {margin:0 5px 0 0;}
			.txt1 {height:auto;line-height:1;}
			&:focus-visible,
			&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
		}
	}
	.cartTools {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;margin:0 0 15px;
		.cartCelector {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
		.cartEmpty {}
	}
	.cart-table-content {
		table {
			.order-header {
				background-color:$theme-color !important;
				th {color:#fff}
			}
			.borrt {border-right-color:transparent}
			thead {
				tr {
					th {
						padding:10px 5px;
						&:after {height:30%;top:50%;transform:translateY(-50%);margin:0;}
					}
				}
			}
			tbody {
				tr {
					border-bottom:1px solid #dbdbdb;box-sizing:border-box;position:relative;
					td {
						font-size:14px;padding:10px 10px;text-align:center;color:#333;box-sizing:border-box;line-height:1.3;
						.noDataWrap {
							padding:20px 0 30px;
							.noData {
								.ico {
									i {font-size:50px;}
								}
								.msg {}
							}
						}
					}
					&.invalid {
						td {
							box-sizing:border-box;position:relative;
							&:before {content:"";display:block;position:absolute;width:100%;height:100%;left:0;top:0;background:rgba(0, 0, 0, .1);z-index:2;}
							&.product-remove {
								&:before {display:none;}
							}
						}
					}
					&.valid {}
				}
			}
			tfoot {
				tr {
					td {
						padding:15px 5px;
						background:#f5f5f5;
					}
				}
			}
			.product-chk {
				width:50px;
				.chks {
					label {
						margin:0;
						&:before {top:4px;}
						&:after {top:8px;}
					}
				}
			}
			td.product-chk {
				.chks {
					display:block;width:17px;height:17px;line-height:1;margin:0 auto;
					label {
						display:block;line-height:1;width:100%;height:100%;padding:0;
						&:before {top:0;}
						&:after {top:4px;}
					}
				}
			}
			.product-thumbnail {
				width:100px;
				a {
					display:block;width:100%;overflow:hidden;border:1px solid #ddd;position:relative;transition:all .3s ease;
					.invalidMark {display:block;position:absolute;width:100%;height:auto;line-height:1;padding:5px 0 5px;background:rgba(0, 0, 0, .5);z-index:1;color:#fff;left:0;bottom:0;}
					.imgContainer {
						display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
						img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
					}
					&:focus-visible,
					&:hover {
						border-color:var(--color-1);
						.imgContainer {
							img {transform:scale(1.1);}
						}
					}
				}
			}
			.product-name {
				width:auto;
				.prodLink {
					display:block;font-size:15px;font-weight:500;color:#333;text-align:left;transition:all .3s ease;
					&:hover {color:var(--color-1);}
				}
				.prodOpt {
					padding:10px 0 0;margin:10px 0 0;border-top:1px solid #ddd;
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;
						dt,
						dd {box-sizing:border-box;line-height:1.2;text-align:left;position:relative;word-wrap:break-word;word-break:keep-all;white-space:normal;}
						dt {
							width:70px;padding:0 10px 0 0;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:13px;right:0;top:2px;}
						}
						dd {flex:1;min-width:0;padding:0 0 0 10px;}
					}
				}
			}
			.product-price-cart {
				width:120px;letter-spacing:0;
				span {
					font-weight:500;color:#333;
					&.old {margin-right:10px;text-decoration:line-through;color:#8e8e8e;}
				}
			}
			.product-quantity {
				width:200px;
				.qtyController {
					display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;width:100%;
					.qtyControllerInner {
						display:flex;flex-flow:row;flex-wrap:wrap;position:relative;width:auto;padding:0;line-height:1;box-sizing:border-box;
						> * {height:30px;box-sizing:border-box;position:relative;}
						.qtybutton {
							width:30px;border:1px solid #ddd;background:none;transition:all .3s ease;
							&:before,
							&:after {content:"";display:block;position:absolute;width:10px;height:2px;background:#555;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							&:focus-visible,
							&:hover {
								background:var(--color-1);border-color:var(--color-1);
								&:before,
								&:after {background:#fff;}
							}
							&.inc {
								border-radius:0 3px 3px 0;
								&:after {transform:rotate(90deg);}
							}
							&.dec {
								border-radius:3px 0 0 3px;
								&:after {display:none;}
							}
							&.disabled-button {
								background:#ddd;cursor:default;
								&:hover,
								&:focus-visible {
									background:#ddd;border-color:#ddd;
									&:before,
									&:after {background:#555;}
								}
							}
						}
						.qtyInput {
							font-size:14px;width:80px;margin:0;padding:0 10px;text-align:center;color:#000;box-sizing:border-box;line-height:1;border-radius:0;border:solid;border-width:1px 0;border-color:#ddd;
							&::-webkit-inner-spin-button {appearance:none;-moz-appearance:none;-webkit-appearance:none;}
						}
					}
				}
			}
			.product-delivery-pay {
				width:120px;letter-spacing:0;
				.deliveryPay{display:block;text-align:center;}
			}
			.product-subtotal {
				width:120px;letter-spacing:0;
				.subTotalPrice {display:block;text-align:center;}
				.buyNow {margin:5px auto 0;}
			}
			.product-remove {
				width:50px;
				a,
				button {
					display:block;width:30px;height:30px;margin:0 auto;border:1px solid #ddd;background:transparent;border-radius:4px;position:relative;transition:all .3s ease;
					&:before,
					&:after {content:"";display:block;position:absolute;width:15px;height:2px;top:0;right:0;bottom:0;left:0;margin:auto;background:#000;transition:all .3s ease;}
					&:before {transform:rotate(45deg);}
					&:after {transform:rotate(-45deg);}
					&:focus-visible,
					&:hover {
						background:var(--color-1);border-color:var(--color-1);
						&:before,
						&:after {background:#fff;}
					}
				}
			}
			.product-wishlist-cart {
				> a,
				> button {
					font-size:13px;font-weight:500;line-height:1.2;display:block;margin:0 auto;padding:10px 15px;text-transform:uppercase;color:#fff;border:none;border-radius:50px;background:none;background-color:$theme-color;
					&:hover,
					&.active {background-color:#333;}
					&:disabled {cursor:not-allowed;}
				}
			}
			.product-status {color:var(--color-1)}
			.product-total {
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					dt,
					dd {line-height:1;box-sizing:border-box;position:relative;}
					dt {
						font-weight:400;padding:0 10px 0 0;margin:0 10px 0 0;
						&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:0px;font-weight:14px;}
					}
					dd {
						font-weight:500;color:#000;
						em {font-style:normal;color:var(--color-1);letter-spacing:0;}
					}
				}
			}
			.customBtn1 {
				display:inline-block;line-height:1;
			}
		}
	}
	.cart-shiping-update-wrapper {
		display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;margin:40px 0 0;
		> * {min-width:240px !important;}
		.clearCart {}
		.shoppingContinue {}
	}
	.tax-wrapper {
		p {margin:0;}
		.tax-select-wrapper {
			margin:5px 0 0;
			.tax-select {
				margin:0 0 26px;
				label {font-size:14px;margin:0 0 5px;color:#242424;}
				select {font-size:12px;background:#fff none repeat scroll 0 0;width:100%;height:40px;padding:0 50px 0 15px;cursor:pointer;color:#242424;border:1px solid #ebebeb;box-shadow:none;}
				input {height:40px;border:1px solid #ebebeb;background:#fff none repeat scroll 0 0;}
			}
		}
	}
	.cart-custom-row {
		margin:40px 0 0;
		.cart-total {
			.box-custom {
				display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;
				.box {
					display:flex;flex-flow:row;flex-wrap:wrap;width:100%;justify-content:space-between;
					.side {
						width:calc( 50% - 10px );
						.sTitle {
							display:block;margin:0 0 20px;padding-right:18px;line-height:1;
							span {display:block;line-height:1.2;font-size:1.412em;font-weight:700;color:#000;}
						}
						.sBox {
							box-sizing:border-box;border-radius:10px;padding:30px;border:1px solid #dbdbdb;
							.msgBox {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;margin:0 0 30px;color:#111;font-size:1.059em;font-weight:500;
							}
							&.boxCoupon {
								background:#f7f7f7;
								.inpBox {
									display:flex;flex-flow:row;flex-wrap:wrap;
									input {flex:1;height:35px;padding:0 10px;border:1px solid #dbdbdb;background:#fff;}
									.cw-nBtn2 {
										border-radius:4px;height:35px;margin:0 0 0 8px;
										&:before {height:35px;}
									}
								}
							}
							&.boxTotal {
								background:#fff;
								.msgBox {
									dl {
										display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;width:100%;margin:0 0 20px;
										&:last-child {margin-bottom:0;}
										dt,
										dd {box-sizing:border-box;line-height:1.2;}
										dt {}
										dd {}
										&.dcInstant {
											dd {color:var(--color-1);}
										}
										&.dcCoupon {
											dd {color:var(--color-1);}
										}
										&.dlvrFee {}
										&.grand {
											padding:36px 0 0;margin:16px 0 0;border-top:1px solid var(--color-db);
											dt {font-weight:700;}
											dd {font-size:1.412em;}
										}
									}
								}
								a {display:block;width:100%;min-width:0;max-width:none;}
								button {display:block;width:100%;min-width:0;max-width:none;}
							}
						}
					}
				}
			}
		}
	}
	.noDataWrap {
		padding:50px 0;
		.ico {font-size:4em;font-weight:500;color:#333;}
	}
}
.tab-cart-content {padding:10px;cursor:pointer;position:relative;display:inline-block;}
.tab-cart-content::after {content:'';position:absolute;width:0;height:2px;bottom:0;left:0;background-color:#000;transition:width 0.3s ease-in-out;}
.tab-cart-content:hover::after {width:100%;}
.tab-cart-active {font-weight:bold;border-bottom:2px solid #000;}
.tab-cart-inactive {color:#888;}
.tab-col-margin-10 {margin:0 10px;}

/** -- OrderDetail */
.order-details-total {
	background-color:#fff2f0;border-radius:25px;padding:18px 20px 18px 20px;
	.amount_type {
		margin:38px 20px;text-transform:uppercase;
		.amount_module {font-weight:600;}
		.amount {float:right;}
		&:last-child {color:red;}
	}
}
.custom-payment-shipping-details {width:100%;display:flex;}
.printRow {
	display:flex;justify-content:space-between;margin-bottom:60px;
	.cart-page-title {margin:0px !important;}
	.print-button {background-color:$theme-color;border:medium none;color:#fff;display:inline-block;font-size:14px;font-weight:500;line-height:1;padding:16px 35px 17px;text-transform:uppercase;cursor:pointer;transition:all 0.3s ease 0s;}
}

/** -- RecentOrder Listing Page */
.custom-recent-order {
	.table-responsive {
		overflow-x:visible;
		table {
			width:100%;display:flex;flex-direction:column;
			thead {
				width:100%;display:flex;
				tr {
					width:100%;display:flex;
					th {
						width:50%;display:flex;align-items:flex-start;
						&:last-child {justify-content:flex-end;}
					}
				}
			}
			tbody {
				tr {
					display:flex;align-items:center;
					td {width:20%;}
				}
			}
		}
	}
}

/* ==============================
* Review Order / Delivery
================================= */
.order {
	/** -- 공용 */
	.orderUtils {
		display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;margin:0 0 20px;
		.orderCate {
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;
				li {
					box-sizing:border-box;position:relative;line-height:1;padding:0 20px 0 0;margin:0 20px 0 0;
					&:after {content:"";display:block;position:absolute;width:1px;height:15px;background:#ddd;right:0;top:2px}
					&:last-child {
						padding:0;margin:0;
						&:after {display:none;}
					}
					button {display:block;border:0;background:transparent;line-height:1;}
				}
			}
		}
		.orderPeriod {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
			dl {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:0 30px 0 0;
				dt,
				dd {line-height:1;box-sizing:border-box;position:relative;}
				dt {
					font-weight:500;color:var(--color-1);margin:0 7px 0 0;padding:0 10px 0 0;
					&:after {content:":";display:block;position:absolute;width:auto;height:auto;right:0;top:3px;font-size:13px;color:#aaa;}
				}
				dd {}
			}
			button {}
		}
	}
	.orderStatus {
		border-radius:5px;background:linear-gradient( to right, #00a1e8 0, #212dd7 100%);padding:2px;margin:0 0 19px;
		ul {
			background:#fff;display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;padding:20px 0;border-radius:3px;width:100%;align-items:center;align-content:center;
			li {
				text-align:center;box-sizing:border-box;padding:0 20px;position:relative;flex:1;min-width:0;
				&:after {content:"";display:block;position:absolute;width:1px;height:26px;background:#d6d6d6;right:0;top:50%;transform:translateY(-50%);}
				&:last-child {
					&:after {display:none;}
				}
				> * {box-sizing:border-box;line-height:1;height:auto;}
				.pTit1 {display:block;font-size:.882em;color:#111;font-weight:500;margin:0 0 10px;}
				.pNum1 {
					.num {font-size:1.294em;color:var(--color-1);font-weight:500;}
					.unit {margin:0 0 0 5px;}
				}
				&.statistic {
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;align-items:flex-end;align-content:flex-end;margin:0 0 5px;
						&:last-child {margin:0;}
						dt,
						dd {line-height:1;box-sizing:border-box;position:relative;font-size:1em;}
						dt {
							padding:0 10px 0 0;	margin:0 7px 0 0;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:3px;font-size:13px;color:#aaa;}
						}
						dd {
							.num {font-size:1em;}
						}
					}
				}
			}
		}
	}
	.orderTit {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;font-size:1.353em;line-height:1;
		.orderState {color:#000;margin:0 10px 0 0;font-weight:700;line-height:1;}
		.orderArrive {line-height:1;}
		i {font-size:1.5em;color:var(--color-1);margin:0 0 -3px 5px;}
	}
	&.List {
		.orderList {
			> ul {
				> li {
					display:block;margin:0 0 40px;
					&:last-child {margin:0;}
					.orderEachDate {
						box-sizing:border-box;border-top:2px solid var(--color-1);margin:0 0 51px;
						&:last-child {margin:0;}
						.orderHead {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;box-sizing:border-box;padding:15px 0;
							* {line-height:1;box-sizing:border-box;}
							.summ {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
								.date {font-size:1.412em;color:#000;font-weight:700;margin:0 13px 0 0;}
								.numb {
									display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;padding:0 10px 0 0;margin:0 10px 0 0;align-items:center;align-content:center;
									&:after {content:"";display:block;position:absolute;width:2px;height:13px;background:#ddd;right:0;top:50%;transform:rotate(20deg) translateY(-50%);}
									&:last-child {
										padding:0;margin:0;
										&:after {display:none;}
									}
									dt,
									dd {font-size:.882em;}
									dt {margin:0 5px 0 0;}
									dd {border-radius:4px;background:var(--color-1);color:#fff;letter-spacing:0;padding:2px 5px 4px;}
								}
								.orderType {font-size:.882em;}
							}
							.view {
								display:block;line-height:1;font-size:1.059em;color:var(--color-1);box-sizing:border-box;position:relative;padding:5px 17px 7px 0;border:0;background:transparent;
								&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:var(--color-1);transform:rotate(-45deg);right:5px;top:10px;transition:all .3s ease;}
								span {display:block;line-height:1;letter-spacing:-.025em;}
								&:focus-visible:after,
								&:hover:after {right:0;}
							}
						}
						.orderBody {
							display:flex;flex-flow:row;flex-wrap:wrap;
							> li {
								box-sizing:border-box;border:1px solid #dbdbdb;border-radius:10px;margin:0 0 20px;width:100%;
								&:last-child {margin:0;}
								.orderInfos {
									display:flex;flex-flow:row;flex-wrap:wrap;
									.prodPkgs {
										flex:1;min-width:0;box-sizing:border-box;padding:25px 30px 30px 30px;
										.pkgTitle {
											margin:0 0 20px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
											.storeName {
												display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
												i {font-size:1.7em;margin:0 0 0 10px;line-height:1;}
											}
											.storeInquiry,
											.storeCounsel {margin:0 0 0 10px;border-radius:5px;}
										}
										.pkgState {
											display:flex;flex-flow:row;flex-wrap:wrap;line-height:1;align-items:center;align-content:center;margin:0 0 20px;
											> * {box-sizing:border-box;position:relative;}
											.stateBill {
												font-size:1.3em;font-weight:500;margin:0 50px 0 0;
												.completeBill {color:#010101;}
												.completeDelivery {color:var(--color-1);}
												.completeCancel {color:#767676;}
											}
											.stateDelivery {
												font-size:1.3em;padding:0 30px 0 0;
												&:after {content:"\f231";display:block;position:absolute;width:auto;height:auto;right:0;top:2px;font-family:"remixicon" !important;}
												&.deliveryReady {color:var(--color-1);}
												&.deliveryComplete {color:#767676;}
											}
										}
										.pkgProduct {
											margin:0 0 20px;
											&:last-child {margin:0;}
										}
									}
									.prodUtils {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:350px;box-sizing:border-box;border-left:1px solid #dbdbdb;
										.btnPkgs {
											width:203px;
											button {
												display:block;box-sizing:border-box;line-height:1;border:solid;border-width:1px;width:100%;font-size:.882em;margin:0 0 7px;background:transparent;border-radius:5px;padding:13px 0 14px;transition:all .3s ease;
												i {margin:0 5px 0 0;}
												&:last-child {margin:0;}
												&.orderDelivery {
													border-color:var(--color-1);color:var(--color-1);
													&:hover,
													&:focus-visible {border-color:var(--color-2);color:var(--color-2);}
												}
												&.order {
													&.pay1 {
														border-color:#bcbcbc;color:#111;
														&:hover,
														&:focus-visible {border-color:var(--color-1);background:var(--color-1);color:#fff;}
													}
													&.pay2 {
														border-color:var(--color-1);background:var(--color-1);color:#fff;
														&:hover,
														&:focus-visible {border-color:var(--color-2);background:var(--color-2);}
													}
												}
												&.orderQc {
													border-color:var(--color-o1);color:var(--color-o1);
													&:hover,
													&:focus-visible {background:var(--color-o1);color:#fff;}
												}
												&.orderCancel {
													border-color:#bcbcbc;color:#111;background:#eee;
													&:hover,
													&:focus-visible {border-color:#555;background:#ddd;}
												}
												&.orderDesign {
													border-color:var(--color-2);color:var(--color-2);
													&:hover,
													&:focus-visible {background:#e9f2ff;}
												}
												&.orderReorder {
													border-color:var(--color-1);color:var(--color-1);
													&:hover,
													&:focus-visible {background:var(--color-1);color:#fff;}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.orderSearch {
			background:#f0f0f0;border-radius:5px;padding:12px 20px 13px;line-height:1;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;margin:0 0 48px;
			dl {
				display:flex;flex-flow:row;flex-wrap:wrap;line-height:1;
				dt,
				dd {font-size:13px;color:#111;}
				dt {margin:0 5px 0 0;}
				dd {letter-spacing:0}
			}
			.toggler {
				display:block;line-height:1;font-size:13px;color:#888;border:0;background:transparent;box-sizing:border-box;position:relative;padding:5px 15px 5px 0;transition:all .3s ease;
				span {display:block;line-height:1;}
				&:after {content:"";display:block;position:absolute;width:8px;height:8px;border:solid;box-sizing:border-box;border-width:0 0 2px 2px;border-color:#888;transform:rotate(-45deg);right:0;top:5px;transition:all .3s ease;}
				&:focus-visible,
				&:hover {
					color:var(--color-1);
					&:after {border-color:var(--color-1);}
				}
			}
			&.on .toggler {
				color:var(--color-1);
				&:after {border-color:var(--color-1);transform:rotate(135deg);top:10px;}
			}
		}
		.orderEachProduct {
			> li {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 36px;
				&:last-child {margin:0;}
				.boxImg {
					display:block;box-sizing:border-box;border:1px solid #ddd;overflow:hidden;width:100px;height:100%;
					.imgContainer {
						display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;
						img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
					}
					&:focus-visible .imgContainer img,
					&:hover .imgContainer img {transform:scale(1.1);}
				}
				.boxTxt {
					flex:1;box-sizing:border-box;padding:0 0 0 30px;
					.f1 {
						display:block;vertical-align:top;line-height:1.2;margin:-3px 0 0;
						.prodBadge {
							display:inline;height:16px;border-radius:3px;width:auto;overflow:hidden;margin:0 8px 0 0;
							span {display:inline-block;padding:0 5px;line-height:14px;}
						}
						.prodName {
							display:inline;color:#111;font-weight:500;border-radius:3px;letter-spacing:-.05em;word-wrap:break-word;word-break:keep-all;white-space:normal;transition:all .3s ease;
							&:focus-visible,
							&:hover {color:var(--color-1);}
						}
					}
					.f2 {
						margin:5px 0 0;
						ul {
							display:flex;flex-flow:row;flex-wrap:wrap;
							li {
								box-sizing:border-box;position:relative;padding:0 10px 0 0;margin:0 10px 0 0;font-size:1.0em;color:#777;line-height:1.2;
								&:after {content:"";display:block;position:absolute;width:2px;height:15px;background:#ddd;right:0;top:3px;transform:rotate(30deg);}
								&:last-child {
									padding:0;margin:0;
									&:after {display:none;}
								}
							}
						}
					}
					.f3 {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;margin:10px 0 0;
						button {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;line-height:1;background:transparent;border-radius:5px;border:1px solid #c0c0c0;height:35px;box-sizing:border-box;position:relative;color:#8b8b8b;padding:0 10px;border-radius:4px;transition:all .3s ease;
							span {display:block;line-height:1;font-size:.882em;}
							i {
								display:block;width:19px;height:19px;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;line-height:1;font-size:1.176em;
								&.over {opacity:0;color:var(--color-1);}
								&.base {opacity:1;}
							}
							&.cart {
								padding:0 22px;margin:0 8px 0 0;
								&:focus-visible,
								&:hover {border-color:var(--color-1);background:var(--color-1);color:#fff;}
							}
							&.favorite {
								width:35px;
								&.on {
									border-color:var(--color-1);background:var(--color-1);
									i.over {opacity:1;}
									i.base {opacity:1;color:#fff;}
								}
								&:not(.on):focus-visible,
								&:not(.on):hover {border-color:var(--color-1);background:var(--color-1);color:#fff;}
								&.on:focus-visible,
								&.on:hover {
									border-color:var(--color-1);background:var(--color-1);color:#fff;
									.over {color:#fff;}
								}
							}
							&.reorder {
								padding:0 22px;margin:0 8px 0 0;
								border-color:var(--color-1);color:var(--color-1);
								&:hover,
								&:focus-visible {background:var(--color-1);color:#fff;}
							}
						}
					}
				}
			}
		}
	}
	&.View {
		/** -- 공용 */
		.frmWr {
			.lv1 {
				display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;border-top:2px solid var(--color-1);
				> li {
					width:100%;padding:10px 20px;
					&.w50p {width:50%;}
					dl {
						dt,
						dd {}
						dt {}
						dd {}
					}
				}
			}
		}

		/** -- 전용 */
		.orderSumm {
			.orderInfo {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;box-sizing:border-box;border:1px solid #ddd;background:#f5f5f5;padding:10px;border-radius:5px;margin:15px 0 0;
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					dt,
					dd {line-height:1;box-sizing:border-box;position:relative;}
					dt {
						padding:0 15px 0 10px;font-weight:500;color:#111;margin:0 12px 0 0;font-size:1.176em;
						&:after {content:":";display:block;position:absolute;width:auto;height:auto;font-size:13px;color:#888;right:0;top:6px;line-height:1;}
					}
					dd {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						.orderId {
							background:var(--color-1);border-radius:3px;color:#fff;padding:2px 5px 5px;line-height:1;margin:0 0 0 5px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
							i {margin:4px 3px 0 0;}
							span {}
						}
						.orderType {color:#000;font-weight:600;margin:0 0 0 5px;}
					}
				}
				button {
					&.orderDelete {display:none;}
				}
			}
		}
		.orderProducts {
			margin:20px 0 0;
			> ul {
				> li.pkgProduct {
					box-sizing:border-box;border:1px solid #ddd;border-radius:5px;margin:0 0 15px;padding:15px;display:flex;flex-flow:row;flex-wrap:wrap;
					&:last-child {margin:0;}
					.prodInfo {
						flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;
						.boxImg {
							.prodImg {
								display:block;box-sizing:border-box;border:1px solid #ddd;width:150px;background:#eee;
								.imgContainer {
									display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;overflow:hidden;
									img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
								}
								&:hover,
								&:focus-visible {
									.imgContainer {
										img {transform:scale(1.1);}
									}
								}
							}
						}
						.boxTxt {
							flex:1;min-width:0;box-sizing:border-box;padding:0 20px;display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;
							.prodName {
								display:flex;flex-flow:row;flex-wrap:wrap;
								.prodBadge {width:auto;}
								.prodNameStr {flex:1;min-width:0;line-height:1.3;margin:0 0 0 10px;color:#333;letter-spacing:-.05em;font-size:.941em;color:#333;}
							}
							.prodSellInfo {
								display:flex;flex-flow:row;flex-wrap:wrap;margin:10px 0 auto;
								li {
									padding:0 15px 0 0;margin:0 10px 0 0;box-sizing:border-box;position:relative;line-height:1.2;
									&:after {content:"";display:block;position:absolute;width:2px;height:14px;background:#ddd;right:0;top:4px;transform:rotate(30deg);}
									&:last-child {
										padding:0;margin:0;
										&:after {display:none;}
									}
								}
							}
							.prodUtils {
								button {
									&.cart {margin:0 10px 0 0;}
									&.favorite {
										width:40px;min-width:0;box-sizing:border-box;position:relative;padding:0;
										&:before {display:none;}
										i {
											display:block;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;width:17px;height:17px;line-height:1;transition:all .3s ease;
										}
									}
								}
							}
						}
					}
					.prodPrice {
						width:200px;box-sizing:border-box;border-left:1px solid #ddd;padding:0 0 0 20px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						dl {
							display:flex;flex-flow:row;flex-wrap:wrap;width:100%;margin:0 0 5px;padding:0 0 5px;border-bottom:1px solid #eee;justify-content:space-between;
							dt,
							dd {font-weight:400;box-sizing:border-box;position:relative;line-height:1;font-size:15px;}
							dt {}
							dd {color:#111;text-align:right;letter-spacing:0;}
						}
						.total {font-weight:400;color:var(--color-1);width:100%;text-align:right;font-size:15px;letter-spacing:0;}
					}
				}
			}
		}
		.orderOrderer {}
		.orderPay {
			.frmWr {
				.lv1 {
					> li {
						width:50%;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;padding:10px 20px;font-size:.882em;
						dl {
							width:100%;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;margin:0 0 10px;
							&:last-child {margin:0;}
							dt,
							dd {line-height:1;font-weight:500;}
							dt {}
							dd {}
						}
						.sTxt1 {text-align:right;width:100%;}
						.sTxt2 {text-align:right;width:100%;margin:5px 0 0;}
						&.liHasPayMethod {order:1;}
						&.liHasService {order:2;}
						&.liHasPayHistory {order:3;}
						&.liHasTotal {order:4;}
						&.liHasService,
						&.liHasTotal {
							background:#f5f5f5;
							dd {color:#000;}
						}
						&.liHasPayMethod2 {}
						&.liHasPayHistory2 {}
						&.liHasTotal2 {width:100%;background:#f5f5f5;}
					}
				}
				.lv2 {
					width:100%;display:flex;flex-flow:row;flex-wrap:wrap;
					> li {
						width:50%;box-sizing:border-box;position:relative;
						&.liHasPrice {
							padding:5px 20px 7px;
							dd {color:#000;}
						}
						&:nth-child(odd) {
							padding-left:0;
							&:after {content:"";display:block;position:absolute;width:200%;height:1px;background:#ddd;left:0;bottom:0;}
						}
						&:nth-child(even) {padding-right:0;}
					}
				}
			}
			.utilsWr {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;align-content:flex-start;justify-content:space-between;margin:20px 0 0;text-align:right;
				.msgs {}
				.btns {
					button {
						margin:0 10px 0 0;
						&:last-child {margin:0;}
					}
				}
			}
			&.order1st {}
			&.order2nd {
				.utilsWr {justify-content:right;}
			}
		}
		.orderPay2nd {}
		.orderSettle {
			.finalTotal {
				border-radius:8px;box-sizing:border-box;border:1px solid #ddd;background:#f5f5f5;padding:20px;margin:0 0 10px;
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
					dt,
					dd {line-height:1;box-sizing:border-box;font-size:1.294em;font-weight:500;}
					dt {color:#000;}
					dd {color:var(--color-1);letter-spacing:0;}
				}
			}
		}
		.orderDelivery {
			.contentsWr {
				box-sizing:border-box;border:1px solid #dbdbdb;border-radius:10px;
				.contentsTit {display:block;box-sizing:border-box;padding:20px 20px 0;margin:0 0 30px;color:#111;}
				.contentsFlow {
					.flow {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						li {
							flex:1;min-width:0;box-sizing:border-box;position:relative;
							&:after {content:"";display:block;position:absolute;width:20px;height:20px;box-sizing:border-box;border:solid;border-width:0 3px 3px 0;border-color:var(--color-1);transform:rotate(-45deg);right:0;top:60px;}
							&:last-child {
								&:after {display:none;}
							}
							.ico {
								width:140px;height:140px;border-radius:50%;box-sizing:border-box;border:1px solid var(--color-db);margin:0 auto;position:relative;overflow:hidden;
								&:after {content:"";display:block;position:absolute;width:110px;height:110px;top:0;right:0;bottom:0;left:0;margin:auto;background:url(/assets/img/_order/orderDetails_spr1.png) no-repeat;}
								&.n01 { &:after {background-position:0 0;} }
								&.n02 { &:after {background-position:-120px 0;} }
								&.n03 { &:after {background-position:-240px 0;} }
								&.n04 { &:after {background-position:-360px 0;} }
								&.n05 { &:after {background-position:-480px 0;} }
							}
							.txt {display:block;text-align:center;color:#111;font-weight:.882em;line-height:1;margin:10px 0 0;}
						}
					}
				}
				.contentsMsg {
					background:#f0f0f0;box-sizing:border-box;padding:50px;margin:30px 0 0;
					.msg1 {}
					dl {
						display:block;margin:30px 0 0;
						dt,
						dd {line-height:1;}
						dt {font-weight:500;color:#111;}
						dd {margin:10px 0 0;}
					}
				}
			}
		}
	}

	/** -- 상세조회 Dialog. */
	.dialog.detailOrder {
		.popupWrap {width:1200px;height:380px;}
		.viewItems {
			.typeDl1 {
				dt,
				dd {}
				dt {
					width:200px;
					.text {}
					.date {display:block;font-size:13px;color:#888;}
					&:after {top:50% !important;transform:translateY(-50%);}
				}
				dd {
					.btns {
						li {
							button {min-width:100px;}
						}
					}
				}
			}
		}
	}

	/** -- 1차 결제 */
	.dialog.orderPay1st {
		.popupWrap {
			width:700px;height:810px;
			dl.bet {
				display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;
				dt,
				dd {font-weight:400;}
				dt {}
				dd {letter-spacing:0;padding:0 15px 0 0;}
			}
			.total {
				box-sizing:border-box;border:1px solid #ddd;border-radius:5px;background:#f5f5f5;padding:15px 0 15px 15px;
			}
			.detail {
				dl {
					padding:5px 0;border:solid;border-width:1px 0 0;border-color:#ddd;font-size:.941em;
					&:last-child {border-bottom:1px solid #ddd;}
				}
			}
			.hasEmail {
				input {flex:1;min-width:0;}
				.tilde {padding:0 10px;}
			}
		}
	}

	/** -- 더보기 */
	.orderListMore {
		display:flex;flex-flow:row;flex-wrap:wrap;width:400px;height:60px;align-items:center;align-content:center;justify-content:center;border-radius:3px;border:1px solid var(--color-db);background:#fff;margin:50px auto 0;transition:all .3s ease;
		span {
			box-sizing:border-box;position:relative;padding:0 20px 0 0;font-size:.941em;line-height:1;font-weight:500;color:#000;transition:all .3s ease;
			&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#000;transform:rotate(45deg);right:0;top:2px;transition:all .3s ease;}
		}
		&:hover,
		&:focus-visible {
			border-color: var(--color-1);
			span {
				color:var(--color-1);
				&:after {border-color: var(--color-1);}
			}
		}
		&.clicked span:after { transform: rotate(-135deg); top: 6px; }
	}
}

/** -- 부가서비스 Dialog. */
.dialog.additionalServices {
	.productInfos {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;border:1px solid #ddd;padding:10px;border-radius:5px;
		.prodImg {
			width:70px;height:70px;box-sizing:border-box;position:relative;border:1px solid #ddd;
			img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
		}
		.prodName {flex:2;min-width:0;word-wrap:break-word;word-break:keep-all;white-space:normal;box-sizing:border-box;padding:0 15px;}
		.prodQty {
			display:flex;flex-flow:row;flex-wrap:wrap;
			dt,
			dd {line-height:1;box-sizing:border-box;font-weight:500;position:relative;}
			dt {
				padding:0 10px 0 0;margin:0 10px 0 0;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;color:#aaa;right:0;top:0px;font-size:13px;}
			}
			dd {padding:0 10px 0 0;}
		}
	}
	.productServices {
		dl {
			border-bottom:1px solid #ddd;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
			&:first-child {border-top:2px solid #aaa;}
			dt,
			dd {box-sizing:border-box;line-height:1;position:relative;}
			dt {
				font-weight:400;width:150px;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;font-size:13px;color:#aaa;right:0;top:3px;}
			}
			dd {
				flex:1;min-width:0;
				ul {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
					> li {
						flex:1;min-width:0;text-align:center;padding:10px 0;box-sizing:border-box;position:relative;
						&:after {content:"";display:block;position:absolute;width:1px;height:15px;right:0;top:50%;transform:translateY(-50%);background:#ddd;}
						&:last-child {
							&:after {display:none;}
						}
					}
				}
			}
		}
	}
	.productHistory {
		dl {
			dt {align-items:center;align-content:center;font-weight:400;}
			dd {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;padding:0 5px 0 0;
				.date {flex:1;min-width:0;}
				.util {
					height:30px;
					select,
					button {font-size:15px;margin:0;height:30px;min-height:0;min-width:0;margin:0 0 0 5px;box-sizing:border-box;position:relative;}
					> * {
						span {font-size:15px;margin:0;}
					}
					.toggler {
						width:30px;padding:0;
						&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#fff;top:0;right:0;bottom:0;left:0;transition:all .3s ease;}
						&.off {
							&:after {margin:7px auto auto auto;transform:rotate(45deg);}
						}
						&.on {
							&:after {margin:12px auto auto auto;transform:rotate(-135deg);}
						}
					}
				}
				.msgs {
					width:100%;
					.msg {
						display:flex;flex-flow:row;flex-wrap:wrap;width:100%;box-sizing:border-box;font-size:15px;position:relative;color:#777;
					}
					.replWrite {
						display:flex;flex-flow:row;flex-wrap:wrap;margin:5px 0 0;
						input {flex:1;min-width:0;font-size:15px;}
						button {
							margin:0 0 0 5px;
							span {font-size:15px;margin:0;}
						}
					}
					.replMsg {
						margin:10px 0 0;padding:5px 0 0 50px;display:flex;flex-flow:row;flex-wrap:wrap;width:100%;box-sizing:border-box;border-top:1px solid #ddd;font-size:15px;position:relative;color:#777;justify-content:space-between;align-items:center;align-content:center;
						.text {order:1;flex:1;min-width:0;word-wrap:break-word;word-break:keep-all;white-space:normal;}
						.writer {
							order:3;width:100%;line-height:1;display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;margin:5px 0 0;
							.regiPerson {flex:1;min-width:0;}
							.regiDate {}
						}
						&:before,
						&:after {display:block;position:absolute;box-sizing:border-box;border:solid;}
						&:before {content:"";width:5px;height:18px;border-width:0 0 1px 1px;border-color:var(--color-1);left:7px;top:0;}
						&:after {content:"Re :";border:0;font-size:12px;line-height:1;background:var(--color-1);color:#fff;border-radius:4px;left:12px;top:9px;padding:2px 5px 3px;}
						.btns {
							order:2;display:flex;flex-flow:row;flex-wrap:wrap;
							button {
								min-width:0;width:auto;height:23px;margin:0 5px 0 0;padding:0 10px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
								&:last-child {margin:0;}
								span {font-size:13px;}
								&.modify {}
								&.delete {}
							}
						}
					}
				}
			}
		}
	}
}

#dialogCustom.orderInquiry {}
#dialogCustom.orderDeliveryTracking {
	.orderNum {
		display:flex;flex-flow:row;flex-wrap:wrap;width:100%;
		dl {
			display:flex;flex-flow:row;flex-wrap:wrap;width:50%;align-items:center;align-content:center;
			dt,
			dd {box-sizing:border-box;line-height:1;position:relative;}
			dt {
				padding:0 10px 0 0;margin:0 6px 0 0;
				&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:0;top:4px;color:#aaa;}
			}
			dd {
				letter-spacing:0;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
				button {
					width:25px;height:25px;box-sizing:border-box;border:1px solid #ddd;border-radius:4px;background:#fff;margin:0 0 0 10px;transition:all .3s ease;
					&:hover,
					&:focus-visible {border-color:var(--color-1);background:var(--color-1);color:#fff;}
				}
			}
		}
	}
	.orderDeliverySumm {border-radius:10px;background:#f5f5f5;text-align:center;padding:10px;margin:20px 0 0;box-sizing:border-box;border:1px solid #ddd;}
	.orderDeliveryStatus {
		margin:30px 0 0;
		ol {
			display:flex;flex-flow:row;flex-wrap:wrap;
			li {
				flex:1;min-width:0;text-align:center;box-sizing:border-box;position:relative;
				&:after {content:"";display:block;position:absolute;width:15px;height:15px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(-45deg);right:0;top:40px;}
				&:last-child {
					&:after {display:none;}
				}
				.boxIco {
					width:98px;height:98px;border-radius:50%;box-sizing:border-box;border:1px solid var(--color-db);margin:0 auto;position:relative;overflow:hidden;
					&:after {content:"";display:block;position:absolute;width:77px;height:77px;top:0;right:0;bottom:0;left:0;margin:auto;background-repeat:no-repeat;}
				}
				.boxTxt {display:block;text-align:center;color:#111;font-weight:.882em;line-height:1;margin:10px 0 0;}
				&.on {
					&:after {border-color:var(--color-1);}
					.boxIco {
						border-color:var(--color-1);background:#f3f8ff;
						&:after {background-position-y:0 !important;}
					}
					.boxTxt {color:var(--color-1);}
				}
			}
		}
	}
	.orderDeliveryHistory {
		margin:30px 0 0;
		ol {
			overflow:hidden;
			li {
				border-bottom:1px solid #ddd;
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;
					dt,
					dd {line-height:1.3;font-size:.941em;box-sizing:border-box;position:relative;}
					dt {width:230px;padding:15px 0;font-weight:500;}
					dd {
						flex:1;min-width:0;padding:15px 0 15px 20px;
						&:before,
						&:after {content:"";display:block;position:absolute;background:#bbb;}
						&:before {width:1px;height:100%;left:10px;top:27px;z-index:1;}
						&:after {width:5px;height:5px;border-radius:50%;left:8px;top:23px;z-index:3;}
					}
				}
				&:first-child {
					dl {
						dd {
							&:before {background:linear-gradient( to bottom, var(--color-1) 0%, #bbb 75% );}
							&:after {background:var(--color-1);}
						}
					}
				}
				&:last-child {
					dl {
						dt {
							&:after {content:"";display:block;position:absolute;width:1px;height:100%;right:-11px;top:27px;background:#fff;z-index:2;}
						}
						dd {
							&:before {}
							&:after {}
						}
					}
				}
			}
		}
	}
	.Domestic {
		.orderDeliveryStatus {
			ol {
				li {
					.boxIco {
						&:after {background-image:url(/assets/img/_order/orderDetails_spr1.png);background-size:413px auto;}
					}
					&.n01 { .boxIco { &:after {background-position:0 -84px;} } }
					&.n02 { .boxIco { &:after {background-position:-84px -84px;} } }
					&.n03 { .boxIco { &:after {background-position:-168px -84px;} } }
					&.n04 { .boxIco { &:after {background-position:-252px -84px;} } }
					&.n05 { .boxIco { &:after {background-position:-336px -84px;} } }
				}
			}
		}
	}
	.International {
		.orderDeliveryStatus {
			ol {
				li {
					.boxIco {
						&:after {background-image:url(/assets/img/_order/orderDetails_spr2.png);background-size:497px auto;}
					}
					&.n01 { .boxIco { &:after {background-position:0 -84px;} } }
					&.n02 { .boxIco { &:after {background-position:-84px -84px;} } }
					&.n03 { .boxIco { &:after {background-position:-168px -84px;} } }
					&.n04 { .boxIco { &:after {background-position:-252px -84px;} } }
					&.n05 { .boxIco { &:after {background-position:-84px -84px;} } }
					&.n06 { .boxIco { &:after {background-position:-336px -84px;} } }
				}
			}
		}
	}
	.DraftDialog {
		.draftOrder {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
			.prodImg {
				width:100px;box-sizing:border-box;border:1px solid #ddd;
				.imgContainer {
					display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
					img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
				}
			}
			.prodInfo {
				flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;padding:0 0 0 15px;
				.name {width:100%;font-size:17px;color:#333;padding:0 0 5px;margin:0 0 5px;border-bottom:1px solid #ddd;}
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;position:relative;
					dt,
					dd {line-height:1.2;box-sizing:border-box;position:relative;font-weight:400;}
					dt {
						padding:0 10px 0 0;margin:0 7px 0 0;
						&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:0;top:3px;font-size:13px;}
					}
					dd {color:#000;}
				}
				.qty {
					padding:0 12px 0 0;margin:0 10px 0 0;
					&:after {content:"";display:block;position:absolute;width:2px;height:14px;background:#ddd;right:0;top:2px;transform:rotate(30deg);}
				}
				.opt {}
			}
		}
	}
}

/* ==============================
* Add to Cart on Product detail page.
================================= */
.cartOnProductViewPage {
	.cartSumm {
		display:flex;flex-flow:row;flex-wrap:wrap;padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #ddd;
		.cartSummInfo {
			flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
			.boxImg {
				width:100px;box-sizing:border-box;border:1px solid #ddd;
				.imgContainer {
					display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
					img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
				}
			}
			.boxTit {flex:1;min-width:0;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;max-height:50px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.3;word-wrap:break-word;word-break:keep-all;white-space:normal;padding:0 15px;font-size:.882em;letter-spacing:-.05em;}
		}
		.cartSummLink {
			box-sizing:border-box;border-left:1px solid #ddd;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:right;padding:0 0 0 10px;
			a {
				display:block;;background:var(--color-1);color:#fff;border-radius:4px;line-height:1;padding:5px 10px 7px;
				span {display:block;line-height:1;}
			}
		}
	}
	.cartRelated {
		.cartRelatedTit {display:block;text-align:center;color:var(--color-1);}
		.cartRelatedCarousel {
			box-sizing:border-box;position:relative;
			.prev,
			.next {
				position:absolute;width:40px;height:40px;border:0;background:rgba(0, 0, 0, .3);z-index:1;border-radius:4px;top:50%;transform:translateY(-50%);display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;transition:all .3s ease;
				&.prev {left:15px;}
				&.next {right:15px;}
				i {color:#fff;}
				&:hover,
				&:focus-visible {background:rgba(0, 0, 0, .5);}
			}
			.carousel {
				overflow:hidden;width:100%;position:relative;margin:20px 0 0;position:relative;z-index:0;
				.carouselWrap {
					display:flex;transition:transform .5s ease-in-out;margin:0 -5px;
					.carouselItem {
						min-width:33.3333%;width:33.3333%;padding:0 10px;box-sizing:border-box;
						.product-img {
							display:block;background:transparent;width:100%;box-sizing:border-box;border:1px solid #ddd;
							.imgContainer {
								display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
							}
							&:hover,
							&:focus-visible {
								.imgContainer {
									img {transform:scale(1.2);}
								}
							}
						}
						.product-tags {
							margin:10px 0 0;
						}
						.product-title {
							display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:45px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;border:0;background:transparent;font-size:.882em;text-align:left;letter-spacing:-.05em;line-height:1.3;margin:5px 0 0;
							span {transition:all .3s ease;}
							&:hover,
							&:focus-visible {
								span {box-shadow:0 -10px 0 rgba(17, 101, 224, .25) inset;color:var(--color-1);}
							}
						}
						.product-price {}
					}
				}
			}
		}
	}
}