@charset "utf-8";

#main-container {}
#main-container .container {margin:0;max-width:none;width:100%;padding:0;}
#main-container .wrap1 {max-width:1400px;margin:0 auto}

/* ==============================
* Main Common
================================= */
.unitTit1 {
	display:block;font-size:35px;color:#010101;font-weight:700;text-align:center;line-height:1;
	@media all and (max-width:1099px) {
		font-size:30px;
	}
	@media all and (max-width:899px) {
		font-size:25px;
	}
	@media all and (max-width:599px) {
		font-size:20px;
	}
}
.unitPkg {
	.unitList {
		display:grid;padding:0;max-width:100%;grid-template-columns:repeat( 5, 1fr );column-gap:30px;row-gap:40px;
		.unit {
			box-sizing:border-box;min-width:0;
			.boxPkg {
				.boxImg {
					box-sizing:border-box;border:1px solid #c8c8c8;
					.imgContainer {
						display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;
						img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
					}
				}
				.boxTxt {
					line-height:1;margin:14px 0 0;
					.prodName {
						margin:6px 0 0;color:#444;line-height:1.2;display:-webkit-box;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:43px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;
						span {transition:all .3s ease;}
					}
					.prodPrice {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;margin:4px 0 0;
						.priceDc {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;font-size:26px;color:#3b3b3b;font-weight:900;
							.currency {font-size:16px;font-weight:400;margin:0 0 0 1px;}
						}
						.priceCost {font-size:14px;color:#444;text-decoration:line-through;margin:2px 0 0 11px;}
					}
					.prodRate {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;width:100%;margin:8px 0 0;
						.salesStar {
							font-size:0;letter-spacing:0;
							i {font-size:14px;color:#999;}
							&[data-count="1"] i:nth-child(-n+1),
							&[data-count="2"] i:nth-child(-n+2),
							&[data-count="3"] i:nth-child(-n+3),
							&[data-count="4"] i:nth-child(-n+4),
							&[data-count="5"] i:nth-child(-n+5) {color:#ffcc00;}
							* {font-size:0;line-height:1;top:auto}
						}
						.salesQuantity {font-size:13px;color:#b2b2b2;margin:0 0 0 3px;}
					}
					.prodDc {
						font-size:13px;color:#c00;margin:10px 0 0;line-height:1.2;
						> * {box-sizing:border-box;position:relative;}
						.priceFinal {
							padding:0 5px 0 0;margin:0 5px 0 0;
							&:after {content:"";display:block;position:absolute;width:1px;height:11px;background:#c00;right:0;top:4px;transform:rotate(20deg);}
						}
						.priceDc {
							&:after {content:"";display:block;position:absolute;width:100%;height:1px;left:0;bottom:-3px;background:#c00;}
						}
					}
				}
				&:focus-visible .boxImg .imgContainer img,
				&:hover .boxImg .imgContainer img {transform:scale(1.025);}
				&:focus-visible .boxTxt .prodName span,
				&:hover .boxTxt .prodName span {box-shadow:0 -12px 0 rgba(17, 101, 224, .1) inset}
			}
		}
	}
	@media all and (max-width:1399px) {
		.unitList {column-gap:20px;row-gap:30px;}
	}
	@media all and (max-width:1199px) {
		.unitList {grid-template-columns:repeat( 4, 1fr );}
	}
	@media all and (max-width:999px) {
		.unitList {grid-template-columns:repeat( 3, 1fr );}
	}
	@media all and (max-width:599px) {
		.unitList {grid-template-columns:repeat( 2, 1fr );}
	}
	@media all and (max-width:399px) {
		.unitList {gap:10px;}
	}
}
.unitMore {
	display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;width:100%;max-width:400px;line-height:1;box-sizing:border-box;border:1px solid #c8c8c8;background:#fff;border-radius:5px;padding:16px 15px 19px;color:#000;transition:all .3s ease;
	.txt {font-size:18px;margin:-2px 0 0;}
	.ico {font-size:25px;position:relative;right:0;transition:right .3s ease;}
	&:focus-visible,
	&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
	&:focus-visible .ico,
	&:hover .ico {right:-10px;}
}
.unitPkg2 {
	margin:65px 0 0;
	.unitList {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;margin:-28px -25px;
		.unit {
			width:33.3333%;box-sizing:border-box;padding:28px 25px;
			.boxImg {
				.imgContainer {
					display:block;height:0;padding:0 0 65.2173%;box-sizing:border-box;position:relative;background:#eee;overflow:hidden;border:0;border-radius:8px;
					img {display:block;position:absolute;width:100%;height:100%;top:0;left:0;transition:all .3s ease}
				}
				&:focus-visible .imgContainer img,
				&:hover .imgContainer img {transform:scale(1.025);}
			}
			.boxTxt {
				display:flex;flex-flow:row;flex-wrap:wrap;line-height:1;margin:10px 0 0;align-items:flex-start;justify-content:space-between;
				.infos {
					flex:1;box-sizing:border-box;padding:0 15px 0 0;
					.facName {
						font-size:18px;color:#000;line-height:1.2;display:-webkit-box;overflow:hidden;text-overflow:ellipsis;white-space:normal;max-height:43px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;
						span {transition:all .3s ease;}
						&:focus-visible span,
						&:hover span {box-shadow:0 -12px 0 rgba(17, 101, 224, .1) inset}
					}
					.facTags {
						margin:7px 0 0;
						ul {
							display:flex;flex-flow:row;flex-wrap:wrap;
							li {
								margin:0 7px 0 0;
								&:last-child {margin:0;}
								a {
									display:block;font-size:13px;color:#444;border-radius:3px;background:#eaeaea;padding:3px 5px 4px;font-weight:500;transition:all .3s ease;
									span {display:block;line-height:1;}
									&:focus-visible,
									&:hover {background:var(--color-1);color:#fff;}
								}
							}
						}
					}
				}
				.favorite {
					margin:5px 0 0;
					button {
						width:42px;height:42px;box-sizing:border-box;position:relative;border:1px solid #ababab;border-radius:50%;background:#fff;line-height:1;transition:all .3s ease;
						i {display:block;width:20px;height:20px;font-size:20px;position:absolute;line-height:1;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
						.off {color:#ababab;}
						.on {color:var(--color-1);opacity:0;}
						&:focus-visible,
						&:hover {background:#eaeaea;}
						&:focus-visible .off,
						&:hover .off {color:var(--color-1);}
						&.on .off {opacity:0;}
						&.on .on {opacity:1;}
					}
				}
			}
		}
	}
}
.temporaryBox {max-width:1400px;margin:0 auto;}

/* ==============================
* Main Section.1
================================= */
#mSection01 {
	box-sizing:border-box;position:relative;
	.swiper-button-prev,
	.swiper-button-next {
		width:50px;height:50px;box-sizing:border-box;border:0;margin:0;transform:translateY(-50%);background:rgba(0, 0, 0, .2);border-radius:5px;transition:all .3s ease;
		&.swiper-button-prev {left:20px;}
		&.swiper-button-next {right:20px;}
		i {font-size:40px;color:#fff;position:relative;line-height:1;}
		&:after {display:none;}
		&:hover,
		&:focus-visible {background:rgba(0, 0, 0, .5);}
	}
	.swiper-button-prev i,
	.swiper-button-next i {}
	.boxPkg {
		.boxTxt {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;position:absolute;width:100%;height:100%;z-index:1;color:#fff;text-align:center;width:100%;max-width:1400px;left:50%;top:0;transform:translateX(-50%);box-sizing:border-box;padding:20px;
			.txtPkg {
				line-height:1;margin:-50px 0 0;
				.txt0 {position:absolute;width:100%;left:0;top:50px;text-align:center;color:#fff;letter-spacing:0;opacity:.5;}
				.txt1 {font-size:22px;letter-spacing:-.05em;font-weight:400;text-shadow:0 0 3px rgba(0, 0, 0, .5);transition:all .3s ease 0s;}/* transform:translateY(20px);opacity:0; */
				.txt2 {
					font-weight:700;margin:50px 0 0;text-shadow:0 0 5px rgba(0, 0, 0, .5), 0 5px 5px rgba(0, 0, 0, .25);
					.p1 {font-size:78px;margin:0 0 15px;transition:all .3s ease .2s;}/* transform:translateY(20px);opacity:0; */
					.p2 {font-size:70px;transition:all .3s ease .4s;}/* transform:translateY(20px);opacity:0; */
					.i1 {display:block;width:auto;height:120px;margin:0 auto 20px;}
					.l1 {
						margin:40px 0 0;transition:all .3s ease .6s;/* transform:translateY(20px);opacity:0; */
						ul {
							display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;margin:-5px;
							li {
								box-sizing:border-box;line-height:1;padding:5px;
								&:last-child {margin:0;}
								span {display:block;font-size:17px;line-height:1;color:#fff;text-shadow:none;font-weight:400;letter-spacing:-.05em;border-radius:3px;background:#374ea2;padding:5px 10px 7px;}
							}
						}
					}
				}
			}
		}
		.boxImg {
			height:638px;box-sizing:border-box;position:relative;z-index:0;
			img {display:block;position:absolute;width:auto;height:100%;left:50%;top:0;transform:translateX(-50%);}
		}
	}
	.swiper-slide-active {
		.txt1,
		.p1,
		.p2,
		.l1 {transform:translateY(0px) !important;opacity:1 !important;}
	}
	.swiper-pagination-wrap {
		position:absolute;left:50%;bottom:0;transform:translateX(-50%);width:100%;max-width:1400px;z-index:2;
		.swiper-auto {
			width:25px;height:25px;position:absolute;z-index:1;border:0;box-sizing:border-box;background:rgba(0, 0, 0, .5);left:50%;transform:translateX(-50%);bottom:70px;border-radius:5px;transition:all .3s ease;
			&:hover,
			&:focus-visible {background:#000;}
			&:after {content:"";display:block;position:absolute;box-sizing:border-box;border:solid;top:0;right:0;bottom:0;left:0;margin:auto;}
			&.swiper-stop {
				&:after {width:8px;height:11px;border-width:0 2px;border-color:#fff;}
			}
			&.swiper-play {
				&:after {width:0;height:0;border-width:6px 0 6px 6px;border-color:transparent transparent transparent #fff;}
			}
		}
		.swiper-pagination {
			display:flex;flex-flow:row;flex-wrap:wrap;position:relative;bottom:0;
			li {
				flex:1;width:auto;height:auto;background:transparent;border-radius:0;
				button {
					display:flex;flex-flow:row;flex-wrap:wrap;height:51px;align-items:center;justify-content:center;width:100%;box-sizing:border-box;padding:0 15px;border:1px solid #b2b2b2;border-right:0;line-height:1;transition:all .3s ease;
					span {font-size:18px;letter-spacing:-.05em;line-height:1.2;display:-webkit-box;overflow:hidden;text-overflow:ellipsis;white-space:normal;max-height:43px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-wrap:break-word;word-break:keep-all;white-space:normal}
					&:focus-visible,
					&:hover {background:var(--color-1);border-color:var(--color-1) !important;color:#fff}
				}
				&.swiper-pagination-bullet {opacity:1;}
				&.swiper-pagination-bullet-active {
					button {background:#3b3b3b;border-color:#3b3b3b !important;color:#fff}
				}
				&:last-child button {border-right:1px solid #b2b2b2;}
			}
		}
	}
	@media all and (max-width:1440px) {
		.swiper-pagination-wrap {
			left:0;transform:none;max-width:none;padding:0 20px;
		}
	}
	@media all and (max-width:1399px) {
		.swiper-button-prev {left:5px;}
		.swiper-button-next {right:5px;}
		.boxPkg {
			.boxTxt {
				.txtPkg {
					margin:-50px 0 0;
					.txt1 {font-size:17px;}
					.txt2 {
						margin:20px 0 0;
						.p1 {font-size:50px;}
						.p2 {font-size:45px;}
						.i1 {height:100px;margin:0 auto 15px;}
						.l1 {
							ul {
								li {
									span {font-size:14px;padding:5px 10px 6px;}
								}
							}
						}
					}
				}
			}
			.boxImg {height:500px;}
		}
		.swiper-pagination-wrap {
			.swiper-pagination {
				li {
					button {
						span {font-size:16px;max-height:40px;}
					}
				}
			}
		}
	}
	@media all and (max-width:1099px) {
		.boxPkg {
			.boxTxt {
				.txtPkg {
					margin:-50px 0 0;
					.txt0 {top:30px;}
					.txt1 {font-size:16px;}
					.txt2 {
						margin:15px 0 0;
						.p1 {font-size:40px;margin:0 0 10px;}
						.p2 {font-size:35px;}
						.i1 {height:80px;margin:0 auto 10px;}
						.l1 {
							margin:15px 0 0;
							ul {
								li {
									span {font-size:12px;padding:3px 5px 4px;}
								}
							}
						}
					}
				}
			}
			.boxImg {height:400px;}
		}
		.swiper-pagination-wrap {
			.swiper-auto {bottom:50px;}
			.swiper-pagination {
				li {
					button {
						height:35px;
						span {font-size:14px;max-height:34px;}
					}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		.swiper-button-prev,
		.swiper-button-next {width:30px;height:30px;}
		.boxPkg {
			.boxTxt {
				.txtPkg {
					margin:-50px 0 0;
					.txt0 {top:20px;font-size:15px;}
					.txt1 {font-size:15px;}
					.txt2 {
						font-size:30px;margin:10px 0 0;
						.p1 {font-size:30px;margin:0 0 5px;}
						.p2 {font-size:25px;}
					}
				}
			}
			.boxImg {height:350px;}
		}
	}
	@media all and (max-width:799px) {
		.swiper-button-prev,
		.swiper-button-next {
			i {font-size:20px;}
			&.swiper-button-prev {left:10px;}
			&.swiper-button-next {right:10px;}
		}
		.boxPkg {
			.boxTxt {
				padding:20px 50px;
				.txtPkg {
					margin:0;
					.txt0 {font-size:13px;}
					.txt1 {font-size:14px;}
					.txt2 {
						margin:10px 0 0;
						.p1 {font-size:26px;}
						.p2 {font-size:20px;}
						.i1 {height:60px;}
						.l1 {
							margin:10px 0 0;
							ul {
								margin:-3px;
								li {
									padding:3px;
									span {font-size:11px;padding:3px 5px 4px;}
								}
							}
						}
					}
				}
			}
			.boxImg {height:280px;}
		}
		.swiper-pagination-wrap {
			left:0;transform:none;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;bottom:10px;
			.swiper-auto {
				width:20px;height:20px;bottom:auto;position:relative;left:auto;transform:none;
				&.swiper-stop {
					&:after {width:6px;height:8px;}
				}
				&.swiper-play {
					&:after {border-width:4px 0 4px 4px;}
				}
			}
			.swiper-pagination {
				display:flex;flex-flow:row;flex-wrap:wrap;position:relative;bottom:auto;margin:0 0 0 10px;
				li {
					button {
						display:block;width:18px;height:18px;padding:0;border:0;box-sizing:border-box;position:relative;background:transparent;
						&:after {content:"";display:block;position:absolute;width:10px;height:10px;border-radius:50%;top:0;right:0;bottom:0;left:0;margin:auto;background:#fff;box-shadow:3px 3px 5px rgba(0, 0, 0, .5);transition:all .3s ease;}
						span {display:block;font-size:0;line-height:1;}
						&:focus-visible,
						&:hover {background:transparent;}
					}
					&.swiper-pagination-bullet-active {
						button {
							background:transparent;
							&:after {background:var(--color-1);border:0;}
						}
					}
					&:last-child button {border-right:0;}
				}
			}
		}
	}
	@media all and (max-width:699px) {
		.boxPkg {
			.boxTxt {
				.txtPkg {
					.txt0 {font-size:12px;}
					.txt1 {font-size:13px;}
					.txt2 {
						.i1 {height:50px;}
					}
				}
			}
			.boxImg {height:230px;}
		}
	}
	@media all and (max-width:599px) {
		.boxPkg {
			.boxTxt {
				.txtPkg {
					.txt0 {top:15px;}
					.txt2 {
						.i1 {height:40px;margin:0 auto 5px;}
					}
				}
			}
			.boxImg {height:200px;}
		}
	}
	@media all and (max-width:499px) {
		.boxPkg {
			.boxTxt {
				.txtPkg {
					margin:-20px 0 0;
					.txt0 {display:none;}
					.txt1 {font-size:12px;}
					.txt2 {
						.p1 {font-size:18px;}
						.p2 {font-size:16px;}
						.i1 {height:35px;}
						.l1 {
							ul {
								margin:-2px;
								li {
									padding:2px;
									span {font-size:10px;padding:3px 5px 4px;}
								}
							}
						}
					}
				}
			}
			.boxImg {height:200px;}
		}
	}
	@media all and (max-width:459px) {
		.boxPkg {
			.boxImg {height:160px;}
		}
		.swiper-pagination-wrap {bottom:5px;}
	}
	@media all and (max-width:399px) {
		.swiper-button-prev,
		.swiper-button-next {top:auto;bottom:10px;transform:none;width:25px;height:25px;display:none;}
		.boxPkg {
			.boxTxt {
				padding:20px 10px;
				.txtPkg {
					.txt2 {
						.i1 {height:30px;}
					}
				}
			}
		}
	}
	@media all and (max-width:359px) {
		.boxPkg {
			.boxTxt {
				.txtPkg {
					margin:-20px 0 0;
					.txt0 {display:none;}
					.txt1 {font-size:11px;}
					.txt2 {
						margin:5px 0 0;
						.p1 {font-size:16px;}
						.p2 {font-size:15px;}
						.l1 {
							ul {
								margin:-1px;
								li {
									padding:1px;
									span {padding:2px 3px 3px;}
								}
							}
						}
					}
				}
			}
			.boxImg {height:140px;}
		}
	}
}

/* ==============================
* Main Section.2
================================= */
#mSection02 {
	width:100%;max-width:1400px;margin:50px auto 0;
	.unitWr {
		display:flex;flex-flow:row;flex-wrap:wrap;margin:0 -25px;
		.unit {
			flex:1;min-width:0;box-sizing:border-box;padding:0 25px;
			.boxPkg {
				.boxImg {
					border-radius:8px;overflow:hidden;
					img {display:block;width:100%;transition:all .3s ease;}
				}
				&:focus-visible .boxImg img,
				&:hover .boxImg img {transform:scale(1.025);}
			}
		}
	}
	@media all and (max-width:1440px) {
		width:auto;margin:40px 20px 0;
	}
	@media all and (max-width:1199px) {
		.unitWr {
			margin:0 -10px;
			.unit {padding:0 10px;}
		}
	}
	@media all and (max-width:999px) {
		width:auto;margin:20px 20px 0;
	}
	@media all and (max-width:699px) {
		.unitWr {
			margin:0;
			.unit {
				flex:none;width:100%;padding:0;margin:0 0 20px;
				&:last-child {margin:0;}
			}
		}
	}
}

/* ==============================
* Main Section.3 : MD RECOMMENDED
================================= */
#mSection03 {
	width:100%;max-width:1400px;margin:40px auto 0;
	.unitPkg {margin:30px 0 0;justify-content:center;}
	.unitMore {margin:30px auto 0;}
	@media all and (max-width:1440px) {
		width:auto;margin:30px 20px 0;
		.unitMore {margin:30px auto 0;}
	}
	@media all and (max-width:999px) {
		width:auto;margin:20px 20px 0;
		.unitPkg {margin:20px 0 0;}
		.unitMore {margin:20px auto 0;}
	}
	@media all and (max-width:699px) {
		.unitPkg {margin:20px 0 0;}
		.unitMore {width:100%;max-width:none;margin:20px auto 0;}
	}
}

/* ==============================
* Main Section.5
================================= */
#mSection05 {
	width:100%;max-width:1400px;margin:50px auto 0;overflow:hidden;
	.sidePkg {
		display:flex;flex-flow:row;flex-wrap:wrap;margin:0 -25px;
		> * {
			width:50%;box-sizing:border-box;padding:0 25px;position:relative;
			.desc {
				position:relative;border-radius:8px;overflow:hidden;
				.txts {
					position:absolute;width:100%;line-height:1;left:0;top:50%;transform:translateY(-50%);z-index:1;color:#fff;box-sizing:border-box;padding:0 50px;word-wrap:break-word;word-break:keep-all;white-space:normal;
					.pTxt1 {font-size:32px;text-shadow:3px 3px 3px rgba(0, 0, 0, .8);font-weight:500;letter-spacing:-.05em;line-height:1.25;}
					.pTxt2 {font-size:15px;line-height:1.5;margin:20px 0 0;}
				}
				.base {display:block;width:100%;position:relative;z-index:0;}
			}
			.swiperPkg {
				box-sizing:border-box;position:relative;
				.swiper-container {
					box-sizing:border-box;border-radius:8px;overflow:hidden;
					.swiper-button-prev {left:10px;}
					.swiper-button-next {right:10px;}
					.swiper-button-prev i,
					.swiper-button-next i {font-size:35px}
					.boxPkg {
						display:block;box-sizing:border-box;
					}
				}
				.swiper-pagination-wrap {
					position:absolute;width:100%;left:0;bottom:15px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;
					.swiper-auto {
						width:12px;height:12px;position:relative;z-index:1;border:0;box-sizing:border-box;position:relative;background:transparent;
						&:after {content:"";display:block;position:absolute;box-sizing:border-box;border:solid;top:0;right:0;bottom:0;left:0;margin:auto;}
						&.swiper-stop {
							&:after {width:8px;height:11px;border-width:0 2px;border-color:#fff;}
						}
						&.swiper-play {
							&:after {width:0;height:0;border-width:6px 0 6px 6px;border-color:transparent transparent transparent #fff;}
						}
					}
					.swiper-pagination {
						position:relative;width:auto;bottom:0;font-size:0;line-height:1;margin:0 0 0 5px;
						.swiper-pagination-bullet {
							width:auto;height:auto;line-height:1;font-size:0;opacity:1;margin:0 2px;background:transparent;
							button {width:12px;height:12px;box-sizing:border-box;border:0;border-radius:50%;background:rgba(0, 0, 0, .25);box-shadow:0 2px 2px rgba(0, 0, 0, .25) inset;transition:all .3s ease;}
							&.swiper-pagination-bullet-active {
								background:transparent;
								button {background:#fff;box-shadow:0 2px 2px rgba(0, 0, 0, .25)}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1440px) {
		width:auto;margin:40px 20px 0;
	}
	@media all and (max-width:1199px) {
		.sidePkg {
			margin:0 -10px;
			> * {
				padding:0 10px;
				.desc {
					.txts {
						padding:0 20px;
						.pTxt1 {font-size:26px;}
						.pTxt2 {font-size:14px;margin:15px 0 0;}
					}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		width:auto;margin:40px 20px 0;
	}
	@media all and (max-width:899px) {
		.sidePkg {
			> * {
				.desc {
					.txts {
						.pTxt1 {font-size:20px;}
						.pTxt2 {font-size:14px;margin:10px 0 0;}
					}
				}
			}
		}
	}
	@media all and (max-width:799px) {
		.sidePkg {
			> * {
				.desc {
					.txts {
						br {display:none;}
						.pTxt1 {font-size:16px;}
						.pTxt2 {font-size:13px;}
					}
				}
			}
		}
	}
	@media all and (max-width:699px) {
		width:auto;margin:20px 20px 0;
		.sidePkg {
			margin:0;
			> * {
				width:100%;padding:0;margin:0 0 20px;
				&:last-child {margin:0;}
				.desc {
					min-height:137px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:left;
					.txts {
						position:relative;top:auto;transform:none;padding:20px;
						.pTxt1 {font-size:20px;}
						.pTxt2 {font-size:14px;margin:5px 0 0;}
					}
					.base {position:absolute;width:100%;left:0;top:50%;transform:translateY(-50%);}
				}
			}
		}
	}
	@media all and (max-width:499px) {
		.sidePkg {
			> * {
				.desc {
					min-height:0;
					.txts {
						.pTxt1 {font-size:18px;}
						.pTxt2 {font-size:14px;margin:10px 0 0;}
					}
				}
			}
		}
	}
	@media all and (max-width:399px) {
		.sidePkg {
			> * {
				.desc {
					.txts {
						.pTxt1 {font-size:16px;}
						.pTxt2 {font-size:13px;margin:5px 0 0;}
					}
				}
				.base {width:auto;height:100%;left:50%;transform:translate(-50%, 0);top:0;}
			}
		}
	}
}

/* ==============================
* Main Section.4 : POPULAR ITEM
================================= */
#mSection04 {
	width:100%;max-width:1400px;margin:50px auto 0;
	.unitWr {
		display:flex;flex-flow:row;flex-wrap:wrap;margin:30px -25px 0;
		.unit {
			flex:1;min-width:0;box-sizing:border-box;padding:0 25px;
			.boxPkg {
				.boxImg {
					border-radius:8px;overflow:hidden;
					img {display:block;width:100%;transition:all .3s ease;}
				}
				&:focus-visible .boxImg img,
				&:hover .boxImg img {transform:scale(1.025);}
			}
		}
	}
	.unitPkg {
		margin:10px 0 0;position:relative;
		.tab-btns {
			position:sticky;left:0;top:90px;background:#fff;padding:20px 0;z-index:1;
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;width:100%;
				&::-webkit-scrollbar {height:8px;}
				&::-webkit-scrollbar-thumb {background:#ddd;border-top:5px solid #fff;}
				&::-webkit-scrollbar-track {background:#fff;border-top:5px solid #fff;}
				&::-webkit-scrollbar-button {width:0;height:0;}
				li {
					flex:1;min-width:0;box-sizing:border-box;padding:0 5px;
					&:first-child {padding-left:0;}
					&:last-child {padding-right:0;}
					> * {
						display:block;padding:7px 5px;box-sizing:border-box;border:1px solid #ededed;background:#fff;transition:all .3s ease;
						.txt {display:block;line-height:1.2;white-space:nowrap;font-weight:500;letter-spacing:-.05em;font-size:14px;text-align:center;}
						&:focus-visible,
						&:hover {background:var(--color-1);color:#fff;border-color:var(--color-1);}
					}
					&.on {
						> * {background:var(--color-1);color:#fff;border-color:var(--color-1);}
					}
				}
			}
		}
		.tab-content {
			margin:20px 0 0;position:relative;z-index:0;
			.tab-units-wr {
				margin:0 0 35px;
				&:last-child {margin:0;}
				.tab-units-header {
					padding:0 0 20px;
					.anchor {position:absolute;left:0;top:-80px;width:0;height:0;}
					h3 {font-size:1.470em;color:#000;box-sizing:border-box;position:relative;}
				}
				.tab-units {
					display:flex;flex-flow:row;flex-wrap:wrap;
					.tab-img-box {
						width:38.3423%;height:100%;box-sizing:border-box;position:relative;overflow:hidden;
						a {
							display:block;background:#000;
							.str {
								position:absolute;z-index:1;width:100%;box-sizing:border-box;padding:0 30px 30px;left:0;bottom:0;font-size:45px;line-height:1;
								&.White {color:#fff;text-shadow:2px 2px 3px rgba(0, 0, 0, .2);}
								&.Black {color:#000;text-shadow:2px 2px 3px rgba(255, 255, 255, .2);}
							}
							.tab-img {display:block;width:100%;position:relative;z-index:0;opacity:.9;transition:all .5s ease;}
							&:hover,
							&:focus-visible {
								.tab-img {transform:scale(1.1);opacity:1;}
							}
						}
					}
					.tab-items {
						flex:1;min-width:0;height:auto;box-sizing:border-box;padding:0 0 0 40px;display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;text-align:center;
						.prodList {
							display:grid;grid-template-columns:repeat(3, 1fr);gap:20px;margin:0 0 40px;flex:1;min-height:0;
							.unit {
								width:100%;min-width:0;text-align:left;
								.noDataWrap {margin:auto;}
							}
						}
						.prodMore {height:62px;margin:0 auto;}
						.itemWr {
							height:100%;
							/* .loadingWrap {align-items:flex-start;padding:100px 0 0;} */
						}
					}
				}
			}
			.prodRate {}
		}
	}
	@media all and (max-width:1440px) {
		width:auto;margin:50px 20px 0;
	}
	@media all and (max-width:1499px) {
		.unitPkg {
			.tab-btns {
				ul {
					flex-wrap:nowrap;overflow-x:auto;justify-content:left;margin:0;
					li {
						flex:0 0 auto;
						&:first-child {padding-left:0;}
						&:last-child {padding-right:0;}
						a {
							padding:5px;
							.txt {}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1299px) {
		.unitPkg {
			.tab-content {
				.tab-units-wr {
					.tab-units {
						.tab-img-box {}
						.tab-items {padding:0 0 0 20px;}
					}
				}
			}
		}
	}
	@media all and (max-width:1199px) {
		.unitWr {
			margin:40px -10px 0;
			.unit {padding:0 10px;}
		}
		.unitPkg {
			.tab-content {
				.tab-units-wr {
					.tab-units {
						.tab-img-box {
							.str {
								padding:0 20px 20px;font-size:35px;
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1099px) {
		.unitPkg {
			.tab-content {
				.tab-units-wr {
					.tab-units {
						.tab-img-box {width:30%;}
						.tab-items {}
					}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		width:auto;margin:30px 20px 0;
		.unitPkg {
			.tab-content {
				.tab-units-wr {
					.tab-units {
						.tab-img-box {
							.str {
								padding:0 15px 15px;font-size:20px;
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:899px) {
		.unitPkg {
			.tab-content {
				.tab-units-wr {
					.tab-units {
						display:block;
						.tab-img-box {
							width:100%;height:0;padding:0 0 20%;margin:0 0 20px;box-sizing:border-box;
							.tab-img {position:absolute;left:0;top:50%;transform:translateY(-50%);}
						}
						.tab-items {
							padding:0;
							.prodList {grid-template-columns:repeat(3, 1fr);gap:20px;margin:0 0 30px;}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:699px) {
		.unitWr {
			margin:20px 0 0;
			.unit {
				flex:none;width:100%;padding:0;margin:0 0 20px;
				&:last-child {margin:0;}
			}
		}
		.unitPkg {
			margin:10px 0 0;
			.tab-content {
				margin:20px 0 0;
				.tab-units-wr {
					margin:0 0 30px;
					.tab-units-header {
						padding:0 0 10px;
						h3 {font-size:1.2em;}
					}
					.tab-units {
						display:block;
						.tab-img-box {padding:0 0 30%;}
						.tab-items {
							padding:0;margin:20px 0 0;
							.prodList {margin:0 0 15px;}
							.prodMore {width:100%;max-width:none;}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:599px) {
		.unitPkg {
			.tab-content {
				margin:0;
				.tab-units-wr {
					.tab-units {
						.tab-items {
							.prodList {grid-template-columns:repeat(2, 1fr);}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:399px) {
		.unitPkg {
			.tab-content {
				.tab-units-wr {
					.tab-units {
						.tab-items {
							.prodList {gap:10px}
						}
					}
				}
			}
		}
	}
}
html[lang='ko'] {
	#mSection04 {
		.unitPkg {
			.tab-btns {
				ul {
					li {
						> * {
							.txt {}
						}
					}
				}
			}
		}
	}
}
html[lang='en'] {
	#mSection04 {
		.unitPkg {
			.tab-btns {
				ul {
					flex-wrap:nowrap;overflow-x:auto;
					li {
						flex:none;
						> * {
							.txt {}
						}
					}
				}
			}
		}
	}
}
html[lang='zh'] {
	#mSection04 {
		.unitPkg {
			.tab-btns {
				ul {
					li {
						flex-basis:auto;
						> * {
							.txt {}
						}
					}
				}
			}
		}
	}
}

/* ==============================
* Main Section.6
================================= */
#mSection06 {
	width:100%;max-width:1400px;margin:0 auto 150px;
	.tagsPkg {
		.tagsList {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;margin:32px 0 0;
			.tag {
				margin:0 15px 0 0;
				&:last-child {margin:0;}
				a {
					display:block;line-height:1;font-size:16px;color:var(--color-1);border-radius:999px;border:1px solid var(--color-1);padding:6px 15px 8px;transition:all .3s ease;
					span {display:block;line-height:1.2;}
					&:focus-visible,
					&:hover {background:var(--color-1);color:#fff;}
				}
			}
		}
	}
	.bannersPkg {
		.bannersList {
			margin:57px 0 0;
			.banner {
				margin:0 0 15px;
				&:last-child {margin:0;}
				a {
					display:block;line-height:1;font-size:0;border-radius:8px;border:0;overflow:hidden;
					img {display:block;width:100%;transition:all .3s ease;}
					&:focus-visible img,
					&:hover img {transform:scale(1.025);}
				}
			}
		}
	}
	.unitPkg {margin:58px 0 0;justify-content: center;}
	.unitMore {margin:100px auto 0;}
}

/* ==============================
* Main Section.7
================================= */
#mSection07 {
	width:auto;max-width:1400px;margin:100px auto 143px;box-sizing:border-box;position:relative;
	&:after {content:"";display:block;position:absolute;width:2000px;height:350px;left:50%;transform:translateX(-50%);top:0;z-index:-1;background:url(/assets/img/_home/mSec7_bg1.jpg)}
	.unitTit2 {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;padding:58px 0 0;
		.img {display:block;width:175px;margin:0 auto}
		.txt {display:block;line-height:1.2;color:#fff;font-size:35px;width:100%;font-weight:700;text-align:center;margin:7px 0 0;}
	}
	.unitMore {margin:100px auto;}
	@media all and (max-width:1440px) {
		margin:0 0 143px;padding:0 20px;overflow:hidden;max-width:1524px;
	}
}