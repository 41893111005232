@charset "utf-8";

/* ==============================
* html & body & root
================================= */
body {background:#fff;overflow-x:hidden;}
#root {min-width:320px;}
#wrap {
	margin:0 auto;font-size:17px;letter-spacing:-.025em;line-height:1.5;
	@media all and (max-width:999px) {
		font-size:16px;line-height:1.4;
	}
	@media all and (max-width:799px) {
		font-size:15px;line-height:1.3;
	}
	@media all and (max-width:599px) {
		font-size:14px;
	}
}
.flone-preloader-wrapper {opacity:0 !important}
#wrap {
	&.stickyActive {
		#header-wrap {
			background:rgba(255, 255, 255, .9);backdrop-filter:blur(5px);
			&.opa0 {opacity:0;}
			&.zid0 {z-index:-1;}
			.pkgs {
				#head {
					&:before {opacity:1;}
					.container {
						padding:20px 0;
						.row1 {
							.boxLogo {width:200px;}
							.boxSearch {margin:0 auto 0 20px;}
							.boxIcon {margin:0 0 0 100px;}
						}
						.row2 {
							overflow:hidden;margin:0;
							.depth1-ul {
								li {
									button,
									a {height:0 !important;border:0 !important;opacity:0;}
								}
							}
						}
					}
					.hideNav {display:none !important;}
				}
			}
		}
		.shop-area {
			.itemPkg {
				.itemFilter {
					.sidebar-style {top:90px;height:calc( 100vh - 90px );}
				}
			}
		}
	}
}

/* ==============================
* LAYER-POPUP WRAP
================================= */
#popupLayer {position:fixed;width:100%;height:0;left:0;top:0;display:flex;flex-flow:row;flex-wrap:wrap;z-index:9999;align-items:center;align-content:center;justify-content:center;opacity:0;padding:20px;box-sizing:border-box;font-size:17px;transition:opacity .5s ease}
#popupLayer input,
#popupLayer textarea,
#popupLayer select {font-family:"Noto Sans KR", "Noto Sans SC", "맑은고딕","돋움",arial,Dotum,Verdana,helvetica,clean,sans-serif !important;}
#popupLayer.show {height:100%;opacity:1}
#popupLayer.hide {opacity:0}
#popupWrap {max-width:100%;max-height:100%;position:relative;top:-50px;opacity:0;z-index:1;transition:top .5s ease, opacity .5s ease, width .5s ease, height .5s ease}
#popupWrap.show {top:0;opacity:1}
#popupOverlay {position:absolute;width:100%;height:100%;left:0;top:0;z-index:0;background:rgba(0, 0, 0, .5)}
#popupWrap:before,
#popupWrap:after {content:"";display:block;position:absolute;width:50%;height:50px;bottom:-4px;filter:blur(10px);background:rgba(0, 0, 0, .5);z-index:-1}
#popupWrap:before {left:10px;transform:rotate(-2deg) skew(-15deg)}
#popupWrap:after {right:10px;transform:rotate(2deg) skew(15deg)}
#popupWrap .popWrap {border-radius:10px;overflow:hidden;position:relative;height:100%}
#popupWrap .popHead {position:relative;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;z-index:1;background:var(--color-1);height:60px;padding:0 20px}
#popupWrap .popHead .popHeadTit {display:block;width:100%;font-size:1.25em;font-weight:500;letter-spacing:-.05em;line-height:1.2;color:#fff}
#popupWrap.has1Btn .popHead .popHeadTit {max-width:calc( 100% - 60px )}
#popupWrap.has2Btn .popHead .popHeadTit {max-width:calc( 100% - 120px )}
#popupWrap.has3Btn .popHead .popHeadTit {max-width:calc( 100% - 180px )}
#popupWrap .popCont {position:relative;z-index:1;background:#fff;box-sizing:border-box;padding:20px;overflow:auto;height:calc( 100% - 60px )}
#popupWrap .popCont .popContScrollBox {height:100%;overflow-x:hidden;overflow-y:auto}
#popupWrap .popFoot {display:flex;flex-flow:row;flex-wrap:wrap;position:absolute;right:0;top:0;z-index:9}
#popupWrap .popFoot button {width:60px;height:60px;box-sizing:border-box;position:relative;border:0;background:rgba(0, 0, 0, .5);transition:all .3s ease}
#popupWrap .popFoot button:focus-visible,
#popupWrap .popFoot button:hover {background:rgba(0, 0, 0, .75)}
#popupWrap .popFoot button .txt {}
#popupWrap .popFoot button .ico {color:#fff;font-size:24px}
#popupWrap .popFoot .popBtnClose .ico {font-size:32px}
#popupWrap .popFoot .popBtnReload .ico {}
#popupWrap .popFoot .popBtnMinsize .ico {}
#popupWrap .popFoot .popBtnMaxsize .ico {}
@media all and (max-width:999px) {
	#popupLayer {font-size:16px}
}
@media all and (max-width:799px) {
	#popupLayer {font-size:15px}
	#popupWrap .popHead {height:40px}
	#popupWrap .popHead .popHeadTit {font-size:1.1em;line-height:1.1}
	#popupWrap.has1Btn .popHead .popHeadTit {max-width:calc( 100% - 40px )}
	#popupWrap.has2Btn .popHead .popHeadTit {max-width:calc( 100% - 80px )}
	#popupWrap.has3Btn .popHead .popHeadTit {max-width:calc( 100% - 120px )}
	#popupWrap .popCont {height:calc( 100% - 40px )}
	#popupWrap .popFoot button {width:40px;height:40px}
	#popupWrap .popFoot button .ico {font-size:18px}
	#popupWrap .popFoot .popBtnClose .ico {font-size:24px}
}
@media all and (max-width:499px) {
	#popupWrap .popHead .popHeadTit {}
	#popupWrap.has1Btn .popHead .popHeadTit {max-width:100%}
	#popupWrap.has2Btn .popHead .popHeadTit {max-width:100%}
	#popupWrap.has3Btn .popHead .popHeadTit {max-width:100%}
	#popupWrap .popCont {padding:20px 20px 40px}
	#popupWrap .popFoot {top:auto;bottom:0}
	#popupWrap .popFoot button {background:transparent}
	#popupWrap .popFoot button:focus-visible,
	#popupWrap .popFoot button:hover {background:transparent}
	#popupWrap .popFoot button .ico {color:#555}
}

#container-wrap {
	position:relative;z-index:1;min-height:600px;
	.wing {
		position:absolute;z-index:1;
		.poss {position:sticky;box-sizing:border-box;}
	}
	.sideL-banner {
		right:50%;width:181px;box-sizing:border-box;margin:0 calc( 1484px - ( 1484px / 2 ) + 20px ) 0 0;
		.poss {
			a {
				display:block;box-sizing:border-box;position:relative;border:1px solid #c8c8c8;border-radius:8px;overflow:hidden;margin:0 0 21px;
				img {display:block;width:100%;transition:all .3s ease;}
				&:last-child {margin:0;}
				&:focus-visible img,
				&:hover img {transform:scale(1.1);}
			}
		}
	}
	.sideR-link {
		left:50%;width:81px;box-sizing:border-box;margin:0 0 0 calc( 1484px - ( 1484px / 2 ) + 20px );
		ul {
			li {
				margin:0 0 15px;
				&:last-child {margin:0;}
				a {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;box-sizing:border-box;position:relative;width:80px;height:80px;border:1px solid #c8c8c8;background:#fff;border-radius:50%;color:#3b3b3b;transition:all .3s ease;
					.ico {font-size:40px;}
					.txt {font-size:0;display:block;position:absolute;line-height:1;width:0;height:0;overflow:hidden;}
					&:focus-visible,
					&:hover {border-color:var(--color-1);background:var(--color-1);color:#fff;box-shadow:0 5px 10px rgba(0, 0, 0, .25)}
				}
			}
		}
	}
	&.main {
		.wing {
			top:calc( 688px - 20px );height:calc( 100% - 638px - 50px );
			.poss {padding:20px 0 0;top:91px;}
		}
	}
	&.sub {
		.wing {
			top:12px;height:100%;
			.poss {padding:20px 0 0;top:91px;}
		}
	}
	@media all and (max-width:1724px) {
		.sideR-link {
			left:auto;right:20px;width:auto;margin:0;
			ul {
				li {
					margin:0 0 10px;
					&:last-child {margin:0;}
					a {
						width:40px;height:40px;box-shadow:0 3px 3px rgba(0, 0, 0, .1);
						.ico {font-size:20px;}
						&:focus-visible,
						&:hover {border-color:var(--color-1);background:var(--color-1);color:#fff;box-shadow:none}
					}
				}
			}
		}
	}
	@media all and (max-width:1440px) {
		.sideL-banner {display:none;}
		&.main,
		&.sub {
			.wing {
				top:50%;padding:0;height:auto;transform:translateY(-50%);position:fixed;
				.poss {padding:0;}
			}
		}
	}
	@media all and (max-width:1099px) {
		.sideR-link {
			ul {
				box-shadow:0 3px 3px rgba(0, 0, 0, .1);box-sizing:border-box;border:1px solid #ddd;border-radius:999px;overflow:hidden;
				li {
					margin:0;box-sizing:border-box;border-bottom:1px solid #ddd;
					&:last-child {border-bottom:0;}
					a {
						width:30px;height:30px;border-radius:0;border:0;
						.ico {font-size:16px;}
						&:focus-visible,
						&:hover {border-color:var(--color-1);background:var(--color-1);color:#fff;box-shadow:none}
					}
				}
			}
		}
	}
	@media all and (max-width:799px) {
		.wing {display:none;}
	}
}

/* ==============================
* Withdraw alert.
================================= */
.sweetalert-overflow-hidden {
	#footer-wrap {z-index:-1;}
}

/* ==============================
* Modal popup open. (KakaoZipFinder)
================================= */
body.divDialogOpen #container-wrap,
body.payModalCasted #container-wrap,
body.modal-KakaoZipFinder-open #container-wrap,
body.modal-KakaoZipFinder-open0 #container-wrap,
body.modal-KakaoZipFinder-open1 #container-wrap {z-index:9;}
.addrZip {width:134px !important;margin:0 !important;}
.addrSumm {flex:1;margin:0 0 0 7px !important;}
.addrDetail {width:100%;max-width:none !important;}
.addrDaum {
	position:fixed;width:100%;height:100%;left:0;top:0;background:rgba(0, 0, 0, .3);border:0;z-index:9999;
	&::backdrop {background:rgba(0, 0, 0, .5);}
	.wrapper {
		position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;display:flex;flex-flow:column;flex-wrap:wrap;width:400px;height:520px;
		> div {min-height:0;flex:1}
		> button {display:block;margin:10px 0 0 !important;min-width:0;max-width:none;width:100% !important;border:1px solid var(--color-1) !important;border-radius:0 !important;}
	}
}

/* ==============================
* 오프닝 팝업. AdminFront 연동
================================= */
#openingPopupPkg {
	position:fixed;width:0;height:0;left:0;top:0;z-index:9999;
	.popupUnit {
		position:absolute;background:#fff;padding:10px 10px 15px;box-shadow:0 5px 5px rgba(0, 0, 0, .25), 0 0 5px rgba(0, 0, 0, .25);
		.boxContents {}
		.boxController {
			display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;margin:10px 0 0;
			.chks {
				label {
					font-size:13px;padding-top:0;padding-bottom:0;outline:0;
					&:before {top:0;}
					&:after {top:4px;}
				}
			}
			.btns {
				width:20px;height:20px;border:1px solid #aaa;box-sizing:border-box;background:#fff;position:relative;transition:all .3s ease;
				&:before,
				&:after {content:"";display:block;position:absolute;width:15px;height:2px;background:#aaa;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;}
				&:before {transform:rotate(45deg);}
				&:after {transform:rotate(-45deg);}
				&:hover,
				&:focus-visible {
					background:var(--color-1);border:1px solid var(--color-1);
					&:before,
					&:after {background:#fff;}
				}
			}
		}
	}
	@media all and (max-width:899px) {
		.popupUnit {
			display:flex;flex-flow:column;flex-wrap:wrap;width:100% !important;height:100% !important;left:0 !important;top:0 !important;box-shadow:none;padding:10px;position:fixed;
			.boxContents {
				flex:1;min-height:0;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
				img {max-width:100%;max-height:100%;}
			}
			.boxController {border-top:1px solid #ddd;padding:10px 0 5px;}
		}
	}
}