#bbsSch {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0 0 20px;
  .schTit {
    margin: 0;
    line-height: 1.2;
  }
  .schFrm {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    > * {
      margin: 0 10px 0 0;
      height: 40px;
      &:last-child {
        margin: 0;
      }
    }
    .schType {
    }
    .schKey {
      width: 200px;
      min-width: 0;
      max-width: none;
    }
    .schCast,
    .schInit {
      height: 40px;
      border-radius: 4px;
      line-height: 1;
      &:before {
        height: 40px;
      }
    }
  }
}
#bbsTab {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0 0 60px;
  .listTab {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    li {
      box-sizing: border-box;
      padding: 0 20px 0 0;
      margin: 0 15px 0 0;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 20px;
        background: #555;
        right: 0;
        top: 13px;
      }
      &:last-child {
        padding: 0;
        margin: 0;
      }
      &:last-child:after {
        display: none;
      }
      button {
        display: block;
        line-height: 1;
        color: #555;
        border: 0;
        background: transparent;
        box-sizing: border-box;
        position: relative;
        padding: 10px 0;
        transition: all 0.3s ease;
        span {
          display: block;
          font-size: 1.294em;
          letter-spacing: -0.05em;
          line-height: 1;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 0;
          height: 2px;
          left: 50%;
          bottom: 0;
          background: var(--color-1);
          transition: all 0.3s ease;
        }
        &:focus-visible,
        &:hover {
          color: var(--color-1);
          &:after {
            left: 0;
            width: 100%;
          }
        }
      }
      &.on {
        button {
          color: var(--color-1);
          &:after {
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
  .listTool {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    > * {
      margin: 0 10px 0 0;
      &:last-child {
        margin: 0;
      }
    }
    .btn1,
    .btn2 {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      width: 105px;
      height: 35px;
      box-sizing: border-box;
      border: solid;
      border-radius: 4px;
      border-width: 1px;
      font-size: 0.941em;
      transition: all 0.3s ease;
    }
    .btn1 {
      border-color: #3b3b3b;
      background: #3b3b3b;
      color: #fff;
    }
    .btn2 {
      border-color: #b2b2b2;
      background: #fff;
      color: #000;
    }
  }
}
#bbs {
  &.List {
    font-size: 17px;
    .tbl1 {
      position: relative;
      border-collapse: collapse;
      border-spacing: 0px;
      table-layout: fixed;
      width: 100%;
      clear: both;
      text-align: center;
      th,
      td {
        font-size: 1.059em;
        line-height: 1.2;
        border: solid;
        box-sizing: border-box;
        border-width: 1px 0;
        border-color: #dbdbdb;
        word-wrap: break-word;
        word-break: keep-all;
        white-space: normal;
        .default {
          font-size: 12px;
          border: solid 1px;
          display: inline-block;
          padding: 1px 5px;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 1px 10px;
          position: absolute;
          top: 25px;
        }
        .default2 {
          font-size: 12px;
          border: solid 1px;
          display: inline-block;
          padding: 1px 5px;
          padding: 1px 10px;
        }
      }
      thead {
        tr {
          th {
            background: #f0f0f0;
            color: #767676;
            font-weight: 500;
            padding: 12px 10px;
          }
        }
      }
      tbody {
        tr {
          th,
          td {
            padding: 35px 10px;
          }
          th {
          }
          td {
            color: #111;
            .noDataWrap {
              padding: 50px 0;
            }
          }
        }
      }
      .tblBtn1 {
        display: inline-block;
        text-decoration: underline;
        border: 0;
        background: transparent;
        text-align: center;
        transition: all 0.3s ease;
        span {
          display: block;
          line-height: 1;
        }
        &:focus-visible,
        &:hover {
          color: var(--color-1);
        }
      }
      .tblBtn2 {
        min-width: 0;
        max-width: none;
        border-radius: 4px;
        height: auto;
        padding: 4px 10px 5px;
        span {
          display: block;
          line-height: 1;
          font-size: 1em;
        }
        &:before {
          display: none;
        }
      }
    }
    .tbl2 {
      position: relative;
      border-collapse: collapse;
      border-spacing: 0px;
      table-layout: fixed;
      width: 100%;
      clear: both;
      text-align: center;
      th,
      td {
        font-size: 1.059em;
        line-height: 1.2;
        text-align: center;
        border: solid;
        box-sizing: border-box;
        border-width: 1px 0;
        border-color: #dbdbdb;
        word-wrap: break-word;
        word-break: keep-all;
        white-space: normal;
        .default {
          font-size: 12px;
          border: solid 1px;
          display: inline-block;
          padding: 1px 5px;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 1px 10px;
          position: absolute;
          top: 25px;
        }
      }
      thead {
        tr {
          th {
            background: #f0f0f0;
            color: #767676;
            font-weight: 500;
            padding: 12px 10px;
          }
        }
      }
      tbody {
        tr {
          th,
          td {
            padding: 37px 10px 38px;
          }
          th {
          }
          td {
            color: #111;
          }
        }
      }
      .tblBtn1 {
        display: inline-block;
        text-decoration: underline;
        border: 0;
        background: transparent;
        text-align: left;
        transition: all 0.3s ease;
        span {
          display: block;
          line-height: 1;
        }
        &:focus-visible,
        &:hover {
          color: var(--color-1);
        }
      }
    }
    .gallery1 {
      ul {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 50px;
        column-gap: 50px;
        li {
        }
        &.hasNoDataUl {
          display: block;
          li {
            display: block;
            &.hasNoData {
              .noDataWrap {
                padding: 50px 0;
              }
            }
          }
        }
      }
    }
    .gallery2 {
      ul {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
        li {
          box-sizing: border-box;
          min-width: 0;
          input[type="checkbox"] {
          }
          label {
            display: block;
            width: 100%;
            padding: 0 0 0 25px;
            margin: 0;
            outline: 0;
            &:before {
              left: 0;
              top: 0;
            }
            &:after {
              top: 4px;
            }
            .boxImg {
              box-sizing: border-box;
              border: 1px solid #ddd;
              transition: all 0.3s ease;
              .imgContainer {
                display: block;
                height: 0;
                padding: 0 0 68.3453%;
                box-sizing: border-box;
                position: relative;
                background: #eee;
                overflow: hidden;
                video,
                img {
                  display: block;
                  position: absolute;
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                  transition: all 0.3s ease;
                }
              }
            }
            .boxTit {
              margin: 5px 0 0;
              span {
                display: block;
                width: auto;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-wrap: normal;
                text-align: center;
                padding: 3px 0;
                line-height: 1;
              }
            }
          }
          input[type="checkbox"]:checked + label {
            .boxImg {
              border-color: var(--color-1);
            }
          }
        }
      }
    }
    .faq1 {
      > ul {
        display: block;
        box-sizing: border-box;
        border-top: 1px solid var(--color-1);
        > a > li {
          display: block;
          box-sizing: border-box;
          border-bottom: 1px solid #dbdbdb;
          .question {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            width: 100%;
            border: 0;
            background: transparent;
            line-height: 1.3;
            padding: 19px 0 18px;
            transition: all 0.3s ease;
            > * {
              font-size: 0.882em;
              box-sizing: border-box;
              position: relative;
            }
            .cate {
              width: 190px;
              color: #888;
            }
            .subj {
              flex: 1;
              text-align: left;
              color: #111;
              font-weight: 500;
              width: auto;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-wrap: normal;
              padding: 0 30px 0 0;
              span {
                transition: all 0.3s ease;
              }
            }
            .info {
              width: 180px;
              color: #888;
              font-weight: 500;
              text-align: center;
              .writer {
                box-sizing: border-box;
                position: relative;
                padding: 0 10px 0 0;
                margin: 0 10px 0 0;
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 1px;
                  height: 14px;
                  background: #dbdbdb;
                  right: 0;
                  top: 4px;
                }
              }
              .date {
              }
            }
            .hit {
              width: 130px;
              color: #888;
              font-weight: 500;
              text-align: center;
              .writer {
                box-sizing: border-box;
                position: relative;
                padding: 0 10px 0 0;
                margin: 0 10px 0 0;
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 1px;
                  height: 14px;
                  background: #dbdbdb;
                  right: 0;
                  top: 4px;
                }
              }
              .date {
              }
            }
            .status {
              width: 145px;
              font-weight: 500;
              &.status1 {
                color: #888;
              }
              &.status2 {
                color: var(--color-1);
              }
            }
            &:focus-visible,
            &:hover {
              background: #e7effc;
              .subj span {
              }
            }
          }
          .answer {
            display: none;
            background: #f1f2f2;
            padding: 20px 0;
            > * {
              margin: 0 0 40px;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              &:last-child {
                margin: 0;
              }
              > * {
                box-sizing: border-box;
                position: relative;
                font-size: 0.882em;
              }
              .cate {
                width: 190px;
                color: #888;
                text-align: center;
                font-weight: 500;
              }
              .subj {
                flex: 1;
                padding: 0 30px 0 0;
                .title {
                  font-weight: 700;
                  color: #111;
                }
                .conts {
                  margin: 20px 0 0;
                }
              }
              .info {
                width: 180px;
                text-align: left;
              }
              .report {
                width: 145px;
                text-align: center;
                button {
                  line-height: 1;
                  box-sizing: border-box;
                  position: relative;
                  padding: 5px 10px 7px;
                  border-radius: 4px;
                  border: 1px solid #aaa;
                  background: transparent;
                  transition: all 0.3s ease;
                  &:focus-visible,
                  &:hover {
                    background: var(--color-1);
                    color: #fff;
                    border-color: var(--color-1);
                  }
                }
              }
              &.originalQuestion {
              }
              &.originalAnswer {
                .cate {
                  color: var(--color-1);
                }
              }
            }
          }
          &.on {
            .answer {
              display: block;
            }
          }
        }
        > li {
          display: block;
          box-sizing: border-box;
          border-bottom: 1px solid #ddd;
          color: #111;
          .question {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            border: 0;
            background: transparent;
            line-height: 1.3;
            padding: 19px 0 18px;
            transition: all 0.3s ease;
            > * {
              font-size: 0.882em;
              box-sizing: border-box;
              position: relative;
            }
            .cate {
              width: 190px;
              color: #111;
              font-weight: 500;
              text-align: center;
            }
            .subj {
              flex: 1;
              text-align: left;
              color: #111;
              font-weight: 500;
              width: auto;
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-wrap: normal;
              padding: 0 30px 0 0;
              span {
                transition: all 0.3s ease;
              }
            }
            .info {
              width: 180px;
              color: #111;
              font-weight: 500;
              text-align: center;
            }
            .hit {
              width: 130px;
              color: #111;
              font-weight: 500;
              text-align: center;
            }
            .status {
              width: 145px;
              font-weight: 500;
              &.status1 {
                color: #111;
              }
              &.status2 {
                color: var(--color-1);
              }
            }
          }
        }
      }
    }
    .faq2 {
      .csInfo {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #e8e8e8;
        padding: 10px 20px;
        justify-content: flex-start;
        .infoBox {
          display: flex;
          flex-direction: row;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          min-width: 160px;
          justify-content: center;
          box-sizing: border-box;
          position: relative;
          padding: 0 20px;
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 2px;
            height: 20px;
            background: #ddd;
            right: 0;
            top: 0px;
          }
          .em {
            color: var(--color-1) !important;
          }
          .infoContent {
            margin-left: 8px;
            font-weight: 600;
          }
        }
        .dividingLine {
          border: 1px solid #efefef;
          margin: 0 20px;
        }
      }
      .csTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding: 30px 20px;
        .stateBox {
          width: 160px;
          text-align: center;
          background-color: var(--color-1);
          color: #fff;
          border-radius: 5px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
        }
        .title {
          width: calc(100% - 190px);
          color: #000;
          font-weight: 600;
        }
      }
      .csContents {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
        padding: 30px 20px;
        .profile {
          width: 160px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .profileImgWrqp {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            overflow: hidden;
          }
          .profileImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .profileText {
            font-size: 10px;
            columns: #000;
            margin-top: 5px;
            font-weight: 600;
            text-align: center;
          }
        }
        .contents {
          width: calc(100% - 190px);
          font-size: 16px;
        }
        .reply {
          .replyTit {
          }
          .replyBody {
          }
        }
      }
    }
  }
  &.Write,
  &.View {
    > ul {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      box-sizing: border-box;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        bottom: 0;
        background: #ccc;
      }
      > li {
        min-height: 50px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
        > dl {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          height: 100%;
          dt,
          dd {
            box-sizing: border-box;
            line-height: 1.2;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
          }
          dt {
            width: 170px;
            background: #f0f0f0;
            color: #000;
            justify-content: center;
            font-weight: 500;
          }
          dd {
            flex: 1;
            min-width: 0;
            padding: 10px 15px;
            input {
            }
            textarea {
              display: block;
              width: 100%;
              height: 200px;
              resize: none;
            }
          }
        }
      }
    }
    .normal1 {
      .sectionTop {
        .title {
          width: 100%;
          padding: 20px 0 0;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          .noticeTag {
            padding: 5px 20px 6px;
            background: var(--color-1);
            border-radius: 5px;
            color: #fff;
            line-height: 1;
            font-size: 14px;
            font-weight: 500;
            height: 100%;
            margin: 0 10px 0 0;
          }
          .text {
            color: #000;
            font-weight: 500;
            flex: 1;
            min-width: 0;
          }
        }
        .info {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          justify-content: right;
          dl {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            box-sizing: border-box;
            position: relative;
            padding: 0 13px 0 0;
            margin: 0 10px 0 0;
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 2px;
              height: 12px;
              right: 0;
              top: 3px;
              transform: rotate(20deg);
              background: #ddd;
            }
            &:last-child {
              padding: 0;
              margin: 0;
              &:after {
                display: none;
              }
            }
            dt,
            dd {
              font-weight: 400;
              box-sizing: border-box;
              position: relative;
              line-height: 1.2;
              font-size: 15px;
            }
            dt {
              padding: 0 8px 0 0;
              margin: 0 5px 0 0;
              &:after {
                content: ":";
                display: block;
                position: absolute;
                width: auto;
                height: auto;
                right: 0;
                top: 3px;
                color: #aaa;
                font-size: 12px;
                line-height: 1;
              }
            }
            dd {
            }
          }
        }
        .files {
          margin: 10px 0 0;
          ul {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            margin: -5px;
            li {
              box-sizing: border-box;
              padding: 5px;
              a {
                box-sizing: border-box;
                border: 1px solid #ddd;
                background: #fafafa;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                padding: 5px 10px;
                border-radius: 5px;
                transition: all 0.3s ease;
                img {
                }
                span {
                  font-size: 14px;
                  line-height: 1;
                  margin: -2px 0 0 5px;
                }
                &:hover,
                &:focus-visible {
                  border-color: var(--color-1);
                  color: var(--color-1);
                }
              }
            }
          }
        }
      }
      .sectionMiddle {
        margin: 20px 0 0;
        img {
          display: block;
          margin: 0 0 10px;
          width: auto;
          max-width: 100%;
        }
      }
      .sectionBottom {
        border-top: 1px solid #ddd;
        padding: 30px 0 0;
      }
    }
  }
  &.Write {
    > ul {
      > li {
      }
    }
  }
  .state {
    display: inline-block;
    line-height: 1;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 5px 5px;
    &.state1 {
      background: var(--color-1);
    }
    &.state2 {
      background: var(--color-o2);
    }
    &.state3 {
      background: var(--color-3);
    }
  }
}
