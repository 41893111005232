/*------ 22. contact Page  ------*/
.contact-area {
	max-width:1400px;margin:0 auto;padding:0;
	.contact-map {position:relative;height:560px;}
	.contact-pkg {
		display:flex;flex-flow:row;flex-wrap:wrap;
		.contact-info-wrap {
			width:400px;padding:40px;background:#eee;
			.single-contact-info {
				display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 20px;
				> * {box-sizing:border-box;position:relative;}
				.contact-icon {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;width:40px;height:40px;margin:0 20px 0 0;border:1px solid #aaa;border-radius:50%;
					i {font-size:20px;line-height:40px;}
				}
				.contact-info-dec {
					padding:10px 0 0;
					p {
						line-height:1.2;margin:0 0 5px;
						a {
							transition:all .3s ease;
							span {transition:all .3s ease;}
							&:focus-visible,
							&:hover {color:var(--color-1)}
							&:focus-visible span,
							&:hover span {box-shadow:0 -10px 0 rgba(0, 0, 0, 0.1) inset}
						}
						&:last-child {margin:0;}
					}
				}
				&:last-child {margin:0;}
			}
			.contact-social {}
		}
		.contact-form {
			flex:1;padding:40px;background:#f3f3f3;
			.contact-title {
				h2 {display:block;font-size:1.470em;font-weight:500;line-height:1;margin:0 0 30px}
			}
			.contact-form-style {
				.gridParent {
					display:grid;grid-template-columns:1fr 1fr;gap:20px;
					input[type=text],
					input[type=email],
					textarea {display:block;background:#fff;width:100%;resize:none}
					textarea {height:175px;box-sizing:border-box;padding:10px}
					.gridChild:nth-child(3) {grid-column:1 / 3}
					.gridChild:nth-child(4) {grid-column:1 / 3}
				}
			}
		}
	}
}