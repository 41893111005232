@charset "utf-8";
.content-area {
  .container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
  }
  @media all and (max-width: 1440px) {
    .container {
      margin: 0 20px;
    }
  }
}

/* ==============================
* Sub Layout
================================= */
#sub-container {
  box-sizing: border-box;
  border-top: 2px solid #e8e8e8;
  padding: 30px 0 0;
  .container {
    padding: 0;
    margin: 0 auto;
    max-width: 1400px;
    width: auto;
    .itmeDepth1Deco {
      height: 0;
      box-sizing: border-box;
      position: relative;
      padding: 0 0 29.919%;
      background: #eee;
      img {
        display: block;
        position: absolute;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        transition: all 0.3s ease;
      }
    }
    > div > .noDataWrap {
      min-height: 620px;
    }
    .noDataHasBtn {
      text-align: center;
      padding: 200px 0;
      .noDataWrap {
        min-height: 0;
        padding: 0;
      }
      button {
        margin: 20px 0 0;
      }
    }
  }
  @media all and (max-width: 1440px) {
    padding: 20px 0 0;
    overflow: hidden;
    .container {
      margin: 0 20px;
    }
  }
}

/* ==============================
* Common
================================= */
.proEditorContents {
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      pointer-events: none;
      transition: opacity 0.3s ease;
    }
    &.expanded:before {
      background: none;
    }
  }
  .prodListMore {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 400px;
    height: 60px;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid var(--color-db);
    background: #fff;
    margin: 50px auto 0;
    transition: all 0.3s ease;
    span {
      box-sizing: border-box;
      position: relative;
      padding: 0 20px 0 0;
      font-size: 0.941em;
      line-height: 1;
      font-weight: 500;
      color: #000;
      transition: all 0.3s ease;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        border: solid;
        border-width: 0 2px 2px 0;
        border-color: #000;
        transform: rotate(45deg);
        right: 0;
        top: 2px;
        transition: all 0.3s ease;
      }
    }
    &:hover,
    &:focus-visible {
      border-color: var(--color-1);
      span {
        color: var(--color-1);
        &:after {
          border-color: var(--color-1);
        }
      }
    }
    &.clicked span:after {
      transform: rotate(-135deg);
      top: 6px;
    }
  }
  @media all and (max-width: 699px) {
    .prodListMore {
      width: 100%;
      height: 50px;
      span {
        &:after {
          top: -1px;
        }
      }
    }
  }
}
/* ==============================
* 01. Factory
================================= */
.factory {
  .facTit1 {
    color: #111;
    text-align: center;
    padding: 50px 0 0;
    > * {
      display: block;
      line-height: 1;
    }
    .sTit1 {
      font-size: 2.705em;
      font-weight: 300;
    }
    .sTit2 {
      font-size: 4.705em;
      font-weight: 700;
      margin: 22px 0 0;
    }
  }
  .facTit2 {
    display: block;
    font-size: 1.647em;
    font-weight: 500;
    color: #111;
    line-height: 1.2;
    padding: 0 0 14px;
    &.hasBorber {
      border-bottom: 1px solid var(--color-1);
    }
  }
  .facMore {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 401px;
    width: 100%;
    height: 60px;
    margin: 90px auto 0;
    box-sizing: border-box;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #cbcbcb;
    line-height: 1;
    padding: 0 10px;
    color: #000;
    transition: all 0.3s ease;
    .txt {
      font-size: 0.941em;
    }
    .ico {
      font-size: 1.176em;
      margin: 2px 0 0 5px;
    }
    &:focus-visible,
    &:hover {
      background: var(--color-1);
      border-color: var(--color-1);
      color: #fff;
    }
  }

  /** ---- List */
  &.List {
    max-width: 1438px;
    .facReccList {
      margin: 100px 0 0;
      > ul {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: -23px -19px;
        > li {
          display: flex;
          flex-flow: column;
          flex-wrap: wrap;
          box-sizing: border-box;
          width: 50%;
          padding: 23px 19px;
          .boxImg {
            box-sizing: border-box;
            position: relative;
            width: 100%;
            .tools {
              position: absolute;
              right: 20px;
              bottom: 20px;
              z-index: 1;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              button {
                display: block;
                width: 40px;
                height: 40px;
                line-height: 1;
                border-radius: 50%;
                border: 0;
                background: #fff;
                margin: 0 5px 0 0;
                color: #555;
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
                transition: all 0.3s ease;
                &:last-child {
                  margin: 0;
                }
                &:focus-visible,
                &:hover {
                  background: var(--color-1);
                  color: #fff;
                }
                i {
                  font-size: 1.176em;
                }
                &.favorite {
                  &.on {
                    color: var(--color-1);
                    &:hover {
                      background: #555;
                      color: #fff;
                    }
                  }
                }
                &.chat {
                }
              }
            }
            .link {
              display: block;
              position: relative;
              z-index: 0;
              box-sizing: border-box;
              border: 1px solid #ddd;
              .imgContainer {
                display: block;
                height: 0;
                padding: 0 0 58.714%;
                box-sizing: border-box;
                position: relative;
                background: #000;
                overflow: hidden;
                img {
                  display: block;
                  position: absolute;
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                  transition: all 0.3s ease;
                }
              }
              &:focus-visible .imgContainer img,
              &:hover .imgContainer img {
                transform: scale(1.2);
              }
            }
          }
          .boxTxt {
            flex: 1;
            background: #eee;
            box-sizing: border-box;
            padding: 21px 230px 20px 35px;
            width: 100%;
            position: relative;
            .facTxts {
              flex: 1;
              box-sizing: border-box;
              .infos {
                display: block;
                margin: 0 0 10px;
                p {
                  span {
                    transition: all 0.3s ease;
                  }
                }
                .facSpecial {
                  font-size: 1.059em;
                  color: #000;
                  font-weight: 700;
                  line-height: 1.3;
                  margin: 0 0 6px;
                }
                .facName {
                  font-size: 1.412em;
                  color: #111;
                  font-weight: 500;
                  line-height: 1.2;
                }
                &:focus-visible .facName span,
                &:hover .facName span {
                  box-shadow: 0 -12px 0 rgba(17, 101, 224, 0.1) inset;
                  color: var(--color-1);
                }
              }
              .facAddr,
              .facIntro {
                display: block;
                box-sizing: border-box;
                position: relative;
                padding: 0 0 0 22px;
                .ico {
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  left: 0;
                  top: 0;
                  line-height: 1;
                }
                .txt {
                  display: block;
                  font-size: 0.941em;
                  line-height: 1.2;
                  .cut {
                  }
                }
                &.facAddr {
                  margin: 0 0 5px;
                  .txt {
                    .cut {
                      display: block;
                      width: auto;
                      max-width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      word-wrap: normal;
                    }
                  }
                }
                &.facIntro {
                  .txt {
                    .cut {
                      display: -webkit-box;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: normal;
                      height: 40px;
                      word-wrap: break-word;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    }
                  }
                }
              }
              .facTags {
                margin: 15px 0 0;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                li {
                  margin: 0 7px 0 0;
                  &:last-child {
                    margin: 0;
                  }
                  a {
                    display: block;
                    font-size: 0.941em;
                    color: #444;
                    border-radius: 3px;
                    background: #dddad2;
                    padding: 4px 6px 7px;
                    font-weight: 500;
                    transition: all 0.3s ease;
                    span {
                      display: block;
                      line-height: 1;
                    }
                    &:focus-visible,
                    &:hover {
                      background: var(--color-1);
                      color: #fff;
                    }
                  }
                }
              }
            }
            .facBtns {
              position: absolute;
              right: 52px;
              top: 21px;
              width: 160px;
              a {
                display: block;
                text-align: center;
                line-height: 1;
                background: #777;
                color: #fff;
                border-radius: 999px;
                margin: 0 0 10px;
                padding: 12px 10px 13px;
                transition: all 0.3s ease;
                &:last-child {
                  margin: 0;
                }
                &:focus-visible,
                &:hover {
                  background: var(--color-1);
                }
                span {
                  display: block;
                  font-size: 0.941em;
                }
                &.infos {
                }
                &.products {
                }
              }
            }
          }
        }
      }
    }
    .facNormalList {
      margin: 100px 0 0;
      > ul {
        > li {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          width: 100%;
          padding: 0 0 46px;
          margin: 0 0 47px;
          border-bottom: 1px solid #777;
          &:last-child {
            margin: 0;
          }
          .boxImg {
            width: 331px;
            .link {
              display: block;
              position: relative;
              z-index: 0;
              box-sizing: border-box;
              border: 1px solid #ddd;
              .imgContainer {
                display: block;
                height: 0;
                padding: 0 0 100%;
                box-sizing: border-box;
                position: relative;
                background: #eee;
                overflow: hidden;
                img {
                  display: block;
                  position: absolute;
                  width: auto;
                  height: auto;
                  max-width: 100%;
                  max-height: 100%;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                  transition: all 0.3s ease;
                }
              }
              &:focus-visible .imgContainer img,
              &:hover .imgContainer img {
                transform: scale(1.2);
              }
            }
          }
          .boxTxt {
            width: calc(100% - 331px);
            box-sizing: border-box;
            padding: 0 0 0 20px;
            position: relative;
            .pkg {
              display: flex;
              flex-flow: column;
              flex-wrap: wrap;
              width: 100%;
              justify-content: space-between;
              height: 100%;
              .facTxts {
                width: 100%;
                .link {
                  display: block;
                  font-size: 2.058em;
                  color: #111;
                  line-height: 1.3;
                  font-weight: 500;
                  width: auto;
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-wrap: normal;
                  padding: 0 180px 0 0;
                  margin: -5px 0 0;
                  span {
                    transition: all 0.3s ease;
                  }
                  &:focus-visible span,
                  &:hover span {
                    color: var(--color-1);
                    box-shadow: 0 -25px 0 rgba(17, 101, 224, 0.1) inset;
                  }
                }
                .tags {
                  margin: 18px 0 0;
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  li {
                    margin: 0 9px 9px 0;
                    &:last-child {
                      margin: 0;
                    }
                    a {
                      display: block;
                      font-size: 1.176em;
                      color: #444;
                      border-radius: 3px;
                      background: #eaeaea;
                      padding: 5px 8px 6px;
                      font-weight: 500;
                      transition: all 0.3s ease;
                      span {
                        display: block;
                        line-height: 1;
                      }
                      &:focus-visible,
                      &:hover {
                        background: var(--color-1);
                        color: #fff;
                      }
                    }
                  }
                }
                .infos {
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  margin: 17px 0 0;
                  li {
                    box-sizing: border-box;
                    position: relative;
                    padding: 0 10px 0 0;
                    margin: 0 10px 0 0;
                    line-height: 1;
                    &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 1px;
                      height: 20px;
                      background: #dbdbdb;
                      right: 0;
                      top: 3px;
                    }
                    &:last-child {
                      padding: 0;
                      margin: 0;
                    }
                    &:last-child:after {
                      display: none;
                    }
                    dl {
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      line-height: 1;
                      dt,
                      dd {
                        font-size: 1.353em;
                        color: #888;
                        font-weight: 400;
                      }
                      dt {
                        margin: 0 5px 0 0;
                      }
                      dd {
                      }
                    }
                  }
                }
                .intro {
                  display: block;
                  width: auto;
                  max-width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  word-wrap: normal;
                  font-size: 1.353em;
                  color: #888;
                  margin: 19px 0 0;
                }
              }
              .facInfos {
                width: 100%;
                box-sizing: border-box;
                padding: 0 200px 0 0;
                .addr {
                  box-sizing: border-box;
                  position: relative;
                  padding: 0 0 0 30px;
                  line-height: 1.5;
                  .ico {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    line-height: 1;
                    font-size: 1.529em;
                  }
                  .txt {
                    display: block;
                    font-size: 1.294em;
                    color: #9d9d9d;
                    width: auto;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-wrap: normal;
                  }
                }
                .qty {
                  box-sizing: border-box;
                  padding: 0 0 0 30px;
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  margin: 5px 0 0;
                  dl {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    padding: 0 15px 0 0;
                    margin: 0 10px 0 0;
                    position: relative;
                    line-height: 1;
                    &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 2px;
                      height: 18px;
                      background: #9d9d9d;
                      right: 0;
                      top: 4px;
                      transform: rotate(30deg);
                    }
                    &:last-child {
                      padding: 0;
                      margin: 0;
                    }
                    &:last-child:after {
                      display: none;
                    }
                    dt,
                    dd {
                      font-weight: 400;
                      font-size: 1.294em;
                      color: #9d9d9d;
                      box-sizing: border-box;
                      position: relative;
                    }
                    dt {
                      padding: 0 10px 0 0;
                      margin: 0 5px 0 0;
                      &:after {
                        content: ":";
                        display: block;
                        position: absolute;
                        width: auto;
                        height: auto;
                        line-height: 1;
                        right: 0;
                        top: -2px;
                      }
                    }
                    dd {
                    }
                  }
                }
              }
            }
            .tools {
              position: absolute;
              right: 0;
              top: 0;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              button {
                display: block;
                width: 50px;
                height: 50px;
                line-height: 1;
                border-radius: 5px;
                border: 0;
                background: #fff;
                margin: 0 5px 0 0;
                color: #000;
                transition: all 0.3s ease;
                &:last-child {
                  margin: 0;
                }
                &:focus-visible,
                &:hover {
                  background: var(--color-1);
                  color: #fff;
                }
                i {
                  font-size: 1.764em;
                }
                &.favorite {
                  &.on {
                    color: var(--color-1);
                    &:hover {
                      background: #555;
                      color: #fff;
                    }
                  }
                }
                &.chat {
                }
              }
            }
            .btns {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 160px;
              a {
                display: block;
                text-align: center;
                line-height: 1;
                background: #777;
                color: #fff;
                border-radius: 999px;
                margin: 0 0 10px;
                padding: 12px 10px 13px;
                transition: all 0.3s ease;
                &:last-child {
                  margin: 0;
                }
                &:focus-visible,
                &:hover {
                  background: var(--color-1);
                }
                span {
                  display: block;
                  font-size: 0.941em;
                }
                &.infos {
                }
                &.products {
                }
              }
            }
          }
        }
      }
    }
  }

  /** ---- View */
  &.View {
    max-width: 1480px;
    box-sizing: border-box;
    .facUtils1 {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      box-sizing: border-box;
      border: solid;
      border-width: 1px 0;
      border-color: var(--color-1);
      padding: 10px 0;
      .sort {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        li {
          button {
            display: block;
            line-height: 1;
            color: #777;
            background: transparent;
            padding: 5px 10px 7px;
            border: 0;
            transition: all 0.3s ease;
            span {
              display: block;
              line-height: 1;
              font-size: 0.882em;
              font-weight: 500;
            }
            &:focus-visible,
            &:hover {
              color: var(--color-1);
            }
          }
          &.on {
            button {
              color: #000;
            }
          }
        }
      }
      .more {
        display: block;
        line-height: 1;
        font-size: 0.882em;
        color: #888;
        box-sizing: border-box;
        position: relative;
        padding: 3px 20px 5px 0;
        transition: all 0.3s ease;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          box-sizing: border-box;
          border: solid;
          border-width: 0 2px 2px 0;
          border-color: #888;
          right: 0;
          top: 6px;
          transform: rotate(-45deg);
          transition: all 0.3s ease;
        }
        &:focus-visible,
        &:hover {
          color: var(--color-1);
          &:after {
            border-color: var(--color-1);
          }
        }
        span {
          display: block;
          em {
            font-style: normal;
          }
        }
      }
      .search {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        .preChks {
          box-sizing: border-box;
          position: relative;
          label {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            border: 0;
            background: transparent;
            color: #888;
            cursor: pointer;
            transition: all 0.3s ease;
            i {
              font-size: 18px;
              line-height: 1;
              margin: 4px 3px 0 0;
            }
            span {
              font-size: 15px;
              line-height: 1;
              box-sizing: border-box;
              position: relative;
              font-weight: 500;
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 2px;
                left: 0;
                bottom: -6px;
                background: var(--color-1);
                transition: all 0.3s ease;
              }
            }
            &:focus-visible,
            &:hover {
              color: var(--color-1);
            }
          }
          input:checked + label {
            color: var(--color-1);
            span {
              &:after {
                width: 100%;
              }
            }
          }
        }
        .form {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          width: 239px;
          height: 34px;
          border-radius: 5px;
          box-sizing: border-box;
          border: 1px solid #e5e5e5;
          margin: 0 0 0 18px;
          input {
            height: 100%;
            border: 0;
            flex: 1;
            padding: 0 10px;
            margin: 0;
            box-sizing: border-box;
            line-height: 1;
            min-width: 0;
            max-width: none;
            font-size: 15px;
          }
          button {
            border: 0;
            background: transparent;
            width: 34px;
            transition: all 0.3s ease;
            i {
              font-size: 18px;
              line-height: 1;
            }
            &:focus-visible,
            &:hover {
              color: var(--color-1);
            }
          }
        }
      }
    }
    .facMore1 {
      box-sizing: border-box;
      border-top: 1px solid #dbdbdb;
      margin: 40px 0 0;
      button {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding: 17px 0 19px;
        border: 1px solid #dbdbdb;
        justify-content: center;
        border-top: 1px solid #fff;
        background: #fff;
        margin: -1px auto 0;
        width: 100%;
        max-width: 225px;
        transition: all 0.3s ease;
        span {
          font-size: 0.941em;
          line-height: 1;
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border: solid;
          box-sizing: border-box;
          border-width: 0 2px 2px 0;
          border-color: #000;
          transform: rotate(45deg);
          margin: -4px 0 0 10px;
          transition: all 0.3s ease;
        }
        &:focus-visible,
        &:hover {
          color: var(--color-1);
          border-color: var(--color-1);
          border-top-color: #fff;
          &:after {
            border-color: var(--color-1);
            transform: rotate(225deg);
            margin: 5px 0 0 10px;
          }
        }
      }
    }
    .facSlide {
      border-radius: 20px;
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      * {
        font-size: 0;
        line-height: 1;
      }
      .swiper-container {
        position: relative;
        z-index: 0;
        .swiper-slide {
          img {
            display: block;
            width: 100%;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          top: 50%;
          transform: translateY(-50%);
          border: 0;
          margin: 0;
          width: 50px;
          height: 50px;
          background: transparent;
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            border: solid;
            box-sizing: border-box;
            border-width: 3px 0 0 3px;
            border-color: #fff;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: all 0.3s ease;
          }
          &.swiper-button-prev {
            &:after {
              transform: rotate(-45deg);
              margin: auto auto auto 20px;
            }
            &:focus-visible:after,
            &:hover:after {
              margin: auto auto auto 15px;
            }
          }
          &.swiper-button-next {
            &:after {
              transform: rotate(135deg);
              margin: auto 20px auto auto;
            }
            &:focus-visible:after,
            &:hover:after {
              margin: auto 15px auto auto;
            }
          }
        }
      }
      .swiper-pagination-wrap {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 1;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          justify-content: center;
          position: absolute;
          left: 50%;
          bottom: 21px;
          transform: translateX(-50%);
          li {
            width: auto;
            height: auto;
            opacity: 1;
            margin: 0 12px 0 0;
            background: transparent;
            &:last-child {
              margin: 0;
            }
            button {
              display: block;
              box-sizing: border-box;
              width: 11px;
              height: 11px;
              border-radius: 50%;
              border: 2px solid #fff;
              background: transparent;
              transition: all 0.3s ease;
              &:focus-visible,
              &:hover {
                background: #fff;
              }
            }
            &.swiper-pagination-bullet-active {
              button {
                background: #fff;
              }
            }
          }
        }
      }
    }
    .facSumm {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      padding: 17px 0 0;
      margin: 30px 0 0;
      border-top: 2px solid var(--color-1);
      > * {
        width: 50%;
        box-sizing: border-box;
        position: relative;
      }
      .facility {
        .name {
          display: block;
          color: #111;
          font-size: 2.058em;
          font-weight: 700;
          margin: 0 0 13px;
          line-height: 1.2;
        }
        .tags {
          ul {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            margin: -4px;
            line-height: 1;
            li {
              padding: 4px;
              line-height: 1;
              a {
                display: block;
                line-height: 1;
                transition: all 0.3s ease;
              }
              span {
                display: block;
                line-height: 1;
                box-sizing: border-box;
                border-radius: 3px;
              }
            }
          }
          &.factory {
            span {
              box-sizing: border-box;
              border: solid;
              border-width: 1px;
              line-height: 1;
              em {
                display: block;
                font-size: 0.882em;
                font-style: normal;
                line-height: 1;
              }
              &.type1 {
                background: linear-gradient(to right, #009fe9 0%, #212ed7 100%);
                border: 0;
                padding: 1px;
                em {
                  background: transparent;
                  color: #fff;
                  padding: 3px 16px 5px;
                }
              }
              &.type2 {
                background: linear-gradient(to right, #009fe9 0%, #212ed7 100%);
                border: 0;
                padding: 1px;
                em {
                  background: #fff;
                  color: var(--color-1);
                  padding: 3px 16px 5px;
                }
              }
              &.type3 {
                border-color: #f00;
                padding: 1px;
                em {
                  background: #fff;
                  color: #f00;
                  padding: 2px 16px 4px;
                }
              }
            }
          }
          &.product {
            margin: 4px 0 0;
            a {
              span {
                font-size: 0.941em;
                background: #eaeaea;
                color: #444;
                padding: 3px 7px 6px;
                transition: all 0.3s ease;
              }
              &:focus-visible span,
              &:hover span {
                background: var(--color-1);
                color: #fff;
              }
            }
          }
        }
        .local {
          display: block;
          sizing: border-box;
          position: relative;
          padding: 0 0 0 25px;
          color: #888;
          line-height: 1.3;
          margin: 20px 0 0;
          i {
            display: block;
            position: absolute;
            font-size: 1.176em;
            left: 0;
            top: 0px;
          }
          .city {
            font-size: 1.059em;
            box-sizing: border-box;
            &:after {
              content: "";
              display: inline-block;
              width: 2px;
              height: 15px;
              background: #aaa;
              transform: rotate(30deg);
              margin: 0 10px;
              position: relative;
              top: 1px;
            }
          }
          .addr {
            font-size: 1.059em;
          }
        }
      }
      .owner {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        border-left: 1px solid #dbdbdb;
        padding: 12px 0 17px 64px;
        margin: 17px 0 0;
        .pic {
          width: 124px;
          box-sizing: border-box;
          border: 1px solid #dbdbdb;
          border-radius: 50%;
          overflow: hidden;
          background: #dbdbdb;
          .imgContainer {
            display: block;
            height: 0;
            padding: 0 0 100%;
            box-sizing: border-box;
            position: relative;
            img {
              display: block;
              position: absolute;
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
        }
        .info {
          margin: 0 auto 0 0;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          line-height: 1;
          > * {
            display: block;
            line-height: 1;
          }
          i {
            color: #00a0e9;
            margin: 4px 0 0 20px;
          }
          .seller {
            color: #111;
            font-size: 1.059em;
            font-weight: 500;
          }
          .level {
            font-size: 0.882em;
            color: #fff;
            background: #00a0e9;
            border-radius: 3px;
            padding: 1px 5px 3px;
            margin: 4px 0 0 5px;
          }
        }
        .inquery {
          display: block;
          padding: 16px 36px 18px;
          background: var(--color-1);
          color: #fff;
          border: 0;
          border-radius: 999px;
          font-size: 1.118em;
          transition: all 0.3s ease;
          span {
            display: block;
            line-height: 1;
            font-weight: 500;
          }
          &:focus-visible,
          &:hover {
            background: var(--color-2);
          }
        }
      }
    }
    .facStatus {
      margin: 68px 0 0;
      ul {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        box-sizing: border-box;
        border: 1px solid #dbdbdb;
        border-radius: 10px;
        padding: 12px 0 25px;
        li {
          flex: 1;
          box-sizing: border-box;
          border-right: 1px solid #dbdbdb;
          padding: 29px 10px 24px;
          &:last-child {
            border-right: 0;
          }
          > * {
            display: block;
            text-align: center;
            line-height: 1.2;
          }
          .sTxt1 {
            color: var(--color-1);
            font-weight: 700;
            font-size: 1.353em;
            &.hasStar {
              font-size: 0;
            }
          }
          .sTxt2 {
            color: #111;
            font-size: 0.882em;
            margin: 6px 0 0;
          }
          .star-ratings {
            display: block;
            font-size: 0;
            line-height: 1;
            top: auto;
            * {
              font-size: 0;
              line-height: 1;
              top: auto;
            }
          }
        }
      }
    }
    .facInformation {
      box-sizing: border-box;
      position: relative;
      .boxInfo {
        box-sizing: border-box;
        padding: 41px 0 83px 37px;
        border-bottom: 1px solid #dbdbdb;
        .pTit1 {
          display: block;
          font-size: 1.647em;
          color: #111;
          line-height: 1.2;
          letter-spacing: -0.025em;
          margin: 0 0 14px;
        }
        .pTit2 {
          display: block;
          font-size: 1.47em;
          color: var(--color-1);
          line-height: 1.2;
          letter-spacing: -0.025em;
          margin: 0 0 12px;
        }
        .pTxt1 {
          display: block;
          font-size: 1.059em;
          color: #111;
          line-height: 1.4;
          letter-spacing: -0.025em;
          margin: 0 0 10px;
        }
      }
      .viewAuth {
        position: absolute;
        right: 0;
        top: -10px;
        z-index: 1;
      }
      .unitWrap {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: 50px 0 0;
        > div {
          box-sizing: border-box;
          width: 100%;
          ul {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            box-sizing: border-box;
            border: solid;
            border-width: 1px 1px 0px 1px;
            border-color: #dbdbdb;
            overflow: hidden;
            &.hasBorderR {
              border-right-width: 1px;
            }
            li {
              box-sizing: border-box;
              position: relative;
              width: 50%;
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 200%;
                height: 1px;
                background: #dbdbdb;
                left: 0;
                bottom: 0;
                z-index: 1;
              }
              dl {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                position: relative;
                z-index: 0;
                dt,
                dd {
                  box-sizing: border-box;
                  font-size: 0.882em;
                  padding: 21px 20px 22px 35px;
                }
                dt {
                  background: #f1f2f2;
                  color: #111;
                  width: 169px;
                }
                dd {
                  color: #767676;
                  &.hasBusiType {
                    span {
                      &:after {
                        content: ", ";
                      }
                      &:last-child:after {
                        content: "";
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.st1 {
          dt,
          dd {
          }
        }
        &.st2 {
          align-items: flex-end;
          align-content: flex-end;
          .unit1 {
            padding: 0 50px 0 0;
          }
          .boxImg {
            border-radius: 20px;
            overflow: hidden;
            img {
              display: block;
              max-width: 100%;
            }
          }
        }
      }
    }
    .facProdList {
      .shop-bottom-area {
        margin: 0;
      }
    }
    .facRating {
      .description-review-bottom {
        padding: 0;
        .reviewUnit {
          &:last-child {
            padding: 0 !important;
            margin: 0 !important;
            border-bottom: 0 !important;
          }
        }
      }
    }
    .facSimilar {
      .facNormalList {
        margin: 45px 0 0;
        > ul {
          > li {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 0 49px;
            margin: 0 0 47px;
            border-bottom: 1px solid #dbdbdb;
            &:last-child {
              margin: 0;
              padding: 0;
              border-bottom: 0;
            }
            .boxImg {
              width: 263px;
              .link {
                display: block;
                position: relative;
                z-index: 0;
                box-sizing: border-box;
                border: 1px solid #ddd;
                .imgContainer {
                  display: block;
                  height: 0;
                  padding: 0 0 100%;
                  box-sizing: border-box;
                  position: relative;
                  background: #eee;
                  overflow: hidden;
                  img {
                    display: block;
                    position: absolute;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    transition: all 0.3s ease;
                  }
                }
                &:focus-visible .imgContainer img,
                &:hover .imgContainer img {
                  transform: scale(1.2);
                }
              }
            }
            .boxTxt {
              width: calc(100% - 331px);
              box-sizing: border-box;
              padding: 0 0 0 23px;
              position: relative;
              .pkg {
                display: flex;
                flex-flow: column;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                height: 100%;
                .facTxts {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 0 540px 0 0;
                  .link {
                    display: block;
                    font-size: 1.647em;
                    color: #111;
                    line-height: 1.3;
                    font-weight: 500;
                    width: auto;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-wrap: normal;
                    padding: 0 180px 0 0;
                    margin: -5px 0 0;
                    span {
                      transition: all 0.3s ease;
                    }
                    &:focus-visible span,
                    &:hover span {
                      color: var(--color-1);
                      box-shadow: 0 -25px 0 rgba(17, 101, 224, 0.1) inset;
                    }
                  }
                  .tags {
                    margin: 14px 0 0;
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    li {
                      margin: 0 7px 7px 0;
                      &:last-child {
                        margin: 0;
                      }
                      a {
                        display: block;
                        font-size: 0.941em;
                        color: #444;
                        border-radius: 3px;
                        background: #eaeaea;
                        padding: 3px 8px 6px;
                        font-weight: 500;
                        transition: all 0.3s ease;
                        span {
                          display: block;
                          line-height: 1;
                        }
                        &:focus-visible,
                        &:hover {
                          background: var(--color-1);
                          color: #fff;
                        }
                      }
                    }
                  }
                  .infos {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    margin: 8px 0 0;
                    li {
                      box-sizing: border-box;
                      position: relative;
                      padding: 3px 10px 3px 0;
                      margin: 0 10px 0 0;
                      line-height: 1;
                      &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 1px;
                        height: 16px;
                        background: #dbdbdb;
                        right: 0;
                        top: 5px;
                      }
                      &:last-child {
                        padding-right: 0;
                        margin: 0;
                      }
                      &:last-child:after {
                        display: none;
                      }
                      dl {
                        display: flex;
                        flex-flow: row;
                        flex-wrap: wrap;
                        line-height: 1;
                        dt,
                        dd {
                          font-size: 1.059em;
                          color: #888;
                          font-weight: 400;
                        }
                        dt {
                          margin: 0 5px 0 0;
                        }
                        dd {
                        }
                      }
                    }
                  }
                  .intro {
                    display: block;
                    width: auto;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    word-wrap: normal;
                    font-size: 1.059em;
                    color: #888;
                    margin: 19px 0 0;
                  }
                }
                .facInfos {
                  width: 100%;
                  box-sizing: border-box;
                  padding: 0 540px 0 0;
                  .addr {
                    box-sizing: border-box;
                    position: relative;
                    padding: 0 0 0 30px;
                    line-height: 1.5;
                    .ico {
                      position: absolute;
                      left: 0;
                      top: 3px;
                      line-height: 1;
                      font-size: 1em;
                    }
                    .txt {
                      display: block;
                      font-size: 1em;
                      color: #9d9d9d;
                      width: auto;
                      max-width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      word-wrap: normal;
                    }
                  }
                  .qty {
                    box-sizing: border-box;
                    padding: 0 0 0 30px;
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    dl {
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      padding: 0 15px 0 0;
                      margin: 0 10px 0 0;
                      position: relative;
                      line-height: 1;
                      &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 2px;
                        height: 14px;
                        background: #9d9d9d;
                        right: 0;
                        top: 3px;
                        transform: rotate(30deg);
                      }
                      &:last-child {
                        padding: 0;
                        margin: 0;
                      }
                      &:last-child:after {
                        display: none;
                      }
                      dt,
                      dd {
                        font-weight: 400;
                        font-size: 1em;
                        color: #9d9d9d;
                        box-sizing: border-box;
                        position: relative;
                      }
                      dt {
                        padding: 0 10px 0 0;
                        margin: 0 5px 0 0;
                        &:after {
                          content: ":";
                          display: block;
                          position: absolute;
                          width: auto;
                          height: auto;
                          line-height: 1;
                          right: 0;
                          top: -2px;
                        }
                      }
                      dd {
                      }
                    }
                  }
                }
              }
              .tools {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                button {
                  display: block;
                  width: 50px;
                  height: 50px;
                  line-height: 1;
                  border-radius: 5px;
                  border: 0;
                  background: #fff;
                  margin: 0 5px 0 0;
                  color: #000;
                  transition: all 0.3s ease;
                  &:last-child {
                    margin: 0;
                  }
                  &:focus-visible,
                  &:hover {
                    background: var(--color-1);
                    color: #fff;
                  }
                  i {
                    font-size: 1.764em;
                  }
                  &.favorite {
                    &.on {
                      color: var(--color-1);
                      &:hover {
                        background: #555;
                        color: #fff;
                      }
                    }
                  }
                  &.chat {
                  }
                }
              }
              .imgs {
                position: absolute;
                right: 0;
                bottom: 0;
                ul {
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  li {
                    width: 165px;
                    box-sizing: border-box;
                    border: 1px solid #ddd;
                    margin: 0 14px 0 0;
                    &:last-child {
                      margin: 0;
                    }
                    .imgContainer {
                      display: block;
                      height: 0;
                      padding: 0 0 100%;
                      box-sizing: border-box;
                      position: relative;
                      background: #eee;
                      overflow: hidden;
                      img {
                        display: block;
                        position: absolute;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        transition: all 0.3s ease;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ==============================
* 03. Hot Deal
================================= */
.hotDeal {
  &.List {
    margin: -30px 0 0;
    .topDeco {
      position: relative;
      height: 300px;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      box-sizing: border-box;
      padding: 0 50px;
      z-index: 0;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2000px;
        height: 300px;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
        background: url(/assets/img/_home/homeHotDeal_bg1.jpg) no-repeat center top;
      }
      .msg {
        p {
          color: #252525;
          &.txt1 {
            font-size: 4.058em;
            line-height: 1;
            letter-spacing: 0;
          } /* font-family:"FogtwoNo5" */
          &.txt2 {
            font-size: 0.941em;
            line-height: 1.2;
            box-sizing: border-box;
            padding: 10px 0 0 4px;
          }
        }
      }
    }
    .eventSection {
      text-align: center;
      padding: 100px 0 100px;
      box-sizing: border-box;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2000px;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: url(/assets/img/_home/homeHotDeal_bg2.jpg) no-repeat center top;
        background-size: cover;
        z-index: -1;
      }
      .deco1 {
        display: inline-block;
        border-radius: 50%;
        font-size: 13px;
        font-weight: 700;
        color: var(--color-1);
        border: 1px solid var(--color-1);
        padding: 8px 20px;
      }
      .p1 {
        display: block;
        font-size: 1.47em;
        color: #010101;
        line-height: 1;
        margin: 22px 0 0;
      }
      .p2 {
        display: block;
        font-size: 2.529em;
        color: #010101;
        line-height: 1.27;
        font-weight: 700;
        margin: 10px 0 0;
        span {
          display: block;
        }
      }
      .period {
        color: #000;
        letter-spacing: 0;
        margin: 15px 0 0;
        font-size: 1.176em;
      }
      .couponImg {
        display: block;
        margin: 44px auto 0;
        width: 100%;
        max-width: 476px;
      }
      .getCoupon {
        display: inline-block;
        border: 0;
        background: var(--color-1);
        color: #fff;
        border-radius: 999px;
        line-height: 1;
        padding: 12px 40px 14px;
        margin: 95px 0 0;
        transition: all 0.3s ease;
        span {
          display: block;
          line-height: 1;
          font-size: 1.059em;
        }
        &:hover,
        &:focus-visible {
          background: var(--color-2);
        }
      }
    }
    .exhibitionSection {
      margin: 100px 0 0;
      .roleTit {
        span {
          display: block;
          line-height: 1.2;
          color: #010101;
          text-align: center;
        }
        .t1 {
          font-size: 1.47em;
        }
        .t2 {
          font-size: 2.588em;
          font-weight: 700;
          margin: 5px 0 0;
        }
      }
      .roleBody {
        margin: 50px 0 0;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          margin: 0 -25px;
          li {
            width: 50%;
            box-sizing: border-box;
            padding: 0 25px;
            a {
              .boxImg {
                box-sizing: border-box;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  border: 2px solid var(--color-db);
                  z-index: 1;
                  transition: all 0.2s linear;
                }
                .imgContainer {
                  display: block;
                  height: 0;
                  padding: 0 0 60.431%;
                  box-sizing: border-box;
                  position: relative;
                  background: #eee;
                  overflow: hidden;
                  z-index: 0;
                  img {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    transition: all 0.3s ease;
                  }
                }
              }
              .boxTxt {
                margin: 27px 0 0;
                span {
                  display: block;
                  line-height: 1.2;
                }
                .t1 {
                  font-size: 1.412em;
                  font-weight: 700;
                  color: #000;
                }
                .t2 {
                  font-size: 15px;
                  color: var(--color-1);
                  margin: 7px 0 0;
                }
              }
              &:hover,
              &:focus-visible {
                .boxImg {
                  &:before {
                    border: 5px solid var(--color-1);
                  }
                  .imgContainer {
                    img {
                      transform: scale(1.1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .tabSection {
      margin: 100px 0 0;
      .tabs {
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        border: 1px solid #eaeaea;
        background: #fff;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          width: 100%;
          li {
            flex: 1;
            min-width: 0;
            .nav-item {
              display: block;
              .nav-link {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                width: 100%;
                color: #000;
                background: #fff;
                border: 0;
                height: 60px;
                border-radius: 0;
                padding: 0 5px;
                transition: all 0.3s ease;
                span {
                  display: block;
                  font-size: 0.941em;
                  line-height: 1;
                  font-weight: 500;
                  text-align: center;
                  word-wrap: break-word;
                  word-break: keep-all;
                  white-space: normal;
                }
                &:hover,
                &:focus-visible {
                  background: #f5f5f5;
                  color: var(--color-1);
                }
                &.active {
                  background: #ababab;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .tab-content {
        padding: 30px 0 0;
        .prodListTit {
          display: block;
          font-size: 1.529em;
          color: #000;
          line-height: 1.2;
          box-sizing: border-box;
          border-bottom: 1px solid #777;
          font-weight: 700;
          padding: 0 0 20px;
        }
      }
    }
  }
}

/* ==============================
* Sub01. PRODUCT
================================= */
.prodList01 {
  .itemPkg {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    > * {
      box-sizing: border-box;
      position: relative;
    }
    .itemFilter {
      padding: 20px 40px 0 0;
      .sidebar-widget {
        padding: 20px 0 0 32px;
      }
    }
    .itemList {
      flex: 1;
      padding: 40px 0 0;
      .unit {
        padding: 0 0 30px;
        margin: 0 0 30px;
        border-bottom: 1px solid #ddd;
        &:last-child {
          padding: 0;
          margin: 0;
          border-bottom: 0;
        }
        .shop-bottom-area {
          margin: 0;
          .noDataWrap {
            min-height: 0;
            padding: 40px 0;
          }
        }
      }
    }
  }
}

/* ==============================
* 바이어 가입안내
================================= */
.JoinBuyer {
  .opening {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    position: relative;
    margin: -30px 0 0;
    padding: 24px 66px 37px 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      z-index: -1;
      background: linear-gradient(45deg, #2871fa 0%, #432892 100%);
    }
    .txt {
      line-height: 1.2;
      flex: 1;
      min-width: 0;
      color: #fff;
      box-sizing: border-box;
      padding: 0 20px;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
      .pTxt1 {
        font-size: 2.294em;
        font-weight: 700;
        br {
          display: none;
        }
      }
      .pTxt2 {
        font-size: 1.647em;
        margin: 10px 0 0;
        br {
          display: none;
        }
      }
    }
    .img {
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .sect {
    margin: 140px 0 0;
    .head {
      text-align: center;
      .pTag1 {
        display: inline-block;
        box-sizing: border-box;
        border-radius: 999px;
        font-size: 1.353em;
        color: var(--color-1);
        border: 1px solid #90b7f1;
        line-height: 1;
        padding: 10px 25px 12px;
        min-width: 245px;
        margin: 0 0 50px;
      }
      .pTxt1,
      .pTxt2 {
        font-size: 2.47em;
        font-weight: 700;
        line-height: 1.2;
      }
      .pTxt1 {
        color: #010101;
      }
      .pTxt2 {
        color: #2f60fb;
        margin: 10px 0 0;
      }
    }
    .body {
    }
    &.Entry {
      .head {
      }
      .body {
        ol {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          margin: 50px -40px 0;
          li {
            flex: 1;
            min-width: 0;
            box-sizing: border-box;
            padding: 0 40px;
            position: relative;
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 6px;
              left: 50%;
              top: 50%;
              transform: translateY(-50%);
              z-index: -1;
            }
            &:last-child:after {
              display: none;
            }
            .pkg {
              padding: 0 0 100%;
              box-sizing: border-box;
              position: relative;
              border-radius: 50%;
              &:before,
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                z-index: 99;
                top: 50%;
                transform: translateY(-50%);
              }
              &:before {
                left: -13px;
              }
              &:after {
                right: -13px;
              }
              .outer {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                box-sizing: border-box;
                padding: 4px;
                .inner {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  background: #fff;
                  box-sizing: border-box;
                  padding: 24px;
                  .base {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    box-sizing: border-box;
                    padding: 24px;
                    .core {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      background: #fff;
                      text-align: center;
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      justify-content: center;
                      .pStep1 {
                        display: block;
                        font-size: 1.764em;
                        font-weight: 700;
                        color: #9a9a9c;
                      }
                      .pTxt1 {
                        width: 100%;
                        font-size: 1.47em;
                        color: #111;
                        line-height: 1.4;
                        margin: 10px 0 0;
                      }
                    }
                  }
                }
              }
            }
            &.n01 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #06f0b0 0%, #18a9eb 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #06f0b0 0%, #18a9eb 100%);
                }
                &:before {
                  display: none;
                }
              }
              &:after {
                background: linear-gradient(to right, #06f0b0 0%, #18a9eb 100%);
              }
            }
            &.n02 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #3eb5ed 0%, #3555ae 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #3eb5ed 0%, #3555ae 100%);
                }
              }
              &:after {
                background: linear-gradient(to right, #3eb5ed 0%, #3555ae 100%);
              }
            }
            &.n03 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #5975ff 0%, #864ec2 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #5975ff 0%, #864ec2 100%);
                }
              }
              &:after {
                background: linear-gradient(to right, #5975ff 0%, #864ec2 100%);
              }
            }
            &.n04 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #a466ec 0%, #31417a 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #a466ec 0%, #31417a 100%);
                }
                &:after {
                  display: none;
                }
              }
              &:after {
                background: linear-gradient(to right, #a466ec 0%, #31417a 100%);
              }
            }
          }
        }
      }
    }
    &.Benefit {
      .head {
      }
      .body {
        box-sizing: border-box;
        padding: 0 120px;
        margin: 65px 0 0;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          margin: 0 -44px;
          li {
            flex: 1;
            min-width: 0;
            box-sizing: border-box;
            padding: 0 44px;
            .pkg {
              box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.15);
              border-radius: 20px;
              text-align: center;
              box-sizing: border-box;
              padding: 50px 20px 50px;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
              img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
              }
              .pTit1 {
                display: block;
                font-size: 1.059em;
                font-weight: 400;
                color: #2f60fb;
                line-height: 1;
                margin: 30px 0 0;
              }
              .pTxt1 {
                line-height: 1.2;
                font-size: 1.412em;
                color: #010101;
                margin: 15px 0 0;
                font-weight: 500;
              }
              .pTxt2 {
                line-height: 1.2;
                font-size: 1.176em;
                color: #8b8b8b;
                margin: 10px 0 0;
              }
            }
          }
        }
        a {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: center;
          width: 100%;
          height: 80px;
          min-height: 0;
          max-width: none;
          margin: 65px 0 0;
          span,
          i {
            font-size: 1.176em;
            line-height: 1;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .descWr {
    box-sizing: border-box;
    position: relative;
    padding: 80px 120px;
    margin: 150px 0 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      background: #efefef;
      z-index: -1;
    }
    .pTit1 {
      font-size: 2.47em;
      font-weight: 700;
      color: #000;
      text-align: center;
      margin: 0 0 40px;
    }
    .pTit2 {
      display: inline-block;
      box-sizing: border-box;
      border-radius: 999px;
      font-size: 1.353em;
      color: var(--color-1);
      border: 1px solid #90b7f1;
      line-height: 1;
      padding: 10px 25px 12px;
      min-width: 245px;
      margin: 0 0 50px;
    }
    .desc {
      text-align: center;
      margin: 0 0 130px;
      &:last-child {
        margin: 0;
      }
      .pList1 {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin: 0 -35px;
        li {
          width: 50%;
          box-sizing: border-box;
          padding: 0 35px;
          margin: 80px 0 0;
          &:nth-child(-n + 2) {
            margin: 0;
          }
          img {
            display: block;
            max-width: 100%;
          }
          .pTxt1 {
            font-size: 1.47em;
            text-align: center;
            color: #000;
            line-height: 1.2;
            margin: 20px 0 0;
            word-wrap: break-word;
            word-break: keep-all;
            white-space: normal;
          }
        }
      }
    }
  }
  @media all and (max-width: 1524px) {
    .opening {
      margin: -20px 0 0;
    }
    .sect {
      &.Entry {
        .body {
          ol {
            li {
              .pkg {
                .outer {
                  .inner {
                    padding: 15px;
                    .base {
                      padding: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1399px) {
    .opening {
      padding: 20px 50px 30px 0;
      .txt {
        padding: 0;
        .pTxt1 {
          br {
            display: block;
          }
        }
      }
    }
    .sect {
      margin: 100px 0 0;
      .head {
        .pTag1 {
          margin: 0 0 30px;
        }
        .pTxt1,
        .pTxt2 {
          font-size: 2em;
        }
        .pTxt2 {
          margin: 5px 0 0;
        }
      }
      &.Entry {
        .body {
          ol {
            li {
              .pkg {
                &:before {
                  width: 20px;
                  height: 20px;
                  left: -8px;
                }
                &:after {
                  width: 20px;
                  height: 20px;
                  right: -8px;
                }
                .outer {
                  .inner {
                    .base {
                      .core {
                        .pStep1 {
                          font-size: 1.5em;
                          line-height: 1;
                        }
                        .pTxt1 {
                          font-size: 1.3em;
                          line-height: 1.2;
                          margin: 10px 0 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.Benefit {
        .head {
        }
        .body {
          padding: 0 50px;
          margin: 50px 0 0;
          ul {
            margin: 0 -20px;
            li {
              padding: 0 20px;
            }
          }
        }
      }
    }
    .descWr {
      padding: 80px 50px;
      margin: 80px 0 0;
      .desc {
        margin: 0 0 50px;
        &:last-child {
          margin: 0;
        }
        .pList1 {
          margin: 0 -20px;
          li {
            padding: 0 20px;
            margin: 50px 0 0;
            .pTxt1 {
              font-size: 1.3em;
              margin: 10px 0 0;
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1199px) {
    .sect {
      margin: 100px 0 0;
      .head {
        .pTag1 {
          margin: 0 0 30px;
        }
        .pTxt1,
        .pTxt2 {
          font-size: 2em;
        }
        .pTxt2 {
          margin: 5px 0 0;
        }
      }
      &.Entry {
        .body {
          ol {
            margin: 30px -20px 0;
            li {
              padding: 0 20px;
              &:after {
                height: 2px;
              }
              .pkg {
                &:before {
                  width: 14px;
                  height: 14px;
                  left: -6px;
                }
                &:after {
                  width: 14px;
                  height: 14px;
                  right: -6px;
                }
                .outer {
                  padding: 2px;
                  .inner {
                    padding: 10px;
                    .base {
                      padding: 5px;
                      .core {
                        .pStep1 {
                          font-size: 1.3em;
                        }
                        .pTxt1 {
                          font-size: 1em;
                          margin: 10px 0 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .descWr {
      padding: 80px 50px;
      margin: 80px 0 0;
      .pTit1 {
        font-size: 2em;
        margin: 0 0 30px;
      }
      .pTit2 {
        font-size: 1.3em;
        margin: 0 0 20px;
      }
      .desc {
      }
    }
  }
  @media all and (max-width: 999px) {
    .opening {
      .txt {
        .pTxt2 {
          font-size: 1.2em;
        }
      }
      .img {
        width: 300px;
      }
    }
    .sect {
      margin: 50px 0 0;
      .head {
        .pTxt1 {
          font-size: 1.5em;
        }
        .pTxt2 {
          font-size: 2em;
        }
      }
      &.Entry {
        .body {
          ol {
            margin: 30px -10px 0;
            li {
              padding: 0 10px;
              .pkg {
                &:before {
                  width: 10px;
                  height: 10px;
                  left: -4px;
                }
                &:after {
                  width: 10px;
                  height: 10px;
                  right: -4px;
                }
              }
            }
          }
        }
      }
      &.Benefit {
        .head {
        }
        .body {
          padding: 0;
          margin: 30px 0 0;
          ul {
            margin: 0 -10px;
            li {
              padding: 0 10px;
              .pkg {
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.15);
                border-radius: 10px;
                padding: 20px;
                img {
                  max-width: 150px;
                }
                .pTit1 {
                  margin: 20px 0 0;
                  line-height: 1;
                }
                .pTxt1 {
                  line-height: 1.2;
                  font-size: 1.2em;
                  margin: 5px 0 0;
                }
                .pTxt2 {
                  line-height: 1.2;
                  font-size: 1em;
                  margin: 5px 0 0;
                }
              }
            }
          }
          a {
            height: 50px;
            margin: 30px 0 0;
          }
        }
      }
    }
    .descWr {
      padding: 40px 0;
      margin: 40px 0 0;
      .desc {
        .pList1 {
          margin: 0 -10px;
          li {
            padding: 0 10px;
            margin: 30px 0 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 899px) {
    .opening {
      .txt {
        .pTxt1 {
          font-size: 1.8em;
        }
      }
      .img {
        width: 300px;
      }
    }
  }
  @media all and (max-width: 799px) {
    .opening {
      padding: 20px 0 30px;
    }
  }
  @media all and (max-width: 699px) {
    .opening {
      padding: 10px 0 20px;
      .txt {
        width: 100%;
        flex: none;
        order: 2;
        text-align: center;
        margin: 20px 0 0;
        .pTxt2 {
        }
      }
      .img {
        width: 100%;
        order: 1;
        img {
          width: 100%;
          max-width: 260px;
          margin: 0 auto;
          position: relative;
          right: -10px;
        }
      }
    }
    .sect {
      margin: 30px 0 0;
      .head {
        .pTag1 {
          font-size: 1.2em;
          padding: 10px 20px 10px;
          margin: 0 0 10px;
        }
        .pTxt1 {
          font-size: 1.2em;
        }
        .pTxt2 {
          font-size: 1.5em;
          margin: 0;
        }
      }
      &.Entry {
        .body {
          ol {
            display: block;
            margin: 20px 0 0;
            li {
              display: block;
              flex: none;
              padding: 10px 0;
              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                padding-bottom: 0;
              }
              &:after {
                width: 2px;
                height: 100%;
                left: 50px;
                top: 50%;
                transform: none;
              }
              .pkg {
                padding: 0;
                border-radius: 20px;
                &:before,
                &:after {
                  width: 10px;
                  height: 10px;
                  top: auto;
                  bottom: auto;
                  transform: none;
                }
                &:before {
                  left: 46px;
                  top: -4px;
                }
                &:after {
                  right: auto;
                  left: 46px;
                  bottom: -4px;
                }
                .outer {
                  height: auto;
                  position: relative;
                  left: auto;
                  top: auto;
                  .inner {
                    height: auto;
                    border-radius: 18px;
                    padding: 5px;
                    .base {
                      height: auto;
                      border-radius: 15px;
                      padding: 3px;
                      .core {
                        height: auto;
                        border-radius: 12px;
                        justify-content: left;
                        text-align: left;
                        padding: 10px 15px;
                        .pStep1 {
                          display: inline-flex;
                        }
                        .pTxt1 {
                          width: auto;
                          margin: 0 0 0 15px;
                          br {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.Benefit {
        .head {
        }
        .body {
          margin: 20px 0 0;
          ul {
            display: block;
            margin: 0;
            li {
              flex: none;
              padding: 0;
              margin: 0 0 20px;
              .pkg {
                padding: 20px;
                text-align: left;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: left;
                img {
                  max-width: none;
                  width: 130px;
                  margin: 0;
                }
                .pPkg1 {
                  flex: 1;
                  min-width: 0;
                  box-sizing: border-box;
                  padding: 0 0 0 10px;
                  .pTit1 {
                    margin: 0;
                  }
                }
              }
            }
          }
          a {
            height: 50px;
            margin: 30px 0 0;
          }
        }
      }
    }
    .descWr {
      .pTit1 {
        font-size: 1.5em;
      }
      .pTit2 {
        font-size: 1.2em;
      }
      .desc {
        .pList1 {
          li {
            .pTxt1 {
              font-size: 1.1em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 499px) {
    .opening {
      .txt {
        .pTxt1 {
          font-size: 1.5em;
        }
        .pTxt2 {
          font-size: 1em;
        }
      }
      .img {
        img {
          max-width: 230px;
          right: -10px;
        }
      }
    }
    .sect {
      margin: 20px 0 0;
      .head {
        .pTag1 {
          display: block;
          width: 100%;
          font-size: 1em;
          padding: 10px 25px 12px;
        }
        .pTxt1 {
          font-size: 1em;
        }
        .pTxt2 {
          font-size: 1.2em;
        }
      }
      &.Benefit {
        .head {
        }
        .body {
          ul {
            li {
              .pkg {
                padding: 20px 20px 20px 0;
                img {
                  width: 100px;
                }
                .pTxt1 {
                  font-size: 1em;
                }
                .pTxt2 {
                  font-size: 1em;
                }
              }
            }
          }
        }
      }
    }
    .descWr {
      padding: 20px 0;
      margin: 20px 0 0;
      .pTit1 {
        font-size: 1.2em;
        margin: 0 0 20px;
      }
      .pTit2 {
        display: block;
        width: 100%;
        font-size: 1em;
      }
      .desc {
        .pList1 {
          li {
            .pTxt1 {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 399px) {
    .sect {
      &.Benefit {
        .body {
          ul {
            li {
              .pkg {
                img {
                  width: 80px;
                }
              }
            }
          }
        }
      }
    }
    .descWr {
      .desc {
        margin: 0 0 30px;
        &:last-child {
          margin: 0;
        }
        .pList1 {
          margin: 0;
          li {
            width: 100%;
            padding: 0;
            margin: 20px 0 0;
            &:nth-child(-n + 2) {
              margin: 20px 0 0;
            }
            &:first-child {
              margin: 0;
            }
            .pTxt1 {
              margin: 5px 0 0;
            }
          }
        }
      }
    }
  }
}

/* ==============================
* 1688 상품주문 프로세스
================================= */
.UseGuide {
  .opening {
    margin: -30px 0 0;
    padding: 100px 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      background: #b9e9ff;
      z-index: -1;
    }
    .img {
      order: 2;
      width: 565px;
      img {
        display: block;
        width: 100%;
      }
    }
    .txt {
      order: 1;
      .txtUnit {
        .pTxt1 {
          display: inline-block;
          box-sizing: border-box;
          position: relative;
          padding: 0 0 20px 20px;
          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            z-index: 0;
            background: url(/assets/img/_useGuide/deco1.png) no-repeat left bottom;
            background-size: contain;
          }
          p {
            font-size: 3.647em;
            color: #262626;
            line-height: 1.2;
            position: relative;
            z-index: 1;
            font-weight: 500;
          }
        }
        .pTxt2 {
          display: inline-block;
          box-sizing: border-box;
          position: relative;
          padding: 0 0 0 20px;
          margin: 70px 0 0;
          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 201px;
            height: 105px;
            right: -80px;
            top: -100px;
            z-index: 1;
            background: url(/assets/img/_useGuide/deco2.png) no-repeat left bottom;
            background-size: cover;
          }
          p {
            font-size: 1.941em;
            line-height: 1.4;
            box-sizing: border-box;
            position: relative;
            color: #666;
            em {
              font-style: normal;
              font-weight: 700;
              color: #000;
            }
          }
        }
      }
    }
  }
  .process {
    text-align: center;
    margin: 100px 0 0;
    .processTit {
      display: inline-block;
      border-radius: 30px;
      font-size: 2.941em;
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
      font-weight: 500;
      line-height: 1.2;
      color: #262626;
      letter-spacing: -0.05em;
      padding: 20px 100px 30px;
      em {
        display: block;
        font-style: normal;
        color: var(--color-1);
      }
    }
    .processList {
      box-sizing: border-box;
      position: relative;
      margin: 100px 0 0;
      ol {
        li {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          box-sizing: border-box;
          position: relative;
          align-items: center;
          align-content: center;
          .icon {
            width: 40.43%;
            box-sizing: border-box;
            position: relative;
            padding: 45px 170px;
            &:before,
            &:after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              background: var(--color-1);
            }
            &:before {
              display: none;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background: var(--color-1);
              z-index: 0;
            }
            &:after {
              display: block;
              width: 8px;
              height: 100%;
              top: 0;
              z-index: -1;
            }
            .graphic {
              box-sizing: border-box;
              border-radius: 50%;
              border: 8px solid var(--color-1);
              background: #fff;
              .img {
                padding: 0 0 100%;
                position: relative;
                height: 0;
                img {
                  display: block;
                  position: absolute;
                  width: auto;
                  height: auto;
                  max-width: 80%;
                  max-height: 80%;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  margin: auto;
                }
              }
            }
          }
          .text {
            flex: 1;
            min-width: 0;
            text-align: left;
            box-sizing: border-box;
            padding: 0 0 0 20px;
            .step {
              display: block;
              line-height: 1;
              font-size: 5.882em;
              font-weight: 700;
              color: #666;
              opacity: 0.2;
              position: relative;
              z-index: 0;
            }
            .msg1 {
              display: block;
              font-size: 2.176em;
              font-weight: 500;
              color: #666;
              position: relative;
              z-index: 1;
              line-height: 1.2;
              letter-spacing: -0.05em;
              padding: 0 0 0 10px;
              margin: -30px 0 0;
            }
            .msg2 {
              display: block;
              border-radius: 20px;
              background: #d4f2ff;
              color: #666;
              line-height: 1.2;
              letter-spacing: -0.05em;
              box-sizing: border-box;
              position: relative;
              text-align: center;
              font-size: 1.47em;
              padding: 30px 20px;
              margin: 30px 0 0 10px;
              &:before {
                content: "";
                display: block;
                position: absolute;
                width: 195px;
                height: 116px;
                background: url(/assets/img/_useGuide/deco4.png) no-repeat left bottom;
                background-size: cover;
                right: -20px;
                top: -30px;
              }
              em {
                font-style: normal;
                font-weight: 700;
                color: #000;
                font-weight: 500;
              }
            }
            .msg3 {
              font-size: 1.764em;
              box-sizing: border-box;
              padding: 0 0 0 20px;
            }
          }
          &.n01 {
            .icon {
              padding-top: 120px;
              &:before {
                display: block;
                top: 0;
              }
            }
          }
          &.n02 {
            .icon {
              padding: 45px 40px;
            }
            .text {
              .msg1 {
                color: var(--color-1);
              }
            }
          }
          &.n04 {
            .icon {
              padding: 45px 40px;
            }
            .text {
              .msg1 {
                color: var(--color-1);
              }
            }
          }
          &.n06 {
            .icon {
              padding-bottom: 120px;
              &:before {
                display: block;
                bottom: 0;
              }
            }
            .text {
              .msg1 {
                color: var(--color-1);
              }
            }
          }
          &.n07 {
            .icon {
              padding-top: 0;
              padding-bottom: 0;
              &:after {
                display: none;
              }
              .graphic {
                border: 0;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1524px) {
    .opening {
      margin: -20px 0 0;
    }
  }
  @media all and (max-width: 1399px) {
    .opening {
      justify-content: space-between;
      padding: 70px 0;
      .img {
        width: 500px;
      }
      .txt {
        .txtUnit {
          .pTxt1 {
            padding: 0 10px 20px;
            margin: 0 0 0 -10px;
          }
          .pTxt2 {
            padding: 0 0 0 10px;
            margin: 50px 0 0 -10px;
            &:before {
              right: -50px;
              top: -50px;
              width: 141px;
              height: 73px;
            }
            p {
              font-size: 1.5em;
              line-height: 1.3;
            }
          }
        }
      }
    }
    .process {
      .processTit {
        border-radius: 20px;
        font-size: 2.941em;
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.15);
        padding: 20px 100px 30px;
      }
      .processList {
        margin: 70px 0 0;
        ol {
          li {
            .icon {
              width: 40%;
              padding: 40px 140px;
            }
            &.n01 {
              .icon {
                padding-top: 100px;
              }
            }
            &.n02 {
              .icon {
                padding: 40px 60px;
              }
            }
            &.n04 {
              .icon {
                padding: 40px 60px;
              }
            }
            &.n06 {
              .icon {
                padding-bottom: 100px;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1199px) {
    .opening {
      padding: 50px 0;
      .img {
        width: 450px;
      }
      .txt {
        .txtUnit {
          .pTxt1 {
            p {
              font-size: 3em;
            }
          }
          .pTxt2 {
            padding: 0 0 0 10px;
            &:before {
              right: -50px;
              top: -50px;
            }
            p {
              font-size: 1.5em;
              line-height: 1.3;
            }
          }
        }
      }
    }
    .process {
      margin: 50px 0 0;
      .processTit {
        border-radius: 20px;
        font-size: 2.5em;
        padding: 20px 50px 30px;
      }
      .processList {
        margin: 50px 0 0;
        ol {
          li {
            .icon {
              width: 35%;
              padding: 30px 100px;
            }
            .text {
              padding: 0 0 0 20px;
              .step {
                font-size: 3.5em;
                margin: 0 0 0 10px;
              }
              .msg1 {
                font-size: 2em;
                padding: 0 0 0 10px;
                margin: -20px 0 0;
              }
              .msg2 {
                border-radius: 15px;
                font-size: 1.3em;
                padding: 20px 20px;
                margin: 20px 0 0 10px;
                &:before {
                  width: 136px;
                  height: 81px;
                  right: -20px;
                  top: -30px;
                }
              }
              .msg3 {
                font-size: 1.5em;
                box-sizing: border-box;
                padding: 0 0 0 20px;
              }
            }
            &.n01 {
              .icon {
                padding-top: 100px;
              }
            }
            &.n02 {
              .icon {
                padding: 30px 40px;
              }
            }
            &.n04 {
              .icon {
                padding: 30px 40px;
              }
            }
            &.n06 {
              .icon {
                padding-bottom: 100px;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 999px) {
    .opening {
      padding: 30px 0 50px;
      .img {
        width: 350px;
      }
      .txt {
        .txtUnit {
          .pTxt1 {
            padding: 0 10px 15px 10px;
            p {
              font-size: 2.5em;
            }
          }
          .pTxt2 {
            padding: 0 0 0 10px;
            margin: 20px 0 0;
            &:before {
              right: -50px;
              top: -50px;
            }
          }
        }
      }
    }
    .process {
      margin: 30px 0 0;
      .processTit {
        font-size: 2.3em;
        padding: 15px 25px 25px;
      }
      .processList {
        margin: 30px 0 0;
        ol {
          li {
            .icon {
              width: 30%;
              padding: 20px 0;
              &:before {
                width: 20px;
                height: 20px;
              }
              &:after {
                width: 4px;
              }
              .graphic {
                border-width: 4px;
              }
            }
            .text {
              .step {
                font-size: 3em;
                margin: 0 0 0 10px;
              }
              .msg1 {
                font-size: 1.5em;
                padding: 0 0 0 10px;
                margin: -20px 0 0;
              }
              .msg2 {
                border-radius: 10px;
                font-size: 1.2em;
                padding: 20px 20px;
                text-align: left;
                &:before {
                  width: 95px;
                  height: 57px;
                  right: -20px;
                  top: -30px;
                }
                br {
                  display: none;
                }
              }
              .msg3 {
                font-size: 1.2em;
                padding: 0 0 0 10px;
              }
            }
            &.n01 {
              .icon {
                padding-top: 50px;
              }
            }
            &.n02 {
              .icon {
                padding: 20px 0;
              }
            }
            &.n04 {
              .icon {
                padding: 20px 0;
              }
            }
            &.n06 {
              .icon {
                padding-bottom: 50px;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 899px) {
    .opening {
      padding: 20px 0 30px;
      .img {
        width: 300px;
      }
      .txt {
        .txtUnit {
          .pTxt1 {
            padding: 0 10px 15px 10px;
            margin: 0 0 0 -10px;
            p {
              font-size: 2.2em;
            }
          }
          .pTxt2 {
            padding: 0 0 0 10px;
            margin: 20px 0 0 -10px;
            p {
              font-size: 1.2em;
            }
            &:before {
              right: -50px;
              top: -50px;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 699px) {
    .opening {
      padding: 20px 0 20px;
      width: 100%;
      justify-content: center;
      .img {
        order: 1;
        width: 300px;
        margin: 0 0 0 -40px;
      }
      .txt {
        width: 100%;
        margin: 20px 0 0;
        .txtUnit {
          text-align: center;
          .pTxt1 {
            padding: 0 10px 15px 10px;
            p {
              font-size: 2.5em;
            }
          }
          .pTxt2 {
            padding: 0 0 0 10px;
            margin: 20px 0 0;
            &:before {
              display: none;
            }
          }
        }
      }
    }
    .process {
      margin: 20px 0 0;
      .processTit {
        display: block;
        font-size: 2.2em;
        padding: 15px 20px 20px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      }
      .processList {
        margin: 20px 0 0;
        ol {
          li {
            .icon {
              width: 20%;
              padding: 10px 0;
              &:before {
                width: 14px;
                height: 14px;
              }
              &:after {
                width: 2px;
                height: 150%;
              }
              .graphic {
                border-width: 2px;
                .img {
                  img {
                    max-width: 60%;
                    max-height: 60%;
                  }
                }
              }
            }
            .text {
              .step {
                font-size: 2.5em;
                margin: 0 0 0 10px;
              }
              .msg1 {
                font-size: 1.3em;
                padding: 0 0 0 10px;
                margin: -15px 0 0;
              }
              .msg2 {
                font-size: 1.1em;
                padding: 10px;
                margin: 10px 0 0 10px;
                &:before {
                  width: 66px;
                  height: 40px;
                  right: 0;
                  top: -20px;
                }
              }
              .msg3 {
                font-size: 1em;
                padding: 0 0 0 10px;
              }
            }
            &.n01 {
              .icon {
                padding-top: 30px;
              }
            }
            &.n02 {
              .icon {
                padding: 10px 0;
              }
            }
            &.n04 {
              .icon {
                padding: 10px 0;
              }
            }
            &.n06 {
              .icon {
                padding-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 499px) {
    .opening {
      .img {
        width: 230px;
        margin: 0 0 0 -40px;
      }
      .txt {
        margin: 20px 0 0;
        .txtUnit {
          text-align: center;
          .pTxt1 {
            p {
              font-size: 1.8em;
            }
          }
          .pTxt2 {
            padding: 0 0 0 10px;
            margin: 15px 0 0;
            p {
              font-size: 1em;
            }
          }
        }
      }
    }
    .process {
      .processTit {
        font-size: 1.5em;
        padding: 10px 20px 12px;
        border-radius: 5px;
      }
    }
  }
  @media all and (max-width: 399px) {
    .opening {
      .img {
        width: 200px;
        margin: 0 0 0 -30px;
      }
    }
    .process {
      .processTit {
        font-size: 1.2em;
      }
      .processList {
        margin: 20px 0 0;
        ol {
          li {
            .icon {
              width: 20%;
              padding: 10px 0;
              &:before {
                width: 12px;
                height: 12px;
              }
              &:after {
                height: 200%;
              }
            }
            .text {
              .step {
                font-size: 2.2em;
                margin: 0;
              }
              .msg1 {
                font-size: 1.2em;
                padding: 0;
                margin: -15px 0 0;
              }
              .msg2 {
                font-size: 1em;
                margin: 5px 0 0;
                &:before {
                  width: 46px;
                  height: 28px;
                  top: -25px;
                }
              }
              .msg3 {
                font-size: 1em;
                padding: 0;
              }
            }
            &.n01 {
              .icon {
                padding-top: 30px;
              }
            }
            &.n02 {
              .icon {
                padding: 10px 0;
              }
            }
            &.n04 {
              .icon {
                padding: 10px 0;
              }
            }
            &.n06 {
              .icon {
                padding-bottom: 30px;
              }
            }
          }
        }
      }
    }
  }
}

/* ==============================
* 회사소개
================================= */
.AboutUs {
  .opening {
    box-sizing: border-box;
    position: relative;
    padding: 200px 20px 100px;
    text-align: center;
    color: #fff;
    margin: -30px 0 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: -1;
      background: url(/assets/img/_AboutUs/deco_bg.jpg) no-repeat center top;
      background-size: contain;
    }
    > * {
      box-sizing: border-box;
      line-height: 1.2;
    }
    .pTxt1 {
      font-size: 2.176em;
    }
    .pTxt2 {
      font-size: 4.705em;
      font-weight: 700;
      margin: 20px 0 0;
      br {
        display: none;
      }
    }
    .pImg1 {
      display: block;
      width: 250px;
      margin: 100px auto 0;
    }
  }
  .middle1 {
    .hTxt1 {
      text-align: center;
      margin: 50px 0 0;
      > * {
        display: block;
        box-sizing: border-box;
        position: relative;
        line-height: 1.2;
        color: #010101;
        word-wrap: break-word;
        word-break: keep-all;
        white-space: normal;
        em {
          font-style: normal;
          color: var(--color-1);
        }
      }
      .sTxt1 {
        font-size: 2.47em;
        font-weight: 800;
        letter-spacing: -0.05em;
      }
      .sTxt2 {
        font-size: 1.47em;
        font-weight: 200;
        letter-spacing: 0.2em;
        margin: 30px 0 0;
      }
    }
    .pTxtWr {
      text-align: center;
      margin: 80px 0 0;
      word-wrap: break-word;
      word-break: keep-all;
      white-space:normal > * {
        box-sizing: border-box;
        line-height: 1.5;
      }
      .pTxt1 {
        font-size: 1.235em;
        color: #010101;
        margin: 50px 0 0;
        &:first-child {
          margin: 0;
        }
      }
      .pLink1 {
        display: inline-flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        box-sizing: border-box;
        line-height: 1;
        border-radius: 5px;
        border: 1px solid #9d9d9d;
        padding: 20px 20px;
        width: 100%;
        max-width: 400px;
        color: #000;
        font-size: 1.059em;
        margin: 50px 0 0;
        transition: all 0.3s ease;
        span {
          margin: -4px 0 0;
        }
        i {
          font-size: 1.588em;
          margin: 0 0 0 10px;
        }
        &:hover,
        &:focus-visible {
          border-color: var(--color-1);
          background: var(--color-1);
          color: #fff;
        }
      }
    }
  }
  .middle2 {
    .hTxt1 {
      font-size: 2.47em;
      line-height: 1;
      text-align: center;
      margin: 100px 0 0;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
      > * {
        display: block;
        line-height: 1.2;
        box-sizing: border-box;
        color: #111;
        em {
          font-style: normal;
          color: var(--color-1);
        }
        &.sTxt1 {
          font-weight: 300;
        }
        &.sTxt2 {
          font-weight: 700;
          margin: 10px 0 0;
        }
      }
    }
    .pListWr {
      padding: 70px 0 420px;
      box-sizing: border-box;
      position: relative;
      margin: 50px 0 0;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2000px;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: #efefef;
        z-index: -1;
      }
      ol {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;
        > li {
          box-sizing: border-box;
          padding: 0 50px;
          .circleBase {
            box-sizing: border-box;
            border: solid;
            padding: 0 0 100%;
            position: relative;
            z-index: 0;
            border-radius: 50%;
            &:before {
              content: "";
              display: block;
              position: absolute;
              width: 13px;
              height: 13px;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 50%;
              z-index: 1;
            }
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 1px;
              top: 50%;
              z-index: -1;
            }
            .circleInner {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              justify-content: center;
              border-radius: 50%;
              background: #fff;
              .txt {
                font-size: 1.176em;
                color: #111;
                text-align: center;
                line-height: 1.2;
              }
            }
          }
          &:nth-child(1) {
            .circleBase {
              &:before {
                display: none;
              }
              &:after {
                background: #333;
                left: 100%;
              }
              width: 180px;
              border-width: 1px;
              border-color: #333;
            }
          }
          &:nth-child(2) {
            z-index: 2;
            &:after {
              background: var(--color-1);
            }
            .circleBase {
              width: 280px;
              border-width: 0;
              &:before {
                background: var(--color-1);
                left: -4px;
              }
              .circleInner {
                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  box-sizing: border-box;
                  border: 5px solid var(--color-1);
                  border-radius: 50%;
                }
                .txt {
                  font-size: 1.882em;
                  font-weight: 900;
                }
              }
            }
          }
          &:nth-child(3) {
            .circleBase {
              &:before {
                background: #333;
                left: -6px;
              }
              &:after {
                background: #333;
                right: 100%;
              }
              width: 180px;
              border-width: 1px;
              border-color: #333;
            }
          }
        }
      }
      ul {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        background: #fff;
        width: auto;
        padding: 22px 33px;
        border-radius: 999px;
        margin: 130px 0 0;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 70px;
          left: 50%;
          top: -100px;
          background: #000;
        }
        > li {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          width: 160px;
          height: 160px;
          border-radius: 50%;
          align-items: center;
          align-content: center;
          justify-content: center;
          border: solid;
          border-width: 2px;
          flex-shrink: 0;
          margin: 0 -11px;
          font-size: 1.412em;
          color: #111;
          font-weight: 700;
          &:nth-child(odd) {
            border-color: var(--color-1);
          }
          &:nth-child(even) {
            border-color: #888;
          }
        }
      }
    }
  }
  .bottom {
    margin: 100px 0 0;
    .hTxt1 {
      font-size: 2.47em;
      line-height: 1.2;
      text-align: center;
      color: #111;
      font-weight: 700;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
      em {
        font-style: normal;
        color: var(--color-1);
      }
    }
    .pList1 {
      display: block;
      margin: 80px auto 0;
      box-sizing: border-box;
      width: 100%;
      max-width: 1044px;
      li {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin: 0 0 50px;
        &:last-child {
          margin: 0;
        }
        .imgBox {
          width: 232px;
          box-sizing: border-box;
          border-radius: 50%;
          overflow: hidden;
          .imgContainer {
            display: block;
            height: 0;
            padding: 0 0 100%;
            box-sizing: border-box;
            position: relative;
            background: #eee;
            overflow: hidden;
            img {
              display: block;
              position: absolute;
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              transition: all 0.3s ease;
            }
          }
        }
        .txtBox {
          flex: 1;
          min-width: 0;
          box-sizing: border-box;
          padding: 0 0 0 30px;
          word-wrap: break-word;
          word-break: keep-all;
          white-space: normal;
          .pTit1 {
            font-size: 2.176em;
            font-weight: 700;
            line-height: 1.2;
            color: #111;
          }
          .pTxt1 {
            font-size: 1.235em;
            line-height: 1.35;
            letter-spacing: -0.05em;
            margin: 30px 0 0;
            color: #111;
          }
        }
      }
    }
  }
  @media all and (max-width: 1524px) {
    .opening {
      margin: -20px 0 0;
    }
  }
  @media all and (max-width: 1399px) {
    .middle1 {
      .pTxtWr {
        margin: 50px 0 0;
        > * {
          line-height: 1.3;
        }
        .pTxt1 {
          font-size: 1.2em;
          margin: 30px 0 0;
          br {
            display: none;
          }
        }
      }
    }
  }
  @media all and (max-width: 1299px) {
    .opening {
      padding: 100px 50px;
      .pTxt1 {
        font-size: 2em;
      }
      .pTxt2 {
        font-size: 4em;
        line-height: 1;
      }
    }
    .middle1 {
      .hTxt1 {
        margin: 50px 0 0;
        .sTxt1 {
          font-size: 2.2em;
          letter-spacing: -0.05em;
          em {
          }
        }
        .sTxt2 {
          font-size: 1.3em;
          letter-spacing: 0;
          margin: 20px 0 0;
        }
      }
    }
  }
  @media all and (max-width: 1099px) {
    .opening {
      .pTxt1 {
        font-size: 2em;
      }
      .pTxt2 {
        margin: 10px 0 0;
        br {
          display: block;
        }
      }
      .pImg1 {
        width: 220px;
        margin: 70px auto 0;
      }
    }
  }
  @media all and (max-width: 999px) {
    .middle1 {
      .hTxt1 {
        margin: 30px 0 0;
        .sTxt1 {
          em {
            display: block;
          }
        }
      }
    }
    .middle2 {
      .hTxt1 {
        font-size: 2.2em;
        margin: 50px 0 0;
      }
      .pListWr {
        padding: 50px 0 270px;
        margin: 30px 0 0;
        ol {
          margin: 0 -50px;
          > li {
            padding: 0 50px;
            flex: 1;
            min-width: 0;
            &:nth-child(1),
            &:nth-child(3) {
              .circleBase {
                width: 100%;
              }
            }
            &:nth-child(2) {
              .circleBase {
                width: 100%;
                .circleInner {
                  .txt {
                    font-size: 1.5em;
                  }
                }
              }
            }
          }
        }
        ul {
          padding: 10px 20px;
          margin: 70px 0 0;
          &:before {
            height: 50px;
            left: 50%;
            top: auto;
            bottom: 100%;
            margin: 0 0 10px;
          }
          > li {
            width: 140px;
            height: 140px;
            font-size: 1.2em;
          }
        }
      }
    }
    .bottom {
      margin: 50px 0 0;
      .hTxt1 {
        font-size: 2.2em;
      }
      .pList1 {
        margin: 40px 0 0;
        li {
          margin: 0 0 30px;
          .imgBox {
            width: 200px;
          }
          .txtBox {
            .pTit1 {
              font-size: 2em;
            }
            .pTxt1 {
              font-size: 1.2em;
              line-height: 1.3;
              margin: 20px 0 0;
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 799px) {
    .opening {
      padding: 50px 40px;
      .pTxt1 {
        font-size: 1.5em;
      }
      .pTxt2 {
        font-size: 3em;
      }
      .pImg1 {
        width: 200px;
        margin: 50px auto 0;
      }
    }
    .middle1 {
      .pTxtWr {
        margin: 30px 0 0;
      }
    }
    .middle2 {
      .pListWr {
        padding: 30px 0;
        margin: 30px 0 0;
        ol {
          margin: 0;
          align-items: flex-start;
          align-content: flex-start;
          padding: 0 0 105px;
          > li {
            padding: 0 20px;
            &:after {
              top: 40px;
            }
            .circleBase {
              padding: 0;
              border-radius: 10px;
              height: 80px;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              justify-content: center;
              .circleInner {
                position: relative;
                height: 100%;
                left: auto;
                top: auto;
                border-radius: 10px;
                .txt {
                  font-size: 1em;
                }
              }
            }
            &:nth-child(1) {
              padding-left: 0;
            }
            &:nth-child(2) {
              .circleBase {
                width: 100%;
                &:before {
                  left: -5px;
                }
                &:after {
                  border-radius: 10px;
                }
                .circleInner {
                  &:before {
                    border-radius: 10px;
                    border-width: 3px;
                  }
                  .txt {
                    font-size: 1.2em;
                  }
                }
              }
            }
            &:nth-child(3) {
              padding-right: 0;
              .circleBase {
                &:before {
                  left: -7px;
                }
              }
            }
          }
        }
        ul {
          padding: 10px;
          margin: 0;
          top: auto;
          bottom: 0;
          width: 100%;
          left: 0;
          transform: none;
          border-radius: 20px;
          z-index: 999;
          &:before {
            height: 20px;
            left: 50%;
            top: auto;
            bottom: 100%;
            margin: 0 0 10px;
          }
          > li {
            flex: 1;
            min-width: 0;
            width: auto;
            height: auto;
            font-size: 1.2em;
            border-radius: 15px;
            padding: 10px 0;
            margin: 0 -5px;
            &:first-child {
              margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .bottom {
      margin: 30px 0 0;
      .pList1 {
        margin: 30px 0 0;
        li {
          margin: 0 0 20px;
          .imgBox {
            width: 150px;
          }
          .txtBox {
            padding: 0 0 0 20px;
            .pTit1 {
              font-size: 1.75em;
            }
            .pTxt1 {
              font-size: 1.2em;
              line-height: 1.3;
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 599px) {
    .opening {
      padding: 40px 40px;
      .pTxt1 {
        font-size: 1.2em;
      }
      .pTxt2 {
        font-size: 2em;
      }
      .pImg1 {
        width: 150px;
        margin: 30px auto 0;
      }
    }
    .middle1 {
      .hTxt1 {
        margin: 20px 0 0;
        .sTxt1 {
          font-size: 1.8em;
        }
        .sTxt2 {
          font-size: 1.1em;
          margin: 10px 0 0;
        }
      }
      .pTxtWr {
        margin: 20px 0 0;
        .pTxt1 {
          font-size: 1em;
          margin: 20px 0 0;
        }
        .pLink1 {
          display: flex;
          max-width: none;
          margin: 20px 0 0;
        }
      }
    }
    .middle2 {
      .hTxt1 {
        font-size: 2em;
        margin: 30px 0 0;
      }
    }
    .bottom {
      .hTxt1 {
        font-size: 2em;
      }
      .pList1 {
        margin: 20px 0 0;
        li {
          align-items: flex-start;
          align-content: flex-start;
          padding: 0 0 20px;
          border-bottom: 1px dashed #ddd;
          &:last-child {
            padding: 0;
            border-bottom: 0;
          }
          .imgBox {
            width: 100px;
          }
          .txtBox {
            .pTit1 {
              font-size: 1.3em;
            }
            .pTxt1 {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 499px) {
    .middle2 {
      .hTxt1 {
        font-size: 1.5em;
        margin: 20px 0 0;
        .sTxt2 {
          margin: 5px 0 0;
        }
      }
      .pListWr {
        padding: 20px 0;
        margin: 20px 0 0;
        ol {
          > li {
            padding: 0 10px;
            &:nth-child(2) {
              .circleBase {
                .circleInner {
                  .txt {
                    font-size: 1em;
                  }
                }
              }
            }
          }
        }
        ul {
          > li {
            font-size: 1em;
          }
        }
      }
    }
    .bottom {
      margin: 20px 0 0;
      .hTxt1 {
        font-size: 1.5em;
      }
    }
  }
  @media all and (max-width: 399px) {
    .middle2 {
      .pListWr {
        ol {
          display: block;
          padding: 0;
          > li {
            display: block;
            width: 50%;
            flex: none;
            padding: 0 0 20px;
            .circleBase {
              height: 40px;
              &:after {
                width: 1px;
                height: 20px;
              }
              .circleInner {
                .txt {
                  br {
                    display: none;
                  }
                }
              }
            }
            &:nth-child(1) {
              .circleBase {
                &:after {
                  left: 50%;
                  top: 100%;
                }
              }
            }
            &:nth-child(2) {
              .circleBase {
                &:before {
                  left: 50%;
                  transform: translateX(-50%);
                  top: -5px;
                }
                .circleInner {
                }
              }
            }
            &:nth-child(3) {
              padding: 0;
              .circleBase {
                &:before {
                  left: 50%;
                  transform: translateX(-50%);
                  top: -5px;
                }
                &:after {
                  right: 50%;
                  top: auto;
                  bottom: 100%;
                }
              }
            }
          }
        }
        ul {
          position: absolute;
          left: calc(50% + 20px);
          top: 0;
          bottom: auto;
          width: calc(50% - 20px);
          height: 100%;
          display: flex;
          flex-flow: column;
          flex-wrap: wrap;
          margin: 0;
          padding: 10px;
          &:before {
            width: 20px;
            height: 1px;
            left: auto;
            right: 100%;
            top: 50%;
          }
          > li {
            flex: 1;
            min-height: 0;
            width: 100%;
            height: auto;
            padding: 0;
            margin: 0 0 5px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    .bottom {
      .hTxt1 {
        font-size: 1.5em;
      }
      .pList1 {
        margin: 20px 0 0;
        li {
          .imgBox {
            width: 70px;
          }
          .txtBox {
            padding: 0 0 0 15px;
            .pTit1 {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}

/* ==============================
* 상품주문, 통관진행, 환율안내, 물류/통관, 부가서비스, FAQ, OEM판촉 프로모션, 사용자 가이드
================================= */
.commonHtml {
  margin: -30px 0 0;
  .gap1 {
    height: 100px;
    clear: both;
  }
  .gap2 {
    height: 50px;
    clear: both;
  }
  .title1 {
    font-size: 1.8em;
    letter-spacing: -0.05em;
    color: #111;
    line-height: 1.2;
    margin: 0 0 25px;
    font-weight: 700;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
    &.c1 {
      color: var(--color-1);
    }
  }
  .title2 {
    font-size: 1.5em;
    letter-spacing: -0.05em;
    color: #010101;
    line-height: 1.2;
    margin: 0 0 20px;
    font-weight: 700;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
  }
  .title3 {
    font-size: 2.47em;
    font-weight: 700;
    color: var(--color-1);
    text-align: center;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
  }
  .text1 {
    font-size: 1.3em;
    line-height: 1.26;
    color: #010101;
    letter-spacing: -0.05em;
    font-weight: 500;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
  }
  .text2 {
    background: #efefef;
    box-sizing: border-box;
    padding: 30px;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
    em {
      font-style: normal;
      font-weight: 500;
    }
    .pTxt1 {
      font-size: 1.1em;
      line-height: 1.5;
      color: #010101;
    }
    .pTxt2 {
      font-size: 1em;
      line-height: 1.5;
      margin: 15px 0 0;
      &.c1 {
        color: var(--color-1);
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  .text3 {
    font-size: 1.588em;
    color: #010101;
    font-weight: 600;
    text-align: center;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
  }
  .text4 {
    font-size: 1.47em;
    color: #000;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: normal;
  }
  .opening {
    box-sizing: border-box;
    position: relative;
    text-align: center;
    padding: 50px 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
      z-index: -1;
      background-color: #eee;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    p {
      display: block;
      box-sizing: border-box;
      line-height: 1.2;
      color: #fff;
      text-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
      letter-spacing: -0.05em;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
    }
    .pTxt1 {
      font-size: 2em;
    }
    .pTxt2 {
      font-size: 2.5em;
      font-weight: 900;
      margin: 5px 0 0;
    }
    .pImg1 {
      display: block;
      width: 250px;
      margin: 30px auto 0;
    }
  }
  .faqForm {
    max-width: 1312px;
    margin: 0 auto;
    .pList1 {
      > li {
        margin: 0 0 60px;
        &:last-child {
          margin: 0;
        }
        .boxQ {
          font-size: 1.294em;
          font-weight: 700;
          margin: 0 0 20px;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          > * {
            box-sizing: border-box;
            position: relative;
            line-height: 1.2;
          }
          .sNum {
            color: var(--color-1);
            height: 100%;
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 3px;
              background: var(--color-1);
              left: 0;
              bottom: -10px;
            }
          }
          .sTxt {
            flex: 1;
            min-width: 0;
            color: #000;
            padding: 0 0 0 10px;
            word-wrap: break-word;
            word-break: keep-all;
            white-space: normal;
          }
        }
        .boxA {
          .msg {
            font-size: 1.059em;
            line-height: 1.36;
            color: #000;
          }
        }
      }
    }
    .pBtn1 {
      display: block;
      width: 315px;
      height: 77px;
      margin: 70px auto 0;
      &:before {
        height: 100%;
      }
      span {
        font-size: 1.118em;
      }
    }
  }
  &.OrderGuide {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/OrderGuide_bg1.jpg);
      }
    }
    .processSumm {
      ol {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;
        li {
          flex: 1;
          min-width: 0;
          text-align: center;
          .icon {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            background: url(/assets/img/_contents/OrderGuide_spr1.png) no-repeat;
          }
          .numb {
            box-sizing: border-box;
            position: relative;
            margin: 20px 0 0;
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 2px;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 0;
            }
            span {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              position: relative;
              z-index: 1;
              margin: 0 auto;
              color: #fff;
              font-weight: 500;
              font-size: 0.941em;
              align-items: center;
              align-content: center;
              justify-content: center;
            }
          }
          .text {
            font-size: 1em;
            font-weight: 500;
            line-height: 1.45;
            font-weight: 500;
            margin: 15px 0 0;
          }
        }
      }
      &.type1688 {
        li {
          .numb {
            &:after {
              background: #f95902;
            }
            span {
              background: #f95902;
            }
          }
          .text {
            color: #f95902;
          }
          &.n01 {
            .icon {
              background-position: 0 0;
            }
          }
          &.n02 {
            .icon {
              background-position: -110px 0;
            }
          }
          &.n03 {
            .icon {
              background-position: -220px 0;
            }
          }
          &.n04 {
            .icon {
              background-position: -330px 0;
            }
          }
          &.n05 {
            .icon {
              background-position: -440px 0;
            }
          }
          &.n06 {
            .icon {
              background-position: -550px 0;
            }
          }
          &.n07 {
            .icon {
              background-position: -660px 0;
            }
          }
          &.n08 {
            .icon {
              background-position: -770px 0;
            }
          }
          &.n09 {
            .icon {
              background-position: -880px 0;
            }
          }
        }
      }
      &.typeSeller {
        li {
          .numb {
            &:after {
              background: #0e62df;
            }
            span {
              background: #0e62df;
            }
          }
          .text {
            color: #0e62df;
          }
          &.n01 {
            .icon {
              background-position: 0 -110px;
            }
          }
          &.n02 {
            .icon {
              background-position: -110px -110px;
            }
          }
          &.n03 {
            .icon {
              background-position: -220px -110px;
            }
          }
          &.n04 {
            .icon {
              background-position: -330px -110px;
            }
          }
          &.n05 {
            .icon {
              background-position: -440px -110px;
            }
          }
          &.n06 {
            .icon {
              background-position: -550px -110px;
            }
          }
          &.n07 {
            .icon {
              background-position: -660px -110px;
            }
          }
        }
      }
    }
    .processDetail {
      padding: 110px 0;
      box-sizing: border-box;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2000px;
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: #efefef;
        z-index: -1;
      }
      ol {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 20px;
        li {
          background: #fff;
          box-sizing: border-box;
          padding: 30px 20px;
          .head {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            .numb {
              font-size: 14px;
              color: #fff;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              justify-content: center;
              margin: 4px 5px 0 0;
            }
            .text {
              flex: 1;
              min-width: 0;
              box-sizing: border-box;
              font-size: 1.2em;
              line-height: 1.2;
              letter-spacing: -0.05em;
            }
          }
          .body {
            margin: 15px 0 0;
            word-wrap: break-word;
            word-break: keep-all;
            white-space: normal;
            .pTxt1 {
              font-size: 1em;
              color: #010101;
              line-height: 1.3;
            }
            .sTxt1 {
              font-size: 0.941em;
              line-height: 1.25;
              margin: 10px 0 0;
            }
          }
        }
      }
      &.type1688 {
        ol {
          li {
            .head {
              .numb {
                background: #f95902;
              }
              .text {
                color: #f95902;
              }
            }
          }
        }
      }
      &.typeSeller {
        ol {
          li {
            .head {
              .numb {
                background: #0e62df;
              }
              .text {
                color: #0e62df;
              }
            }
            &:last-child {
              grid-column: 2;
            }
          }
        }
      }
    }
  }
  &.CurrencyRatio {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/CurrencyRatio_bg1.jpg);
      }
    }
  }
  &.PriceGuide {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/PriceGuide_bg1.jpg);
      }
    }
    .infoMsg {
      margin: 0 0 40px;
    }
    .infoBox1 {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      background: #efefef;
      padding: 60px 80px;
      .boxImg {
        width: 230px;
        img {
          display: block;
          width: 100%;
        }
      }
      .boxTxt {
        flex: 1;
        min-width: 0;
        box-sizing: border-box;
        padding: 0 0 0 50px;
        .pTit1 {
          font-size: 1.529em;
          line-height: 1.2;
          color: #010101;
          margin: 0 0 20px;
        }
        .pTxt1 {
          font-size: 1.294em;
          color: var(--color-1);
        }
        .pTxt2 {
          font-size: 1.176em;
          color: #010101;
          line-height: 1.625;
          margin: 20px 0 0;
        }
      }
    }
    .infoBox2 {
      .type1 {
        display: block;
        li {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          box-sizing: border-box;
          margin: 0 0 30px;
          &:last-child {
            margin: 0;
          }
          &:first-child {
            .body {
              dl {
                dt {
                  display: block;
                }
              }
            }
          }
          .head {
            width: 50%;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            align-items: baseline;
            .pTit1 {
              width: 100%;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              height: 75px;
              align-items: center;
              align-content: center;
              position: relative;
              > * {
                box-sizing: border-box;
                position: relative;
                z-index: 1;
              }
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                left: 30px;
                top: 50%;
                z-index: 0;
                background-size: 5px 1px;
              }
              .numb {
                font-size: 13px;
                color: #fff;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                margin: 0 7px 0 0;
              }
              .text {
                font-size: 1.2em;
                line-height: 1.2;
                letter-spacing: -0.05em;
                background: #fff;
                padding: 0 10px 0 0;
              }
            }
            .pTxt1 {
              width: 100%;
              box-sizing: border-box;
              padding: 0 0 0 25px;
              color: #111;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
            }
          }
          .body {
            flex: 1;
            min-width: 0;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;
            dl {
              position: relative;
              dt,
              dd {
                text-align: center;
                box-sizing: border-box;
                width: 100%;
                word-wrap: break-word;
                word-break: keep-all;
                white-space: normal;
              }
              dt {
                position: absolute;
                left: 0;
                bottom: 100%;
                font-size: 1.5em;
                color: #010101;
                line-height: 1;
                margin: 0 0 15px;
                display: none;
              }
              dd {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                background: #efefef;
                height: 75px;
                align-items: center;
                align-content: center;
                justify-content: center;
                line-height: 1.3;
                font-weight: 500;
                padding: 0 20px;
              }
            }
            &.has1 {
              dl {
                width: 100%;
              }
            }
            &.has2 {
              dl {
                width: calc(50% - 8px);
              }
            }
            &.has2Merge {
              dl {
                width: 50%;
                &:first-child {
                  dd {
                    width: 200%;
                  }
                }
                &:last-child {
                  dd {
                    display: none;
                  }
                }
              }
            }
          }
        }
        &.CN {
          li {
            .head {
              .pTit1 {
                &:after {
                  background-image: radial-gradient(#f95902 1px, transparent 1px);
                }
                .numb {
                  background: #f95902;
                }
                .text {
                  color: #f95902;
                }
              }
            }
            .body {
              dl {
                dd {
                  color: #f95902;
                }
              }
            }
          }
        }
        &.KR {
          li {
            .head {
              .pTit1 {
                &:after {
                  background-image: radial-gradient(var(--color-1) 1px, transparent 1px);
                }
                .numb {
                  background: var(--color-1);
                }
                .text {
                  color: var(--color-1);
                }
              }
            }
            .body {
              dl {
                dd {
                  color: var(--color-1);
                }
              }
            }
          }
        }
      }
    }
    .infoBox3 {
      margin: 30px 0 0;
      .type1 {
        > li {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          background: #efefef;
          padding: 20px 30px;
          margin: 0 0 20px;
          &:last-child {
            margin: 0;
          }
          &:nth-child(2) {
            background: #dbe8fa;
          }
          .boxImg {
            width: 150px;
            height: 150px;
            background: url(/assets/img/_contents/PriceGuide_spr1.png) no-repeat;
            &.ico1 {
              background-position: 0 0;
            }
            &.ico2 {
              background-position: -160px 0;
            }
            &.ico3 {
              background-position: -320px 0;
            }
          }
          .boxTxt {
            flex: 1;
            min-width: 0;
            box-sizing: border-box;
            padding: 0 0 0 30px;
            h3 {
              font-size: 1.2em;
              font-weight: 700;
              color: #000;
              line-height: 1.2;
              margin: 0 0 15px;
            }
            ul {
              li {
                box-sizing: border-box;
                position: relative;
                padding: 0 0 0 15px;
                line-height: 1.3;
                word-wrap: break-word;
                word-break: keep-all;
                white-space: normal;
                margin: 0 0 10px;
                color: #333;
                font-weight: 500;
                &:last-child {
                  margin: 0;
                }
                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 8px;
                  height: 2px;
                  background: #333;
                  left: 0;
                  top: 10px;
                }
                strong {
                  font-weight: 800;
                  color: #000;
                }
                em {
                  font-style: normal;
                  color: var(--color-1);
                  margin: 0 0 0 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.AdditionalServices {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/AdditionalServices_bg1.jpg);
      }
    }
    .infoBox1 {
      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        li {
          border-radius: 20px;
          box-sizing: border-box;
          padding: 50px 30px 30px;
          .numb {
            display: block;
            font-size: 1.588em;
            color: #000;
            line-height: 1;
          }
          .item {
            display: block;
            font-size: 1.647em;
            font-weight: 700;
            line-height: 1;
            margin: 15px 0 0;
          }
          .icon {
            width: 200px;
            height: 200px;
            margin: 40px auto 0;
            background: url(/assets/img/_contents/AdditionalServices_spr1.png) no-repeat;
          }
          .desc {
            border-radius: 15px;
            background: #fff;
            height: 100px;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin: 20px 0 0;
            padding: 0 20px;
            p {
              font-size: 1.176em;
              line-height: 1.35;
              letter-spacing: -0.05em;
              text-align: center;
              color: #000;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
            }
          }
          &.typeFree {
            background: #efefef;
            .item {
              color: #000;
            }
          }
          &.typePaid {
            background: #dbe8fa;
            .item {
              color: var(--color-1);
            }
          }
          &.n01 {
            .icon {
              background-position: 0 0;
            }
          }
          &.n02 {
            .icon {
              background-position: -210px 0;
            }
          }
          &.n03 {
            .icon {
              background-position: -420px 0;
            }
          }
          &.n04 {
            .icon {
              background-position: -630px 0;
            }
          }
          &.n05 {
            .icon {
              background-position: -840px 0;
            }
          }
          &.n06 {
            .icon {
              background-position: -1050px 0;
            }
          }
        }
      }
    }
  }
  &.Faq {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/Faq_bg1.jpg);
      }
    }
    .imgBox1 {
    }
    .faqBox1 {
      ul {
        li {
          box-sizing: border-box;
          position: relative;
          padding: 30px 0;
          &:last-child {
            padding-bottom: 0;
          }
          &:nth-child(odd) {
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 2000px;
              height: 100%;
              left: 50%;
              top: 0;
              transform: translateX(-50%);
              background: #efefef;
              z-index: -1;
            }
          }
          .boxQ {
            font-size: 1.412em;
            font-weight: 700;
            color: #000;
            letter-spacing: -0.05em;
            line-height: 1.2;
            button {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              width: 100%;
              line-height: 1;
              text-align: left;
              border: 0;
              background: transparent;
              transition: all 0.3s ease;
              span {
                line-height: 1;
              }
              i {
                margin: 5px 0 0 5px;
                &.off {
                }
                &.on {
                  display: none;
                }
              }
              &:hover,
              &:focus-visible {
                color: var(--color-1);
              }
            }
          }
          .boxA {
            display: none;
            font-size: 1.059em;
            line-height: 1.5625;
            color: #010101;
            letter-spacing: -0.05em;
            margin: 30px 0 0;
            .pTxt1,
            .pTxt2 {
              margin: 0 0 20px;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
              &:last-child {
                margin: 0;
              }
            }
          }
          &.on {
            .boxQ {
              button {
                color: var(--color-1);
                i {
                  &.off {
                    display: none;
                  }
                  &.on {
                    display: block;
                  }
                }
              }
            }
            .boxA {
              display: block;
            }
          }
        }
      }
    }
  }
  &.OemPromo {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/OemPromo_bg1.jpg);
      }
    }
    .intro {
      max-width: 1312px;
      margin: 0 auto;
      .pList1 {
        margin: 40px 0 0;
        ul {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          margin: -30px;
          justify-content: center;
          li {
            width: 25%;
            box-sizing: border-box;
            padding: 30px;
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
    .flow {
      max-width: 1312px;
      margin: 0 auto;
      .pTit1 {
        display: block;
        width: 230px;
        margin: 0 auto;
      }
      .pList1 {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 0 115px;
        position: relative;
        margin: 35px 0 0;
        &:before,
        &:after {
          content: "";
          display: block;
          position: absolute;
          z-index: -1;
          box-sizing: border-box;
        }
        &:before {
          width: 20px;
          height: 20px;
          border: solid;
          border-width: 0 5px 5px 0;
          border-color: var(--color-1);
          transform: rotate(-45deg);
          right: 0;
          top: 82px;
        }
        &:after {
          width: 100%;
          height: 5px;
          left: 0;
          top: 89px;
          background: var(--color-1);
        }
        li {
          .boxIco {
            box-sizing: border-box;
            border-radius: 50%;
            border: 2px solid var(--color-1);
            width: 183px;
            height: 183px;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            background: #fff;
            .ico {
              width: 130px;
              height: 130px;
              background: url(/assets/img/_contents/OemPromo_spr1.png) no-repeat;
              &.n01 {
                background-position: 0 0;
              }
              &.n02 {
                background-position: -140px 0;
              }
              &.n03 {
                background-position: -280px 0;
              }
              &.n04 {
                background-position: -420px 0;
              }
              &.n05 {
                background-position: -560px 0;
              }
            }
          }
          .boxTxt {
            text-align: center;
            margin: 20px 0 0;
            p {
              font-size: 1.353em;
              color: #010101;
              line-height: 1.36;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
            }
          }
        }
      }
    }
    .message {
      max-width: 1312px;
      margin: 0 auto;
      .box {
        border-radius: 20px;
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
        background: url(/assets/img/_contents/OemPromo_bg2.jpg) no-repeat center center;
        background-size: cover;
        padding: 70px 20px;
        height: 665px;
        > * {
          box-sizing: border-box;
          word-wrap: break-word;
          word-break: keep-all;
          white-space: normal;
        }
        .pTxt1 {
          font-size: 2.705em;
          font-weight: 700;
          opacity: 0.5;
          color: #fff;
          line-height: 1;
        }
        .pTxt2 {
          font-size: 3.058em;
          font-weight: 600;
          color: #fff;
          line-height: 1;
          margin: auto auto 0;
        }
        .pTxt3 {
          font-size: 1.705em;
          color: var(--color-1);
          font-weight: 700;
          line-height: 1.25;
          text-shadow: 3px 0 0 #fff, -3px 0 0 #fff, 0 3px 0 #fff, 0 -3px 0 #fff, 2px 2px 0 #fff, -2px -2px 0 #fff, -2px 2px 0 #fff, 2px -2px 0 #fff;
          margin: 20px 0 0;
        }
        .pTxt4 {
          font-size: 1.47em;
          color: #fff;
          line-height: 1.25;
          margin: 50px auto auto;
        }
        .pTxt5 {
          font-size: 2.47em;
          color: #fff;
          font-weight: 700;
          line-height: 1;
        }
      }
    }
  }
  &.UserGuide {
    .opening {
      &:after {
        background-image: url(/assets/img/_contents/UserGuide_bg1.jpg);
      }
    }
    .intro {
      max-width: 1312px;
      margin: 0 auto;
      box-sizing: border-box;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 2000px;
        height: 1037px;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        background: url(/assets/img/_contents/UserGuide_bg2.jpg) no-repeat center top;
        background-size: cover;
        z-index: -1;
      }
      .pMsg1 {
        .text4 {
          margin: 40px 0 0;
        }
      }
      .pDesc1 {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 150px 0 0;
        dt,
        dd {
          box-sizing: border-box;
        }
        dt {
          width: 257px;
          height: 257px;
          border-radius: 50%;
          border: 1px solid var(--color-1);
          background: #fff;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: center;
          font-size: 1.705em;
          color: var(--color-1);
          text-align: center;
        }
        dd {
          box-sizing: border-box;
          padding: 0 0 0 30px;
          min-width: 0;
          flex: 1;
          div {
            margin: 0 0 50px;
            font-size: 1.353em;
            letter-spacing: -0.04em;
            color: #000;
            word-wrap: break-word;
            word-break: keep-all;
            white-space: normal;
            &:last-child {
              margin: 0;
            }
            em {
              font-weight: 800;
              color: var(--color-1);
              font-style: normal;
            }
          }
        }
      }
    }
    .platform {
      max-width: 1100px;
      margin: 0 auto;
      h3 {
        font-size: 1.705em;
        color: var(--color-1);
        text-align: center;
        font-weight: 700;
        margin: 0 0 30px;
      }
      ul {
        li {
          background: #dbe8fa;
          border-radius: 999px;
          padding: 20px;
          margin: 0 0 20px;
          &:last-child {
            margin: 0;
          }
          dl {
            text-align: center;
            dt,
            dd {
              box-sizing: border-box;
              line-height: 1.2;
              color: #000;
              word-wrap: break-word;
              word-break: keep-all;
              white-space: normal;
            }
            dt {
              font-size: 1.529em;
            }
            dd {
              font-size: 1.118em;
              margin: 10px 0 0;
            }
          }
        }
      }
    }
    .tblPkg1 {
      margin: 30px 0 0;
      dl {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        dt,
        dd {
          box-sizing: border-box;
        }
        dt {
          width: 84px;
          font-size: 1.118em;
          font-weight: 700;
          color: var(--color-1);
          line-height: 1.2;
          background: #dbe8fa;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: center;
          padding: 0 0 60px;
          box-sizing: border-box;
          position: relative;
          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 60px;
            left: 0;
            bottom: 0;
            background: #fff;
          }
        }
        dd {
          flex: 1;
          min-width: 0;
          .sTit1 {
            display: block;
            text-align: center;
            background: #efefef;
            color: var(--color-1);
            font-weight: 700;
            padding: 15px 0;
          }
          table {
            position: relative;
            border-collapse: collapse;
            border-spacing: 0px;
            table-layout: fixed;
            width: 100%;
            clear: both;
            text-align: center;
            .col1 {
              width: 120px;
            }
            .col2 {
              width: auto;
            }
            th,
            td {
              font-size: 16px;
              font-weight: 400;
              line-height: 1;
              box-sizing: border-box;
              border-bottom: 1px solid #efefef;
              white-space: nowrap;
              position: relative;
            }
            thead {
              tr {
                th {
                  color: #8d8d8d;
                  padding: 10px 0;
                  &:first-child {
                    border-right: 1px solid #efefef;
                    background: #fff;
                  }
                }
              }
            }
            tbody {
              tr {
                th {
                  color: #000;
                  font-weight: 500;
                  border-right: 1px solid #efefef;
                  background: #fff;
                  padding: 10px 0;
                }
                td {
                  padding: 10px 0;
                  img {
                    display: block;
                    margin: 0 auto;
                  }
                }
              }
            }
          }
          .guide {
            box-sizing: border-box;
            padding: 15px calc((100% - 120px) / 10 / 2) 0 calc(120px + ((100% - 120px) / 10 / 2));
            text-align: center;
            position: relative;
            height: 60px;
            .guideRail {
              height: 2px;
              background: #eee;
              .rail {
                height: 100%;
                background: #5e96ea;
                box-sizing: border-box;
                position: relative;
                &:before,
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background: var(--color-1);
                  top: -4px;
                }
                &:before {
                  left: -5px;
                }
                &:after {
                  right: -5px;
                }
              }
            }
            .guideName {
              display: block;
              font-size: 1.118em;
              color: var(--color-1);
              text-align: center;
              font-weight: 600;
              line-height: 1;
              margin: 5px 0 0;
            }
          }
        }
      }
    }
    .boxPkg1 {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      margin: 50px 0 0;
      align-items: center;
      align-content: center;
      .side {
        flex: 1;
        min-width: 0;
        &.L {
          .pTxt1 {
            display: block;
            font-size: 1.235em;
            color: #000;
            text-align: center;
            font-weight: 500;
            br {
              display: none;
            }
          }
          .pImg1 {
            display: block;
            width: 230px;
            margin: 20px auto 0;
          }
          .pList2 {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: center;
            box-sizing: border-box;
            padding: 0 40px;
            margin: 30px 0 0;
            li {
              flex: 1;
              min-width: 0;
              box-sizing: border-box;
              padding: 0 10px;
              .boxIco {
                width: 125px;
                height: 125px;
                border-radius: 50%;
                box-sizing: border-box;
                border: 1px solid #80adef;
                position: relative;
                margin: 0 auto;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                &:before {
                  content: "";
                  display: inline-flex;
                  width: 80px;
                  height: 80px;
                  background: url(/assets/img/_contents/UserGuide_spr1.png) no-repeat;
                }
                &.n01:before {
                  background-position: 0 0;
                }
                &.n02:before {
                  background-position: -90px 0;
                }
                &.n03:before {
                  background-position: -180px 0;
                }
                &.n04:before {
                  background-position: -270px 0;
                }
              }
              .boxTxt {
                text-align: center;
                font-size: 0.941em;
                color: var(--color-1);
                font-weight: 500;
                margin: 10px 0 0;
              }
            }
          }
        }
        &.R {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          justify-content: right;
          height: 100%;
          padding: 0 0 0 50px;
          .pList3 {
            background: #efefef;
            box-sizing: border-box;
            padding: 30px;
            width: 100%;
          }
        }
      }
    }
    .sellerRecruit {
      .pTit1 {
        text-align: center;
        margin: 0 0 30px;
        span {
          display: block;
        }
        .title2 {
          margin: 0 0 5px;
        }
      }
      .pMsg1 {
        text-align: center;
        .text3 {
          margin: 0 0 10px;
        }
        .text1 {
        }
      }
      .pBtn1 {
        text-align: center;
        margin: 40px 0 0;
        .ico1 {
          width: 180px;
          height: 150px;
          margin: 0 auto;
          background: url(/assets/img/_contents/UserGuide_spr1.png) no-repeat 0 -90px;
        }
        .btn1 {
          display: inline-block;
          line-height: 1;
          border-radius: 999px;
          padding: 15px 50px 16px;
          box-sizing: border-box;
          border: 1px solid #90b7f1;
          color: var(--color-1);
          font-size: 1.353em;
          font-weight: 500;
          margin: 20px 0 0;
          transition: all 0.3s ease;
          &:hover,
          &:focus-visible {
            border-color: var(--color-1);
            background: var(--color-1);
            color: #fff;
          }
        }
      }
    }
    .recommend {
      max-width: 1312px;
      margin: 0 auto;
      .pTit1 {
        margin: 0 0 30px;
        text-align: center;
      }
      .pList1 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        li {
          .boxImg {
            img {
              display: block;
              width: 100%;
            }
          }
          .boxTxt {
            font-size: 1.118em;
            text-align: center;
            line-height: 1.4;
            margin: 5px 0 0;
            word-wrap: break-word;
            word-break: keep-all;
            white-space: normal;
          }
        }
      }
    }
    .process {
      max-width: 1312px;
      margin: 0 auto;
      .pTit1 {
        margin: 0 0 30px;
        text-align: center;
      }
      .pList1 {
        ol {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          margin: 50px -40px 0;
          li {
            flex: 1;
            min-width: 0;
            box-sizing: border-box;
            padding: 0 40px;
            position: relative;
            &:after {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 6px;
              left: 50%;
              top: 50%;
              transform: translateY(-50%);
              z-index: -1;
            }
            &:last-child:after {
              display: none;
            }
            .pkg {
              padding: 0 0 100%;
              box-sizing: border-box;
              position: relative;
              border-radius: 50%;
              &:before,
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                z-index: 99;
                top: 50%;
                transform: translateY(-50%);
              }
              &:before {
                left: -9px;
              }
              &:after {
                right: -9px;
              }
              .outer {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                box-sizing: border-box;
                padding: 3px;
                .inner {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  background: #fff;
                  box-sizing: border-box;
                  padding: 18px;
                  .base {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    box-sizing: border-box;
                    padding: 18px;
                    .core {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      background: #fff;
                      text-align: center;
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      justify-content: center;
                      .pStep1 {
                        display: block;
                        font-size: 1.353em;
                        font-weight: 700;
                        color: #9a9a9c;
                      }
                      .pTxt1 {
                        width: 100%;
                        font-size: 1.118em;
                        color: #111;
                        line-height: 1.4;
                        margin: 10px 0 0;
                        height: 53px;
                        display: flex;
                        flex-flow: row;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                      }
                    }
                  }
                }
              }
            }
            &.n01 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #06f0b0 0%, #18a9eb 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #06f0b0 0%, #18a9eb 100%);
                }
                &:before {
                  display: none;
                }
              }
              &:after {
                background: linear-gradient(to right, #06f0b0 0%, #18a9eb 100%);
              }
            }
            &.n02 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #3eb5ed 0%, #3555ae 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #3eb5ed 0%, #3555ae 100%);
                }
              }
              &:after {
                background: linear-gradient(to right, #3eb5ed 0%, #3555ae 100%);
              }
            }
            &.n03 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #5975ff 0%, #864ec2 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #5975ff 0%, #864ec2 100%);
                }
              }
              &:after {
                background: linear-gradient(to right, #5975ff 0%, #864ec2 100%);
              }
            }
            &.n04 {
              .pkg,
              .base {
                background: linear-gradient(to bottom, #a466ec 0%, #31417a 100%);
              }
              .pkg {
                &:before,
                &:after {
                  background: linear-gradient(to bottom, #a466ec 0%, #31417a 100%);
                }
                &:after {
                  display: none;
                }
              }
              &:after {
                background: linear-gradient(to right, #a466ec 0%, #31417a 100%);
              }
            }
          }
        }
      }
      .pBtns1 {
        max-width: 510px;
        margin: 50px auto 0;
        a {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          justify-content: center;
          margin: 0 0 30px;
          font-size: 1.118em;
          height: 80px;
          border-radius: 8px;
          color: #fff;
          transition: all 0.3s ease;
          &:last-child {
            margin: 0;
          }
          &:after {
            content: "";
            display: inline-flex;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            border: solid;
            border-width: 0 2px 2px 0;
            border-color: #fff;
            transform: rotate(-45deg);
            margin: 0 0 0 20px;
            position: relative;
            right: 0;
            transition: all 0.3s ease;
          }
          &:hover,
          &:focus-visible {
            &:after {
              right: -10px;
            }
          }
          &.btn1 {
            background: #2f60fb;
            &:hover,
            &:focus-visible {
              background: var(--color-2);
            }
          }
          &.btn2 {
            background: #888;
            &:hover,
            &:focus-visible {
              background: #333;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1440px) {
    margin: -20px 0 0;
    &.UserGuide {
      .process {
        .pList1 {
          ol {
            li {
              .pkg {
                .outer {
                  .inner {
                    padding: 15px;
                    .base {
                      padding: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1399px) {
    .gap1 {
      height: 70px;
    }
    .gap2 {
      height: 35px;
    }
    .title1 {
      font-size: 1.7em;
      margin: 0 0 20px;
    }
    .title2 {
      font-size: 1.2em;
      margin: 0 0 20px;
    }
    .title3 {
      font-size: 2.3em;
    }
    .text1 {
      font-size: 1.2em;
      line-height: 1.2;
    }
    .text2 {
      padding: 20px;
      .pTxt1 {
        font-size: 1em;
        line-height: 1.3;
      }
      .pTxt2 {
        line-height: 1.3;
        margin: 15px 0 0;
      }
    }
    .text3 {
      font-size: 1.4em;
    }
    .text4 {
      font-size: 1.4em;
    }
    .opening {
      padding: 40px 0;
      .pTxt1 {
        font-size: 1.8em;
      }
      .pTxt2 {
        font-size: 2.3em;
      }
      .pImg1 {
        margin: 20px auto 0;
      }
    }
    .faqForm {
      .pList1 {
        > li {
          margin: 0 0 50px;
          .boxQ {
            font-size: 1.5em;
          }
          .boxA {
            .msg {
              font-size: 1.2em;
            }
          }
        }
      }
    }
    &.OrderGuide {
      .processSumm {
        ol {
          li {
            .text {
              line-height: 1.2;
            }
          }
        }
      }
      .processDetail {
        padding: 50px 0;
      }
    }
    &.PriceGuide {
      .infoBox1 {
        padding: 40px;
        .boxTxt {
          padding: 0 0 0 30px;
          .pTit1 {
            font-size: 1.5em;
          }
          .pTxt1 {
            font-size: 1.2em;
          }
          .pTxt2 {
            font-size: 1.1em;
            line-height: 1.4;
            margin: 15px 0 0;
          }
        }
      }
      .infoBox2 {
        .type1 {
          li {
            .head {
              width: 400px;
              .pTit1 {
                .text {
                  font-size: 1.1em;
                }
              }
            }
            .body {
              dl {
                dt,
                dd {
                }
                dt {
                  font-size: 1.2em;
                }
                dd {
                  br {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .intro {
        .pList1 {
          margin: 30px 0 0;
          ul {
            margin: -20px;
            li {
              padding: 20px;
            }
          }
        }
      }
    }
    &.UserGuide {
      .process {
        .pList1 {
          ol {
            li {
              .pkg {
                &:before {
                  width: 20px;
                  height: 20px;
                  left: -8px;
                }
                &:after {
                  width: 20px;
                  height: 20px;
                  right: -8px;
                }
                .outer {
                  .inner {
                    .base {
                      .core {
                        .pStep1 {
                          font-size: 1.5em;
                          line-height: 1;
                        }
                        .pTxt1 {
                          font-size: 1.3em;
                          line-height: 1.2;
                          margin: 10px 0 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1299px) {
    &.OemPromo {
      .flow {
        .pList1 {
          margin: 30px 0 0;
          padding: 0 100px;
          &:before {
            width: 19px;
            height: 19px;
            border-width: 0 2px 2px 0;
            top: 55px;
            right: 2px;
          }
          &:after {
            height: 2px;
            top: 63px;
          }
          li {
            .boxIco {
              width: 128px;
              height: 128px;
              .ico {
                width: 91px;
                height: 91px;
                background-size: 483px auto;
                &.n01 {
                  background-position: 0 0;
                }
                &.n02 {
                  background-position: -98px 0;
                }
                &.n03 {
                  background-position: -196px 0;
                }
                &.n04 {
                  background-position: -294px 0;
                }
                &.n05 {
                  background-position: -392px 0;
                }
              }
            }
            .boxTxt {
              margin: 10px 0 0;
              p {
                font-size: 1.2em;
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
    &.UserGuide {
      .intro {
        .pMsg1 {
          .text4 {
            margin: 30px 0 0;
          }
        }
        .pDesc1 {
          margin: 100px 0 0;
          dt {
            width: 200px;
            height: 200px;
            font-size: 1.3em;
          }
          dd {
            flex: 1;
            min-width: 0;
            div {
              margin: 0 0 30px;
              font-size: 1.3em;
              letter-spacing: -0.05em;
              &.msg1 {
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
      .boxPkg1 {
        margin: 30px 0 0;
        .side {
          &.L {
            .pList2 {
              padding: 0;
              li {
                flex: 1;
                min-width: 0;
                box-sizing: border-box;
                padding: 0 10px;
                .boxIco {
                  width: 100px;
                  height: 100px;
                  &:before {
                    width: 56px;
                    height: 56px;
                    background-size: 245px 168px;
                  }
                  &.n01:before {
                    background-position: 0 0;
                  }
                  &.n02:before {
                    background-position: -63px 0;
                  }
                  &.n03:before {
                    background-position: -126px 0;
                  }
                  &.n04:before {
                    background-position: -189px 0;
                  }
                }
              }
            }
          }
          &.R {
            padding: 0 0 0 20px;
          }
        }
      }
    }
  }
  @media all and (max-width: 1199px) {
    &.PriceGuide {
      .infoBox1 {
        padding: 30px;
        .boxImg {
          width: 200px;
        }
        .boxTxt {
          padding: 0 0 0 20px;
          .pTit1 {
            font-size: 1.3em;
          }
          .pTxt1 {
            font-size: 1.1em;
          }
          .pTxt2 {
            font-size: 1em;
            br {
              display: none;
            }
          }
        }
      }
      .infoBox3 {
        margin: 20px 0 0;
        .type1 {
          > li {
            padding: 20px;
            .boxImg {
              width: 105px;
              height: 105px;
              background-size: 329px auto;
              &.ico1 {
                background-position: 0 0;
              }
              &.ico2 {
                background-position: -112px 0;
              }
              &.ico3 {
                background-position: -224px 0;
              }
            }
          }
        }
      }
    }
    &.AdditionalServices {
      .infoBox1 {
        ul {
          li {
            padding: 20px;
            .numb {
              font-size: 1.3em;
            }
            .item {
              font-size: 1.4em;
              margin: 10px 0 0;
            }
            .icon {
              width: 140px;
              height: 140px;
              margin: 20px auto 0;
              background-size: 875px 140px;
            }
            .desc {
              border-radius: 10px;
              height: 60px;
              margin: 10px 0 0;
              padding: 0 10px;
              p {
                font-size: 15px;
                line-height: 1.2;
              }
            }
            &.n01 {
              .icon {
                background-position: 0 0;
              }
            }
            &.n02 {
              .icon {
                background-position: -147px 0;
              }
            }
            &.n03 {
              .icon {
                background-position: -294px 0;
              }
            }
            &.n04 {
              .icon {
                background-position: -441px 0;
              }
            }
            &.n05 {
              .icon {
                background-position: -588px 0;
              }
            }
            &.n06 {
              .icon {
                background-position: -735px 0;
              }
            }
          }
        }
      }
    }
    &.Faq {
      .faqBox1 {
        ul {
          li {
            padding: 50px 0;
            .boxQ {
              font-size: 2em;
              margin: 0 0 20px;
            }
            .boxA {
              font-size: 1em;
              line-height: 1.3;
              .pTxt1 {
                margin: 0 0 15px;
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .message {
        .box {
          padding: 70px 20px;
          height: auto;
          .pTxt1 {
            font-size: 2em;
          }
          .pTxt2 {
            font-size: 3em;
            margin: 20px auto 0;
          }
          .pTxt3 {
            font-size: 1.5em;
            margin: 15px 0 0;
          }
          .pTxt4 {
            font-size: 1.3em;
            margin: 30px auto auto;
          }
          .pTxt5 {
            font-size: 2.4em;
            margin: 10px 0 0;
          }
        }
      }
    }
    &.UserGuide {
      .process {
        .pList1 {
          ol {
            margin: 30px -20px 0;
            li {
              padding: 0 20px;
              &:after {
                height: 2px;
              }
              .pkg {
                &:before {
                  width: 14px;
                  height: 14px;
                  left: -6px;
                }
                &:after {
                  width: 14px;
                  height: 14px;
                  right: -6px;
                }
                .outer {
                  padding: 2px;
                  .inner {
                    padding: 10px;
                    .base {
                      padding: 5px;
                      .core {
                        .pStep1 {
                          font-size: 1.3em;
                        }
                        .pTxt1 {
                          font-size: 1em;
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .pBtns1 {
          margin: 30px auto 0;
          a {
            margin: 0 0 20px;
            height: 50px;
          }
        }
      }
      .boxPkg1 {
        margin: 20px 0 0;
        .side {
          &.L {
            .pList2 {
              padding: 0;
            }
          }
        }
      }
      .recommend {
        .pTit1 {
          margin: 0 0 20px;
        }
        .pList1 {
          gap: 30px;
          li {
            .boxTxt {
              font-size: 1em;
              line-height: 1.3;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1099px) {
    &.OrderGuide {
      .processSumm {
        ol {
          margin: -10px 0;
          li {
            flex: none;
            padding: 10px 0;
            .numb {
              margin: 0;
              span {
                width: 20px;
                height: 20px;
                font-size: 13px;
              }
            }
            .text {
              margin: 10px 0 0;
            }
          }
        }
        &.type1688 {
          li {
            width: 33.3333%;
          }
        }
        &.typeSeller {
          li {
            width: 25%;
          }
        }
      }
      .processDetail {
        padding: 20px 0;
        ol {
          grid-template-rows: none;
          li {
            padding: 20px;
            .head {
              .numb {
                font-size: 14px;
                margin: 2px 5px 0 0;
                width: 22px;
                height: 22px;
              }
            }
            .body {
              margin: 10px 0 0;
              .pTxt1 {
                line-height: 1.2;
              }
              .sTxt1 {
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 999px) {
    .title1 {
      font-size: 1.4em;
      margin: 0 0 15px;
    }
    .title2 {
      font-size: 1.1em;
      margin: 0 0 10px;
    }
    .title3 {
      font-size: 2em;
    }
    .text1 {
      font-size: 1em;
    }
    .text2 {
      padding: 20px;
      .pTxt1 {
        font-size: 1em;
        line-height: 1.2;
      }
      .pTxt2 {
        font-size: 1em;
        line-height: 1.2;
        margin: 10px 0 0;
      }
    }
    .text3 {
      font-size: 1.3em;
      margin: 5px 0 0;
    }
    .text4 {
      font-size: 1.3em;
    }
    .opening {
      padding: 40px 0 30px;
      .pTxt1 {
        font-size: 1.3em;
      }
      .pTxt2 {
        font-size: 2.2em;
      }
      .pImg1 {
        width: 200px;
        margin: 15px auto 0;
      }
    }
    .faqForm {
      .pList1 {
        > li {
          margin: 0 0 30px;
          .boxQ {
            font-size: 1.3em;
          }
          .boxA {
            .msg {
              font-size: 1.1em;
            }
          }
        }
      }
      .pBtn1 {
        height: 50px;
        margin: 50px auto 0;
        span {
          font-size: 1em;
        }
      }
    }
    &.OrderGuide {
      .processDetail {
        ol {
          li {
            padding: 15px;
            .head {
              .numb {
                font-size: 12px;
                margin: 0 5px 0 0;
              }
            }
          }
        }
      }
    }
    &.PriceGuide {
      .infoBox2 {
        .type1 {
          li {
            margin: 0 0 20px;
          }
        }
      }
      .infoBox3 {
        margin: 20px 0 0;
        .type1 {
          > li {
            padding: 15px 20px;
            .boxImg {
              width: 63px;
              height: 63px;
              background-size: 197px auto;
              &.ico1 {
                background-position: 0 0;
              }
              &.ico2 {
                background-position: -67px 0;
              }
              &.ico3 {
                background-position: -134px 0;
              }
            }
            .boxTxt {
              h3 {
                margin: 0 0 10px;
              }
              ul {
                li {
                  margin: 0 0 10px;
                }
              }
            }
          }
        }
      }
    }
    &.AdditionalServices {
      .infoBox1 {
        ul {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          gap: 15px;
          li {
            padding: 15px;
            border-radius: 10px;
            .numb {
              font-size: 1em;
            }
            .item {
              font-size: 1.1em;
              margin: 5px 0 0;
            }
            .icon {
              width: 98px;
              height: 98px;
              margin: 10px auto 0;
              background-size: 613px 98px;
            }
            .desc {
              border-radius: 5px;
              height: 50px;
              p {
                font-size: 15px;
                br {
                  display: none;
                }
              }
            }
            &.n01 {
              .icon {
                background-position: 0 0;
              }
            }
            &.n02 {
              .icon {
                background-position: -103px 0;
              }
            }
            &.n03 {
              .icon {
                background-position: -206px 0;
              }
            }
            &.n04 {
              .icon {
                background-position: -309px 0;
              }
            }
            &.n05 {
              .icon {
                background-position: -412px 0;
              }
            }
            &.n06 {
              .icon {
                background-position: -515px 0;
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .intro {
        .pList1 {
          ul {
            margin: -10px;
            li {
              padding: 10px;
            }
          }
        }
      }
      .flow {
        .pList1 {
          margin: 20px 0 0;
          padding: 0;
          &:before {
            width: 19px;
            height: 19px;
            top: 36px;
          }
          &:after {
            top: 44px;
          }
          li {
            width: 20%;
            .boxIco {
              width: 90px;
              height: 90px;
              margin: 0 auto;
              .ico {
                width: 64px;
                height: 64px;
                background-size: 338px auto;
                &.n01 {
                  background-position: 0 0;
                }
                &.n02 {
                  background-position: -69px 0;
                }
                &.n03 {
                  background-position: -137px 0;
                }
                &.n04 {
                  background-position: -206px 0;
                }
                &.n05 {
                  background-position: -274px 0;
                }
              }
            }
            .boxTxt {
              p {
                font-size: 1.1em;
              }
            }
          }
        }
      }
    }
    &.UserGuide {
      .tblPkg1 {
        dl {
          dt {
            padding: 0 0 50px;
            &:after {
              height: 50px;
            }
          }
          dd {
            .tblContainer {
              overflow-y: hidden;
              overflow-x: auto;
              table {
                .col1 {
                  width: 70px;
                }
                .col2 {
                  width: 80px;
                }
                thead {
                  tr {
                    th {
                      &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 2;
                        &:after {
                          content: "";
                          display: block;
                          position: absolute;
                          width: 1px;
                          height: 100%;
                          background: #efefef;
                          right: 0;
                          top: 0;
                        }
                      }
                    }
                  }
                }
                tbody {
                  tr {
                    th {
                      position: sticky;
                      left: 0;
                      z-index: 2;
                      &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        background: #efefef;
                        right: 0;
                        top: 0;
                      }
                    }
                  }
                }
              }
              .guide {
                padding: 15px calc(80px / 2) 0 calc(70px + (80px / 2));
                width: 870px;
                height: 50px;
                .guideName {
                  font-size: 1em;
                }
              }
            }
          }
        }
      }
      .boxPkg1 {
        margin: 20px 0 0;
        .side {
          &.L {
            .pList2 {
              li {
                .boxIco {
                  width: 70px;
                  height: 70px;
                  &:before {
                    width: 39px;
                    height: 39px;
                    background-size: 171px 118px;
                  }
                  &.n01:before {
                    background-position: 0 0;
                  }
                  &.n02:before {
                    background-position: -44px 0;
                  }
                  &.n03:before {
                    background-position: -88px 0;
                  }
                  &.n04:before {
                    background-position: -132px 0;
                  }
                }
              }
            }
          }
          &.R {
            padding: 0 0 0 20px;
            .pList3 {
              padding: 15px 25px;
            }
          }
        }
      }
      .process {
        .pList1 {
          ol {
            margin: 30px -10px 0;
            li {
              padding: 0 10px;
              .pkg {
                &:before {
                  width: 10px;
                  height: 10px;
                  left: -4px;
                }
                &:after {
                  width: 10px;
                  height: 10px;
                  right: -4px;
                }
              }
            }
          }
        }
      }
      .sellerRecruit {
        .pTit1 {
          margin: 0 0 20px;
        }
        .pBtn1 {
          margin: 20px 0 0;
        }
      }
    }
  }
  @media all and (max-width: 899px) {
    &.PriceGuide {
      .infoBox2 {
        .type1 {
          li {
            display: block;
            .head {
              width: 100%;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              .pTit1 {
                width: auto;
                height: auto;
                &:after {
                  display: none;
                }
              }
              .pTxt1 {
                padding: 0 0 0 10px;
                width: auto;
              }
            }
            .body {
              flex: none;
              dl {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                margin: 10px 0 0;
                dt,
                dd {
                  text-align: left;
                }
                dt {
                  width: auto;
                  position: relative;
                  left: auto;
                  bottom: auto;
                  font-size: 1em;
                  margin: 0;
                  display: block;
                  padding: 0 10px 0 0;
                }
                dd {
                  flex: 1;
                  min-width: 0;
                  height: auto;
                  justify-content: left;
                  line-height: 1.2;
                  padding: 10px;
                }
              }
              &.has2 {
                dl {
                  width: calc(50% - 10px);
                }
              }
              &.has2Merge {
                dl {
                  width: calc(50% - 10px);
                  &:first-child {
                    dd {
                      width: 100%;
                    }
                  }
                  &:last-child {
                    dd {
                      display: flex;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .message {
        .box {
          padding: 40px 20px;
          .pTxt1 {
            font-size: 1.7em;
          }
          .pTxt2 {
            font-size: 2.5em;
          }
          .pTxt3 {
            font-size: 1.3em;
          }
          .pTxt4 {
            font-size: 1.2em;
          }
          .pTxt5 {
            font-size: 2em;
          }
        }
      }
    }
    &.UserGuide {
      .intro {
        &:after {
          width: 900px;
          height: 800px;
          background-size: contain;
        }
        .pMsg1 {
          .text4 {
            margin: 20px 0 0;
          }
        }
        .pDesc1 {
          margin: 40px 0 0;
          dt {
            width: 150px;
            height: 150px;
            font-size: 1.2em;
          }
          dd {
            padding: 0 0 0 20px;
            div {
              margin: 0 0 20px;
              font-size: 1.2em;
            }
          }
        }
      }
      .platform {
        h3 {
          font-size: 1.5em;
          margin: 0 0 20px;
        }
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          gap: 20px;
          li {
            padding: 15px;
            margin: 0;
            border-radius: 8px;
            dl {
              dt {
                font-size: 1.3em;
              }
              dd {
                font-size: 1.1em;
              }
            }
          }
        }
      }
      .recommend {
        .pList1 {
          gap: 15px;
          li {
            .boxTxt {
              line-height: 1.2;
              letter-spacing: -0.05em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 799px) {
    .gap1 {
      height: 50px;
    }
    .gap2 {
      height: 30px;
    }
    .title3 {
      font-size: 1.8em;
    }
    .text3 {
      font-size: 1.2em;
    }
    .text4 {
      font-size: 1.2em;
    }
    .opening {
      padding: 30px 0 20px;
      &:after {
        width: 1000px;
      }
      p {
        text-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
      }
      .pTxt1 {
        font-size: 1.2em;
      }
      .pTxt2 {
        font-size: 2em;
      }
      .pImg1 {
        width: 200px;
        margin: 10px auto 0;
      }
    }
    &.OrderGuide {
      .processSumm {
        ol {
          li {
            .icon {
              width: 70px;
              height: 70px;
              background-size: 686px 147px;
            }
          }
        }
        &.type1688 {
          li {
            &.n01 {
              .icon {
                background-position: 0 0;
              }
            }
            &.n02 {
              .icon {
                background-position: -77px 0;
              }
            }
            &.n03 {
              .icon {
                background-position: -154px 0;
              }
            }
            &.n04 {
              .icon {
                background-position: -231px 0;
              }
            }
            &.n05 {
              .icon {
                background-position: -308px 0;
              }
            }
            &.n06 {
              .icon {
                background-position: -385px 0;
              }
            }
            &.n07 {
              .icon {
                background-position: -462px 0;
              }
            }
            &.n08 {
              .icon {
                background-position: -539px 0;
              }
            }
            &.n09 {
              .icon {
                background-position: -616px 0;
              }
            }
          }
        }
        &.typeSeller {
          li {
            &.n01 {
              .icon {
                background-position: 0 -77px;
              }
            }
            &.n02 {
              .icon {
                background-position: -77px -77px;
              }
            }
            &.n03 {
              .icon {
                background-position: -154px -77px;
              }
            }
            &.n04 {
              .icon {
                background-position: -231px -77px;
              }
            }
            &.n05 {
              .icon {
                background-position: -308px -77px;
              }
            }
            &.n06 {
              .icon {
                background-position: -385px -77px;
              }
            }
            &.n07 {
              .icon {
                background-position: -462px -77px;
              }
            }
          }
        }
      }
      .processDetail {
        ol {
          grid-template-columns: repeat(2, 1fr);
          gap: 15px;
          li {
            padding: 15px;
            &:last-child {
              grid-column: span 2 !important;
            }
          }
        }
      }
    }
    &.PriceGuide {
      .infoBox1 {
        padding: 20px;
        .boxImg {
          width: 150px;
        }
      }
    }
    &.Faq {
      .imgBox1 {
        .btn-zoom {
          right: auto;
          top: auto;
          left: 15px;
          bottom: 15px;
        }
      }
      .faqBox1 {
        ul {
          li {
            padding: 30px 0;
            .boxQ {
              font-size: 1.5em;
              margin: 0 0 15px;
            }
            .boxA {
              .pTxt1 {
                margin: 0 0 10px;
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .intro {
        .pList1 {
          margin: 20px 0 0;
        }
      }
    }
    &.UserGuide {
      .boxPkg1 {
        display: block;
        .side {
          &.L {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            .pPkg1 {
              padding: 10px 20px 0;
              .pTxt1 {
                br {
                  display: block;
                }
              }
              .pImg1 {
                margin: 10px auto 0;
              }
            }
            .pList2 {
              flex: 1;
              min-width: 0;
              margin: 0;
              li {
                .boxIco {
                  width: 70px;
                  height: 70px;
                  &:before {
                    width: 39px;
                    height: 39px;
                    background-size: 171px 118px;
                  }
                  &.n01:before {
                    background-position: 0 0;
                  }
                  &.n02:before {
                    background-position: -44px 0;
                  }
                  &.n03:before {
                    background-position: -88px 0;
                  }
                  &.n04:before {
                    background-position: -132px 0;
                  }
                }
              }
            }
          }
          &.R {
            padding: 0;
            margin: 20px 0 0;
            .pList3 {
              padding: 15px 25px;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              li {
                width: 50%;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 699px) {
    .faqForm {
      .pList1 {
        > li {
          margin: 0 0 20px;
          .boxQ {
            font-size: 1.2em;
          }
          .boxA {
            .msg {
              font-size: 1em;
            }
          }
        }
      }
      .pBtn1 {
        margin: 30px auto 0;
      }
    }
    &.UserGuide {
      .intro {
        .pMsg1 {
          .text4 {
            margin: 15px 0 0;
          }
        }
        .pDesc1 {
          display: block;
          margin: 20px 0 0;
          dt {
            width: 100%;
            height: auto;
            padding: 10px;
            border-radius: 8px;
          }
          dd {
            padding: 10px 0 0;
            div {
              margin: 0 0 15px;
              font-size: 1.1em;
            }
          }
        }
      }
      .platform {
        h3 {
          font-size: 1.2em;
          margin: 0 0 10px;
        }
        ul {
          gap: 15px;
          li {
            padding: 10px;
            margin: 0;
            border-radius: 8px;
            dl {
              dt {
                font-size: 1.2em;
              }
              dd {
                font-size: 1em;
              }
            }
          }
        }
      }
      .tblPkg1 {
        dl {
          dt {
            width: 50px;
          }
          dd {
            .tblContainer {
              table {
                .col1 {
                  width: 70px;
                }
                .col2 {
                  width: 70px;
                }
                tbody {
                  tr {
                    td {
                      .pChk1 {
                        width: 15px;
                      }
                    }
                  }
                }
              }
              .guide {
                padding: 15px calc(70px / 2) 0 calc(70px + (70px / 2));
                width: 770px;
                height: 50px;
                .rail {
                  &:before,
                  &:after {
                    width: 6px;
                    height: 6px;
                    top: -2px;
                  }
                  &:before {
                    left: -3px;
                  }
                  &:after {
                    right: -3px;
                  }
                }
                .guideName {
                  font-size: 1em;
                  top: 20px;
                }
              }
            }
          }
        }
      }
      .boxPkg1 {
        display: block;
        .side {
          &.L {
            display: block;
            .pPkg1 {
              padding: 0;
              .pTxt1 {
                br {
                  display: none;
                }
              }
              .pImg1 {
                margin: 10px auto 0;
              }
            }
            .pList2 {
              margin: 15px 0 0;
            }
          }
          &.R {
            padding: 0;
            margin: 20px 0 0;
            .pList3 {
              padding: 15px 25px;
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              li {
                width: 50%;
                padding-right: 20px;
              }
            }
          }
        }
      }
      .process {
        .pList1 {
          ol {
            display: block;
            margin: 20px 0 0;
            li {
              display: block;
              flex: none;
              padding: 10px 0;
              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                padding-bottom: 0;
              }
              &:after {
                width: 2px;
                height: 100%;
                left: 50px;
                top: 50%;
                transform: none;
              }
              .pkg {
                padding: 0;
                border-radius: 20px;
                &:before,
                &:after {
                  width: 10px;
                  height: 10px;
                  top: auto;
                  bottom: auto;
                  transform: none;
                }
                &:before {
                  left: 46px;
                  top: -4px;
                }
                &:after {
                  right: auto;
                  left: 46px;
                  bottom: -4px;
                }
                .outer {
                  height: auto;
                  position: relative;
                  left: auto;
                  top: auto;
                  .inner {
                    height: auto;
                    border-radius: 18px;
                    padding: 5px;
                    .base {
                      height: auto;
                      border-radius: 15px;
                      padding: 3px;
                      .core {
                        height: auto;
                        border-radius: 12px;
                        justify-content: left;
                        text-align: left;
                        padding: 10px 15px;
                        .pStep1 {
                          display: inline-flex;
                        }
                        .pTxt1 {
                          width: auto;
                          margin: 0 0 0 15px;
                          height: auto;
                          br {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .pBtns1 {
          margin: 20px auto 0;
          max-width: none;
        }
      }
      .sellerRecruit {
        .pBtn1 {
          .ico1 {
            width: 126px;
            height: 105px;
            background-position: 0 -63px;
            background-size: 245px 168px;
          }
          .btn1 {
            display: block;
            padding: 15px 50px 16px;
            font-size: 1em;
          }
        }
      }
    }
  }
  @media all and (max-width: 599px) {
    .gap1 {
      height: 30px;
    }
    .gap2 {
      height: 20px;
    }
    .text3 {
      font-size: 1.1em;
    }
    .text4 {
      font-size: 1.1em;
    }
    .opening {
      padding: 15px 0;
      &:after {
        width: 700px;
        background-size: cover;
      }
      .pTxt1 {
        font-size: 1em;
      }
      .pTxt2 {
        font-size: 1.5em;
        margin: 3px 0 0;
        font-weight: 500;
      }
      .pImg1 {
        width: 120px;
        margin: 10px auto 0;
      }
    }
    &.OrderGuide {
      .processSumm {
        ol {
          margin: -5px 0; /* justify-content:left; */
          li {
            padding: 5px 0;
            .numb {
              span {
                font-size: 12px;
              }
            }
            .text {
              margin: 5px 0 0;
            }
          }
        }
        &.type1688 {
          li {
            width: 33.3333%;
          }
        }
        &.typeSeller {
          li {
            width: 33.3333%;
          }
        }
      }
    }
    &.PriceGuide {
      .infoBox1 {
        display: block;
        padding: 20px;
        .boxImg {
          width: 170px;
          margin: 0 auto;
        }
        .boxTxt {
          text-align: center;
          padding: 0;
          margin: 10px 0 0;
          .pTit1 {
            margin: 0 0 5px;
          }
        }
      }
      .infoBox2 {
        .type1 {
          li {
            .head {
              width: 100%;
              display: block;
              .pTit1 {
                width: 100%;
                .numb {
                  width: 20px;
                  height: 20px;
                  font-size: 12px;
                  margin: 0 5px 0 0;
                }
              }
              .pTxt1 {
                width: 100%;
                padding: 3px 0 0 25px;
              }
            }
            .body {
              flex: none;
              dl {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                margin: 10px 0 0;
                width: 100% !important;
                dt,
                dd {
                  text-align: left;
                }
                dt {
                  width: 130px;
                }
                dd {
                  flex: 1;
                  min-width: 0;
                  height: auto;
                  justify-content: left;
                  line-height: 1.2;
                  padding: 10px;
                }
              }
            }
          }
        }
      }
      .infoBox3 {
        .type1 {
          > li {
            display: block;
            .boxImg {
              margin: 0 auto;
            }
            .boxTxt {
              padding: 0;
              ul {
                li {
                  em {
                    display: block;
                    margin: 5px 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.AdditionalServices {
      .infoBox1 {
        ul {
          display: block;
          li {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            margin: 0 0 10px;
            padding: 15px 15px 10px;
            &:last-child {
              margin: 0;
            }
            .numb {
              font-size: 1em;
              width: 40px;
            }
            .item {
              font-size: 1em;
              width: calc(100% - 40px);
              margin: 0;
            }
            .icon {
              margin: 0;
              width: 69px;
              height: 69px;
              background-size: 429px 69px;
              margin: 15px 0 0;
            }
            .desc {
              height: auto;
              padding: 10px;
              flex: 1;
              min-height: 0;
              height: 100%;
              margin: 15px 0 0 15px;
              p {
                width: 100%;
                text-align: left;
                font-size: 14px;
              }
            }
            &.n01 {
              .icon {
                background-position: 0 0;
              }
            }
            &.n02 {
              .icon {
                background-position: -72px 0;
              }
            }
            &.n03 {
              .icon {
                background-position: -144px 0;
              }
            }
            &.n04 {
              .icon {
                background-position: -216px 0;
              }
            }
            &.n05 {
              .icon {
                background-position: -288px 0;
              }
            }
            &.n06 {
              .icon {
                background-position: -361px 0;
              }
            }
          }
        }
      }
    }
    &.Faq {
      .faqBox1 {
        ul {
          li {
            padding: 15px 0;
            .boxQ {
              font-size: 1.1em;
              margin: 0 0 10px;
            }
          }
        }
      }
    }
    &.OemPromo {
      .intro {
        .pList1 {
          ul {
            margin: -5px;
            li {
              width: 33.3333%;
              padding: 5px;
            }
          }
        }
      }
      .flow {
        .pList1 {
          margin: 10px 0 0;
          &:before {
            width: 9px;
            height: 9px;
            top: 27px;
          }
          &:after {
            top: 30px;
          }
          li {
            .boxIco {
              width: 63px;
              height: 63px;
              margin: 0 auto;
              .ico {
                width: 45px;
                height: 45px;
                background-size: 237px auto;
                &.n01 {
                  background-position: 0 0;
                }
                &.n02 {
                  background-position: -48px 0;
                }
                &.n03 {
                  background-position: -96px 0;
                }
                &.n04 {
                  background-position: -144px 0;
                }
                &.n05 {
                  background-position: -192px 0;
                }
              }
            }
            .boxTxt {
              p {
                font-size: 1em;
              }
            }
          }
        }
      }
    }
    &.UserGuide {
      .tblPkg1 {
        dl {
          box-sizing: border-box;
          position: relative;
          dt {
            width: 60px;
            height: 50px;
            padding: 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            &:after {
              display: none;
            }
          }
          dd {
            position: relative;
            z-index: 1;
            .sTit1 {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              justify-content: center;
              box-sizing: border-box;
              padding: 0 0 0 60px;
              height: 50px;
            }
            .tblContainer {
              table {
                .col1 {
                  width: 60px;
                }
                .col2 {
                  width: 60px;
                }
                thead,
                tbody {
                  tr {
                    th,
                    td {
                      font-size: 14px;
                      padding: 5px 0;
                      .pChk1 {
                        width: 10px;
                      }
                    }
                  }
                }
              }
              .guide {
                padding: 15px calc(60px / 2) 0 calc(60px + (60px / 2));
                width: 660px;
                height: 45px;
                .guideName {
                  font-size: 1em;
                  top: 20px;
                }
              }
            }
          }
        }
      }
      .boxPkg1 {
        display: block;
        .side {
          &.L {
            display: block;
            .pPkg1 {
              padding: 0;
              .pTxt1 {
                br {
                  display: none;
                }
              }
              .pImg1 {
                margin: 10px auto 0;
                width: 180px;
              }
            }
            .pList2 {
              margin: 15px 0 0;
              li {
                padding: 0;
                .boxIco {
                  width: 60px;
                  height: 60px;
                  &:before {
                    width: 39px;
                    height: 39px;
                    background-size: 171px 118px;
                  }
                  &.n01:before {
                    background-position: 0 0;
                  }
                  &.n02:before {
                    background-position: -44px 0;
                  }
                  &.n03:before {
                    background-position: -88px 0;
                  }
                  &.n04:before {
                    background-position: -132px 0;
                  }
                }
                .boxTxt {
                  font-size: 13px;
                }
              }
            }
          }
          &.R {
            margin: 15px 0 0;
            .pList3 {
              padding: 20px;
              li {
                width: 100%;
                padding-right: 0;
              }
            }
          }
        }
      }
      .recommend {
        .pList1 {
          gap: 15px;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: auto;
          li {
            &:last-child {
              grid-column: span 2;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 499px) {
    .opening {
      p {
        text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
      }
    }
    .faqForm {
      .pList1 {
        > li {
          margin: 0 0 15px;
          .boxQ {
            font-size: 1.1em;
            margin: 0 0 10px;
            .sNum {
              &:after {
                height: 2px;
                bottom: -5px;
              }
            }
          }
          .boxA {
            .msg {
              br {
                display: none;
              }
            }
          }
        }
      }
      .pBtn1 {
        margin: 20px auto 0;
        width: 100%;
        min-height: 0;
        max-width: none;
      }
    }
    &.OrderGuide {
      .processDetail {
        ol {
          display: block;
          li {
            margin: 0 0 15px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    &.PriceGuide {
      .infoBox2 {
        .type1 {
          li {
            .body {
              dl {
                display: block;
                width: 100% !important;
                dt,
                dd {
                  text-align: left;
                }
                dt {
                  width: 100%;
                  padding: 0;
                }
                dd {
                  flex: none;
                  width: 100%;
                  margin: 5px 0 0;
                }
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .flow {
        .pList1 {
          margin: 10px 0 0;
          &:before {
            width: 9px;
            height: 9px;
            top: 18px;
            border-width: 0 1px 1px 0;
          }
          &:after {
            top: 22px;
            height: 1px;
          }
          li {
            .boxIco {
              width: 44px;
              height: 44px;
              margin: 0 auto;
              border-width: 1px;
              .ico {
                width: 31px;
                height: 31px;
                background-size: 166px auto;
                &.n01 {
                  background-position: 0 0;
                }
                &.n02 {
                  background-position: -34px 0;
                }
                &.n03 {
                  background-position: -67px 0;
                }
                &.n04 {
                  background-position: -101px 0;
                }
                &.n05 {
                  background-position: -134px 0;
                }
              }
            }
            .boxTxt {
              margin: 5px 0 0;
            }
          }
        }
      }
      .message {
        .box {
          padding: 20px 20px;
          border-radius: 8px;
          .pTxt1 {
            font-size: 1.5em;
          }
          .pTxt2 {
            font-size: 1.8em;
            margin: 10px auto 0;
          }
          .pTxt3 {
            font-size: 1.2em;
            text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff;
          }
          .pTxt4 {
            font-size: 1.1em;
            margin: 15px auto auto;
            br {
              display: none;
            }
          }
          .pTxt5 {
            font-size: 1.3em;
          }
        }
      }
    }
    &.UserGuide {
      .platform {
        h3 {
          font-size: 1.2em;
          margin: 0 0 10px;
        }
        ul {
          display: block;
          li {
            padding: 10px;
            margin: 0 0 15px;
            dl {
              dt {
                font-size: 1.1em;
              }
              dd {
                font-size: 1em;
                margin: 5px 0 0;
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 399px) {
    .opening {
      &:after {
        width: 600px;
      }
      .pTxt2 {
        font-size: 1.3em;
      }
      .pImg1 {
        width: 100px;
      }
    }
    &.OrderGuide {
      .processSumm {
        ol {
          li {
            .icon {
              width: 49px;
              height: 49px;
              background-size: 480px 103px;
            }
          }
        }
        &.type1688 {
          li {
            &.n01 {
              .icon {
                background-position: 0 0;
              }
            }
            &.n02 {
              .icon {
                background-position: -54px 0;
              }
            }
            &.n03 {
              .icon {
                background-position: -108px 0;
              }
            }
            &.n04 {
              .icon {
                background-position: -162px 0;
              }
            }
            &.n05 {
              .icon {
                background-position: -216px 0;
              }
            }
            &.n06 {
              .icon {
                background-position: -270px 0;
              }
            }
            &.n07 {
              .icon {
                background-position: -323px 0;
              }
            }
            &.n08 {
              .icon {
                background-position: -377px 0;
              }
            }
            &.n09 {
              .icon {
                background-position: -431px 0;
              }
            }
          }
        }
        &.typeSeller {
          li {
            &.n01 {
              .icon {
                background-position: 0 -54px;
              }
            }
            &.n02 {
              .icon {
                background-position: -54px -54px;
              }
            }
            &.n03 {
              .icon {
                background-position: -108px -54px;
              }
            }
            &.n04 {
              .icon {
                background-position: -162px -54px;
              }
            }
            &.n05 {
              .icon {
                background-position: -216px -54px;
              }
            }
            &.n06 {
              .icon {
                background-position: -270px -54px;
              }
            }
            &.n07 {
              .icon {
                background-position: -323px -54px;
              }
            }
          }
        }
      }
    }
    &.OemPromo {
      .intro {
        .pList1 {
          ul {
            li {
              width: 50%;
            }
          }
        }
      }
      .flow {
        .pList1 {
          display: block;
          margin: 10px 0 0;
          &:before {
            width: 10px;
            height: 10px;
            top: auto;
            bottom: 2px;
            transform: rotate(45deg);
            right: auto;
            left: 18px;
          }
          &:after {
            width: 1px;
            height: 100%;
            top: 0;
            right: auto;
            left: 22px;
          }
          li {
            width: 100%;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            padding: 5px 0;
            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 20px;
            }
            .boxTxt {
              flex: 1;
              min-width: 0;
              text-align: left;
              margin: 0 0 0 10px;
              p {
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
      .message {
        .box {
          .pTxt1 {
            font-size: 1.3em;
          }
          .pTxt2 {
            font-size: 1.5em;
          }
          .pTxt3 {
            font-size: 1.1em;
          }
          .pTxt4 {
            font-size: 1em;
            margin: 10px auto auto;
          }
          .pTxt5 {
            font-size: 1.2em;
          }
        }
      }
    }
    &.UserGuide {
      .boxPkg1 {
        display: block;
        .side {
          &.R {
            margin: 15px 0 0;
            .pList3 {
              padding: 0;
              background: #fff;
            }
          }
        }
      }
      .recommend {
        .pList1 {
          display: block;
          li {
            display: block;
            width: 100%;
            margin: 0 0 15px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
    &.Faq {
      .imgBox1 {
        .btn-zoom {
          left: 10px;
          bottom: 10px;
        }
      }
    }
  }
}
html[lang="en"] {
  .commonHtml {
    &.UserGuide {
      .tblPkg1 {
        margin: 30px 0 0;
        dl {
          dt {
            font-size: 14px;
          }
          dd {
            table {
              th,
              td {
                font-size: 13px;
                word-wrap: break-word;
                word-break: keep-all;
                white-space: normal;
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
  }
}

/* ==============================
* 캐릭터 소개
================================= */
.Character {
  margin: -30px 0 0;
  .gap1 {
    height: 100px;
    clear: both;
  }
  .gap2 {
    height: 50px;
    clear: both;
  }
  .opening {
    padding: 200px 0 300px;
    box-sizing: border-box;
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      z-index: -1;
      background: url(/assets/img/_contents/Character_IntroBg1.jpg) no-repeat center top;
      background-size: cover;
    }
    > * {
      display: block;
      line-height: 1.2;
      box-sizing: border-box;
      color: #374ea2;
    }
    .pTxt1 {
      font-size: 1.5em;
      font-weight: 500;
    }
    .pTxt2 {
      font-size: 3em;
      font-weight: 900;
      margin: 5px 0 0;
      br {
        display: none;
      }
    }
    .pTxt3 {
      font-size: 2em;
      font-weight: 700;
      margin: 30px 0 0;
    }
  }
  .intro1 {
    .pTit1 {
      display: block;
      line-height: 1;
      text-align: center;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
      > * {
        display: block;
        line-height: 1.2;
      }
      .sTxt1 {
        font-size: 2.5em;
        font-weight: 700;
        color: #010101;
        em {
          font-style: normal;
          color: #0e62df;
        }
      }
      .sTxt2 {
        font-size: 1.5em;
        font-weight: 200;
        letter-spacing: 0.3em;
        color: #0e62df;
        margin: 10px 0 0;
      }
    }
    .pImg1 {
      display: block;
      margin: 50px auto;
      max-width: 900px;
      box-sizing: border-box;
      position: relative;
      img {
        display: block;
        width: 100%;
        position: relative;
        z-index: 0;
      }
    }
    .pTit2 {
      text-align: center;
      color: #93b0cc;
      font-size: 2em;
      font-weight: 500;
      letter-spacing: 0;
    }
    .pTxt1 {
      text-align: center;
      color: #010101;
      font-size: 1.2em;
      margin: 30px 0 0;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
    }
  }
  .charac {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    .characterImg {
      position: absolute;
      top: 0;
      z-index: 1;
      img {
        display: block;
        width: 100%;
      }
    }
    .characterMsg {
      box-sizing: border-box;
      .pkgString {
        position: relative;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 2000px;
          height: 100%;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          background-repeat: no-repeat;
          background-position: center top;
          background-size: cover;
          z-index: -1;
        }
        .charName {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          align-items: flex-end;
          align-content: flex-end;
          line-height: 1;
          > * {
            line-height: 1;
          }
          .kor {
            font-size: 3em;
            font-weight: 900;
          }
          .eng {
            font-size: 1.5em;
            font-weight: 500;
            margin: 0 0 0 20px;
          }
        }
        .charTags {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          margin: 80px 0 0 0;
          li {
            font-size: 1.5em;
            font-weight: 500;
            margin: 0 10px 0 0;
            line-height: 1;
            &:last-child {
              margin: 0;
            }
          }
        }
        .charDesc {
          color: #222c33;
          font-size: 1.3em;
          line-height: 1.66;
          margin: 30px 0 0;
          word-wrap: break-word;
          word-break: keep-all;
          white-space: normal;
        }
      }
      .pkgColor {
        margin: 40px 0 0;
        box-sizing: border-box;
        .charColor {
          text-align: left;
          box-sizing: border-box;
          dl {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            dt,
            dd {
              box-sizing: border-box;
            }
            dt {
              width: 100px;
              font-size: 1.412em;
              color: #1d1d1b;
            }
            dd {
              flex: 1;
              min-width: 0;
              ul {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                li {
                  width: 33.3333%;
                  display: flex;
                  flex-flow: row;
                  flex-wrap: wrap;
                  align-items: center;
                  align-content: center;
                  padding: 8px 0;
                  .chip {
                    width: 37px;
                    height: 37px;
                    border-radius: 50%;
                    border: solid;
                    border-width: 1px;
                    box-sizing: border-box;
                  }
                  .code {
                    box-sizing: border-box;
                    padding: 0 0 0 15px;
                    letter-spacing: 0;
                    width: 180px;
                    > * {
                      display: block;
                      line-height: 1;
                      color: #1d1d1b;
                      font-size: 15px;
                    }
                    .rgb {
                      font-weight: 700;
                    }
                    .cmyk {
                      margin: 3px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.Root {
      text-align: left;
      .characterImg {
        padding: 50px 0 0;
        left: 0;
        width: 481px;
      }
      .characterMsg {
        .pkgString {
          padding: 100px 0 50px 550px;
          &:after {
            background-image: url(/assets/img/_contents/Character_CharBg1.jpg);
            background-color: #a7dffe;
          }
          .charName {
            justify-content: left;
            .kor {
              color: #197ab1;
            }
            .eng {
              color: #479ecf;
            }
          }
          .charTags {
            justify-content: left;
            li {
              color: #479ecf;
            }
          }
        }
        .pkgColor {
          padding: 0 0 0 550px;
          .charColor {
            dl {
              dd {
                ul {
                  li {
                    justify-content: left;
                    text-align: left;
                    .chip {
                    }
                    .code {
                      flex: 1;
                    }
                    .chip1 {
                      background: #374ea2;
                      border-color: #374ea2;
                    }
                    .chip2 {
                      background: #fff;
                      border-color: #7d868b;
                    }
                    .chip3 {
                      background: #ffd54c;
                      border-color: #ffd54c;
                    }
                    .chip4 {
                      background: #222c33;
                      border-color: #222c33;
                    }
                    .chip5 {
                      background: #7d868b;
                      border-color: #7d868b;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.Baroo {
      text-align: right;
      .characterImg {
        padding: 150px 0 0;
        right: 0;
        width: 427px;
      }
      .characterMsg {
        .pkgString {
          padding: 100px 550px 50px 0;
          &:after {
            background-image: url(/assets/img/_contents/Character_CharBg2.jpg);
            background-color: #c3f1ff;
          }
          .charName {
            justify-content: right;
            .kor {
              color: #1d89a9;
            }
            .eng {
              color: #5fb3cc;
            }
          }
          .charTags {
            justify-content: right;
            li {
              color: #5fb3cc;
            }
          }
        }
        .pkgColor {
          padding: 0 550px 0 0;
          .charColor {
            dl {
              dd {
                ul {
                  li {
                    justify-content: right;
                    text-align: right;
                    .chip {
                    }
                    .code {
                      flex: none;
                    }
                    .chip1 {
                      background: #374ea2;
                      border-color: #374ea2;
                    }
                    .chip2 {
                      background: #fff;
                      border-color: #7d868b;
                    }
                    .chip3 {
                      background: #ffd54c;
                      border-color: #ffd54c;
                    }
                    .chip4 {
                      background: #222c33;
                      border-color: #222c33;
                    }
                    .chip5 {
                      background: #afb5bf;
                      border-color: #afb5bf;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .turnAround {
    .pTit1 {
      text-align: center;
      color: #93b0cc;
      font-size: 2.3em;
      font-weight: 500;
      margin: 0 0 40px;
    }
    .pTit2 {
      font-size: 1.705em;
      color: #479ecf;
      margin: 0 0 20px;
    }
    .pImg1 {
      box-sizing: border-box;
      position: relative;
      img {
        display: block;
        position: relative;
        max-width: 100%;
        z-index: 0;
      }
    }
  }
  .appliedMotion {
    box-sizing: border-box;
    position: relative;
    padding: 100px 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2000px;
      height: 100%;
      left: 50%;
      top: 0;
      background: #93aebe;
      z-index: -1;
      transform: translateX(-50%);
    }
    .pTit1 {
      text-align: center;
      color: #fff;
      font-size: 2.3em;
      font-weight: 500;
      margin: 0 0 40px;
    }
    .pList1 {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      margin: -20px;
      li {
        width: 50%;
        box-sizing: border-box;
        padding: 20px;
        .boxImg {
          position: relative;
          img {
            display: block;
            width: 100%;
            max-width: 905px;
            margin: 0 auto;
            position: relative;
            z-index: 0;
          }
        }
        .boxTxt {
          p {
            text-align: center;
            color: #fff;
            font-size: 1.705em;
            font-weight: 500;
          }
        }
      }
    }
  }
  @media all and (max-width: 1524px) {
  }
  @media all and (max-width: 1499px) {
    .opening {
      padding: 150px 0 200px;
      &:after {
        width: 1500px;
      }
      .pTxt1 {
        font-size: 1.3em;
      }
      .pTxt2 {
        font-size: 2.7em;
      }
      .pTxt3 {
        font-size: 1.8em;
        margin: 20px 0 0;
      }
    }
  }
  @media all and (max-width: 1440px) {
    margin: -20px 0 0;
  }
  @media all and (max-width: 1399px) {
    .gap1 {
      height: 70px;
    }
    .gap2 {
      height: 35px;
    }
    .charac {
      &.Root {
        .characterImg {
          width: 400px;
        }
        .characterMsg {
          .pkgString {
            padding: 75px 0 50px 450px;
          }
          .pkgColor {
            padding: 0 0 0 450px;
          }
        }
      }
      &.Baroo {
        .characterImg {
          width: 400px;
        }
        .characterMsg {
          .pkgString {
            padding: 100px 450px 50px 0;
          }
          .pkgColor {
            padding: 0 450px 0 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 1299px) {
    .opening {
      padding: 130px 0 170px;
      &:after {
        width: 1300px;
      }
      .pTxt1 {
        font-size: 1.2em;
      }
      .pTxt2 {
        font-size: 2.5em;
        margin: 0;
      }
    }
    .intro1 {
      .pTit1 {
        .sTxt1 {
          font-size: 2.3em;
        }
        .sTxt2 {
          font-size: 1.3em;
          letter-spacing: 0;
        }
      }
      .pImg1 {
        margin: 40px auto;
        max-width: 800px;
      }
      .pTit2 {
        font-size: 1.8em;
      }
      .pTxt1 {
        font-size: 1em;
        margin: 20px 0 0;
      }
    }
    .charac {
      .characterMsg {
        .pkgString {
          &:after {
            width: 1300px;
          }
          .charName {
            .kor {
              font-size: 2.5em;
            }
            .eng {
              font-size: 1.3em;
              margin: 0 0 0 10px;
            }
          }
          .charTags {
            margin: 40px 0 0 0;
            li {
              font-size: 1.2em;
            }
          }
          .charDesc {
            font-size: 1.2em;
            line-height: 1.5;
            margin: 20px 0 0;
          }
        }
        .pkgColor {
          .charColor {
            dl {
              align-items: flex-start;
              align-content: flex-start;
              dt {
                font-size: 1em;
                padding: 13px 0 0;
              }
              dd {
                ul {
                  li {
                    width: 50%;
                    justify-content: left !important;
                    text-align: left !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .turnAround {
      .pTit1 {
        font-size: 2em;
        margin: 0 0 30px;
      }
      .pTit2 {
        font-size: 1.5em;
        margin: 0 0 15px;
      }
    }
    .appliedMotion {
      padding: 50px 0;
      .pTit1 {
        font-size: 2.3em;
        margin: 0 0 30px;
      }
      .pList1 {
        li {
          .boxTxt {
            p {
              font-size: 1.5em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 1099px) {
    .intro1 {
      .pTit1 {
        .sTxt1 {
          font-size: 2em;
        }
        .sTxt2 {
          font-size: 1.2em;
          margin: 5px 0 0;
        }
      }
      .pImg1 {
        margin: 30px auto;
        width: 80%;
      }
    }
    .charac {
      .characterImg {
      }
      .characterMsg {
        .pkgString {
          &:after {
            width: 1100px;
          }
          .charName {
            .kor {
              font-size: 2em;
            }
            .eng {
              font-size: 1.2em;
            }
          }
          .charTags {
            margin: 30px 0 0 0;
            li {
              font-size: 1.1em;
            }
          }
          .charDesc {
            font-size: 1.1em;
            margin: 10px 0 0;
          }
        }
        .pkgColor {
          margin: 20px 0 0;
          .charColor {
            dl {
              dt {
                width: 70px;
              }
            }
          }
        }
      }
      &.Root {
        .characterImg {
          width: 300px;
        }
        .characterMsg {
          .pkgString {
            padding: 75px 0 50px 350px;
          }
          .pkgColor {
            padding: 0 0 0 350px;
          }
        }
      }
      &.Baroo {
        .characterImg {
          width: 300px;
        }
        .characterMsg {
          .pkgString {
            padding: 100px 350px 50px 0;
          }
          .pkgColor {
            padding: 0 350px 0 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 999px) {
    .opening {
      padding: 100px 0 150px;
      &:after {
        width: 1000px;
      }
      .pTxt1 {
        font-size: 1.1em;
      }
      .pTxt2 {
        font-size: 2.2em;
      }
      .pTxt3 {
        font-size: 1.5em;
      }
    }
    .intro1 {
      .pTit1 {
        .sTxt1 {
          font-size: 1.8em;
        }
        .sTxt2 {
          font-size: 1.2em;
        }
      }
      .pTit2 {
        font-size: 1.8em;
      }
      .pTxt1 {
        margin: 15px 0 0;
        br {
          display: none;
        }
      }
    }
    .turnAround {
      .pTit1 {
        font-size: 1.8em;
        margin: 0 0 30px;
      }
      .pTit2 {
        font-size: 1.3em;
      }
    }
    .appliedMotion {
      padding: 30px 0;
      .pTit1 {
        font-size: 1.8em;
      }
      .pList1 {
        li {
          .boxTxt {
            p {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 899px) {
    .charac {
      .characterImg {
      }
      .characterMsg {
        .pkgString {
          &:after {
            width: 900px;
          }
          .charName {
            .kor {
              font-size: 1.8em;
            }
            .eng {
              font-size: 1.1em;
            }
          }
          .charTags {
            margin: 20px 0 0 0;
            li {
              font-size: 1em;
            }
          }
        }
        .pkgColor {
          margin: 20px 0 0;
          .charColor {
            dl {
              dt {
                width: 70px;
                padding: 10px 0 0;
              }
              dd {
                ul {
                  li {
                    .chip {
                      width: 24px;
                      height: 24px;
                    }
                    .code {
                      padding: 0 0 0 10px;
                      > * {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.Root {
        .characterImg {
          width: 250px;
          padding: 50px 0 0;
        }
        .characterMsg {
          .pkgString {
            padding: 50px 0 30px 280px;
          }
          .pkgColor {
            padding: 0 0 0 280px;
          }
        }
      }
      &.Baroo {
        .characterImg {
          width: 250px;
          padding: 50px 0 0;
        }
        .characterMsg {
          .pkgString {
            padding: 50px 280px 30px 0;
          }
          .pkgColor {
            padding: 0 280px 0 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 799px) {
    .gap1 {
      height: 50px;
    }
    .gap2 {
      height: 30px;
    }
    .opening {
      padding: 70px 0 120px;
      &:after {
        width: 800px;
      }
      .pTxt1 {
        font-size: 1em;
      }
      .pTxt2 {
        font-size: 2em;
      }
      .pTxt3 {
        font-size: 1.2em;
        margin: 10px 0 0;
      }
    }
    .charac {
      .characterImg {
      }
      .characterMsg {
        .pkgString {
          &:after {
            width: 800px;
          }
          .charName {
            .kor {
              font-size: 1.5em;
            }
          }
          .charDesc {
            font-size: 1em;
          }
        }
        .pkgColor {
          margin: 15px 0 0;
          .charColor {
            dl {
              display: block;
              dt {
                width: 100%;
                padding: 0;
                margin: 0 0 10px;
              }
              dd {
                ul {
                  li {
                    .code {
                      > * {
                        font-size: 13px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.Root {
        .characterImg {
          width: 200px;
          padding: 50px 0 0;
        }
        .characterMsg {
          .pkgString {
            padding: 50px 0 30px 230px;
          }
          .pkgColor {
            padding: 0 0 0 230px;
          }
        }
      }
      &.Baroo {
        .characterImg {
          width: 200px;
          padding: 50px 0 0;
        }
        .characterMsg {
          .pkgString {
            padding: 50px 230px 30px 0;
          }
          .pkgColor {
            padding: 0 230px 0 0;
          }
        }
      }
    }
    .intro1 {
      .pTit1 {
        .sTxt1 {
          font-size: 1.4em;
        }
        .sTxt2 {
          font-size: 1.1em;
        }
      }
      .pTit2 {
        font-size: 1.4em;
      }
    }
    .turnAround {
      .pTit1 {
        font-size: 1.4em;
        margin: 0 0 20px;
      }
      .pTit2 {
        font-size: 1.2em;
      }
    }
    .appliedMotion {
      .pTit1 {
        font-size: 1.4em;
        margin: 0 0 20px;
      }
      .pList1 {
        li {
          .boxTxt {
            p {
              font-size: 1.1em;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 699px) {
    .opening {
      padding: 70px 0 100px;
      &:after {
        width: 700px;
      }
      .pTxt1 {
        font-size: 1em;
      }
      .pTxt2 {
        font-size: 2em;
      }
      .pTxt3 {
        font-size: 1.1em;
      }
    }
    .charac {
      .characterImg {
        position: relative;
        left: auto !important;
        right: auto !important;
        top: auto;
        margin: 0 0 0 auto;
        img {
          width: auto;
          height: 100%;
        }
      }
      .characterMsg {
        margin: -30px 0 0;
        text-align: left;
        justify-content: left;
        .pkgString {
          position: static;
          &:after {
            height: 250px;
            width: 700px;
          }
          .charName {
            justify-content: left !important;
          }
          .charTags {
            justify-content: left !important;
          }
        }
        .pkgColor {
        }
      }
      &.Root {
        .characterImg {
          width: auto;
          height: 300px;
          padding: 30px 0 0;
        }
        .characterMsg {
          .pkgString {
            padding: 0;
          }
          .pkgColor {
            padding: 0;
          }
        }
      }
      &.Baroo {
        .characterImg {
          width: auto;
          height: 300px;
          padding: 30px 0 0;
        }
        .characterMsg {
          .pkgString {
            padding: 0;
          }
          .pkgColor {
            padding: 0;
          }
        }
      }
    }
  }
  @media all and (max-width: 599px) {
    .gap1 {
      height: 30px;
    }
    .gap2 {
      height: 20px;
    }
    .opening {
      padding: 30px 0 100px;
      text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
      &:after {
        width: 600px;
      }
      .pTxt1 {
        font-weight: 600;
      }
      .pTxt2 {
        font-size: 2em;
        line-height: 1;
        margin: 10px 0 0;
        br {
          display: block;
        }
      }
    }
    .appliedMotion {
      padding: 20px 0;
      .pList1 {
        margin: 0 0 10px;
        li {
          width: 100%;
          padding: 0;
          .boxImg {
            img {
              max-width: 80%;
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 499px) {
    .opening {
      &:after {
        width: 500px;
      }
      .pTxt2 {
        font-size: 1.8em;
      }
      .pTxt3 {
        margin: 5px 0 0;
      }
    }
    .charac {
      .characterImg {
        height: 200px !important;
        padding: 20px 0 0 !important;
      }
      .characterMsg {
        margin: -35px 0 0;
        .pkgString {
          position: static;
          &:after {
            height: 150px;
            width: 500px;
          }
          .charName {
            display: block;
            .kor {
              display: block;
            }
            .eng {
              display: block;
              margin: 5px 0 0;
              font-size: 1em;
            }
          }
          .charDesc {
            br {
              display: none;
            }
          }
        }
        .pkgColor {
          .charColor {
            dl {
              dd {
                ul {
                  li {
                    .chip {
                    }
                    .code {
                      width: auto;
                      flex: 1;
                      min-width: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media all and (max-width: 399px) {
    .opening {
      padding: 30px 0 70px;
      &:after {
        width: 400px;
      }
      .pTxt2 {
        font-size: 1.5em;
      }
      .pTxt3 {
        margin: 5px 0 0;
      }
    }
    .charac {
      .characterMsg {
        .pkgColor {
          .charColor {
            dl {
              dd {
                ul {
                  li {
                    display: block;
                    .chip {
                      width: 30px;
                      height: 10px;
                      border-radius: 5px;
                    }
                    .code {
                      padding: 0;
                      margin: 5px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
