.dynamic-table {
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 10px 20px;
  }

  th {
    background-color: #f0f0f0;
    // cursor: pointer;

    // &:hover {
    //   background-color: #e2e2e2;
    // }

    &.asc::after {
      content: " ↑";
    }

    &.desc::after {
      content: " ↓";
    }
  }

  tr {
    border-bottom: 1px solid #ccc;

    // &:nth-child(even) {
    //   background-color: #f9f9f9;
    // }

    // &:hover {
    //   background-color: #f1f1f1;
    // }
  }

  td {
    padding: 24px 20px;
    text-align: left;
    .info-box {
      display: flex;
      flex-direction: column;
      width: 50%;
      .title {
        display: block;
        color: black;
        font-weight: 500;
        word-wrap: break-word;
        transition: all 0.3s ease;
        &:hover {
          color: #0d6efd;
        }
      }

      .price {
        font-size: 14px;
      }
    }
  }

  .img-fluid {
    width: 112px;

    a {
      display: block;
      // width: 100%;
      overflow: hidden;
      border: 1px solid #ddd;
      transition: all 0.3s ease;

      img {
        display: block;
        width: 100%;
        transition: all 0.3s ease;
      }

      &:focus-visible,
      &:hover {
        border-color: var(--color-1);

        img {
          transform: scale(1.1);
        }
      }
    }
  }
}
