.snowflake {
  position: fixed;
  top: -10px;
  z-index: 9999;
  user-select: none;
  pointer-events: none;
  color: #fff;
  font-size: 1em;
  opacity: 0.8;
  animation: fall linear infinite;

  &.small {
    font-size: 0.5em;
  }

  &.medium {
    font-size: 1em;
  }

  &.large {
    font-size: 1.5em;
  }
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}
