.subHeaderMenuWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 630px) {
  .subHeaderMenuWrapper {
    width: 100%;
  }
}
