.description-review-area {
	margin:50px 0 0;
	.description-review-wrapper {
		/** -- Tab Button */
		.description-review-topbar {
			display:flex;box-sizing:border-box;position:relative;height:120px;align-items:center;align-content:center;
			.nav-item {
				margin:0 50px 0 0;
				&:last-child {margin:0;}
				a {
					font-size:1.294em;line-height:1;padding:10px 0;background:transparent;border-radius:0;color:#111;border:solid;box-sizing:border-box;border-width:0 0 3px;border-color:transparent;font-weight:500;
					.cnt {font-style:normal;color:var(--color-1);}
					&.active {border-color:var(--color-1);color:var(--color-1);}
					&:focus-visible,
					&:hover {color:var(--color-1);}
				}
			}
			&:before,
			&:after {content:"";display:block;position:absolute;width:2000px;height:1px;left:50%;transform:translateX(-50%);z-index:-1;background:#dbdbdb;}
			&:before {top:0;}
			&:after {bottom:0;}
		}

		/** -- Tab Body */
		.description-review-bottom {
			padding:60px 0 0;
			img,
			video {display:block;max-width:100%;margin:0 auto;height:auto;width:auto;}

			/** ---- Common */
			.proCommonTit {
				box-sizing:border-box;position:relative;padding:0 0 29px;
				.pTit1 {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;line-height:1;position:relative;
					span {font-size:1.647em;color:#111;}
					em {font-size:1.294em;color:var(--color-1);font-style:normal;margin:0 0 0 2px;}
				}
				.pUtils1 {
					position:absolute;right:0;bottom:30px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-end;align-content:flex-end;
					> * {
						margin:0 0 0 30px;
						&:first-child {margin:0;}
					}
					.chks1 {
						box-sizing:border-box;position:relative;display:none;
						label {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;border:0;background:transparent;color:#888;cursor:pointer;color:#111;transition:all .3s ease;
							i {font-size:1.059em;line-height:1;margin:4px 3px 0 0}
							span {
								font-size:.882em;line-height:1;box-sizing:border-box;position:relative;font-weight:500;
								&:after {content:"";display:block;position:absolute;width:0;height:2px;left:0;bottom:-6px;background:var(--color-1);transition:all .3s ease;}
							}
							&:focus-visible,
							&:hover {color:var(--color-1);}
						}
						input:checked + label {
							color:var(--color-1);
							span {
								&:after {width:100%}
							}
						}
					}
					.sel1 {
						margin-bottom:1px;display:none;
						select {display:block;font-size:.882em;height:auto;border:0;min-width:0;padding:0 20px 0 0;background:#fff url(../img/ic_sel2.png) no-repeat right center;color:#111;width:130px;}
					}
					.pBtn1 {
						line-height:1;box-sizing:border-box;border:0;background:var(--color-1);border-radius:999px;padding:16px 0 18px;width:240px;
						span {display:block;font-size:1.118em;color:#fff;font-weight:500;}
					}
				}
				&.hasBorder {border-bottom:2px solid var(--color-1);}
			}

			/** ---- Product detail description */
			.proEditorContents {
				max-width:100%;
				.content {
					text-align:center;max-width:100%;overflow:hidden;padding:0;position:relative;
					&:before {content:'';position:absolute;bottom:0;left:0;width:100%;height:50px;background:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));pointer-events:none;transition:opacity 0.3s ease;}
					&.expanded {
						padding:0;max-height:none;
						&:before {background:#none;}
					}
					> div {max-width:100% !important;}
				}
			}

			/** ---- Product detail information */
			.proAnotherInfo {
				dl {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:0;box-sizing:border-box;border-top:1px solid var(--color-1);position:relative;
					&:after {content:"";display:block;position:absolute;width:100%;height:1px;background:#dbdbdb;left:0;bottom:0;}
					dt,
					dd {font-size:.882em;margin:0;line-height:1.2;box-sizing:border-box;position:relative;border-bottom:1px solid #dbdbdb;padding:15px 15px;word-wrap:break-word;word-break:keep-all;white-space:normal;}
					dt {width:200px;color:#111;background:#f2f2f2;}
					dd {
						width:calc( 50% - 200px );
						.pList1 {
							max-height:200px;overflow-x:hidden;overflow-y:auto;
							&::-webkit-scrollbar {width:8px}
							&::-webkit-scrollbar-thumb {background:#ccc;border:solid;border-width:0 0 0 5px;border-color:#fff;}
							&::-webkit-scrollbar-track {background:#eee;border:solid;border-width:0 0 0 5px;border-color:#fff;}
							&::-webkit-scrollbar-button {height:0;}
							&:hover::-webkit-scrollbar-thumb {background:var(--color-1);}
							ul {
								display:flex;flex-flow:row;flex-wrap:wrap;line-height:1.5;
								li {
									box-sizing:border-box;letter-spacing:-.05em;padding:0 10px 0 0;
									&:last-child {margin:0;}
								}
							}
						}
					}
				}
			}

			/** ---- Product review */
			.proReview {
				.proReviewTotal {
					margin:0 0 60px;
					.proReviewTotalBodyPkg {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;box-sizing:border-box;border:solid;border-width:1px 0;border-color:var(--color-1) transparent #dbdbdb transparent;padding:29px 0;
						.proReviewTotalBody {
							width:100%;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
							.boxStar {
								width:470px;line-height:1;box-sizing:border-box;padding:10px 0;margin:-18px 0 0;
								.pStar1 {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;line-height:1;margin:0 0 0 -12px;
									i {color:#ffe000;font-size:3em;margin:10px 10px 0 0}
									span {color:#111;font-size:3.529em;font-weight:500;}
								}
								.pTxt1 {
									font-size:.882em;color:#111;text-align:center;line-height:1.2;margin:26px 0 0;
									em {font-size:500;font-style:normal;}
								}
							}
							.boxScore {
								flex:1;box-sizing:border-box;padding:9px 54px 10px 75px;border-left:1px solid #dbdbdb;
								ul {
									li {
										margin:0 0 12px;
										&:last-child {margin:0;}
										dl {
											display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;line-height:1;
											dt,
											dd {line-height:1;}
											dt {
												width:100px;
												span {font-size:13px;font-weight:500;color:#111;}
												&:after {display:none}
											}
											dd {
												flex:1;min-width:0;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
												.scoreBar {
													flex:1;height:13px;border-radius:999px;background:#dbdbdb;
													.bar {height:100%;border-radius:999px;background:#888;}
												}
												.scoreNum {width:30px;font-size:13px;margin:0 0 0 15px;}
											}
										}
									}
								}
							}
						}
					}
					.proReviewBenefit {
						box-sizing:border-box;border:1px solid #dbdbdb;border-radius:10px;margin:26px 0 0;padding:29px 31px 31px;
						.pTit1 {display:block;font-size:.882em;color:#111;margin:0 0 10px;}
						.pTxt1 {
							.c-list01 {
								> li {
									font-size:.882em;margin:0 0 5px;padding:0 0 0 12px;color:#888;
									&:last-child {margin:0;}
									&:before {width:5px;top:9px;}
								}
							}
						}
					}
				}
				.proReviewMedia {
					margin:0 0 60px;
					.proReviewMediaBody {
						.imgCastBtn {
							display:block;width:100%;background:transparent;box-sizing:border-box;border:1px solid #dbdbdb;border-radius:10px;overflow:hidden;position:relative;
							.cover {
								display:block;position:absolute;width:100%;height:100%;left:0;top:0;background:rgba(17, 17, 17, .7);z-index:2;opacity:0;border-radius:10px;transition:all .3s ease;
								&:before,
								&:after {content:"";display:block;position:absolute;width:24px;height:3px;background:#fff;top:0;right:0;bottom:0;left:0;margin:auto;}
								&:after {transform:rotate(90deg);}
							}
							.imgNum {width:38px;height:38px;text-align:center;line-height:38px;position:absolute;right:0;bottom:0;background:rgba(17, 17, 17, .8);border-radius:10px 0 10px 0;z-index:1;color:#fff;font-size:1.412em;}
							.imgContainer {
								display:block;height:0;padding:0 0 100%;position:relative;background:#eee;box-sizing:border-box;border-radius:10px;z-index:0;transition:all .3s ease;
								img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
							}
							&:focus-visible,
							&:hover {
								.cover {opacity:1;}
							}
						}
						.swiper-button-prev,
						.swiper-button-next {
							border:0;width:40px;height:40px;background:none;
							&:before {content:"";display:block;position:absolute;width:20px;height:20px;border:solid;box-sizing:border-box;border-width:0 2px 2px 0;border-color:var(--color-1);top:0;right:0;bottom:0;left:0;transition:all .3s ease;}
							&:after {display:none;}
							&.swiper-button-prev {
								left:0;
								&:before {transform:rotate(135deg);margin:auto auto auto 17px;}
							}
							&.swiper-button-next {
								right:0;
								&:before {transform:rotate(-45deg);margin:auto 17px auto auto;}
							}
							&:focus-visible,
							&:hover {
								&.swiper-button-prev:before {margin:auto auto auto 12px}
								&.swiper-button-next:before {margin:auto 12px auto auto}
							}
						}
						.swiper-pagination-wrap {
							display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;margin:20px 0 0;
							ul {
								font-size:0;line-height:1;
								li {
									width:auto;height:auto;font-size:0;line-height:1;opacity:1;background:transparent;margin:0 2px;
									button {
										width:12px;height:12px;border:0;border-radius:50%;background:#dbdbdb;transition:all .3s ease;
										&:focus-visible,
										&:hover {background:#aaa;}
									}
									&.swiper-pagination-bullet-active {
										button {background:var(--color-1);}
									}
								}
							}
						}
					}
				}
				.proReviewList {
					.proReviewTools {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;box-sizing:border-box;border:solid;border-width:1px 0;border-color:var(--color-1);margin:0 0 45px;padding:10px 0 9px 14px;
						.sort {
							ul {
								display:flex;flex-flow:row;flex-wrap:wrap;
								li {
									margin:0 32px 0 0;
									&:last-child {margin:0;}
									button {
										display:block;box-sizing:border-box;line-height:1;border:0;background:transparent;font-size:15px;color:#aaa;font-weight:500;padding:5px 0;transition:all .3s ease;
										span {}
										&:focus-visible,
										&:hover {color:var(--color-1);}
									}
									&.on {
										button {color:#111;}
									}
								}
							}
						}
						.search {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
							.preChks {
								box-sizing:border-box;position:relative;
								label {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;border:0;background:transparent;color:#888;cursor:pointer;transition:all .3s ease;
									i {font-size:18px;line-height:1;margin:4px 3px 0 0}
									span {
										font-size:15px;line-height:1;box-sizing:border-box;position:relative;font-weight:500;
										&:after {content:"";display:block;position:absolute;width:0;height:2px;left:0;bottom:-6px;background:var(--color-1);transition:all .3s ease;}
									}
									&:focus-visible,
									&:hover {color:var(--color-1);}
								}
								input:checked + label {
									color:var(--color-1);
									span {
										&:after {width:100%}
									}
								}
							}
							.form {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:239px;height:34px;border-radius:5px;box-sizing:border-box;border:1px solid #e5e5e5;margin:0 0 0 18px;
								input {height:100%;border:0;flex:1;padding:0 10px;margin:0;box-sizing:border-box;line-height:1;min-width:0;max-width:none;font-size:15px;}
								button {
									border:0;background:transparent;width:34px;transition:all .3s ease;
									i {font-size:18px;line-height:1;}
									&:focus-visible,
									&:hover {color:var(--color-1);}
								}
							}
						}
					}
					.proReviewUnitPkg {
						.reviewUnit {
							display:flex;flex-flow:row;flex-wrap:wrap;margin:0 0 41px;padding:0 0 39px;border-bottom:1px solid #dbdbdb;box-sizing:border-box;position:relative;
							> * {box-sizing:border-box;position:relative;}
							.review-media {
								width:251px;margin:0 29px 0 0;
								.imgCastBtn {
									display:block;width:100%;background:transparent;box-sizing:border-box;border:1px solid #dbdbdb;border-radius:10px;overflow:hidden;position:relative;
									.cover {
										display:block;position:absolute;width:100%;height:100%;left:0;top:0;background:rgba(17, 17, 17, .7);z-index:2;opacity:0;border-radius:10px;transition:all .3s ease;
										&:before,
										&:after {content:"";display:block;position:absolute;width:24px;height:3px;background:#fff;top:0;right:0;bottom:0;left:0;margin:auto;}
										&:after {transform:rotate(90deg);}
									}
									.imgNum {width:38px;height:38px;text-align:center;line-height:38px;position:absolute;right:0;bottom:0;background:rgba(17, 17, 17, .8);border-radius:10px 0 10px 0;z-index:1;color:#fff;font-size:1.412em;}
									.imgContainer {
										display:block;height:0;padding:0 0 100%;position:relative;background:#eee;box-sizing:border-box;border-radius:10px;z-index:0;transition:all .3s ease;
										img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
									}
									&:focus-visible,
									&:hover {
										.cover {opacity:1;}
									}
								}
							}
							.review-content {
								flex:1;padding:2px 50px 0 0;display:flex;flex-flow:column;flex-wrap:wrap;justify-content:space-between;
								.reviewScore {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
									.pStar1 {
										font-size:0;line-height:1;box-sizing:border-box;
										* {font-size:0;line-height:1;box-sizing:border-box;top:auto;}
									}
									.pTxt1 {font-size:1.176em;color:#111;letter-spacing:0;font-weight:500;margin:0 0 0 3px;}
								}
								.reviewWriterInfo {
									margin:17px 0 0;
									ul {
										display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
										li {
											padding:0 18px 0 0;margin:0 12px 0 0;box-sizing:border-box;position:relative;line-height:1.2;font-size:.941em;color:#888;
											&:after {content:"";display:block;position:absolute;width:1px;height:14px;background:#dbdbdb;right:0;top:3px;}
											&:last-child {padding:0;margin:0;}
											&:last-child:after {display:none}
											&.writerName {color:#111;}
											.report {
												border:0;background:transparent;line-height:1;padding:3px 5px 4px;border-radius:3px;color:#888;transition:all .3s ease;
												&:focus-visible,
												&:hover {background:var(--color-1);color:#fff;}
											}
										}
									}
								}
								.reviewProductInfo {
									margin:7px 0 auto 0;
									ul {
										display:flex;flex-flow:row;flex-wrap:wrap;
										li {
											padding:0 10px 0 0;margin:0 9px 0 0;box-sizing:border-box;position:relative;line-height:1.2;font-size:.941em;color:#888;
											&:after {content:"";display:block;position:absolute;width:1px;height:12px;background:#888;right:0;top:4px;transform:rotate(20deg);}
											&:last-child {padding:0;margin:0;}
											&:last-child:after {display:none}
											.product {color:#111;}
										}
									}
								}
								.reviewCont {
									margin:40px 0 0;font-size:.941em;display:-webkit-box;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:auto;word-wrap:break-word;-webkit-line-clamp:3;-webkit-box-orient:vertical;box-sizing:border-box;position:relative;height:auto;max-height:72px;
									&:after {content:"";display:none;position:absolute;width:100%;height:30px;background:linear-gradient( to top, #fff 0, rgba(255, 255, 255, 0) 100% );left:0;bottom:0;}
									.reviewContCore {
										display:inline;box-sizing:border-box;position:relative;
									}
								}
								.reviewExpandToggle {
									display:none;margin:15px 0 0;
									button {
										line-height:1;color:#888;font-size:.941em;box-sizing:border-box;position:relative;padding:5px 20px 5px 0;border:0;background:transparent;
										&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 0 2px 2px;border-color:#888;transform:rotate(-45deg);right:0;top:6px;transition:all .3s ease;}
										&:focus-visible,
										&:hover {
											color:var(--color-1);
											&:after {border-color:var(--color-1);}
										}
									}
								}
							}
							&.hasOverflow {
								.review-content {
									.reviewCont {
										&:after {display:block;}
									}
									.reviewExpandToggle {display:block;}
								}
							}
							&.hasSpread {
								.review-content {
									.reviewCont {
										display:block;max-height:none;
										&:after {display:none}
									}
									.reviewExpandToggle {
										button {
											color:var(--color-1);
											&:after {transform:rotate(135deg);top:11px;border-color:var(--color-1);}
										}
									}
								}
							}
							.review-vote {
								width:172px;height:251px;align-content:flex-end;
								button {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;background:transparent;line-height:1;border:1px solid #dbdbdb;border-radius:999px;padding:16px 29px 15px;margin:0 0 0 auto;transition:all .3s ease;
									i {font-size:1.412em;color:var(--color-1);margin:0 9px 0 0;transition:all .3s ease;}
									span {font-size:1.176em;transition:all .3s ease;}
									&:focus-visible,
									&:hover {
										background:var(--color-1);border-color:var(--color-1);
										i {color:#fff;}
										span {color:#fff;}
									}
								}
							}
							.review-Controller {
								position:absolute;right:0;top:0;
								button {margin:0 0 0 10px;}
							}
							.review-reply {
								width:100%;background:#f1f2f2;margin:38px 0 0;box-sizing:border-box;position:relative;padding:26px 20px 37px 59px;
								i {display:block;position:absolute;left:28px;top:27px;line-height:1;font-size:1.412em;}
								.replySumm {
									display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;line-height:1;
									* {font-size:.941em;}
									span {
										box-sizing:border-box;position:relative;padding:0 15px 0 0;margin:0 13px 0 0;color:#888;
										&:after {content:"";display:block;position:absolute;width:1px;height:14px;background:#dbdbdb;right:0;top:2px;}
										&.seller {color:#111;font-weight:500;}
									}
									button {
										border:0;border-radius:3px;color:#888;padding:5px 5px 5px;margin:0 0 0 -3px;background:transparent;transition:all .3s ease;
										&:focus-visible,
										&:hover {background:var(--color-1);color:#fff;}
									}
								}
								.replyCont {font-size:.941em;line-height:1.25;color:#111;margin:7px 0 0;}
							}
						}
					}
				}
				.pro-pagination-style {}
				.noDataWrap {min-height:0;}
				.proReviewLogin {
					display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;box-sizing:border-box;margin:0 0 20px;text-align:center;
					a {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;line-height:1.2;padding:15px 30px;font-weight:500;border-radius:5px;border:1px solid #dbdbdb;;transition:all .3s ease;
						i {font-size:1.176em;margin:0 5px 0 0;line-height:1;}
						span {}
						&:focus-visible,
						&:hover {border-color:var(--color-1);background:var(--color-1);color:#fff;}
					}
				}
			}

			/** ---- Product Q&A */
			.proQna {
				.proQnaList {
					> ul {
						display:block;box-sizing:border-box;border-top:1px solid var(--color-1);
						> li {
							display:block;box-sizing:border-box;border-bottom:1px solid #dbdbdb;
							.question {
								display:flex;flex-flow:row;flex-wrap:wrap;width:100%;border:0;background:transparent;line-height:1.3;padding:19px 0 18px;transition:all .3s ease;
								> * {font-size:.882em;box-sizing:border-box;position:relative;}
								.cate {width:190px;color:#888;}
								.subj {
									flex:1;text-align:left;color:#111;font-weight:500;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;padding:0 30px 0 0;
									span {transition:all .3s ease;}
								}
								.info {
									width:180px;color:#888;font-weight:500;text-align:left;
									.writer {
										box-sizing:border-box;position:relative;padding:0 10px 0 0;margin:0 10px 0 0;
										&:after {content:"";display:block;position:absolute;width:1px;height:14px;background:#dbdbdb;right:0;top:4px;}
									}
									.date {}
								}
								.status {
									width:145px;font-weight:500;
									&.status1 {color:#888;}
									&.status2 {color:var(--color-1);}
								}
								&:focus-visible,
								&:hover {
									background:#e7effc;
									.subj span {}
								}
							}
							.answer {
								display:none;background:#f1f2f2;padding:20px 0;
								> * {
									margin:0 0 40px;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
									&:last-child {margin:0;}
									> * {box-sizing:border-box;position:relative;font-size:.882em;}
									.cate {width:190px;color:#888;text-align:center;font-weight:500;}
									.subj {
										flex:1;padding:0 30px 0 0;
										.title {font-weight:700;color:#111;margin:0 0 20px;}
										.conts {}
									}
									.info {
										width:180px;text-align:left;
									}
									.report {
										width:145px;text-align:center;
										button {
											line-height:1;box-sizing:border-box;position:relative;padding:5px 10px 7px;border-radius:4px;border:1px solid #aaa;background:transparent;transition:all .3s ease;
											&:focus-visible,
											&:hover {background:var(--color-1);color:#fff;border-color:var(--color-1);}
										}
									}
									&.originalQuestion {}
									&.originalAnswer {
										.cate {color:var(--color-1);}
									}
								}
							}
							&.on {
								.answer {display:block;}
							}
						}
					}
				}
			}

			/** ---- Product Shipping/Exchange */
			.proDelivery {
				.proDeliveryList1 {
					.typeList1 {
						display:block;box-sizing:border-box;border-top:1px solid var(--color-1);
						> li {
							display:block;box-sizing:border-box;border:1px solid #dbdbdb;border-top:0;
							dl {
								display:flex;flex-flow:row;flex-wrap:wrap;
								dt,
								dd {box-sizing:border-box;font-size:.882em;line-height:1.3;padding:23px 22px 23px;}
								dt {width:250px;background:#f2f2f2;font-weight:500;color:#111;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;}
								dd {
									flex:1;min-width:0;line-height:1.4;
									.pTxt1 {
										margin:0 0 15px;
										&:last-child {margin:0;}
									}
								}
							}
						}
					}
					.typeList2 {
						display:flex;flex-flow:row;flex-wrap:wrap;margin:-35px;
						> li {
							flex:1;box-sizing:border-box;position:relative;padding:35px;
							&:after {content:"";display:block;position:absolute;width:14px;height:14px;border:solid;border-width:0 2px 2px 0;border-color:#7b7b7b;transform:rotate(-45deg) translateY(-50%);right:-10px;top:50%;}
							&:last-child:after {display:none}
							.pPkg1 {
								box-sizing:border-box;border:1px solid #dadada;background:#f2f2f2;border-radius:4px;padding:35px 28px 58px;height:100%;
								.pTit1 {color:var(--color-1);}
								.pTxt2 {color:#111;font-weight:500;margin:5px 0 0;line-height:1.6;}
							}
						}
					}
				}
			}
		}

		/** -- 상품 View 페이지내 추천상품, 관련상품 */
		.shop-area {
			.itemPkg {
				.itemList {
					.shop-bottom-area {
						.row {
							&.grid {padding:30px 30px 25px;border:5px solid #eee;border-radius:8px;}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1299px) {
		.description-review-wrapper {
			.description-review-topbar {height:90px;}
		}
	}
	@media all and (max-width:1199px) {
		.description-review-wrapper {
			.shop-area {
				.itemPkg {
					.itemList {
						.shop-bottom-area {
							.row {
								grid-template-columns:repeat(4, 1fr);
								&.grid {padding:20px;border-width:4px;border-radius:5px;}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		.description-review-wrapper {
			.description-review-topbar {
				padding:0 40px 0 0;height:auto;
				.nav-item {
					margin:0;flex:1;min-width:0;
					a {display:inline-block;padding:30px 0 28px;}
				}
			}
			.description-review-bottom {
				padding:40px 0 0;
			}
		}
	}
	@media all and (max-width:799px) {
		.description-review-wrapper {
			.description-review-topbar {
				box-sizing:border-box;border:solid;border-width:1px 0;border-color:#ddd;
				&:before,
				&:after {display:none;}
				.nav-item {
					flex:none;margin:0 30px 0 0;
				}
			}
			.description-review-bottom {
				padding:20px 0 0;
			}
			.shop-area {
				.itemPkg {
					.itemList {
						.shop-bottom-area {
							.row {
								gap:15px;
								&.grid {padding:15px;border-width:3px;}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:699px) {
		.description-review-wrapper {
			.description-review-topbar {
				padding:5px 40px 5px 0;
				.nav-item {
					margin:0;
					a {font-size:1em;padding:5px 0;border-width:0 0 1px;}
				}
				&.has4 {
					.nav-item {width:50%;}
				}
				&.has5 {
					.nav-item {width:33.3333%;}
				}
			}
			.description-review-bottom {
				padding:20px 0 0;
			}
			.shop-area {
				.itemPkg {
					.itemList {
						.shop-bottom-area {
							margin:20px 0 0;
							.c-tit01 {margin:0 0 10px;font-size:1.5em;}
							.row {
								grid-template-columns:repeat(2, 1fr);grid-template-row:repeat(2, 1fr);gap:15px;
								&.grid {padding:0;border-width:0;}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:599px) {
		.description-review-wrapper {
			.description-review-topbar {
				padding:5px 40px 5px 0;
			}
			.shop-area {
				.itemPkg {
					.itemList {
						.shop-bottom-area {
							margin:20px 0 0;
						}
					}
				}
			}
		}
	}
	@media all and (max-width:399px) {
		.description-review-wrapper {
			.shop-area {
				.itemPkg {
					.itemList {
						.shop-bottom-area {
							.row {
								gap:10px;
							}
						}
					}
				}
			}
		}
	}
}

/* ==============================
* Q & A 쓰기 / Review
================================= */
.modalCustom {
	height:100%;
	* > {font-size:17px;letter-spacing:-.025em;line-height:1.5;}
	.modalCustomTit {}
	.modalCustomWrap {
		height:calc( 100% - 70px );overflow:auto;
		.modalCustomBody {
			max-height:none !important;overflow:hidden !important;
			.frmWr {
				.lv1 {
					> li {
						dl {
							min-height:40px;
							dt,
							dd {}
							dt {}
							dd {
								padding:0 20px 0 0;
								label {font-size:1em;}
								input[type='text'] {display:block;height:40px;box-sizing:border-box;border:1px solid #ccc;line-height:40px;padding:0 10px;}
								&.hasEditor {margin:10px 0 0;}
							}
						}
					}
				}
			}
		}
		.modalCustomFooter {
			.cBtnBox1 {margin:10px 0;}
		}
	}
	@media all and (max-width:999px) {
		font-size:16px;line-height:1.4;
	}
	@media all and (max-width:799px) {
		font-size:15px;line-height:1.3;
	}
	@media all and (max-width:599px) {
		font-size:14px;
	}
	&.proQnaWrite {
	}
}
