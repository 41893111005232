/*------- 21. Checkout page  -----------*/
.checkout-area {
  .checkoutTit {
    display: flex;

    align-items: center;
    align-content: center;
    .checkoutName {
    }
    .checkoutLink {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      font-size: 0.7em;
      box-sizing: border-box;
      border: 1px solid #333;
      border-radius: 4px;
      margin: 0 0 0 15px;
      line-height: 1;
      color: #333;
      padding: 5px 10px;
      position: relative;
      top: 2px;
      background: #fff;
      transition: all 0.3s ease;
      .ico {
        margin: 0 5px 0 0;
      }
      .txt1 {
        height: auto;
        line-height: 1;
      }
      &:focus-visible,
      &:hover {
        background: var(--color-1);
        border-color: var(--color-1);
        color: #fff;
      }
    }
  }
  .checkoutPkg {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    > * {
      box-sizing: border-box;
      padding: 0 15px;
      position: relative;
    }

    /** -- Left : 배송정보, 통관정보, 주문상품, 배송방식 */
    .sectBillings {
      flex: 1;
      min-width: 0;

      /** -- 01. 배송정보 */
      .sectDeliveryInfos {
        .hasAddr {
          .addrZip {
            text-align: center;
          }
          .addrSumm {
            max-width: 100% !important;
            flex: 1 !important;
            min-width: 0;
          }
        }
      }

      /** -- 02. 통관정보 */
      .sectCustomNums {
        .hasCustom {
          width: 100%;
          input[type="text"] {
            flex: 1;
            min-width: 0;
            max-width: 100% !important;
          }
          button {
            margin: 0 0 0 10px;
          }
          .customList {
            display: block;
            width: 100%;
            li {
              display: block;
              margin: 0 0 10px;
              padding: 0 0 10px;
              border-bottom: 1px solid #ddd;
              &:last-child {
                margin: 0;
                padding: 0;
                border-bottom: 0;
              }
            }
          }
        }
      }

      /** -- 03. 주문상품 */
      .sectOrderedProd {
        .prodList {
          > li {
            box-sizing: border-box;
            border-radius: 10px;
            border: 1px solid #ddd;
            background: #fafafa;
            padding: 10px;
            margin: 0 0 20px;
            &:last-child {
              margin: 0;
            }
            dl.prodLayout {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              width: calc(100% + 20px);
              margin: -10px;
              > * {
                padding: 10px;
                box-sizing: border-box;
                border-right: 1px solid #ddd;
                &:last-child {
                  border-right: 0;
                }
              }
              > dt.prodPic {
                flex: 1;
                min-width: 0;
                .sellerName {
                  display: none;
                }
                .prodInfo {
                  a {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    box-sizing: border-box;
                    position: relative;
                    width: 100%;
                    .infoImg {
                      .imgContainer {
                        display: block;
                        height: 0;
                        padding: 0 0 100%;
                        box-sizing: border-box;
                        position: relative;
                        background: #eee;
                        overflow: hidden;
                        z-index: 0;
                        width: 100px;
                        height: 100px;
                        img {
                          display: block;
                          position: absolute;
                          width: auto;
                          height: auto;
                          max-width: 100%;
                          max-height: 100%;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          margin: auto;
                          transition: all 0.3s ease;
                        }
                      }
                    }
                    .infoTxt {
                      flex: 1;
                      min-width: 0;
                      box-sizing: border-box;
                      padding: 0 0 0 15px;
                      display: flex;
                      flex-flow: column;
                      flex-wrap: wrap;
                      justify-content: center;
                      .prodName {
                        font-size: 0.882em;
                        letter-spacing: -0.05em;
                        word-wrap: break-word;
                        word-break: keep-all;
                        white-space: normal;
                        line-height: 1.2;
                        color: #333;
                        display: -webkit-box;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        max-height: 38px;
                        word-wrap: break-word;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                      .prodOption {
                        margin: 5px 0 0;
                        border-radius: 4px;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                        background: #fff;
                        padding: 5px;
                        dl {
                          display: flex;
                          flex-flow: row;
                          flex-wrap: wrap;
                          justify-content: space-between;
                          padding: 0 0 5px;
                          margin: 0 0 5px;
                          border-bottom: 1px dashed #ddd;
                          &:last-child {
                            padding: 0;
                            margin: 0;
                            border-bottom: 0;
                          }
                          dt,
                          dd {
                            line-height: 1.2;
                            font-size: 14px;
                            font-weight: 500;
                          }
                          dt {
                          }
                          dd {
                          }
                        }
                      }
                    }
                    &:hover,
                    &:focus-visible {
                      &:before {
                        border-color: var(--color-1);
                        border-width: 5px;
                      }
                      .imgContainer {
                        img {
                          transform: scale(1.2);
                        }
                      }
                    }
                  }
                }
              }
              > dd {
                &.prodQty {
                  width: 100px;
                }
                &.prodDelivery {
                  width: 130px;
                }
                &.prodService {
                  width: 130px;
                }
                &.prodTotal {
                  width: 130px;
                }
                .configDl {
                  display: flex;
                  flex-flow: column;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  height: 100%;
                  dt,
                  dd {
                    text-align: center;
                    color: #333;
                  }
                  dt {
                  }
                  dd {
                    min-height: 40px;
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    .selectMethod {
                      height: 40px;
                      min-width: 110px;
                    }
                    .selectService {
                      height: 40px;
                      min-width: 110px;
                      border-radius: 4px;
                    }
                    .orderTotalPrice {
                      display: block;
                      line-height: 1.2;
                    }
                    .orderService {
                      display: block;
                      line-height: 1.2;
                    }
                  }
                }
              }
            }
          }
          #dialogCustom {
            text-align: left;
            .c-tit03 {
              label {
                padding-left: 0;
                padding-right: 25px;
                margin: 0;
                &:before {
                  left: auto;
                  right: 0;
                  top: 8px;
                }
                &:after {
                  left: auto;
                  right: 4px;
                  top: 12px;
                }
              }
            }
            .imgPreview {
              display: flex;
              flex-flow: row;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              padding: 0 0 20px;
              border-bottom: 1px solid #ddd;
              .boxImg {
                width: 80px;
                box-sizing: border-box;
                border: 1px solid #ddd;
                .imgContainer {
                  display: block;
                  height: 0;
                  padding: 0 0 100%;
                  box-sizing: border-box;
                  position: relative;
                  background: #eee;
                  overflow: hidden;
                  img {
                    display: block;
                    position: absolute;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    transition: all 0.3s ease;
                  }
                }
              }
              .boxTxt {
                flex: 1;
                min-width: 0;
                line-height: 1;
                box-sizing: border-box;
                padding: 0 0 0 15px;
                > * {
                  box-sizing: border-box;
                  line-height: 1.2;
                }
                .prodName {
                  &:after {
                    content: "";
                    display: inline-block;
                    width: 2px;
                    height: 15px;
                    background: #ddd;
                    transform: rotate(20deg);
                    margin: 0 10px -1px;
                  }
                }
                .prodNum {
                  em {
                    font-style: normal;
                    color: var(--color-1);
                  }
                }
              }
            }
            .frmWr {
              dl {
                dt {
                  text-align: left;
                  .chks,
                  .rdos {
                    label {
                      &:before {
                        top: 5px;
                      }
                      &:after {
                        top: 9px;
                      }
                    }
                  }
                }
                dd {
                  &.hasService1 {
                    text-align: left;
                    justify-content: space-between;
                    .desc {
                    }
                    .price {
                      color: var(--color-1);
                    }
                    .additional {
                      display: flex;
                      flex-flow: row;
                      flex-wrap: wrap;
                      align-items: center;
                      align-content: center;
                      width: 100%;
                      margin: 10px 0 0;
                      label {
                      }
                      input {
                        flex: 1;
                        min-width: 0;
                        margin: 0 10px;
                        height: 30px;
                      }
                      .unit {
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .storeTotal {
          box-sizing: border-box;
          padding: 10px 0;
          border-bottom: 2px solid #555;
          dl {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;
            dt,
            dd {
            }
            dt {
              font-weight: 500;
              color: #111;
            }
            dd {
              em {
                font-style: normal;
                color: var(--color-1);
                font-weight: 500;
              }
            }
          }
        }
      }

      /** -- 04. 배송방식 */
      .sectDeliveryMethod {
        .lv1 {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            min-width: 0;
            width: auto;
            dl {
              dt {
                width: 100px;
              }
              dd {
                flex: 1;
                min-width: 0;
                padding-right: 0;
                select {
                  max-width: none !important;
                  width: auto !important;
                  margin: 0 10px 0 0 !important;
                  &:last-child {
                    margin: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    /** -- Right : 결제요약 */
    .sectOrder {
      width: 500px;
      margin: -15px 0 0;
      .order-area {
        position: sticky;
        top: 90px;
        padding: 15px 0 0;
        .order-wrap {
          padding: 35px;
          background: #f7f7f7;
          border-radius: 10px;
          box-sizing: border-box;
          border: 1px solid #ddd;
          .order-productInfo {
            > * {
              padding: 0 0 20px;
              margin: 0 0 20px;
              border-bottom: 1px solid #ddd;
              &:last-child {
                padding: 0;
                margin: 0;
                border-bottom: 0;
              }
            }
            .order-top {
              display: flex;
              justify-content: space-between;
              font-size: 1.059em;
              color: #111;
              font-weight: 500;
            }
            .order-middle {
              dl {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: space-between;
                margin: 0 0 10px;
                &:last-child {
                  margin: 0;
                }
                dt,
                dd {
                  box-sizing: border-box;
                  line-height: 1.2;
                }
                dt {
                  font-weight: 500;
                }
                dd {
                  color: #000;
                  letter-spacing: 0;
                  font-weight: 700;
                }
              }
            }
            .order-bottom {
              ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
              .shippingRow {
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;
                dl {
                  dt {
                  }
                  dd {
                  }
                }
              }
              .shippingFree {
                dl {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  dt {
                    font-weight: 500;
                    color: #000;
                  }
                  dd {
                    color: var(--color-1);
                  }
                }
              }
            }
            .order-subTotal {
              dl {
                display: flex;
                align-items: center;
                justify-content: space-between;
                dt {
                  font-weight: 500;
                  color: #000;
                }
                dd {
                  color: var(--color-1);
                }
              }
            }
            .order-total {
              dl {
                display: flex;
                align-items: center;
                justify-content: space-between;
                dt {
                  font-weight: 500;
                  color: #000;
                }
                dd {
                  color: var(--color-1);
                  font-weight: 700;
                }
              }
            }
          }
        }
        .payment-method {
          margin: 20px 0 0;
          .card-info {
            padding: 25px 5px 40px 5px;
            border: 1px solid #e5e5e5;
            width: 100%;
            border-radius: 10px;
            box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);
          }
          .place-order {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
            .chks {
            }
            .icon-container {
              font-size: 24px;
              i {
                margin: 0 5px 0 0;
                &:last-child {
                  margin: 0;
                }
              }
            }
            .agreement-info-wrap {
              width: 100%;
              font-size: 14px;
              margin: 0 0 15px;
              height: 130px;
              padding: 15px;
              border: 1px solid #ddd;
              background: #fff;
            }
            .orderBtn {
              min-width: 0;
              max-width: none;
              width: 100%;
              margin: 10px 0 0;
            }
          }
        }
        .place-order {
          .termsContainer {
            margin: 20px 0 0;
            .termsChks {
              .unit {
                margin: 0 0 10px;
                &:last-child {
                  margin: 0;
                }
                .chks {
                }
                .moreDesc {
                  line-height: 1;
                  box-sizing: border-box;
                  font-size: 0.941em;
                  margin: 0 0 0 25px;
                  border: 0;
                  color: var(--color-1);
                  border-bottom: 1px solid var(--color-1);
                  background: #fff;
                }
              }
            }
          }
        }
        .payment-accordion {
          margin: 0 0 16px;
          &:last-child {
            margin: 0 0 0;
          }
          h4 {
            font-size: 16px;
            margin: 0;
            color: #212121;
            a {
              position: relative;
              display: block;
              color: #212121;
              &:hover {
                color: $theme-color;
              }
            }
          }
          .panel-body {
            padding: 5px 0 0 0;
            p {
              font-size: 14px;
              padding: 0 0 0 10px;
              color: #333;
            }
          }
        }
        iframe {
          margin: none;
          padding: none;
          border: none;
          line-height: 0;
          float: left;
        }
      }
    }
  }
}

.CheckIn {
  text-align: center;
  padding: 50px 0 0;
  .sectMsg {
    .pTit1 {
      > * {
        display: block;
        line-height: 1.2;
        color: #000;
        word-wrap: break-word;
        word-break: keep-all;
        white-space: normal;
      }
      .sTxt1 {
        font-size: 1.118em;
        font-weight: 500;
      }
      .sTxt2 {
        font-size: 1.764em;
        font-weight: 700;
        margin: 20px 0 0;
        border: 1px solid #ddd;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 10px 20px 10px;
      }
    }
    .pIco1 {
      width: 189px;
      height: 172px;
      margin: 46px auto 0;
      background: url(/assets/img/_member/login_spr.png) no-repeat 0 -440px;
    }
    .pTxt2 {
      font-size: 15px;
      color: #8b8b8b;
      line-height: 1.2;
      font-weight: 500;
      margin: 57px 0 0;
      word-wrap: break-word;
      word-break: keep-all;
      white-space: normal;
    }
    .pFiled {
      width: 189px;
      height: 172px;
      margin: 46px auto 0;
      background: url(/assets/img/_member/failed.png) no-repeat center center;
      background-size: contain;
    }
    .pError {
      width: 189px;
      height: 172px;
      margin: 46px auto 0;
      background: url(/assets/img/_member/error.png) no-repeat center center;
      background-size: contain;
    }
    .pLsit1 {
      margin: 20px 0 0;
      li {
        text-align: left;
      }
    }
  }
  .sectBtn {
    width: 100%;
    border-top: 2px solid #777;
    padding: 50px 0 0;
    margin: 100px auto 0;
    button {
      min-width: 160px;
    }
  }
}

.delivery-header {
  display: flex;
  gap: 20px;
  align-items: center;
  button {
    font-size: 25px;
    border: none;
    background-color: unset;
  }
}
.delivery-config {
  width: 100% !important;
  padding: 0 !important;
}
.deliveryList {
  table {
    .colNickname {
      width: 150px;
      .default {
        display: block;
        font-size: 12px;
        box-sizing: border-box;
        border: 1px solid var(--color-1);
        color: var(--color-1);
        width: 80px;
        margin: 0 auto;
      }
    }
    .colRecipient {
      width: 120px;
    }
    .colAddress {
      width: auto;
    }
    .colTelephone {
      width: 150px;
    }
    .colManagement {
      width: 130px;
      button {
        min-width: 0;
        max-width: none;
        width: 100%;
      }
    }
    thead {
      tr {
        th {
          padding: 10px 5px;
        }
      }
    }
    tbody {
      tr {
        th,
        td {
          padding: 10px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .checkoutPkg {
    flex-direction: column;
  }

  .sectOrder {
    width: 100% !important;
  }
}
