/*------- 6. Product style  --------*/
.product-area {
	position:relative;
	.row {
		&.three-column {
			.col-xl-3 {flex:1 0 33.3333%;max-width:33.3333%;transition:0.5s;}
		}
		&.five-column {
			.col-xl-3 {flex:1 0 20%;max-width:20%;transition:0.5s;}
		}
	}
	&--style2 {
		.swiper-slider-navigation-wrapper {width:100%;text-align:center;}
		.ht-swiper-button-nav {
			font-size:28px;line-height:1;position:absolute;top:15px;right:0;display:inline-block;width:35px;height:35px;padding:0;transition:all 0.3s ease 0s;text-align:center;color:#c6c6c6;border:1px solid #d2d2d2;border-radius:5px;background-color:#f9f9f9;
			i {padding-top:3px;}
			&:hover {color:#fff;border:1px solid $theme-color;background-color:$theme-color;}
			&.swiper-button-prev {right:40px;left:auto;}
		}
	}
}
.product-details-content {
	.pro-details-rating-wrap {
		display:flex;align-items:center;margin:0 0 17px;
		.pro-details-rating {
			position:relative;margin-right:39px;
			&:before {position:absolute;top:4px;right:-19px;display:none;width:2px;height:15px;content:"";background-color:#d1d1d1;}
			i {
				font-size:17px;margin-right:5px;color:#5f5d5d;
				&.yellow {color:#ffa900;}
			}
		}
		span {
			a {
				font-size:15px;color:$theme-color;
				&:hover {color:#000;}
			}
		}
	}
	p {font-size:15px;line-height:28px;margin:0;color:#333;}
	.pro-details-size-color {
		.pro-details-color-wrap {
			margin-right:20px;
			span {font-size:15px;font-weight:500;display:block;margin:0 0 15px;}
			.pro-details-color-content {
				ul {
					li {
						position:relative;display:block;float:left;width:14px;height:14px;margin-right:15px;cursor:pointer;transition:all 0.4s ease 0s;border-radius:50px;
						&:last-child {margin-left:15px;}
						&.blue {border:1px solid #4798f3;background-color:#4798f3;}
						&.maroon {border:1px solid #736751;background-color:#736751;}
						&.gray {border:1px solid #c0c0c0;background-color:#c0c0c0;}
						&.green {border:1px solid #139c57;background-color:#139c57;}
						&.yellow {border:1px solid #e28b37;background-color:#e28b37;}
					}
				}
				&--single {
					position:relative;display:inline-block;width:14px;height:14px;margin-right:15px;cursor:pointer;transition:all 0.4s ease 0s;background-color:lightgray;border-radius:50px;margin-left:20px;
					&:last-child {margin-left:15px;}
					input {
						position:absolute;height:100%;cursor:pointer;opacity:0;
						&:checked {
							&~.checkmark {
								&:after {visibility:visible;opacity:1;}
							}
						}
					}
					.checkmark {
						position:relative;display:inline-block;width:14px;height:14px;margin:1px 0px -1px 1px;
						&:after {position:absolute;top:-7px;left:-4px;visibility:hidden;width:20px;height:20px;content:"";transition:0.3s;opacity:0;border:2px solid #333;border-radius:50%;}
					}
					&:hover {
						input {
							&~.checkmark {
								&:after {visibility:visible;opacity:1;}
							}
						}
					}
				}
			}
		}
		.pro-details-size {
			margin:15px;
			span {font-size:15px;font-weight:500;display:block;margin:0 0 10px;}
			.pro-details-size-content {
				select {font-size:14px;width:50%;color:#333;border:0 solid #eceff8;background:transparent;box-shadow:none;height:45px;padding:0px 15px;border:1px solid #e6e6e6;}
				ul {
					li {
						display:inline-block;margin:0 2px 0 0;list-style:outside none none;
						a {
							font-size:12px;line-height:1;display:inline-block;padding:6px 9px 7px;text-transform:uppercase;color:#000;background-color:#f1f2f6;
							&:hover {color:#fff;background-color:$theme-color;}
						}
					}
				}
				&--single {
					font-size:12px;line-height:1;position:relative;display:inline-block;margin:0 5px 0 0;text-transform:uppercase;color:#000;background-color:#f1f2f6;
					&:hover {color:#fff;background-color:$theme-color;}
					input {
						position:absolute;top:0;left:0;height:100%;cursor:pointer;opacity:0;
						&:checked {
							&~.size-name {color:$white;background-color:$theme-color;}
						}
					}
					.size-name {font-size:12px;font-weight:400;width:100%;margin-bottom:0;padding:8px;}
				}
			}
		}
	}
	.pro-details-quality {
		.pro-details-wishlist {
			a,
			button {
				font-size:18px;color:#000;border:none;background:none;
				&:hover,
				&.active {color:$theme-color;}
			}
		}
		.pro-details-compare {
			a,
			button {
				font-size:18px;margin-left:25px;color:#000;border:none;background:none;
				&:hover,
				&.active {color:$theme-color;}
			}
		}
	}
	.pro-details-meta {
		display:flex;margin:0 0 10px;
		span {font-size:15px;margin-right:5px;color:#676767;}
		ul {
			li {
				display:inline-block;margin:0 10px 0 0;
				a {
					font-size:15px;color:#676767;
					&:hover {color:$theme-color;}
				}
			}
		}
	}
	.pro-details-social {
		margin:24px 0 0;
		ul {
			display:flex;
			li {
				margin-right:40px;
				&:last-child {margin-right:0;}
				a {
					font-size:16px;color:#343538;
					&:hover {color:$theme-color;}
				}
			}
		}
	}
	&.pro-details-slider-content {
		.product-details-price {justify-content:center;}
		p {width:59%;margin:0 auto;}
		.pro-details-rating-wrap {justify-content:center;}
		.pro-details-size-color {justify-content:center;text-align:left;}
		.pro-details-quality {justify-content:center;}
		.pro-details-meta {justify-content:center;}
		.pro-details-social {
		ul {justify-content:center;}
		}
	}
}
.quickview-wrap {
	.nav-style-1.owl-carousel > .owl-nav div {
		font-size:20px;left:0;color:#333;
		&:hover {color:$theme-color}
		&.owl-next {right:0;left:auto}
	}
	&:hover .nav-style-1.owl-carousel > .owl-nav div {visibility:visible;opacity:1}
}

/*  prodduct 2     */
.tab-filter-wrap {display:flex;align-items:center;justify-content:space-between;}
.product-tab-list-2 {
	.nav-item {
		&:first-child {
			.nav-link {padding-left:0;}
		}
	}
	a {
		display:inline-block;margin-right:23px;
		&.nav-link {background-color:transparent !important;}
		&:last-child {margin-right:0;}
		h4 {
			font-size:18px;font-weight:500;margin:0;padding-bottom:5px;transition:all 0.3s ease 0s;color:#555;border-bottom:2px solid transparent;
			&:hover {color:#000000;}
		}
		&.active {
			h4 {color:#000000;border-bottom:2px solid #000000;}
		}
	}
}
.filter-active {
	position:relative;
	a,
	button {
		font-size:18px;font-weight:500;color:#010101;border:none;background:none;
		&:hover,
		&.active {color:$theme-color;}
		i {font-size:14px;}
	}
}
.product-filter-wrapper {
	overflow:hidden;height:0;transition:height 0.4s ease-out;background-color:#fff;
	.product-filter {
		h5 {
			font-size:16px;font-weight:500;position:relative;margin-bottom:25px;padding-bottom:7px;text-transform:capitalize;color:#000;
			&::before {position:absolute;bottom:0;left:0;width:20px;height:1px;content:"";background-color:#000;}
		}
		ul.sort-by {
			li {
				margin-bottom:3px;
				&:last-child {margin-bottom:0;}
			}
		}
		ul.color-filter {
			li {
				margin-bottom:6px;
				&:last-child {margin-bottom:0;}
				input {display:inline-block;float:left;width:20px;height:20px;margin:3px 8px 0 0;}
				a {text-transform:capitalize;}
			}
		}
		.product-tags {
			ul {
				li {
					margin-bottom:4px;
					a {text-transform:capitalize;}
				}
			}
		}
		ul {
			li {
				button {
					text-transform:capitalize;border:none;background:none;
					&:hover,
					&.active {color:$theme-color;}
				}
			}
		}
		&--tag {
			ul {
				li {
					display:inline-block;
					button {
						margin-right:10px;margin-bottom:10px;text-transform:lowercase;border:1px solid #e6e6e6;
						&:hover {border-color:$theme-color;}
					}
				}
			}
		}
	}
	&__inner {margin-bottom:60px;padding:45px 45px 7px;box-shadow:0 0 6px rgba(0, 0, 0, 0.1);}
}
.price-filter {
	.price-slider-amount {
		input {font-size:16px;font-weight:500;padding:0;border:none;background:transparent;}
	}
	#slider-range {
		height:5px;margin-bottom:12px;margin-left:auto;border:medium none;border-radius:50px;background:#dbdbdb none repeat scroll 0 0;
		span {transition:all 0.0s ease 0s;}
		.ui-slider-range {position:absolute;display:block;transition:all 0.0s ease 0s;border:none;background:$theme-color none repeat scroll 0 0;}
		.ui-slider-handle {width:15px;height:15px;margin-left:0;border:medium none;border:4px solid $theme-color;border-radius:50%;background:#fff none repeat scroll 0 0;}
	}
}
.tab-content.jump {
	.tab-pane {
		display:block;overflow:hidden;height:0;opacity:0;
		&.active {display:block;overflow:visible;height:auto;opacity:1;}
	}
}

.product-wrap-2 {
	position:relative;
	overflow:hidden;
	.product-img {
		position:relative;height:300px;display:flex;align-items:center;justify-content:center;
		img {width:100%;}
		span {
			font-size:14px;font-weight:500;position:absolute;top:20px;right:20px;
			&.pink {color:$theme-color;}
			&.red {color:#ff3d2a;}
			&.purple {color:$theme-color;}
		}
		& .default-img {transition:all 0.5s ease-in-out;}
		& .hover-img {position:absolute;top:0;left:0;visibility:hidden;width:100%;transition:all 0.5s ease-in-out;transform:translateX(100%);opacity:0;}
		.product-action-2 {
			position:absolute;top:50%;display:flex;justify-content:center;width:100%;transform:translateY(-50%);
			a,
			button {
				font-size:16px;line-height:42px;display:inline-block;width:42px;height:42px;margin:0 3px;transition:all 0.4s ease-in-out;transform:scaleX(0);text-align:center;color:#fff;border:none;border-radius:50px;background:none;background-color:$theme-color;
				&:hover,
				&.active {background-color:$theme-color;}
			}
			&.product-action-2-red {
				a {
					background-color:#df262b;
					&:hover {background-color:$theme-color;}
				}
			}
		}
		&-badges {
			position:absolute;top:12px;right:12px;
			&>span {
				font-size:13px;font-weight:500;line-height:1;position:static;display:block;margin-bottom:10px;padding:3px 11px;border-radius:3px;
				&.pink {color:$theme-color;}
				&.purple {color:$theme-color;}
				&:last-child {margin-bottom:0;}
			}
		}
	}
	.product-content-2 {
		display:flex;justify-content:space-between;margin:20px 0 0;
		.title-price-wrap-2 {
			h3 {
				line-height:1;margin:0 0 6px;
				a {
					font-size:16px;color:#000000;
					&:hover {color:$theme-color;}
				}
			}
			.price-2 {
				span {
					font-size:15px;position:relative;margin-right:17px;color:#000000;
					&.old {
						text-decoration:line-through;color:$theme-color;
						&.red {color:#ff3d2a;}
					}
					&::before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
					&:last-child:before {display:none;}
				}
			}
			&.title-price-wrap-2-white {
				h3 {
					a {
						color:#fff;
						&:hover {color:$theme-color;}
					}
				}
				.price-2 {
					span {
						color:#fff;
						&.old {text-decoration:line-through;color:$theme-color;}
					}
				}
			}
		}
		.pro-wishlist-2 {
			a,
			button {
				font-size:16px;display:inline-block;margin:4px 0 0 10px;color:#929292;border:none;background:none;
				&:hover,
				&.active {color:$theme-color;}
			}
		}
	}
	&:hover .hover-img {visibility:visible;transform:translateX(0);opacity:1;}
	&:hover .default-img {visibility:hidden;transform:translateX(-100%);}
	&:hover .product-action-2 a {transform:scaleX(1);}
	&:hover .product-action-2 button {transform:scaleX(1);}
	&.pro-glaucous-color {
		.product-img {
			span.glaucous {color:#3d6882;}
			.product-action-2 {
				a,
				button {
					background-color:#3d6882;
					&:hover,
					&.active {color:#000;background-color:#fff;}
				}
			}
		}
		.product-content-2 {
			.pro-wishlist-2 a:hover {color:#3d6882;}
			.title-price-wrap-2 {
				h3 a:hover {color:#3d6882;}
				.price-2 {
					span.old {color:#3d6882;}
				}
			}
		}
	}
	&.pro-puce-color {
		.product-img {
			span.puce {color:#de3e3e;}
			.product-action-2 {
				a,
				button {
					background-color:#de3e3e;
					&:hover {color:#000;background-color:#fff;}
				}
			}
		}
		.product-content-2 {
			.pro-wishlist-2 a:hover,
			.pro-wishlist-2 button:hover {color:#de3e3e;}
			.title-price-wrap-2 {
				h3 a:hover {color:#de3e3e;}
				.price-2 {
					span.old {color:#de3e3e;}
				}
			}
		}
	}
}

/* product hm3 */
.section-padding-1 .container-fluid {padding:0 70px;}

/* product hm4 */
.hm4-section-padding .container-fluid {padding:0 70px;}
.toggle-item-active,
.toggle-item-active2,
.toggle-item-active3,
.toggle-item-active4,
.toggle-item-active5,
.toggle-item-active6 {display:none;}
.view-more {
	display:block;width:100%;text-align:center;
	a {
		font-weight:500;line-height:1;display:inline-block;padding-bottom:2px;text-align:center;color:#555;border-bottom:1px solid #cccccc;
		&:hover {color:$theme-color;border-bottom:1px solid $theme-color;}
	}
	&.round-btn {
		a {
			font-size:16px;line-height:1;display:inline-block;padding:16px 40px 16px 40px;text-transform:capitalize;color:#fff;border-radius:34px 34px 34px 34px;background-color:#c61a32;
			&:hover {background-color:$theme-color;}
		}
	}
}

/* product hm5 */
.hm5-section-padding {
	.container-fluid {padding-right:0;padding-left:0;}
	.row {margin-right:-10px;margin-left:-10px;}
	div[class^="col-"] {padding-right:10px;padding-left:10px;}
}

.product-wrap-3 {
	position:relative;
	.product-img {
		position:relative;
		a {
			img {width:100%;}
		}
		&-badges {
			position:absolute;z-index:9;top:12px;right:12px;
			span {
				font-size:13px;font-weight:500;line-height:1;display:block;margin-bottom:10px;padding:3px 11px;color:#fff;border-radius:3px;
				&.purple {background-color:$theme-color;}
				&.pink {background-color:$theme-color;}
				&:last-child {margin-bottom:0;}
			}
		}
	}
	.product-content-3-wrap {
		position:absolute;z-index:8;top:5%;right:5%;bottom:5%;left:5%;visibility:hidden;width:90%;height:90%;transition:all 0.4s ease 0s;transform:scale(0.85);opacity:0;background:rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
		.product-content-3 {
			position:absolute;top:50%;left:50%;width:100%;transition:all 0.4s ease 0s;transform:translate(-50%, -50%);text-align:center;
			.product-title {
				h3 {font-size:18px;line-height:1;margin:0;}
			}
			.price-3 {
				margin:10px 0 20px;
				span {
					font-size:16px;font-weight:500;position:relative;margin:0 9px;color:#000;
					&::before {position:absolute;top:10px;right:-13px;width:7px;height:2px;content:"";background-color:#000;}
					&:last-child::before {display:none;}
					&.old {text-decoration:line-through;color:#8e8e8e;}
				}
			}
			.product-action-3 {
				.pro-same-action {display:inline-block;}
				a,
				button {
					font-size:16px;line-height:42px;display:inline-block;width:42px;height:42px;margin:0 3px;transition:all 0.4s ease-in-out;text-align:center;color:#fff;border:none;border-radius:50px;background:none;background-color:$theme-color;
					&:hover,
					&.active {background-color:$theme-color;}
					&:disabled {cursor:not-allowed;}
				}
			}
		}
	}
	&:hover .product-content-3-wrap {visibility:visible;transform:scale(1);opacity:1;}
}

/* product hm6 */
.hm6-section-padding {
	.container-fluid {padding-right:70px;padding-left:70px;}
	.row {margin-right:-10px;margin-left:-10px;}
	div[class^="col-"] {padding-right:10px;padding-left:10px;}
}





.product-wrap-4 {
	position:relative;overflow:hidden;
	a {
		img {width:100%;transition:all 0.5s ease 0s;transform:scale(1);}
	}
	.position-1 {position:absolute;right:0;bottom:75px;left:0;text-align:center;}
	.position-2 {position:absolute;bottom:55px;left:60px;}
	.position-3 {position:absolute;top:51px;right:0;left:0;text-align:center;}
	.product-content-4 {
		h4 {font-size:30px;line-height:38px;margin:0 0 12px;}
		.price-4 {
			span {
				font-size:18px;position:relative;margin:0 24px 0 0;color:#000000;
				&:before {position:absolute;top:12px;right:-17px;width:7px;height:2px;content:"";background-color:#6b6264;}
				&:last-child:before {display:none;}
				&.old {text-decoration:line-through;color:$theme-color;}
			}
			&.price-4-center {
				span {margin:0 12px;}
			}
		}
	}
	&:hover a img {transform:scale(1.1);}
}
.width-50-percent {flex:0 0 50%;max-width:50%;}
.width-30-percent {flex:0 0 30%;max-width:30%;}

/* product home 8*/
.collection-product {
	.collection-img {
		overflow:hidden;
		a {
			img {width:100%;transition:all 0.5s ease 0s;transform:scale(1);}
		}
	}
	.collection-content {
		margin:23px 0 0;
		span {font-weight:500;}
		h4 {
			line-height:1;margin:4px 0 0;
			a {
				font-size:16px;font-weight:500;color:#000000;
				&:hover {color:$theme-color;}
			}
		}
		.collection-btn {
			font-size:13px;font-weight:400;line-height:1;position:relative;display:inline-block;overflow:hidden;margin-top:15px;padding:8px 11px;text-transform:uppercase;color:#666;border:1px solid #010101;border-radius:50px;background-color:transparent;
			&:before,
			&:after {position:absolute;z-index:-1;right:0;bottom:0;left:auto;width:0;height:100%;content:"";transition:all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);}
			&:hover {
				color:$white;border-color:$theme-color;background-color:transparent;
				&:after {right:auto;left:0;width:100%;background-color:$theme-color;}
			}
		}
	}
	&:hover a img {transform:scale(1.1);}
}





.product-wrap-5 {
	position:relative;
	.product-img {
		position:relative;
		a {
			img {width:100%;}
		}
		&-badges {position:absolute;top:12px;right:12px;}
		span {
			font-size:13px;font-weight:500;line-height:1;display:block;margin-bottom:10px;padding:3px 11px;color:#fff;border-radius:3px;
			&.purple {background-color:$theme-color;}
			&.pink {background-color:$theme-color;}
			&:last-child {margin-bottom:0;}
		}
	}
	.product-action-4 {
		position:absolute;z-index:999;bottom:0;left:0;display:flex;justify-content:center;width:100%;padding:10px;transition:all 0.5s ease 0s;transform:scaleY(0);text-align:center;opacity:1;background:none repeat scroll 0 0 $theme-color;
		.pro-same-action {
			margin:0 2px;
			a,
			button {
				font-size:16px;line-height:36px;display:inline-block;width:35px;height:35px;margin:0 3px;text-align:center;color:#000;border:none;border-radius:50px;background:none;background-color:#fff;
				&:hover,
				&.active {color:#fff;background-color:$theme-color;}
			}
		}
	}
	.product-content-5 {
		margin-top:28px;
		h3 {
			font-size:16px;line-height:17px;margin:0 0 5px;
			a {
				color:#000;
				&:hover {color:$theme-color;}
			}
		}
		.price-5 {
			span {
				font-size:15px;position:relative;margin:0 8px;color:#000000;
				&:before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
				&:last-child:before {display:none;}
				&.old {text-decoration:line-through;color:$theme-color;}
			}
		}
	}
	&:hover .product-action-4 {transform:scaleY(1);opacity:1;}
}



.product-wrap-6 {
	display:flex;
	.product-img {
		position:relative;flex-basis:170px;margin-right:30px;
		&-badges {position:absolute;top:12px;right:12px;}
		span {
			font-size:13px;font-weight:500;line-height:1;display:block;margin-bottom:10px;padding:3px 11px;color:#fed700;border-radius:3px;
			&:last-child {margin-bottom:0;}
		}
	}
	.product-content {
		flex-basis:calc(100% - 200px);
		h3 {
			font-size:16px;font-weight:400;line-height:1;margin-bottom:15px;
			a {
				color:#3d3d3d;
				&:hover {color:#fed700;}
			}
		}
		.product-price {
			margin-bottom:10px;
			margin-left:-8px;
			span {
				font-size:18px;position:relative;margin:0 8px;color:#000000;
				&:before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
				&:last-child:before {display:none;}
				&.old {text-decoration:line-through;color:#fed700;}
			}
		}
		.product-rating {
			margin-bottom:15px;
			i {
				font-size:17px;margin-right:3px;
				&.yellow {color:#ffa900;}
			}
		}
		.product-action {
			display:flex;justify-content:flex-end;
			.pro-same-action {
				margin-left:5px;
				&:first-child {margin-left:0;}
				button,
				a {
					font-size:23px;line-height:55px;display:inline-block;width:55px;height:55px;text-align:center;color:#000;border:none;background:none;background-color:#f6f6f6;
					&:hover,
					&.active {color:#fff;background-color:#fed700;}
				}
			}
		}
	}
}






.product-wrap-7 {
	position:relative;overflow:hidden;
	.product-img {
		position:relative;
		img {width:100%;}
		span {font-size:14px;font-weight:500;position:absolute;top:20px;right:20px;color:#fed700;}
		& .default-img {transition:all 0.5s ease-in-out;}
		& .hover-img {position:absolute;top:0;left:0;visibility:hidden;width:100%;transition:all 0.5s ease-in-out;transform:translateX(100%);opacity:0;}
		.product-action-2 {
			position:absolute;right:10px;bottom:10px;display:flex;justify-content:flex-end;width:100%;
			a,
			button {
				font-size:16px;line-height:42px;display:inline-block;width:42px;height:42px;margin:0 3px;transition:all 0.4s ease-in-out;transform:scaleX(0);text-align:center;color:#fff;border:none;background:none;background-color:#555;
				&:hover,
				&.active {background-color:#fed700;}
			}
		}
		&-badges {
			position:absolute;top:12px;left:12px;
			&>span {
				font-size:13px;font-weight:500;line-height:1;position:static;display:block;margin-bottom:10px;padding:3px 11px;border-radius:3px;
				&:last-child {margin-bottom:0;}
			}
		}
		.pro-wishlist-2 {
			line-height:35px;position:absolute;z-index:9;top:20px;right:20px;text-align:center;
			a,
			button {
				font-size:16px;display:inline-block;width:35px;height:35px;transition:all 0.3s ease-in-out;color:#fff;border:none;border-radius:100%;background:#555555;
				&:hover,
				&.active {background:#fed700;}
			}
		}
	}
	.product-content-2 {
		display:flex;justify-content:space-between;margin:20px 0 0;
		.title-price-wrap-2 {
			h3 {
				line-height:1;margin:0 0 6px;
				a {
					font-size:16px;font-weight:500;color:#444;
					&:hover {color:#fed700;}
				}
			}
			.price-2 {
				span {
					font-size:15px;position:relative;margin-right:17px;color:#444;
					&.old {
						text-decoration:line-through;color:#444;
						&.red {color:#ff3d2a;}
					}
					&::before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
					&:last-child:before {display:none;}
				}
			}
		}
	}
	&:hover .hover-img {visibility:visible;transform:translateX(0);opacity:1;}
	&:hover .product-action-2 a {transform:scaleX(1);}
	&:hover .product-action-2 button {transform:scaleX(1);}
}




.product-wrap-8 {
	display:flex;
	.product-img {
		position:relative;flex-basis:170px;margin-right:30px;
		&-badges {position:absolute;top:12px;right:12px;}
		span {
			font-size:13px;font-weight:500;line-height:1;display:block;margin-bottom:10px;padding:3px 11px;color:#fff;border-radius:3px;background-color:#ff0000;
			&:last-child {margin-bottom:0;}
		}
	}
	.product-content {
		flex-basis:calc(100% - 200px);
		h3 {
			font-size:16px;font-weight:400;line-height:1;margin-bottom:15px;
			a {
				color:#3d3d3d;
				&:hover {color:#ff0000;}
			}
		}
		.product-price {
			margin-bottom:10px;margin-left:-8px;
			span {
				font-size:18px;position:relative;margin:0 8px;color:#000000;
				&:before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
				&:last-child:before {display:none;}
				&.old {text-decoration:line-through;color:#ff0000;}
			}
		}
		.product-rating {
			margin-bottom:15px;
			i {
				font-size:17px;margin-right:3px;
				&.yellow {color:#ffa900;}
			}
		}
		.product-action {
			display:flex;justify-content:flex-end;
			.pro-same-action {
				margin-left:5px;
				&:first-child {margin-left:0;}
				button,
				a {
					font-size:23px;line-height:55px;display:inline-block;width:55px;height:55px;text-align:center;color:#000;border:none;background:none;background-color:#f6f6f6;
					&:hover,&.active {color:#fff;background-color:#ff0000;}
				}
			}
		}
	}
	&--brown {
		.product-img {
			span {background-color:#bca487;}
		}
		.product-content {
			h3 {
				a {
					&:hover {color:#bca487;}
				}
			}
			.product-price {
				span {
					&.old {color:#bca487;}
				}
			}
			.product-action {
				.pro-same-action {
					button,
					a {
						&:hover,
						&.active {background-color:#bca487;}
					}
				}
			}
		}
	}
}




.product-wrap-9 {
	position:relative;overflow:hidden;
	.product-img {
		position:relative;
		img {width:100%;}
		span {font-size:14px;font-weight:500;position:absolute;top:20px;right:20px;color:#ff0000;}
		& .default-img {transition:all 0.5s ease-in-out;}
		& .hover-img {position:absolute;top:0;left:0;visibility:hidden;width:100%;transition:all 0.5s ease-in-out;transform:translateX(100%);opacity:0;}
		.product-action-2 {
			position:absolute;right:10px;bottom:0;display:flex;justify-content:flex-end;width:100%;
			a,
			button {
				font-size:16px;line-height:55px;display:inline-block;width:55px;height:55px;margin:0 3px;transition:all 0.4s ease-in-out;transform:scaleX(0);text-align:center;color:#555;border:none;background:none;background-color:#fff;
				&:hover,
				&.active {color:#fff;background-color:#ff0000;}
			}
		}
		&-badges {
			position:absolute;top:12px;right:12px;
			&>span {
				font-size:13px;font-weight:500;line-height:1;position:static;display:block;margin-bottom:10px;padding:3px 11px;border-radius:3px;
				&:last-child {margin-bottom:0;}
			}
		}
	}
	.product-content-2 {
		display:flex;justify-content:space-between;margin:20px 0 0;
		.title-price-wrap-2 {
			h3 {
				font-family:$josefin;line-height:1;margin:0 0 6px;
				a {
					font-size:16px;font-weight:500;color:#444;
					&:hover {color:#ff0000;}
				}
			}
			.price-2 {
				span {
					font-size:15px;position:relative;margin-right:17px;color:#444;
					&.old {
						text-decoration:line-through;color:#444;
						&.red {color:#ff3d2a;}
					}
					&::before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
					&:last-child:before {display:none;}
				}
			}
		}
		.pro-wishlist-2 {
			line-height:35px;z-index:9;text-align:center;
			a,
			button {
				font-size:16px;display:inline-block;transition:all 0.3s ease-in-out;color:#929292;border:none;background:none;
				&:hover,
				&.active {color:#ff0000;}
			}
		}
	}
	&:hover .hover-img {visibility:visible;transform:translateX(0);opacity:1;}
	&:hover .product-action-2 a {transform:scaleX(1);}
	&:hover .product-action-2 button {transform:scaleX(1);}
}



.product-wrap-10 {
	position:relative;overflow:hidden;
	.product-img {
		position:relative;
		img {width:100%;}
		span {font-size:14px;font-weight:500;position:absolute;top:20px;right:20px;}
		& .default-img {transition:all 0.5s ease-in-out;}
		& .hover-img {position:absolute;top:0;left:0;visibility:hidden;width:100%;transition:all 0.5s ease-in-out;transform:translateX(100%);opacity:0;}
		.product-action-2 {
			position:absolute;top:50%;left:20px;display:flex;flex-direction:column;justify-content:center;width:auto;transform:translateY(-50%);
			a,
			button {
				font-size:16px;line-height:42px;display:inline-block;width:42px;height:42px;margin:3px 0;transition:all 0.4s ease-in-out;transform:translateX(-200px);text-align:center;color:#fff;border:none;border-radius:50px;background:none;background-color:#bca487;
				&:hover,
				&.active {background-color:#000;}
			}
		}
		&-badges {
			position:absolute;top:12px;right:12px;
			&>span {
				font-size:13px;font-weight:500;line-height:1;position:static;display:block;margin-bottom:10px;padding:3px 11px;color:#bca487;border-radius:3px;
				&:last-child {margin-bottom:0;}
			}
		}
	}
	.product-content-2 {
		display:flex;justify-content:space-between;margin:20px 0 0;
		.title-price-wrap-2 {
			h3 {
				font-family:$josefin;line-height:1;margin:0 0 6px;
				a {
					font-size:18px;color:#000000;
					&:hover {color:#bca487;}
				}
			}
			.price-2 {
				span {
					font-size:15px;position:relative;margin-right:17px;color:#444;
					&.old {text-decoration:line-through;color:#444;}
					&::before {position:absolute;top:10px;right:-13px;width:6px;height:2px;content:"";background-color:#a5a5a5;}
					&:last-child:before {display:none;}
				}
			}
		}
		.pro-wishlist-2 {
			a,
			button {
				font-size:16px;display:inline-block;margin:4px 0 0 10px;color:#929292;border:none;background:none;
				&:hover,
				&.active {color:$theme-color;}
			}
		}
	}
	&:hover .hover-img {visibility:visible;transform:translateX(0);opacity:1;}
	&:hover .default-img {visibility:hidden;transform:translateX(-100%);}
	&:hover .product-action-2 a {transform:translateX(0);}
	&:hover .product-action-2 button {transform:translateX(0);}
	&--style2 {
		.product-img {
			.product-action-2 {
				button,
				a {
					color:#000;background-color:transparent;
					&:hover,
					&.active {color:#bca487;background-color:transparent;}
				}
			}
		}
		&--blue {
			.product-img {
				.product-action-2 {
					button,
					a {
						color:#000;background-color:transparent;
						&:hover,
						&.active {color:#053399;background-color:transparent;}
					}
				}
				&-badges {
					&>span {color:#053399;}
				}
			}
			.product-content-2 {
				.title-price-wrap-2 {
					h3 {
						a {
							&:hover {color:#053399;}
						}
					}
				}
			}
		}
	}
}

/* product home 9*/
.collection-wrap-2 {margin-top:-175px;}
.collection-product-2 {
	position:relative;overflow:hidden;
	a {
		img {width:100%;transition:all 0.5s ease 0s;transform:scale(1);}
	}
	.collection-content-2 {
		position:absolute;right:0;bottom:30px;left:0;text-align:center;
		span {font-size:14px;font-weight:500;}
		h4 {font-size:16px;font-weight:500;line-height:21px;margin:5px 0 0;}
	}
	&:hover a img {transform:scale(1.1);}
}

/* product hm9 */
.hm9-section-padding {
	.container-fluid {padding-right:70px;padding-left:70px;}
	.custom-row-4 {margin-right:-10px;margin-left:-10px;}
	div[class^="custom2-"] {padding-right:10px;padding-left:10px;}
}
.custom-row-4 {display:flex;flex-wrap:wrap;}
.custom2-col-5 {position:relative;flex:0 0 20%;width:100%;max-width:20%;min-height:1px;}

/*------- home 14 ----------*/
.product-top-bar {
	display:flex;justify-content:space-between;
}
.product-tab-list-3 {
	position:relative;z-index:99;margin:4px 0 0;
	a {
		margin-left:24px;
		&.nav-link {padding:0;background-color:transparent !important;}
		&.active {
			h4 {color:#242424;}
		}
		&:first-child {
			margin-left:45px;
		}
		h4 {font-size:14px;line-height:1;margin:0;color:#878787;}
	}
}
.product-tab-list-5 {
	justify-content:center;width:100%;
	a {
		font-size:16px;line-height:1;display:inline-block;margin:0 20px;padding:0 0 7px;color:#000000;border-bottom:2px solid transparent;
		&.nav-link {padding:0;border-radius:0;background-color:transparent !important;}
		&.active {
			border-bottom:2px solid #59a3e1;
			h4 {color:#59a3e1;}
		}
	}
}
.product-tab-list-6 {
	.nav-item {
		&:first-child {
			.nav-link {padding-left:0;}
		}
	}
	a {
		display:inline-block;margin-right:23px;
		&.nav-link {background-color:transparent !important;}
		&:last-child {margin-right:0;}
		h4 {
			font-size:18px;font-weight:500;margin:0;padding-bottom:5px;transition:all 0.3s ease 0s;color:#555;
			&:hover {color:#000000;}
		}
		&.active {
			h4 {color:#bca487;}
		}
	}
}
.product-tab-list-7 {
	border-bottom:2px solid #d1d1d1;
	.nav-item {
		&:first-child {
			.nav-link {padding-left:0;}
		}
	}
	a {
		display:inline-block;margin-right:23px;
		&.nav-link {border-radius:0;background-color:transparent !important;}
		&:last-child {margin-right:0;}
		h4 {
			font-family:$josefin;font-size:24px;font-weight:700;margin:0;padding-bottom:5px;transition:all 0.3s ease 0s;color:#010101;
			&:hover {color:#000000;}
		}
		&.active {
			position:relative;
			&:after {position:absolute;bottom:-2px;left:0;width:100%;height:2px;content:"";background-color:#053399;}
			h4 {color:#053399;}
		}
	}
}
.product-slider-active {
	.owl-nav {
		position:absolute;top:-85px;right:0;
		div {
			font-size:18px;line-height:31px;display:inline-block;width:35px;height:35px;transition:all 0.3s ease 0s;text-align:center;color:#c6c6c6;border:1px solid #d2d2d2;border-radius:5px;background-color:#f9f9f9;
			&:hover {color:#fff;border:1px solid #ff3d2a;background-color:#ff3d2a;}
			&.owl-next {margin-left:3px;}
		}
	}
}
.pro-view-more {
	margin-top:30px;
	a {
		font-size:16px;line-height:1;display:inline-block;padding:20px 35px 19px;text-transform:uppercase;color:#828282;border:1px solid #d2d2d2;
		i {margin-right:10px;}
		&:hover {color:#fff;border:1px solid #df262b;background-color:#df262b;}
	}
}
.bg-png-1 {
	position:absolute;top:0;left:78px;
}
.funfact-area {position:relative;}
.bg-png-2 {
	position:absolute;top:0;right:550px;
}
.category-grid-area {
	margin-top:-100px;
}
.category-grid {
	position:relative;z-index:9;
	&__image {
		a {
			img {width:100%;}
		}
	}
	&__content {
		position:absolute;bottom:20px;width:100%;
		a {
			font-size:18px;font-weight:400;display:block;width:80%;margin:0 auto;padding:25px 15px;text-align:center;color:#000;background-color:#fff;
			&:hover {background-color:#bca487;}
		}
	}
}












.product-tab-list {
	justify-content:center;
	&.pt-30,
	&.pt-55 {}
	a {
		margin:0 11px;
		&.nav-link {background-color:transparent !important}
		h4 {
			font-size:18px;font-weight:500;margin:0;transition:all 0.3s ease 0s;color:#555;
			&:hover {color:#000}
		}
		&.active h4 {color:#000}
	}
	&.product-tab-fruits {
		a {
			h4 {
				color:#000000;border-bottom:2px solid transparent;
				&:hover {color:#6eab49}
			}
			&.active h4 {color:#6eab49;border-bottom:2px solid #6eab49}
		}
	}
	&.product-tab-pink {
		a {
			h4 {
				color:#000000;border-bottom:2px solid transparent;
				&:hover {color:#e90042}
			}
			&.active h4 {color:#e90042;border-bottom:2px solid #e90042}
		}
	}
	&.product-tab-pink2 {
		a {
			h4 {
				color:#000000;border-bottom:2px solid transparent;
				&:hover {color:#ed59a0}
			}
			&.active h4 {color:#ed59a0;border-bottom:2px solid #ed59a0}
		}
	}
	&.product-tab-white {
		a {
			h4 {color:#fff}
		}
	}
	&--style2 {
		.nav-item {
			a {
				margin:0;margin-left:20px;padding:0;text-transform:uppercase;
				&.active {
					h4 {color:#fed700}
				}
				h4 {font-weight:700;color:#5f5f5f}
			}
			&:first-child {
				a {margin-left:0}
			}
		}
	}
}

.product-wrap,
.product-list-image-wrap {
	position:relative;
	.product-img {
		position:relative;overflow:hidden;border:1px solid #ddd;
		> a {
			display:block;box-sizing:border-box;height:0;position:relative;padding:0 0 100%;overflow:hidden;background:#f5f5f5;
			> img {
				display:block;position:absolute;left:0;top:0;width:100%;height:100%;margin:auto;transition:all .3s ease;
				&[src=""],
				&:not([src]) {visibility:hidden;}
			}
			&:focus-visible img,
			&:hover img {transform:scale(1.2) rotate(5deg)}
			.no-img {
				position:absolute;left:0;top:50%;width:100%;transform:translate(0, -50%);font-size:14px;text-align:center;font-size:30px;
				i {display:inline-block;line-height:1;color:#aaa;}
				span {display:block;font-size:14px;}
			}
		}
		.product-action {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;position:absolute;width:100%;height:100%;left:0;bottom:0;z-index:9;transform:none;background:transparent;transition:all .3s ease;
			.pro-pkg {
				display:flex;flex-flow:row;flex-wrap:wrap;position:relative;z-index:1;
				.pro-same-action {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;border:0;margin:0 8px 0 0;visibility:hidden;opacity:0;transform:translateY(20px);transition:all .3s ease;
					&:last-child {margin:0;}
					a,
					button {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;font-size:15px;width:44px;height:44px;border-radius:50%;color:#fff;background:transparent;border:1px solid #fff;box-sizing:border-box;position:relative;transition:all .3s ease;
						i {font-size:20px}
						&:not([disabled]):focus-visible,
						&:not([disabled]):hover {background:#fff;}
						&:not([disabled]):focus-visible i,
						&:not([disabled]):hover i {color:var(--color-1);}
					}
				}
				.pro-same-action.pro-moveView {}
				// .pro-same-action.pro-cart {transition-delay:0.1s;}
				// .pro-same-action.pro-quickview {transition-delay:0.2s}
				.pro-same-action.pro-copy {transition-delay:0.1s}
				.pro-same-action.pro-kakao {transition-delay:0.2s}
				.pro-same-action.pro-favorite {transition-delay:0.3s}
			}
			.baseBtn {position:absolute;width:100%;height:100%;left:0;top:0;z-index:0;background:transparent;}
		}
		&-badges {
			position:absolute;top:12px;right:12px;
			span {
				font-size:13px;font-weight:500;line-height:1;display:block;margin-bottom:10px;padding:3px 11px;color:#fff;border-radius:3px;
				&.pink {background-color:$theme-color}
				&.purple {background-color:$theme-color}
				&:last-child {margin-bottom:0}
			}
		}
		.hover-img {position:absolute;top:0;left:50%;visibility:hidden;transition-duration:0.7s;transform:translate(-50%, 20px);opacity:0}
	}
	&:hover .product-img .product-action {background:rgba(17, 101, 224, .85);}
	&:hover .product-img .product-action .pro-same-action {opacity:1;visibility:visible;transform:translateY(0);}
	.product-content {
		margin:10px 0 0;
		h3 {
			display:block;margin:5px 0 0;
			a {
				display:-webkit-box;overflow:hidden;text-overflow:ellipsis;white-space:normal;height:40px;word-wrap:break-word;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.1;
				span {font-size:.882em;color:#444;word-break:break-all;transition:all .3s ease}
				&:focus-visible span,
				&:hover span {box-shadow:0 -12px 0 rgba(0, 0, 0, .1) inset;color:var(--color-1)}
			}
		}
		.product-tags {
			display:flex;flex-flow:row;flex-wrap:wrap;
			> li {margin:0 3px 3px 0;}
		}
		.product-price {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;margin:10px 0 0;line-height:1;
			.priceDc {
				display:block;font-size:20px;color:#000;font-weight:900;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;
				.currency {font-size:14px;font-weight:400;margin:0 0 0 1px;}
			}
			.priceCost {font-size:14px;color:#444;text-decoration:line-through;margin:0 0 0 11px;}
		}
		.product-rating {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;;line-height:1;margin:10px 0 0;
			.salesStar {
				display:block;font-size:0;line-height:1;
				* {line-height:1;top:auto;font-size:0;}
			}
			.salesQuantity {font-size:13px;color:#444;margin:0 0 0 3px;}
		}
		.product-hash {
			margin:10px 0 0;
			ul {
				display:flex;flex-flow:row;flex-wrap:wrap;line-height:1;
				li {
					margin:0 6px 6px 0;
					&:last-child {margin-right:0}
					a {
						display:block;line-height:1;border-radius:3px;background:#eaeaea;padding:3px 5px 4px;font-size:12px;color:#000;transition:all .3s ease;
						span {display:block;line-height:1;}
						&:focus-visible,
						&:hover {background:var(--color-1);color:#fff;}
					}
				}
			}
		}
		.product-factory {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;padding:5px 0 0;margin:5px 0 0;border-top:1px solid #ededed;
			.factory {
				display:flex;flex-flow:row;flex-wrap:wrap;flex:1;font-size:13px;box-sizing:border-box;padding:4px 0 0 5px;
				.facName {
					display:block;width:100%;line-height:1.3;box-sizing:border-box;position:relative;padding:0 0 0 17px;
					i {display:block;position:absolute;left:0;top:0;}
					/*
					span {transition:all .3s ease;}
					&:focus-visible i,
					&:hover i {color:var(--color-1);}
					&:focus-visible span,
					&:hover span {box-shadow:0 -12px 0 rgba(0, 0, 0, .1) inset;color:var(--color-1)}
					*/
				}
				.facQty {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:5px 8px 0 0;padding:0 10px 0 0;position:relative;box-sizing:border-box;
					&:after {content:"";display:block;position:absolute;width:2px;height:13px;background:#ddd;right:0;top:1px;transform:rotate(30deg);}
					&:last-child {padding-right:0;margin-right:0;}
					&:last-child:after {display:none}
					dt,
					dd {box-sizing:border-box;position:relative;line-height:1;}
					dt {
						padding:0 5px 0 0;margin:0 5px 0 0;
						&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;right:-2px;top:-1px;color:#aaa;}
					}
					dd {}
					&.min {padding-left:17px;}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		.product-content {
			h3 {
				a {height:36px;}
			}
		}
	}
	@media all and (max-width:699px) {
		.product-content {
			.product-price {
				margin:5px 0 0;
				.priceDc {
					font-size:17px;
					.currency {font-size:12px;}
				}
			}
		}
	}
	@media all and (max-width:599px) {
		.product-content {
			h3 {
				a {height:32px;}
			}
		}
	}
	@media all and (max-width:399px) {
		.product-content {
			.product-price {
				.priceDc {
					font-size:15px;
					.currency {font-size:10px;}
				}
			}
		}
	}
}
.shop-list-wrap {display:none}

.react-modal {
	.proReviewWrite {
		.modalCustomTit {}
		.modalCustomWrap {
			.modal-body {max-height:none;}
		}
	}
}

.modal {
	box-sizing:border-box;padding:20px !important;display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;
	.modal-dialog {
		position:relative;width:100%;height:100%;width:100%;height:100%;max-width:960px;max-height:850px;box-sizing:border-box;background:#fff;border-radius:5px;border:0;box-shadow:0 1px 10px rgba(0, 0, 0, .5);margin:auto;
		.modal-content {
			height:100%;border:0;
			.modal-header {
				display:flex;flex-flow:row;flex-wrap:wrap;min-height:70px;max-height:70px;box-sizing:border-box;padding:15px;border:0;align-items:center;border-radius:0;border-bottom:1px solid #ddd;position:relative;color:#000;
				.close {
					position:absolute;width:40px;height:40px;right:10px;top:50%;transform:translateY(-50%);border:0;background:transparent;cursor:pointer;opacity:1;color:#333;font-size:0;
					span {font-size:26px;transition:all .3s ease;}
					&:focus-visible span,
					&:hover span {color:var(--color-1);transform:rotate(90deg);}
				}
			}
			.modal-body {
				overflow-y:auto;padding:20px;border:0;box-sizing:border-box;position:relative;/* max-height:calc( 100% - 54px ); */
				.row {
					display:flex;flex-flow:row;flex-wrap:wrap;margin:0;max-height:100%;overflow:auto;height:100%;
					> * {box-sizing:border-box;position:relative}
					&::-webkit-scrollbar {width:15px}
					&::-webkit-scrollbar-thumb {background:#777;border-left:10px solid #fff;}
					&::-webkit-scrollbar-track {background:#ddd;border-left:10px solid #fff;}
					&:hover::-webkit-scrollbar-thumb {background:var(--color-1);}
					&::-webkit-scrollbar-button {height:0}
					.sticky-wrapper {position:sticky;top:0;height:fit-content;display:flex;flex-direction:column;justify-content:space-between;height:100%;}
					.box-img {
						width:300px;padding:0;overflow:auto;
						.modal-imgShow {
							.product-large-image-wrapper {
								box-sizing:border-box;border:1px solid #ddd;
								.react_lightgallery_item {}
								.single-image {
									display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;z-index:0;
									img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto}
								}
							}
							.product-small-image-wrapper {}
						}
						.modal-detail {display:flex;flex-direction:column;gap:5px;padding:5px 0 0;
							.btn-box {display:flex;justify-content:space-between;gap:5px;
								> :first-child  {width:70%;}
								> :last-child {width:30%;}
							}
							.product-details-title {display:block;font-size:1em;color:#000;line-height:1.2;margin:0;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-wrap:break-word;white-space:normal;overflow:hidden;display:-webkit-box;}
							.product-details-price {
								line-height:1;font-weight:900;display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-end;justify-content:space-between;
								span {line-height:1;}
								.priceDiscount {order:3}
								.priceOriginal.old {font-size:1.059em;margin-left:20px;text-decoration:line-through;color:#333;margin:0 auto 0 0;order:2}
								.price {font-size:1.294em;color:var(--color-r1);margin:0 15px 0 0;order:1}
							}
							.pro-details-rating-wrap {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;font-size:0;line-height:1;margin:10px 0 0;box-sizing:border-box;border:1px solid #ddd;padding:10px;justify-content:center;border-radius:5px;
								.pro-details-rating {
									margin:0;
									.star-ratings {
										display:block;position:relative;top:0;box-sizing:border-box;
										.star-grad {}
										.star-container {}
									}
								}
							}
							}
						.product-small-image-wrapper {
							margin:10px 0 0;
							.single-image {
								box-sizing:border-box;border:1px solid #ddd;
								.imgContainer {
									display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;z-index:0;
									&:before {content:"";display:none;position:absolute;width:100%;height:100%;left:0;top:0;box-sizing:border-box;border:3px solid var(--color-1);z-index:1}
									&.on {border:0;}
									&.on:before {display:block;}
									& img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;z-index:0;transition:all .3s ease}
									& img:focus {outline:3px solid rgba(59, 153, 252, .5)}
								}
							}
							.swiper-wrapper {
								.swiper-slide {}
							}
							.swiper-button-prev,
							.swiper-button-next {
								position:absolute;top:50%;transform:translateY(-50%);width:30px;height:30px;background:rgba(0, 0, 0, .5);color:#fff;border-radius:5px;margin:0;border:0;transition:all .3s ease;
								i {font-size:0}
								i:before {font-size:30px}
								&.swiper-button-disabled {opacity:0}
								&:focus-visible,
								&:hover {background:var(--color-1)}
							}
							.swiper-button-prev {left:5px}
							.swiper-button-next {right:5px}
						}
					}
					.box-cont {
						flex:1;min-width:0;padding:0 0 0 20px;
						.product-details-content {
							.pro-details-list {
								display:flex;flex-direction:column;align-items:center;
								// padding:0 0 20px;margin:0 0 20px;
								// border-bottom:1px solid #ddd;
								.proEditorContents {
									font-size:.941em;line-height:1.3;
									max-width:100%;
									// margin:20px 0;padding:0 0 20px;
									// border-bottom:1px solid #ddd;width:100%;
									> div {max-width:100%;}
									img {display:block;max-width:100%;height:auto;}
								}
								.proSpecification {
									li {display:block;margin:0 0 10px;}
									li:last-child {margin:0;}
									dl {
										display:flex;flex-flow:row;flex-wrap:wrap;margin:0;
										dt,
										dd {font-size:.941em;margin:0;line-height:1.2;font-weight:400;box-sizing:border-box;position:relative;}
										dt {
											width:120px;color:#999;
											&:after {content:":";display:block;position:absolute;width:auto;height:auto;right:10px;top:0px;}
										}
										dd {flex:1}
									}
								}
							}
							.pro-details-size-color {}
							.pro-details-quality {
								display:flex;flex-flow:row;flex-wrap:wrap;margin:0;justify-content:space-between;
								.cart-plus-minus {
									display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;width:calc( 50% - 10px );align-items:center;justify-content:space-between;
									button {
										width:50px;height:50px;box-sizing:border-box;border:1px solid #ddd;position:relative;background:#fff;transition:all .3s ease;
										&:focus-visible,
										&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff}
									}
									.dec.qtybutton {border-right:1px solid #ddd;}
									.cart-plus-minus-box {width:calc( 100% - 100px );text-align:center;border:solid;border-width:1px 0 1px 0;border-color:#ddd;box-sizing:border-box;height:50px}
									.inc.qtybutton {border-left:1px solid #ddd;}
								}
								.pro-details-cart {
									width:calc( 50% - 10px );
									button {min-width:0;max-width:100%;width:100%}
								}
							}
							.pro-details-meta-wrap {
								margin:20px 0 0;
								.pro-details-meta {
									display:block;margin:0 0 10px;
									&:last-child {margin:0;}
									dl {
										display:flex;flex-flow:row;flex-wrap:wrap;margin:0;
										dt,
										dd {font-size:.941em;margin:0;line-height:1.2;font-weight:400;box-sizing:border-box;position:relative;}
										dt {
											width:120px;color:#999;
											&:after {content:":";display:block;position:absolute;width:auto;height:auto;right:10px;top:0px;}
										}
										dd {flex:1}
									}
								}
							}
						}
					}
				}
			}
			.quickview-big-img {
				img {width:100%}
			}
		}
	}
	@media all and (max-width:999px) {
		.modal-dialog {max-width:100%;max-height:100%;margin:0;}
	}
}
.prodBadge {
	display:flex;flex-flow:row;flex-wrap:wrap;height:21px;line-height:1;width:100%;
	span {
		display:block;font-size:10px;box-sizing:border-box;border:solid;border-width:1px;line-height:1;padding:0 9px;font-weight:500;line-height:17px;
		&.type1 {color:#000;border-color:#000;}
		&.type2 {color:var(--color-1);border-color:var(--color-1);background:#fff}
		&.type3 {color:#000;border-color:#ffcc00;background:#ffcc00}
		&.type4 {color:var(--color-r1);border-color:var(--color-r1);background:#fff}
		&.type5 {color:#fff;border-color:#ff4f4f;background:#ff4f4f;}
		&.type6 {color:#fff;border-color:transparent;background:linear-gradient(45deg, #1165e0 0%, #00a0e9 100%);}
		&.type7 {color:var(--color-3);border-color:var(--color-g1);background:var(--color-db);}
		&.type8 {
			color:#000;border-color:#000;
			&:before {content:"None";}
		}
	}
}
/*
.options {
	padding:0 0 24px;margin:0 0 23px;border-bottom:1px solid #dbdbdb;
	div {display:flex;flex-wrap:wrap;align-items:center;}
	.optionName {
		width:120px;color:#111;font-weight:500;padding:0 15px 0 0;position:relative;font-size:15px;
		&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:6px;top:2px;}
	}
	.checkbox-item {display:inline-block;border-radius:5px;border:1px solid #ccc;}
	.checkbox-item label {display:flex;align-items:center;}
	.hidden-checkbox {display:none;}
	.checkbox-item input[type="checkbox"] + .checkbox-label {padding:5px 10px;cursor:pointer;background-color:#fff;border-radius:5px;transition:background-color 0.3s, color 0.3s;}
	.checkbox-item input[type="checkbox"]:checked + .checkbox-label {background-color:#007bff;color:#fff;border-color:#007bff;}
}
*/