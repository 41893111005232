.shop-area {
	box-sizing:border-box;
	.row {margin:0;display:flex;flex-flow:row;flex-wrap:wrap;}

	/* ==============================
	* LIST
	================================= */
	.itmeDepth1Deco {
		margin:0 0 25px;
		img {display:block;max-width:100%;}
	}
	.itemUtil {
		display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;align-items:center;align-content:center;box-sizing:border-box;padding:0 0 15px 300px;border-bottom:1px solid #9b9b9b;position:relative;
		.summ {
			max-width:50%;
			> * {display:block;color:#444;line-height:1;}
			.summCount {
				font-size:1.176em;margin:0;position:absolute;left:0;top:5px;font-weight:500;
				em {font-style:normal;color:var(--color-1);}
			}
			.summCate {
				font-size:1.176em;font-weight:700;width:auto;max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;word-wrap:normal;
				span {
					box-sizing:border-box;position:relative;padding:0 13px 0 0;margin:0 8px 0 0;
					&:after {content:"";display:block;position:absolute;width:10px;height:10px;border:solid;box-sizing:border-box;border-width:0 2px 2px 0;border-color:#ccc;right:0;top:10px;transform:rotate(-45deg);}
					&:last-child {
						padding:0;margin:0;
						&:after {display:none;}
					}
				}
			}
		}
		.sns {
		}
	}
	.itemPkg {
		display:flex;flex-flow:row;position:relative;box-sizing:border-box;
		& > * {box-sizing:border-box;position:relative}
		.itemFilter {
			width:300px;position:relative;padding:0 20px 0 0;
			&:after {content:"";display:block;position:absolute;width:100%;height:20px;background:#fff;left:0;bottom:0;}
			.sidebar-style {
				position:sticky;top:0;box-sizing:border-box;height:100vh;overflow-x:hidden;overflow-y:auto;
				&::-webkit-scrollbar {width:13px;}
				&::-webkit-scrollbar-thumb {background:#ccc;border-left:10px solid #fff;}
				&::-webkit-scrollbar-track {background:#eee;border-left:10px solid #fff;}
				&::-webkit-scrollbar-button:decrement {height:10px;}
				&::-webkit-scrollbar-button:increment {height:20px;}
				&:hover::-webkit-scrollbar-thumb {background:var(--color-1);}
				.sideBarTit {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:space-between;line-height:1;padding:8px 0 9px;border-bottom:2px solid #ededed;width:100%;margin:0 40px 20px 0;
					.txt {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;
						i {color:#000;font-size:22px;margin:0 8px 0 0;}
						span {color:var(--color-1);font-weight:700;font-size:15px;}
					}
					.resetBtn {
						width:25px;height:25px;border:0;background:transparent;opacity:.5;transition:all .3s ease;
						i {display:block;font-size:20px;transition:all .3s ease;}
						&:focus-visible,
						&:hover {opacity:1;}
						&:focus-visible i,
						&:hover i {transform:rotate(360deg);color:var(--color-1);}
					}
				}
				.sideBarBody {
					box-sizing:border-box;padding:0 0 20px;
				}
			}
		}
		.itemList {
			flex:1;
			.shop-top-bar {
				display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;border:1px solid #ddd;padding:15px;
				.infoNum {
					em {font-style:normal;color:var(--color-1);font-weight:500}
				}
			}
			.prodOrder {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;height:44px;justify-content:space-between;
				.sorting {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:flex-start;
					li {
						margin:0 10px 0 0;
						&:last-child {margin:0;}
						button {
							display:block;line-height:1;font-size:14px;color:#444;font-weight:500;border-radius:999px;background:#eaeaea;border:0;padding:5px 10px 6px;overflow:hidden;box-sizing:border-box;position:relative;
							span {display:block;position:relative;z-index:1;transition:all .3s ease;}
							&:after {content:"";display:block;position:absolute;width:100%;height:100%;left:0;top:0;z-index:0;background:linear-gradient( 45deg, #1165e0 0%, #00a0e9 100%);opacity:0;transition:all .3s ease;}
							&:focus-visible span,
							&:hover span {color:#fff;}
							&:focus-visible:after,
							&:hover:after {opacity:1;}
						}
						.sort_active {color:#fff;background:linear-gradient( 45deg, #1165e0 0%, #00a0e9 100%)}
					}
				}
				.numbers {
					display:none;
					select {
						display:block;font-size:14px;border:0;height:auto;min-width:0;max-width:none;padding:5px 0;width:90px;transition:all .3s ease;background:#fff url(../img/ic_sel2.png) no-repeat right 50%;cursor:pointer;
						&:focus-visible,
						&:hover {color:var(--color-1);}
					}
				}
			}
			.shop-bottom-area {
				margin:48px 0 0;
				.row {
					> * {padding:0;transition:all .5s ease}
					&.grid {display:grid;gap:20px;margin:0;grid-template-columns:repeat(4, 1fr);box-sizing:border-box;}/* padding:30px;border:5px solid #eee;border-radius:8px; */
					&.list {
						display:block;margin:20px 0 0;
						.product-unit {
							margin:0 0 20px;padding:0 0 20px;border-bottom:1px solid #ddd;box-sizing:border-box;
							&:last-child {margin:0;padding:0;border-bottom:0}
							.product-wrap {
								display:flex;flex-flow:row;flex-wrap:wrap;box-sizing:border-box;
								.product-img {width:250px}
								.product-img > a {padding:0 0 100%}
								.product-content {flex:1;margin:0;padding:0 0 0 20px}
							}
							.shop-list-wrap {display:none}
						}
					}
				}
			}
		}
	}
	.shop-top-bar {
		display:flex;align-items:center;justify-content:space-between;
		.select-shoing-wrap {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;
			.shop-select {
				margin:0 20px 0 0;
				select {font-size:14px;min-width:143px;padding:5px;color:#606060;border:1px solid #e6e6e6}
			}
			p {
				margin:0;color:#606060;
			}
		}
		.shop-tab {
			a,
			button {
				width:30px;height:30px;margin:0 0 0 10px;padding:0;background:none;border:1px solid #ddd;background:#fff;border-radius:3px;color:#999;transition:all .3s ease;
				i {font-size:18px}
				&:first-child {margin:0}
				&.active {color:var(--color-1)}
			}
		}
	}
	.shop-list-wrap {
		.product-wrap {
			&:hover .hover-img {visibility:inherit;transform:none;opacity:inherit}
		}
		.shop-list-content {
			h3 {
				font-size:24px;margin:0;color:#010101;
				a {
					color:#010101;
					&:hover {color:$theme-color}
				}
			}
			.product-list-price {
				margin:13px 0 22px;
				span {
					font-size:24px;line-height:1;display:inline-block;color:#fe5252;
					&.old {font-size:18px;margin-left:15px;text-decoration:line-through;color:#cfcfcf}
				}
			}
			.rating-review {
				display:flex;align-items:center;
				.product-list-rating {
					i {
						font-size:17px;margin:0 3px 0;color:#5f5d5d;
						&.yellow {color:#ffa900}
					}
				}
				a {
					font-size:15px;line-height:1;position:relative;margin-left:40px;color:#ffcf75;
					&:before {position:absolute;top:0;left:-21px;width:1px;height:15px;content:"";background-color:#d1d1d1}
				}
			}
			p {
				font-size:15px;line-height:28px;margin:15px 0 40px;color:#8f8f8f;
			}
			.shop-list-btn {
				a,
				button {
					font-size:14px;font-weight:500;line-height:1;z-index:1;display:inline-block;padding:14px 32px 15px;text-transform:uppercase;color:#fff;border:1px solid transparent;background-color:#343538;
					&:hover {border:1px solid $theme-color}
				}
			}
		}
		&:hover .product-wrap .hover-img {visibility:visible;transform:translate(-50%, 0);opacity:1}
	}
	&.noResult {
		.itemUtil {padding:0;}
		.shop-top-bar {display:none;}
		.noDataWrap {display:flex;padding:100px 0;}
	}
	@media all and (max-width:1399px) {
		.itemUtil {
			padding:0 0 0 250px;
		}
		.itemPkg {
			.itemFilter {
				width:250px;padding:0 20px 0 0;
			}
			.itemList {
				.shop-bottom-area {
					.row {
						&.grid {gap:20px;}
					}
				}
			}
		}
	}
	@media all and (max-width:1199px) {
		.itemPkg {
			.itemList {
				.shop-bottom-area {
					.row {
						&.grid {grid-template-columns:repeat(3, 1fr);}
					}
				}
			}
		}
	}
	@media all and (max-width:899px) {
		.itemUtil {
			padding:0 0 10px;align-items:center;align-content:center;
			.summ {
				display:flex;flex-flow:row;flex-wrap:wrap;padding:0;
				.summCount {position:relative;left:auto;top:auto;font-size:1em;margin:0 0 0 10px;order:2;}
				.summCate {font-size:1em;order:1;}
			}
			.sns {margin:0;}
		}
		.itemPkg {
			display:block;
			.itemFilter {
				width:100%;padding:0;
				.sidebar-style {
					height:auto;
					.sideBarTit {margin:0 0 10px;}
				}
			}
			.itemList {
				.prodOrder {height:auto;}
				.shop-bottom-area {
					margin:20px 0 0;
					.row {
						&.grid {grid-template-columns:repeat(3, 1fr);}
					}
				}
			}
		}
	}
	@media all and (max-width:799px) {
		.itemUtil {
			padding:0 0 10px;
			.summ {
				display:flex;flex-flow:row;flex-wrap:wrap;padding:0;order:2;width:100%;
				.summCount {position:relative;left:auto;top:auto;font-size:1em;margin:0 0 0 10px;order:2;}
				.summCate {font-size:1em;order:1;}
			}
			.sns {margin:0 0 10px;order:1;width:100%;}
		}
	}
	@media all and (max-width:599px) {
		.itemPkg {
			.itemList {
				.shop-bottom-area {
					margin:15px 0 0;
					.row {
						&.grid {grid-template-columns:repeat(2, 1fr);}
					}
				}
			}
		}
	}
	@media all and (max-width:399px) {
		.itemPkg {
			.itemList {
				.shop-bottom-area {
					.row {
						&.grid {row-gap:20px;column-gap:10px}
					}
				}
			}
		}
	}

	/* ==============================
	* VIEW
	================================= */
	.itemLineage {
		display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;padding:0 0 30px;
		ul.lineage {
			display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;
			li {
				box-sizing:border-box;padding:0 15px 0 0;margin:0 5px 0 0;position:relative;
				&:after {content:"";display:block;position:absolute;width:8px;height:8px;box-sizing:border-box;border:solid;border-width:0 1px 1px 0;border-color:#767676;right:0;top:50%;transform:rotate(-45deg) translateY(-50%);}
				&:last-child {padding:0;margin:0;}
				&:last-child:after {display:none;}
				span {
					font-size:14px;color:#767676;line-height:1;
					&.no-wrap {white-space: nowrap;max-width:100%;}
				}
				a {
					display:flex;flex-wrap:wrap;align-items:center;justify-content:center;width:auto;height:auto;line-height:1;transition:all .3s ease;
					&:hover,
					&:focus-visible {color:var(--color-1);}
				}
			}
		}
	}
	.box-img {
		width:500px;box-sizing:border-box;padding:0;
		.product-manufacture {
			background:linear-gradient( to right, #00a0e9 0%, #212dd7 100% );border-radius:10px;padding:1px;margin:0 0 20px;
			.btn {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:space-between;height:auto;background:#fff;border-radius:8px;padding:15px 29px 15px;transition:all .3s ease;
				* {line-height:1;box-sizing:border-box;transition:all .3s ease;}
				span {font-size:13px;color:#767676;}
				> div {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;}
				.manufacture-box {display:flex;gap:30px;align-items:center;}
				.nation {
					display:flex;align-items:center;
					img {box-sizing:border-box;width:24px;height:15px;border:1px solid #ddd;background:#fff;margin:0 11px 0 0;}
				}
				.factory {
					margin:0 auto 0 34px;
					i {font-size:24px;margin:0 10px 0 0;position:relative;top:1px;}
				}
				.btnMark {
					span {font-size:15px;}
					i {font-size:24px;position:relative;top:1px;}
				}
				&:focus-visible,
				&:hover {
					background:transparent;
					* {color:#fff;}
				}
			}
		}
		.product-large-image-wrapper {
			position:relative;width:100%;box-sizing:border-box;border:1px solid #ddd;border-radius:8px;overflow:hidden;
			.product-img-badges {
				position:absolute;z-index:3;top:20px;left:20px;
				span {
					font-size:13px;font-weight:500;line-height:1;display:block;margin-bottom:10px;padding:3px 11px;color:#fff;border-radius:3px;
					&.pink {background-color:$theme-color;}
					&.purple {background-color:$theme-color;}
				}
			}
			.single-galleryCast {
				position:absolute;top:20px;right:20px;z-index:1;
				button {
					width:40px;height:40px;line-height:1;font-size:30px;padding:0;border:none;background:none;
					i {display:block;transform:rotate(45deg);transition:all .3s ease;}
					&:focus-visible i,
					&:hover i {transform:rotate(135deg);color:var(--color-1)}
				}
			}
			.single-image {
				display:block;height:0;padding:0 0 100%;box-sizing:border-box;background:#fafafa;position:relative;z-index:0;
				video,
				img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease}
				video {width:100%;height:100%;}
			}
			.option-image {
				display:none;position:absolute;z-index:1;width:100%;height:100%;left:0;top:0;background:#fafafa;
				img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
				&.on {display:block;}
			}
		}
		.product-small-image-wrapper {
			box-sizing:border-box;padding:0 95px;position:relative;margin:37px 0 0;
			&:hover,
			&:focus-visible {
				.ht-swiper-button-nav {visibility:visible;opacity:1;}
			}
			.swiperOutWrap {
				.swiper-container {
					.swiper-wrapper {
						.single-image {
							border:1px solid #dbdbdb;overflow:hidden;border-radius:10px;cursor:pointer;height:100%;
							.imgContainer {
								display:block;height:0;padding:0 0 100%;box-sizing:border-box;position:relative;background:#eee;
								img,
								video {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;transition:all .3s ease;object-fit:cover;object-position:center;}
								&.on {border-color:#212dd7;}
							}
							&.on {border:3px solid var(--color-1);}
						}
					}
				}
			}
			.swiperArrow {
				display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:center;position:absolute;width:30px;height:30px;top:50%;transform:translateY(-50%);border:0;background:transparent;font-size:26px;line-height:1;border-radius:5px;transition:all .3s ease;
				&.swiperPrev {left:20px;}
				&.swiperNext {right:20px;}
				&.swiper-button-disabled {opacity:.5;cursor:default}
				&:not(.swiper-button-disabled):focus-visible,
				&:not(.swiper-button-disabled):hover {color:var(--color-1);background:#eee;}
			}
		}
	}
	.box-cont {
		flex:1;min-width:0;box-sizing:border-box;padding:0 0 0 20px;
		.product-details-content {
			.contBorder {
				box-sizing:border-box;border-radius:8px;border:1px solid #c0c0c0;padding:23px 31px 32px;
				.pro-details-rating-wrap {
					display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;justify-content:space-between;border-bottom:1px solid var(--color-1);padding:0 0 10px;margin:0 0 20px;
					.pro-details-rating {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;margin:0;
						* {line-height:1;}
						.star-ratings {
							display:flex !important;flex-flow:row;flex-wrap:wrap;line-height:1;top:auto;
							.star-container {
								display:flex !important;flex-flow:row;flex-wrap:wrap;
							}
						}
						.star-txts {
							display:flex;flex-flow:row;flex-wrap:wrap;color:#111;font-size:.941em;margin:-1px 0 0 3px;
							.starScore {}
							.saleQty {}
						}
					}
					.pro-details-share {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;
						.cast {
							display:block;width:30px;height:30px;font-size:24px;color:#000;line-height:1;border:0;background:transparent;position:relative;
							i {
								display:block;line-height:1;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;width:24px;height:24px;transition:all .3s ease;
							}
							.over {z-index:1;color:var(--color-1);opacity:0;}
							.base {z-index:0;}
							&:focus-visible .base,
							&:hover .base {color:var(--color-1);}
							&.on {
								.over {opacity:1;}
								.base {opacity:0;}
							}
						}
						.bookmark {
							.ri-heart-3-line{
								&:focus-visible,
								&:hover {color:#f00;}
							}
							.ri-heart-3-fill{
							}
						}
						.share {
							display: none;
						}
					}
				}
				.product-details-title {
					padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #dbdbdb;
					.nameBrand {display:block;color:#111;font-size:13px;line-height:1.2;margin:0 0 5px;}
					.nameProd {display:block;color:#111;font-size:1.412em;font-weight:700;line-height:1.2;word-wrap:break-word;word-break:keep-all;white-space:normal;}
					.util {
						box-sizing:border-box;position:relative;padding:0 100px 0 0;margin:10px 0 0;
						* {box-sizing:border-box;line-height:1;text-align:left;}
						.copy {
							flex:1;padding:4px 0 0;
							button {
								display:block;width:auto;max-width:100%;position:relative;border:0;background:transparent;padding:0 0 0 20px;color:#767676;transition:all .3s ease;
								i {display:block;position:absolute;left:0;top:0px;font-size:13px;}
								span {display:block;font-size:13px;width:100%;word-wrap:break-word;word-break:keep-all;white-space:normal}
								&:focus-visible,
								&:hover {color:var(--color-1);}
							}
						}
						.sampleOrder {
							position:absolute;right:0;top:0;
							> * {
								display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:right;border:0;background:transparent;
								span {font-size:15px;color:#111;}
								i {font-size:20px;margin:0 0 -2px;}
							}
						}
					}
				}
				.product-details-price {
					display:flex;flex-flow:row;flex-wrap:wrap;padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #dbdbdb;justify-content:space-between;
					.numbers {
						box-sizing:border-box;padding:0 20px 0 0;min-width:0;flex:1;display:flex;flex-flow:row;flex-wrap:wrap;
						* {line-height:1;box-sizing:border-box;position:relative;}
						.prices {
							display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;width:100%;margin:0 0 10px;
							.priceDiscount {font-size:1.647em;color:#111;font-weight:700;margin:0 15px 0 0;}
							.priceDcPercenter {color:#f44;margin:0 10px 0 0;}
							.priceOriginal {text-decoration:line-through;}
						}
						dl {
							display:flex;flex-flow:row;flex-wrap:wrap;font-size:13px;margin:0 10px 0 0;padding:0 10px 0 0;
							&:after {content:"";display:block;position:absolute;width:2px;height:12px;background:#ccc;right:0;top:1px;transform:rotate(20deg);}
							&:last-child {padding:0;margin:0;}
							&:last-child:after {display:none}
							dt {font-weight:400;padding:0 10px 0 0;margin:0 7px 0 0;}
							dt:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:0;top:0px;}
							dd {font-weight:400}
						}
					}
					.sampleOrder {}
				}
				.product-details-list {
					padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #dbdbdb;
					* {box-sizing:border-box;position:relative;line-height:1.2;}
					> ul {
						display:block;
						> li {
							display:block;margin:0 0 10px;
							&:last-child {margin:0;}
						}
					}
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;
						dt,
						dd {font-size:15px;word-wrap:break-word;word-break:keep-all;white-space:normal;}
						dt {
							width:120px;color:#111;font-weight:500;padding:0 15px 0 0;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:6px;top:3px;}
						}
						dd {
							flex:1;min-width:0;
							.listCate,
							.listTags,
							.listOptions {
								display:flex;flex-flow:row;flex-wrap:wrap;margin:-3px;
								li {
									padding:3px;box-sizing:border-box;
									a {
										display:block;border-radius:999px;box-sizing:border-box;border:1px solid #ddd;background:#f5f5f5;padding:2px 5px 3px;font-size:13px;line-height:1;font-weight:500;transition:all .3s ease;
										span {display:block;line-height:1;}
										&:focus-visible,
										&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
									}
								}
								&.listOptions {
									margin-top:-8px;
									> li {
										&.hasImg {margin-right:5px;}
										&.hasntImg {margin-right:20px;}
										.rdos {
											display:flex;flex-flow:row;flex-wrap:wrap;
											input {opacity:0;}
											label {
												.txtContainer {
													white-space:nowrap;
												}
											}
											&.hasntImg {
											}
											&.hasImg {
												label {
													display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;padding:3px 5px 3px 3px;box-sizing:border-box;border:1px solid #ddd;border-radius:3px;margin:0;
													&:before,
													&:after {display:none;}
													&:hover,
													&:focus-visible {border-color:var(--color-1);}
													.imgContainer {
														width:40px;height:40px;box-sizing:border-box;position:relative;
														img {display:block;position:absolute;width:auto;height:auto;max-width:100%;max-height:100%;top:0;right:0;bottom:0;left:0;margin:auto;}
													}
													.txtContainer {
														flex:1;min-width:0;margin:0 0 0 5px;
													}
												}
												input[type="radio"]:checked + label {border-color:var(--color-1);color:var(--color-1);background:#f1f6fd;}
											}
										}
									}
								}
							}
							&.hasCates {
								ul {
									display:flex;flex-flow:row;flex-wrap:wrap;
									> li {
										padding:0 15px 0 0;margin:0 10px 0 0;box-sizing:border-box;position:relative;
										&:last-child {
											padding:0;margin:0;
											&:after {display:none;}
										}
										&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#aaa;transform:rotate(-45deg);right:0;top:4px;}
										a {
											transition:all .3s ease;
											&:hover,
											&:focus-visible {color:var(--color-1);text-decoration:underline;}
										}
									}
								}
							}
						}
					}
					&.options {
						padding:0 0 17px;
						dl {
							dd {
								ul {
									max-height:250px;overflow-x:hidden;overflow-y:auto;
									&::-webkit-scrollbar {width:13px}
									&::-webkit-scrollbar-thumb {background:#ccc;border-left:5px solid #fff;border-right:5px solid #fff;}
									&::-webkit-scrollbar-track {background:#eee;border-left:5px solid #fff;border-right:5px solid #fff;}
									&::-webkit-scrollbar-button {height:5px}
									.rdos.hasntImg {
										label {padding:5px 0 3px 25px;}
									}
								}
							}
						}
					}
				}
				.product-details-quantity {
					padding:0 0 20px;margin:0 0 20px;border-bottom:1px solid #dbdbdb;
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
						dt,
						dd {font-size:15px;box-sizing:border-box;position:relative;}
						dt {
							width:120px;color:#111;font-weight:500;padding:0 15px 0 0;
							&:after {content:":";display:block;position:absolute;width:auto;height:auto;line-height:1;font-size:12px;right:6px;top:2px;}
						}
						dd {
							.cart-plus-minus {
								display:flex;flex-flow:row;flex-wrap:wrap;
								.qtybutton {
									width:40px;height:35px;box-sizing:border-box;position:relative;border:1px solid #dbdbdb;background:#fff;font-weight:500;transition:all .3s ease;
									i {font-size:15px;}
									&.dec {border-radius:999px 0 0 999px;}
									&.inc {border-radius:0 999px 999px 0;}
									&:focus-visible,
									&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
									&:disabled {
										color: #666;
										background-color: #ccc;
										border:#ccc;
										cursor: default;
										&:hover {
											background:#ccc;border-color:#ccc;
											&:before,
											&:after {background:#666;;}
										}
									}
								}
								.qtyNum {width:78px;height:35px;text-align:center;padding:0 10px;border:solid;border-width:1px 0;border-color:#dbdbdb;background:#fff;font-size:13px;}
							}
						}
					}
					.alertMessage {
						display:flex;flex-flow:row;flex-wrap:wrap;justify-content:right;margin:10px 0 0;
						span {font-size:15px;letter-spacing:-.05em;font-weight:500;}
						.remain {color:#000;}
						.deny {color:#a00;}
					}
				}
				.product-details-total {
					dl {
						display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:space-between;
						* {box-sizing:border-box;line-height:1;}
						dt,
						dd {color:#111;}
						dt {font-weight:500;}
						dd {
							.num {letter-spacing:0;}
							.unit {font-size:.882em;font-weight:500;margin:0 0 0 3px;}
						}
						&.priceDc {
							dt {font-size:1.059em;}
							dd {
								.num {font-size:1.412em;}
							}
						}
						&.priceTotal {
							margin:15px 0 0;
							dt {font-size:1.294em;}
							dd {
								.num {font-size:1.647em;color:var(--color-1);font-weight:500;}
							}
						}
					}
				}
			}
			.contBtns {
				display:flex;flex-flow:row;flex-wrap:wrap;justify-content:space-between;margin:20px 0 0;
				> * {width:calc( 50% - 5px );display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;border-radius:999px;box-sizing:border-box;border:solid;border-width:1px;height:56px;line-height:1;font-size:1.118em;transition:all .3s ease;}
				.cart {
					border-color:var(--color-1);color:var(--color-1);background:#fff;
					&.roleBtn:focus-visible,
					&.roleBtn:hover {background:var(--color-1);color:#fff;}
				}
				.checkout {
					border-color:var(--color-1);color:#fff;background:var(--color-1);
					&.roleBtn:focus-visible,
					&.roleBtn:hover {background:var(--color-2);}
				}
				.chat {
					border-color:var(--color-1);font-size:1.412em;background:#fff;color:var(--color-1);display:none;
					&:focus-visible,
					&:hover {background:var(--color-1);color:#fff;}
				}
				&.notLogged {
					display:block;
					.cart {width:100%;}
				}
			}
			.soldout {
				grid-template-columns:1fr 56px;
				> * {display:flex;flex-flow:row;flex-wrap:wrap;align-items:center;align-content:center;justify-content:center;border-radius:999px;box-sizing:border-box;border:solid;border-width:1px;height:56px;line-height:1;font-size:1.118em;transition:all .3s ease;}
				.checkout {
					border-color: #dbdbdb;
					background-color: #dbdbdb;
				}
			}
		}
	}
	@media all and (max-width:1299px) {
		.itemLineage {
			padding:0 0 20px;
		}
		.box-img {
			width:450px;
			.product-large-image-wrapper {
				.single-galleryCast {
					top:15px;right:15px;
				}
			}
			.product-small-image-wrapper {
				padding:0 30px;margin:20px 0 0;
				.swiperArrow {
					&.swiperPrev {left:-5px;}
					&.swiperNext {right:-5px;}
				}
			}
		}
		.box-cont {
			.product-details-content {
				.contBorder {
					padding:15px 20px 20px;
					.product-details-list {
						dl {
							dt {width:100px;}
						}
					}
					.product-details-quantity {
						dl {
							dt {width:100px;}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1199px) {
		.box-img {
			width:420px;
		}
		.box-cont {
			.product-details-content {
				.contBorder {
					.product-details-title {
						.nameProd {font-size:1.3em;}
					}
					.product-details-price {
						.prices {
							.priceDiscount {font-size:1.3em;;margin:0 10px 0 0;}
						}
					}
					.product-details-total {
						dl {
							&.priceTotal {
								dt {font-size:1.3em;}
								dd {
									.num {font-size:1.3em;}
								}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1099px) {
		.box-img {
			width:auto;flex:.4;min-width:0;
		}
		.box-cont {
			flex:.6;
			.product-details-content {
				.contBorder {
					.product-details-list {
						dl {
							dd {
								.listOptions {
									li {margin-right:15px;}
								}
							}
						}
					}
				}
			}
		}
	}
	@media all and (max-width:999px) {
		.itemLineage {
			ul.lineage {
				order:2;width:100%;justify-content:center;margin:5px 0 0;
				li {
					margin-bottom:5px;
					&:last-child {margin-bottom:5px;}
				}
			}
			ul.snsShare {order:1;width:100%;justify-content:center;margin:0 0 5px;}
		}
	}
	@media all and (max-width:899px) {
		.row {margin:0;display:block;}
		.box-img {
			display:block;max-width:450px;margin:0 auto 20px;
			.product-small-image-wrapper {margin:10px 0 0;}
		}
		.box-cont {
			display:block;padding:0;
			.product-details-content {
				.contBorder {
					.pro-details-rating-wrap {padding:0 0 10px;margin:0 0 10px;}
					.product-details-title {padding:0 0 10px;margin:0 0 10px;}
					.product-details-price {padding:0 0 10px;margin:0 0 10px;}
					.product-details-list {
						padding:0 0 10px;margin:0 0 10px;
						&.options {
							dl {
								dd {
									.listOptions {
										max-height:200px;margin-top:-5px;
										> li {
											.rdos {
												label {
													padding:3px 0 3px 20px;
													&:before {top:3px;}
													&:after {top:7px;}
												}
											}
										}
									}
								}
							}
						}
					}
					.product-details-quantity {padding:0 0 10px;margin:0 0 10px;}
					.product-details-total {
					}
				}
			}
		}
	}
	@media all and (max-width:499px) {
		.box-cont {
			.product-details-content {
				.contBorder {
					padding:0;border:0;
					.product-details-list,
					.product-details-quantity {
						padding:0 0 10px;margin:0 0 10px;
						&.options {
							dl {
								dd {
									.listOptions {margin-top:0;}
								}
							}
						}
						dl {
							display:block;
							dt,
							dd {display:block;line-height:1.2;width:100%;}
							dt {
								margin:0 0 5px;padding:0;
								&:after {display:none;}
							}
							dd {
								.cart-plus-minus {
									width:100%;
									.qtyNum {width:auto;flex:1;min-width:0;}
								}
							}
						}
					}
					.product-details-price {
						.prices {margin:0 0 5px;}
					}
				}
				.contBtns {
					grid-template-columns:1fr 45px;gap:10px;margin:15px 0 0;
					> * {height:45px;}
					.cart {grid-row:1;grid-column:1;}
					.checkout {grid-row:2;grid-column:1 / span 2;}
					.chat {grid-row:1;grid-column:2;}
				}
			}
		}
	}
}

/* ==============================
* Pagination
================================= */
.pro-pagination-style {
	margin:40px 0 0;
	ul {
		display:flex;flex-flow:row;flex-wrap:wrap;justify-content:center;align-items:center;align-content:center;
		li {
			margin:0 3px;
			a,
			button {
				display:block;font-size:12px;width:40px;height:40px;line-height:38px;padding:0;text-align:center;color:#000 !important;border:1px solid #cbcbcb;;background:none;font-weight:500;transition:all .3s ease;
			}
			&:not(.disabled) {
				> *:focus-visible,
				> *:hover {color:#fff !important;background:var(--color-1);border-color:var(--color-1);}
				> *:focus-visible:after,
				> *:hover:after {color:#fff !important;}
			}
			&.active {
				color:#fff !important;background:#3e3e3e;
			}
			&.disabled {
				opacity:.5;
				* {cursor:default}
			}
			&.page-item {
				&.active {
					a {border-color:#3e3e3e;background:#3e3e3e;color:#fff !important;cursor:default;}
				}
				.page-link {
					box-shadow:0 0 12px 0.8px rgba(0, 0, 0, 0.1) !important;
					&:focus {box-shadow:none}
				}
			}
			&.break-me {
				a {
					font-size:0;
					&:after {content:"\ef79";font-family:"remixicon" !important;color:#9e9e9e;font-size:24px;}
				}
			}
			&.previous,
			&.next {
				a {
					font-size:0;
					&:after {font-family:"remixicon" !important;color:#9e9e9e;font-size:24px;}
				}
				&.previous {
					a:after {content:"\ea64";}
				}
				&.next {
					a:after {content:"\ea6e";}
				}
			}
		}
	}
	.prodListMore {
		display:flex;flex-flow:row;flex-wrap:wrap;width:400px;height:60px;align-items:center;align-content:center;justify-content:center;border-radius:3px;border:1px solid var(--color-db);background:#fff;margin:50px auto 0;transition:all .3s ease;
		span {
			box-sizing:border-box;position:relative;padding:0 20px 0 0;font-size:.941em;line-height:1;font-weight:500;color:#000;transition:all .3s ease;
			&:after {content:"";display:block;position:absolute;width:10px;height:10px;box-sizing:border-box;border:solid;border-width:0 2px 2px 0;border-color:#000;transform:rotate(45deg);right:0;top:2px;transition:all .3s ease;}
		}
		&:hover,
		&:focus-visible {
			border-color: var(--color-1);
			span {
				color:var(--color-1);
				&:after {border-color: var(--color-1);}
			}
		}
		&.clicked span:after { transform: rotate(-135deg); top: 6px; }
	}
}

.product-option-quantity {
	display:flex; flex-direction:column; align-items:flex-start; gap:3;
	li {border:1px solid #ccc; border-radius:100px;padding:10px;}
	.option-box{display:flex;flex-direction:column;}
	.cart-plus-minus {
		display:flex;flex-flow:row;flex-wrap:wrap;
		.qtybutton {
			width:40px;height:35px;box-sizing:border-box;position:relative;border:1px solid #dbdbdb;background:#fff;font-weight:500;transition:all .3s ease;
			& i {font-size:15px;}
			&.dec {border-radius:999px 0 0 999px;}
			&.inc {border-radius:0 999px 999px 0;}
			&:focus-visible,
			&:hover {background:var(--color-1);border-color:var(--color-1);color:#fff;}
			&:disabled {
				color: #666;
				background-color: #ccc;
				border:#ccc;
				cursor: default;
				&:hover {
					background:#ccc;border-color:#ccc;
					&:before,
					&:after {background:#666;;}
				}
			}
		}
		.qtyNum {width:78px;height:35px;text-align:center;padding:0 10px;border:solid;border-width:1px 0;border-color:#dbdbdb;background:#fff;font-size:13px;}
	}
}